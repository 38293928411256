import { Entity, EntityData, EntityFieldKey, EntityModel } from '@typescript-kit/data';
import { Big } from 'big.js';
import { hasValue } from '@typescript-kit/core';

export class SalaryRecordFieldKey extends EntityFieldKey {
  static readonly COMPANY_ID = 'companyId';
  static readonly EMPLOYEE_ID = 'employeeId';
  static readonly DATE = 'date';
  static readonly HOUR_RATE = 'hourRate';
  static readonly SALARY = 'salary';
  static readonly HOLIDAY_SUBVENTION = 'holidaySubvention';
  static readonly CHRISTMAS_REMUNERATION = 'christmasRemuneration';
  static readonly HOLIDAY_COMPENSATION = 'holidayCompensation';
  static readonly TRAVEL_COSTS_REMUNERATION = 'travelCostsRemuneration';
  static readonly TRAVEL_COSTS_COMPENSATION = 'travelCostsCompensation';
  static readonly SALARY_TRANSFORMATION = 'salaryTransformation';
  static readonly NON_CASH_BENEFITS_CAR = 'nonCashBenefitsCar';
  static readonly NON_CASH_BENEFITS_OTHER = 'nonCashBenefitsOther';
}

export interface SalaryRecordData extends EntityData {
  companyId?: string;
  employeeId?: string;
  date?: string;
  hourRate?: string;
  salary?: string;
  holidaySubvention?: string;
  christmasRemuneration?: string;
  holidayCompensation?: string;
  travelCostsRemuneration?: string;
  travelCostsCompensation?: string;
  salaryTransformation?: string;
  nonCashBenefitsCar?: string;
  nonCashBenefitsOther?: string;
}

// noinspection DuplicatedCode
export class SalaryRecord extends Entity implements SalaryRecordData {
  readonly companyId: string;
  readonly employeeId: string;
  readonly date: string;
  readonly hourRate: string;
  readonly salary: string;
  readonly holidaySubvention: string;
  readonly christmasRemuneration: string;
  readonly holidayCompensation: string;
  readonly travelCostsRemuneration: string;
  readonly travelCostsCompensation: string;
  readonly salaryTransformation: string;
  readonly nonCashBenefitsCar: string;
  readonly nonCashBenefitsOther: string;

  constructor(data: SalaryRecordData) {
    super(data);
    this.companyId = data.companyId;
    this.employeeId = data.employeeId;
    this.date = data.date;
    this.hourRate = data.hourRate;
    this.salary = data.salary;
    this.holidaySubvention = data.holidaySubvention;
    this.christmasRemuneration = data.christmasRemuneration;
    this.holidayCompensation = data.holidayCompensation;
    this.travelCostsRemuneration = data.travelCostsRemuneration;
    this.travelCostsCompensation = data.travelCostsCompensation;
    this.salaryTransformation = data.salaryTransformation;
    this.nonCashBenefitsCar = data.nonCashBenefitsCar;
    this.nonCashBenefitsOther = data.nonCashBenefitsOther;
  }
}


export class SalaryRecordModel extends EntityModel implements SalaryRecordData {
  static readonly KEYS = EntityModel.KEYS.concat(
    Object.keys(SalaryRecordFieldKey).map((key) => SalaryRecordFieldKey[key])
  );

  constructor(data: SalaryRecordData) {
    super(data, SalaryRecordModel.KEYS);
  }

  get companyId(): string {
    return this.get(SalaryRecordFieldKey.COMPANY_ID);
  }

  get employeeId(): string {
    return this.get(SalaryRecordFieldKey.EMPLOYEE_ID);
  }

  get date(): string {
    return this.get(SalaryRecordFieldKey.DATE);
  }

  get hourRate(): string {
    return this.get(SalaryRecordFieldKey.HOUR_RATE);
  }

  set hourRate(value: string) {
    this.set(SalaryRecordFieldKey.HOUR_RATE, value);
  }

  get salary(): string {
    return this.get(SalaryRecordFieldKey.SALARY);
  }

  set salary(value: string) {
    this.set(SalaryRecordFieldKey.SALARY, value);
  }

  get holidaySubvention(): string {
    return this.get(SalaryRecordFieldKey.HOLIDAY_SUBVENTION);
  }

  set holidaySubvention(value: string) {
    this.set(SalaryRecordFieldKey.HOLIDAY_SUBVENTION, value);
  }

  get christmasRemuneration(): string {
    return this.get(SalaryRecordFieldKey.CHRISTMAS_REMUNERATION);
  }

  set christmasRemuneration(value: string) {
    this.set(SalaryRecordFieldKey.CHRISTMAS_REMUNERATION, value);
  }

  get holidayCompensation(): string {
    return this.get(SalaryRecordFieldKey.HOLIDAY_COMPENSATION);
  }

  set holidayCompensation(value: string) {
    this.set(SalaryRecordFieldKey.HOLIDAY_COMPENSATION, value);
  }

  get travelCostsRemuneration(): string {
    return this.get(SalaryRecordFieldKey.TRAVEL_COSTS_REMUNERATION);
  }

  set travelCostsRemuneration(value: string) {
    this.set(SalaryRecordFieldKey.TRAVEL_COSTS_REMUNERATION, value);
  }

  get travelCostsCompensation(): string {
    return this.get(SalaryRecordFieldKey.TRAVEL_COSTS_COMPENSATION);
  }

  set travelCostsCompensation(value: string) {
    this.set(SalaryRecordFieldKey.TRAVEL_COSTS_COMPENSATION, value);
  }

  get salaryTransformation(): string {
    return this.get(SalaryRecordFieldKey.SALARY_TRANSFORMATION);
  }

  set salaryTransformation(value: string) {
    this.set(SalaryRecordFieldKey.SALARY_TRANSFORMATION, value);
  }

  get nonCashBenefitsCar(): string {
    return this.get(SalaryRecordFieldKey.NON_CASH_BENEFITS_CAR);
  }

  set nonCashBenefitsCar(value: string) {
    this.set(SalaryRecordFieldKey.NON_CASH_BENEFITS_CAR, value);
  }

  get nonCashBenefitsOther(): string {
    return this.get(SalaryRecordFieldKey.NON_CASH_BENEFITS_OTHER);
  }

  set nonCashBenefitsOther(value: string) {
    this.set(SalaryRecordFieldKey.NON_CASH_BENEFITS_OTHER, value);
  }

  protected setupValues(values: SalaryRecordData, exclusive: boolean) {
    this.setupInputValue(SalaryRecordFieldKey.HOUR_RATE, values, exclusive);
    this.setupInputValue(SalaryRecordFieldKey.SALARY, values, exclusive);
    this.setupInputValue(SalaryRecordFieldKey.HOLIDAY_SUBVENTION, values, exclusive);
    this.setupInputValue(SalaryRecordFieldKey.CHRISTMAS_REMUNERATION, values, exclusive);
    this.setupInputValue(SalaryRecordFieldKey.HOLIDAY_COMPENSATION, values, exclusive);
    this.setupInputValue(SalaryRecordFieldKey.TRAVEL_COSTS_REMUNERATION, values, exclusive);
    this.setupInputValue(SalaryRecordFieldKey.TRAVEL_COSTS_COMPENSATION, values, exclusive);
    this.setupInputValue(SalaryRecordFieldKey.SALARY_TRANSFORMATION, values, exclusive);
    this.setupInputValue(SalaryRecordFieldKey.NON_CASH_BENEFITS_CAR, values, exclusive);
    this.setupInputValue(SalaryRecordFieldKey.NON_CASH_BENEFITS_OTHER, values, exclusive);
    return super.setupValues(values, exclusive) as SalaryRecordData;
  }

  protected setupInputValue(key: string, values: SalaryRecordData, exclusive: boolean) {
    if (!(key in values) && !exclusive) {
      return;
    }
    values[key] = hasValue(values[key]) ? new Big(values[key]).toFixed(2) : null;
  }
}
