import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@angular-kit/core';
import { ViewModule } from '@angular-kit/view';
import { BsViewModule } from '@angular-kit/bs-view';

import { LogbookRoutingModule } from './logbook-routing.module';
import { LogbookInitializer } from './logbook.initializer';
import { LogbookContentComponent } from './component/logbook-content/logbook-content.component';
import { LogbookService } from '@teamworks/global';
import { LogbookEntryService } from './service/logbook-entry.service';

@NgModule({
  declarations: [
    LogbookContentComponent
  ],
  providers: [
    LogbookService,
    LogbookEntryService,
  ],
  imports: [
    CommonModule,
    CoreModule,
    ViewModule,
    BsViewModule,
    LogbookRoutingModule
  ],
})
export class LogbookModule {
  constructor() {
    LogbookInitializer.initialize();
  }
}
