import { getJsonExport, LocaleProvider } from '@typescript-kit/core';
import * as texts from './text/salary.de.text.json';

export class SalaryInitializer {

  static initialize() {
    SalaryInitializer.registerLocales();
  }

  static registerLocales() {
    LocaleProvider.registerPackageLocale('de', 'salary', {
      texts: getJsonExport(texts)
    });
  }
}
