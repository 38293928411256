import { Injectable } from '@angular/core';
import { HttpService } from '@angular-kit/core';
import { SalaryOverviewRecordData } from '@teamworks/global';
import { SalaryRecordData } from '@teamworks/global';
import { AuthenticationService } from '@angular-kit/authentication';

@Injectable()
export class SalaryService {

  constructor(
    private httpService: HttpService,
    private authenticationService: AuthenticationService,
  ) { }

  async loadSalaryOverviewRecordList(year: string, month: string): Promise<SalaryOverviewRecordData[]> {
    const encodedYear = encodeURIComponent(year);
    const encodedMonth = encodeURIComponent(month);
    return this.httpService.get(`api/salary/salary-overview-record/${encodedYear}/${encodedMonth}`);
  }

  async saveSalaryRecord(data: SalaryRecordData) {
    return this.httpService.post(`api/salary/salary-record`, data);
  }

  async downloadSalaryOverviewAsCSV(year: string, month: string): Promise<void> {
    const authorization = this.authenticationService.authenticationToken;
    const encodedYear = encodeURIComponent(year);
    const encodedMonth = encodeURIComponent(month);
    const query = `authorization=${authorization}`;
    window.open(`api/salary/salary-overview-csv/${encodedYear}/${encodedMonth}?${query}`);
  }

}
