import moment from 'moment';
import { Injectable } from '@angular/core';
import { ApplicationError, ChangedEvent, hasValue, ObservableList, ObservableMap, ObserveModes } from '@typescript-kit/core';
import { PersistenceService } from '@angular-kit/core';
import { ViewService } from '@angular-kit/view';
import { TimeRecordingViewFieldKey, TimeRecordingViewModel } from '../view-model/time-recording.view-model';
import { TimeRecordingPersistenceKey } from '../key/time-recording-persistence.key';
import {
  BalanceModel,
  BalanceTypes,
  Carryover,
  CarryoverModel,
  Comment,
  CommentFieldKey,
  CommentModel,
  Project,
  ProjectData,
  PublicHoliday,
  SharedPermissionKey,
  TargetHours,
  TargetHoursData,
  TargetHoursModel,
  TimeRecord,
  TimeRecordData,
  TimeRecordModel,
  TimeRecordTypes,
  WeekDays,
  WorkingHoursRecord,
  WorkingHoursRecordData,
  WorkingHoursRecordFieldKey,
  WorkingHoursRecordModel
} from '@teamworks/global';
import { WorkingHoursService } from '../service/working-hours.service';
import { ReportService } from '../service/report.service';
import { TimeRecordService } from '../service/time-record.service';
import { PublicHolidayService } from '../service/public-holiday.service';
import { CarryoverService } from '../service/carryover.service';
import { CommentService } from '../service/comment.service';
import { TimeRecordingService } from '../service/time-recording.service';
import { ShellViewService } from '../../shell/view/shell.view-service';
import { TimeRecordingSharedViewService } from './time-recording-shared.view-service';
import { BalanceService } from '../service/balance.service';
import { TargetHoursService } from '../service/target-hours.service';
import { ProjectService } from '../service/project.service';
import { EntityService } from '../../shared/service/entity.service';
import { TimeRecordingViewKey } from '../key/time-recording-view.key';
import { AuthenticationService } from '@angular-kit/authentication';
import { AuthorizationService } from '@teamworks/angular';
import { Subscription } from 'rxjs';

@Injectable()
export class EmployeeTimeRecordingSharedViewService extends ViewService<TimeRecordingViewModel, any> {
  private readonly entityService = this.injector.get(EntityService);
  private readonly persistenceService = this.injector.get(PersistenceService);
  private readonly timeRecordService = this.injector.get(TimeRecordService);
  private readonly targetHoursService = this.injector.get(TargetHoursService);
  private readonly projectService = this.injector.get(ProjectService);
  private readonly publicHolidayService = this.injector.get(PublicHolidayService);
  private readonly workingHoursService = this.injector.get(WorkingHoursService);
  private readonly carryoverService = this.injector.get(CarryoverService);
  private readonly balanceService = this.injector.get(BalanceService);
  private readonly commentService = this.injector.get(CommentService);
  private readonly timeRecordingService = this.injector.get(TimeRecordingService);
  private readonly reportService = this.injector.get(ReportService);
  private readonly authenticationService = this.injector.get(AuthenticationService);
  private readonly authorizationService = this.injector.get(AuthorizationService);

  private readonly shellViewService = this.injector.get(ShellViewService);
  private readonly sharedViewService = this.injector.get(TimeRecordingSharedViewService);

  private refreshSummaryTimeout: any;

  private userChangedSubscription: Subscription;

  initialize() {
    super.initialize(this.sharedViewService.viewModel);

    this.userChangedSubscription = this.authenticationService.currentUserChanged
      .subscribe(() => this.refreshIsProjectAdmin());
  }

  finalize() {
    super.finalize();
  }

  protected onViewModelPropertyChanged(event: ChangedEvent) {
    if (event.originalEvent !== event) {
      const originalEvent = event.originalEvent;
      if (originalEvent.source === this.viewModel.timeRecordList) {
        this.onTimeRecordListItemChanged(originalEvent);
      } else if (originalEvent.source === this.viewModel.targetHoursMap) {
        this.onTargetHoursMapItemChanged(originalEvent);
      } else if (originalEvent.source === this.viewModel.carryover) {
        this.onCarryoverPropertyChanged(originalEvent);
      } else if (originalEvent.source === this.viewModel.monthComment) {
        this.onMonthCommentPropertyChanged(originalEvent);
      } else if (originalEvent.source === this.viewModel.defaultWorkingHoursRecordList) {
        this.onDefaultWorkingHoursRecordItemChanged(originalEvent);
      }
      return;
    }
    const changes = event.changes;
    if (
      changes[TimeRecordingViewFieldKey.EMPLOYEE_ID] ||
      this.viewModel.employeeId && (
        changes[TimeRecordingViewFieldKey.YEAR] ||
        changes[TimeRecordingViewFieldKey.MONTH]
      )
    ) {
      this.shellViewService.incrementLoadingCounter();
      const loadedData: { [key: string]: any } = {};
      const loadPromises: Promise<any>[] = [
        this.loadCarryover()
          .then((carryover) => loadedData.carryover = carryover),
        this.loadTimeRecordList()
          .then((timeRecordList) => loadedData.timeRecordList = timeRecordList),
        this.loadTargetHoursList()
          .then((targetHoursList) => loadedData.targetHoursMap = targetHoursList),
        this.loadMonthComment()
          .then((monthComment) => loadedData.monthComment = monthComment),
        this.loadDefaultWorkingHoursRecordList()
          .then((workingHoursRecordList: WorkingHoursRecord[]) => loadedData.defaultWorkingHoursRecordList = workingHoursRecordList),
        this.loadPublicHolidayList()
          .then((publicHolidayList) => loadedData.publicHolidayList = publicHolidayList)
      ];
      Promise.all(loadPromises)
        .then(() => {
          if (this.isFinalized) {
            return;
          }
          loadedData.timeRecordList = this.createTimeRecordObservableList(
            loadedData.timeRecordList, loadedData.publicHolidayList || this.viewModel.publicHolidayList
          );
          loadedData.targetHoursMap = this.createTargetHoursObservableMap(loadedData.targetHoursMap);
          loadedData.defaultWorkingHoursRecordList = this.createWorkingHoursRecordObservableList(loadedData.defaultWorkingHoursRecordList);
          this.viewModel.setValues(loadedData);
        })
        .catch((error) => {
          console.error('Unexpected error', error);
        })
        .then(() => {
          this.shellViewService.decrementLoadingCounter();
        });
    }
    if (
      changes[TimeRecordingViewFieldKey.TIME_RECORD_LIST] ||
      changes[TimeRecordingViewFieldKey.CARRYOVER] ||
      changes[TimeRecordingViewFieldKey.DEFAULT_WORKING_HOURS_RECORD_LIST]
    ) {
      this.scheduleRefreshSummary();
    }
  }

  private onTimeRecordListItemChanged(event: ChangedEvent) {
    if (event.originalEvent !== event) {
      const originalEvent = event.originalEvent;
      if (originalEvent.source instanceof TimeRecordModel) {
        this.onTimeRecordPropertyChanged(originalEvent);
      }
      return;
    }
    this.scheduleRefreshSummary();
  }

  private onTimeRecordPropertyChanged(event: ChangedEvent) {
    // noinspection JSIgnoredPromiseFromCall
    this.saveTimeRecord(event.source);
    this.scheduleRefreshSummary();
  }

  private onTargetHoursMapItemChanged(event: ChangedEvent) {
    if (event.originalEvent !== event) {
      if (event.originalEvent.source instanceof TargetHoursModel) {
        this.onTargetHoursPropertyChanged(event.originalEvent);
      }
      return;
    }
    this.scheduleRefreshSummary();
  }

  private onTargetHoursPropertyChanged(event: ChangedEvent) {
    // noinspection JSIgnoredPromiseFromCall
    this.saveTargetHours(event.source);
    this.scheduleRefreshSummary();
  }

  private onDefaultWorkingHoursRecordItemChanged(event: ChangedEvent) {
    if (event.originalEvent !== event) {
      if (event.originalEvent.source instanceof WorkingHoursRecordModel) {
        this.onDefaultWorkingHoursRecordPropertyChanged(event.originalEvent);
      }
      return;
    }
  }

  private onDefaultWorkingHoursRecordPropertyChanged(event: ChangedEvent) {
    if (
      event.changes[WorkingHoursRecordFieldKey.WEEKDAY]
      || event.changes[WorkingHoursRecordFieldKey.PROJECT_ID]
      || event.changes[WorkingHoursRecordFieldKey.HOURS]
    ) {
      const workingHoursRecord: WorkingHoursRecordModel = event.source;
      if (hasValue(workingHoursRecord.hours)) {
        // noinspection JSIgnoredPromiseFromCall
        this.saveDefaultWorkingHoursRecord(workingHoursRecord);
      } else if (workingHoursRecord.modified) {
        // noinspection JSIgnoredPromiseFromCall
        this.deleteDefaultWorkingHoursRecord(workingHoursRecord);
      }
    }
  }

  private onCarryoverPropertyChanged(event: ChangedEvent) {
    const carryover: CarryoverModel = event.source;
    if (event.originalEvent !== event) {
      if (event.originalEvent.source === carryover.flextimeBalance) {
        if (hasValue(carryover.flextimeBalance.value)) {
          // noinspection JSIgnoredPromiseFromCall
          this.saveBalance(carryover.flextimeBalance);
        } else {
          // noinspection JSIgnoredPromiseFromCall
          this.deleteBalance(carryover.flextimeBalance);
        }
      }
      if (event.originalEvent.source === carryover.holidayBalance) {
        if (hasValue(carryover.holidayBalance.value)) {
          // noinspection JSIgnoredPromiseFromCall
          this.saveBalance(carryover.holidayBalance);
        } else {
          // noinspection JSIgnoredPromiseFromCall
          this.deleteBalance(carryover.holidayBalance);
        }
      }
    }
    this.scheduleRefreshSummary();
  }

  private onMonthCommentPropertyChanged(event: ChangedEvent) {
    const comment: CommentModel = event.source;
    if (!event.changes[CommentFieldKey.VALUE]) {
      return;
    }
    if (comment.value) {
      // noinspection JSIgnoredPromiseFromCall
      this.saveMonthComment(comment);
    } else {
      // noinspection JSIgnoredPromiseFromCall
      this.deleteMonthComment(comment);
    }
  }

  public async refreshIsProjectAdmin() {
    try {
      this.shellViewService.incrementLoadingCounter();
      const permissions = await this.authorizationService.loadPermissionList();
      if (this.isFinalized) {
        return;
      }
      this.viewModel.isProjectAdmin = permissions.find((p) => p.key === SharedPermissionKey.PROJECT_ADMIN) !== undefined;
    } catch (error) {
      if (this.isFinalized) {
        return;
      }
      this.viewModel.employeeList = null;
      this.alertService.alertError(error);
    } finally {
      this.shellViewService.decrementLoadingCounter();
    }
  }

  public async refreshTimeRecordList(): Promise<void> {
    return this.loadTimeRecordList()
      .then((timeRecordList: TimeRecord[]) => this.createTimeRecordObservableList(timeRecordList, this.viewModel.publicHolidayList))
      .then((timeRecordList: ObservableList<TimeRecordModel>) => {
        if (!this.isFinalized) {
          this.viewModel.timeRecordList = timeRecordList;
        }
      });
  }

  public async loadTimeRecordList(): Promise<TimeRecord[]> {
    const employeeId = this.viewModel.employeeId;
    const year = this.viewModel.year;
    const month = this.viewModel.month;
    if (!employeeId || !year || !month) {
      return undefined;
    }
    return this.timeRecordService.loadTimeRecordListByMonth(employeeId, year, month)
      .then((timeRecordList: TimeRecord[]) => {
        return timeRecordList.sort((a, b) => {
          const aDate = moment(a.date).valueOf();
          const bDate = moment(b.date).valueOf();
          if (aDate < bDate) {
            return -1;
          }
          if (bDate < aDate) {
            return 1;
          }
          if (!a.from) {
            return b.from ? 1 : 0;
          }
          if (!b.from) {
            return -1;
          }
          const aFrom = moment(a.from, 'HH:mm', true).valueOf();
          const bFrom = moment(b.from, 'HH:mm', true).valueOf();
          if (aFrom === bFrom) {
            return 0;
          }
          return aFrom < bFrom ? -1 : 1;
        });
      })
      .catch((error: ApplicationError) => {
        if (!this.isFinalized) {
          this.alertService.alertError(error);
        }
        return undefined;
      });
  }

  public async saveTimeRecord(timeRecord: TimeRecordModel, delay = 0): Promise<void> {
    const savedData = await this.entityService.scheduleModify(
      timeRecord, () => this.timeRecordService.saveTimeRecord(timeRecord), delay
    );
    if (!savedData) {
      return;
    }
    timeRecord.setValues({ index: savedData.index }, { silent: true });
    if (this.isFinalized || !this.viewModel.targetHoursMap.has(timeRecord.date)) {
      await this.setDefaultWorkTargetHours(timeRecord.date);
    }
  }

  public async deleteTimeRecord(timeRecord: TimeRecordModel, delay = 0): Promise<void> {
    const deletedData = await this.entityService.scheduleModify(
      timeRecord, () => this.timeRecordService.deleteTimeRecord(timeRecord), delay
    );
    if (!deletedData) {
      return;
    }
    if (this.isFinalized || this.viewModel.targetHoursMap.has(timeRecord.date)) {
      await this.clearDefaultWorkTargetHours(timeRecord.date);
    }
  }

  public async loadTargetHoursList(): Promise<TargetHours[]> {
    const employeeId = this.viewModel.employeeId;
    const year = this.viewModel.year;
    const month = this.viewModel.month;
    if (!employeeId || !year || !month) {
      return undefined;
    }
    return this.targetHoursService.loadTargetHoursListByMonth(employeeId, year, month)
      .catch((error: ApplicationError) => {
        if (!this.isFinalized) {
          this.alertService.alertError(error);
        }
        return undefined;
      });
  }

  public async saveTargetHours(targetHours: TargetHoursModel, delay = 0): Promise<TargetHours> {
    return this.entityService.scheduleModify(
      targetHours, () => this.targetHoursService.saveTargetHours(targetHours), delay
    );
  }

  public async deleteTargetHours(targetHours: TargetHoursModel, delay = 0): Promise<TargetHours> {
    return this.entityService.scheduleModify(
      targetHours, () => this.targetHoursService.deleteTargetHours(targetHours.employeeId, targetHours.type, targetHours.date), delay
    );
  }

  public async setDefaultWorkTargetHours(date: string): Promise<void> {
    const addedTargetHours = await this.timeRecordingService.setDefaultWorkTargetHours(this.viewModel.employeeId, date);
    if (this.isFinalized || !addedTargetHours) {
      return;
    }
    if (this.viewModel.targetHoursMap.has(date)) {
      return;
    }
    this.viewModel.targetHoursMap.set(date, new TargetHoursModel(addedTargetHours));
  }

  public async clearDefaultWorkTargetHours(date: string): Promise<void> {
    const deletedTargetHours = await this.timeRecordingService.clearDefaultWorkTargetHours(this.viewModel.employeeId, date);
    if (this.isFinalized || !deletedTargetHours) {
      return;
    }
    const targetHours = this.viewModel.targetHoursMap.get(date);
    if (!targetHours || targetHours.modified !== deletedTargetHours.modified) {
      return;
    }
    this.viewModel.targetHoursMap.remove(deletedTargetHours.date);
  }

  public refreshProjectList() {
    return this.sharedViewService.refreshProjectList();
  }

  public async loadProjectList() {
    return this.projectService.loadProjectList()
      .catch((error: ApplicationError) => {
        if (!this.isFinalized) {
          this.alertService.alertError(error);
        }
        return undefined;
      });
  }

  public async saveProject(project: ProjectData): Promise<Project> {
    return this.entityService.scheduleModify(project, () => this.projectService.saveProject(project));
  }

  private async loadCarryover(): Promise<any> {
    const employeeId = this.viewModel.employeeId;
    const year = this.viewModel.year;
    const month = this.viewModel.month;
    if (!employeeId || !year || !month) {
      return undefined;
    }
    return this.carryoverService.loadCarryover(employeeId, year, month)
      .then((carryover: Carryover) => {
        const date = `${year}-${month}-01`;
        return new CarryoverModel({
          ...carryover,
          flextimeBalance: { employeeId, type: BalanceTypes.FLEXTIME, date, ...carryover.flextimeBalance },
          holidayBalance: { employeeId, type: BalanceTypes.HOLIDAY, date, ...carryover.holidayBalance },
        });
      })
      .catch((error: ApplicationError) => {
        if (!this.isFinalized) {
          this.alertService.alertError(error);
        }
        return undefined;
      });
  }

  private async saveBalance(balance: BalanceModel) {
    return this.entityService.scheduleModify(
      balance, () => this.balanceService.saveBalance(balance)
    );
  }

  private async deleteBalance(balance: BalanceModel) {
    await this.entityService.scheduleModify(
      balance, () => this.balanceService.deleteBalance(balance.employeeId, balance.type, balance.date)
    );
    balance.setValues({ editorId: undefined, modified: undefined }, { silent: true });
  }


  public async loadPublicHolidayList() {
    const year = this.viewModel.year;
    const month = this.viewModel.month;
    if (!year || !month) {
      return undefined;
    }
    return this.publicHolidayService.loadPublicHolidayList(this.viewModel.year, this.viewModel.month)
      .catch((error: ApplicationError) => {
        if (!this.isFinalized) {
          this.alertService.alertError(error);
        }
        return undefined;
      });
  }

  public async loadDefaultWorkingHoursRecordList(): Promise<WorkingHoursRecord[]> {
    if (!this.viewModel.employeeId) {
      return undefined;
    }
    try {
      return this.workingHoursService.loadWorkingHoursRecordList(this.viewModel.employeeId);
    } catch (error) {
      if (!this.isFinalized) {
        this.alertService.alertError(error);
      }
      return undefined;
    }
  }

  public async saveDefaultWorkingHoursRecord(model: WorkingHoursRecordModel) {
    const savedData = await this.entityService.scheduleModify(model, () => this.workingHoursService.saveWorkingHoursRecord(model));
    if (savedData) {
      model.setValues({ index: savedData.index }, { silent: true });
    }
  }

  public async deleteDefaultWorkingHoursRecord(data: WorkingHoursRecordData) {
    return this.entityService.scheduleModify(data, () => this.workingHoursService.deleteWorkingHoursRecord(data.employeeId, data.index));
  }

  public async loadMonthComment(): Promise<CommentModel> {
    const employeeId = this.viewModel.employeeId;
    const year = this.viewModel.year;
    const month = this.viewModel.month;
    if (!employeeId || !year || !month) {
      return undefined;
    }
    const type = 'time-recording';
    const date = `${year}-${month}-01`;
    return this.commentService.loadComment(employeeId, type, date)
      .then((monthComment: Comment) => {
        return new CommentModel({ employeeId, type, date, ...monthComment });
      })
      .catch((error: ApplicationError) => {
        if (!this.isFinalized) {
          this.alertService.alertError(error);
        }
        return undefined;
      });
  }

  public async saveMonthComment(comment: CommentModel) {
    await this.entityService.scheduleModify(
      comment, () => this.commentService.saveComment(comment)
    );
  }

  private async deleteMonthComment(comment: CommentModel) {
    await this.entityService.scheduleModify(
      comment, () => this.commentService.deleteComment(comment.employeeId, comment.type, comment.date)
    );
    comment.setValues({ editorId: undefined, modified: undefined });
  }


  private createTimeRecordObservableList(
    timeRecordList: TimeRecord[], publicHolidayList: PublicHoliday[]
  ): ObservableList<TimeRecordModel> {
    if (!timeRecordList) {
      return null;
    }
    let timeRecordIndex = 0;
    const employeeId = this.viewModel.employeeId;
    const year = +this.viewModel.year;
    const month = +this.viewModel.month - 1;
    const momentDate = moment([year, month, 1]);
    const timeRecordModels: TimeRecordModel[] = [];
    while (momentDate.month() === month) {
      const date = momentDate.format('YYYY-MM-DD');
      if (timeRecordIndex < timeRecordList.length && timeRecordList[timeRecordIndex].date === date) {
        timeRecordModels.push(new TimeRecordModel(timeRecordList[timeRecordIndex]));
        timeRecordIndex++;
        while (timeRecordIndex < timeRecordList.length && timeRecordList[timeRecordIndex].date === date) {
          timeRecordModels.push(new TimeRecordModel(timeRecordList[timeRecordIndex]));
          timeRecordIndex++;
        }
      } else {
        const publicHoliday = this.findPublicHoliday(date, publicHolidayList);
        timeRecordModels.push(new TimeRecordModel({ employeeId, date, comment: publicHoliday ? publicHoliday.name : null }));
      }
      momentDate.add(1, 'days');
    }
    return new ObservableList<TimeRecordModel>(timeRecordModels, { observeMode: ObserveModes.FLAT });
  }

  public findPublicHoliday(date: string, publicHolidayList: PublicHoliday[]): PublicHoliday {
    return publicHolidayList && publicHolidayList.find((item) => item.date === date) || null;
  }

  private createTargetHoursObservableMap(dataList: TargetHoursData[]): ObservableMap<TargetHoursModel> {
    const targetHoursMapData = {};
    if (dataList) {
      dataList.forEach((dataItem) => targetHoursMapData[dataItem.date] = new TargetHoursModel(dataItem));
    }
    return new ObservableMap<TargetHoursModel>(targetHoursMapData, { observeMode: ObserveModes.FLAT });
  }

  private createWorkingHoursRecordObservableList(recordDataList: WorkingHoursRecordData[]): ObservableList<WorkingHoursRecordModel> {
    if (!recordDataList) {
      recordDataList = [
        { employeeId: this.viewModel.employeeId, weekDay: WeekDays.MONDAY, hours: null },
        { employeeId: this.viewModel.employeeId, weekDay: WeekDays.TUESDAY, hours: null },
        { employeeId: this.viewModel.employeeId, weekDay: WeekDays.WEDNESDAY, hours: null },
        { employeeId: this.viewModel.employeeId, weekDay: WeekDays.THURSDAY, hours: null },
        { employeeId: this.viewModel.employeeId, weekDay: WeekDays.FRIDAY, hours: null },
      ];
    }
    return new ObservableList<WorkingHoursRecordModel>(
      recordDataList.map((data) => new WorkingHoursRecordModel(data)), { observeMode: ObserveModes.FLAT }
    );
  }

  private scheduleRefreshSummary() {
    this.alertService.discardAll();
    if (this.refreshSummaryTimeout) {
      clearTimeout(this.refreshSummaryTimeout);
    }
    this.refreshSummaryTimeout = setTimeout(() => {
      this.refreshSummaryTimeout = null;
      if (this.isFinalized) {
        return;
      }
      this.refreshSummary();
    }, 0);
  }

  private refreshSummary() {
    this.viewModel.summary = this.timeRecordingService.calculateSummary(
      this.viewModel.timeRecordList, this.viewModel.targetHoursMap, this.viewModel.carryover
    );
  }

  public createPdfReport() {
    const employeeId = this.viewModel.employeeId;
    const employee = this.viewModel.employeeList.find(employee => employee.id === employeeId);
    const year = this.viewModel.year;
    const month = this.viewModel.month;
    const hiddenColumns = this.persistenceService.getValue(TimeRecordingPersistenceKey.HIDDEN_COLUMNS);
    const documentName = this.textService.getText(`${TimeRecordingViewKey.EMPLOYEE_TIME_RECORDING}/name`);
    const fileName = `${year}-${month} - ${employee.name} - ${documentName}.pdf`;
    if (employeeId && year && month) {
      this.reportService.createTimeRecordingReport(employeeId, year, month, hiddenColumns, fileName);
    }
  }

  public async fillExpectedWorkingHours() {
    const workingHoursRecordList = this.viewModel.defaultWorkingHoursRecordList;
    if (!workingHoursRecordList || workingHoursRecordList.size === 0) {
      return;
    }
    const workingHoursRecordListPerDay = this.workingHoursService.getWorkingHoursRecordListPerDay(workingHoursRecordList);
    const year = +this.viewModel.year;
    const month = +this.viewModel.month - 1;
    const momentDate = moment([year, month, 1]);
    const fillTimeRecordList: TimeRecordData[] = [];
    const fillDateSet = new Set<string>();
    while (momentDate.month() === month) {
      const weekday = momentDate.isoWeekday();
      const dayWorkingHoursRecordList = workingHoursRecordListPerDay.get(weekday);
      if (hasValue(dayWorkingHoursRecordList)) {
        const date = momentDate.format('YYYY-MM-DD');
        const publicHoliday = this.viewModel.publicHolidayList.find(item => item.date === date);
        dayWorkingHoursRecordList.forEach((workingHoursRecord) => {
          fillTimeRecordList.push({
            employeeId: this.viewModel.employeeId,
            date,
            hours: workingHoursRecord.hours || null,
            type: publicHoliday ? TimeRecordTypes.PUBLIC_HOLIDAY : TimeRecordTypes.WORK,
            projectId: workingHoursRecord.projectId || null,
            comment: publicHoliday ? publicHoliday.name : null
          });
        });
        fillDateSet.add(date);
      }
      momentDate.add(1, 'days');
    }
    await this.timeRecordService.saveTimeRecordList(fillTimeRecordList);
    // TODO: do on server
    await this.refreshTimeRecordList();
    await Promise.all(Array.from(fillDateSet).map(date => this.setDefaultWorkTargetHours(date)));
  }

}
