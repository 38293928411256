import { Entity, EntityData, EntityFieldKey, EntityModel } from '@typescript-kit/data';
import { PublicHoliday } from './public-holiday.model';

export type TimeRecordActivity = 'come' | 'leave' | 'total-day' | 'total-week';

export class ProjectTimeRecordActivities {
  static readonly COME: TimeRecordActivity = 'come';
  static readonly LEAVE: TimeRecordActivity = 'leave';
  static readonly TOTAL_DAY: TimeRecordActivity = 'total-day';
  static readonly TOTAL_WEEK: TimeRecordActivity = 'total-week';
}

export class ProjectTimeRecordFieldKey extends EntityFieldKey {
  static readonly EMPLOYEE_ID = 'employeeId';
  static readonly DATE = 'date';
  static readonly TIME = 'time';
  static readonly HOURS = 'hours';
  static readonly ACTIVITY = 'activity';
  static readonly HOLIDAY = 'holiday';
  static readonly COMMENT = 'comment';
}

export interface ProjectTimeRecordData extends EntityData {
  employeeId?: string;
  date?: string;
  time?: string;
  hours?: number;
  activity?: TimeRecordActivity;
  holiday?: PublicHoliday;
  comment?: string;
}

export class ProjectTimeRecord extends Entity implements ProjectTimeRecordData {
  readonly employeeId: string;
  readonly date: string;
  readonly time: string;
  readonly hours: number;
  readonly activity: TimeRecordActivity;
  readonly holiday?: PublicHoliday;
  readonly comment?: string;

  constructor(data: ProjectTimeRecordData) {
    super(data);
    this.employeeId = data.employeeId;
    this.date = data.date;
    this.time = data.time;
    this.hours = data.hours;
    this.activity = data.activity;
    this.holiday = data.holiday;
    this.comment = data.comment;
  }
}

export class ProjectTimeRecordModel extends EntityModel implements ProjectTimeRecordData {
  static readonly KEYS = EntityModel.KEYS.concat(
    Object.keys(ProjectTimeRecordFieldKey).map((key) => ProjectTimeRecordFieldKey[key])
  );

  constructor(data: ProjectTimeRecordData) {
    super(data, ProjectTimeRecordModel.KEYS);
  }

  get employeeId(): string {
    return this.get(ProjectTimeRecordFieldKey.EMPLOYEE_ID);
  }

  get date(): string {
    return this.get(ProjectTimeRecordFieldKey.DATE);
  }

  get time(): string {
    return this.get(ProjectTimeRecordFieldKey.TIME);
  }

  get hours(): number {
    return this.get(ProjectTimeRecordFieldKey.HOURS);
  }

  get activity(): TimeRecordActivity {
    return this.get(ProjectTimeRecordFieldKey.ACTIVITY);
  }

  set activity(value: TimeRecordActivity) {
    this.set(ProjectTimeRecordFieldKey.ACTIVITY, value);
  }

  get holiday(): PublicHoliday | null {
    return this.get(ProjectTimeRecordFieldKey.HOLIDAY, null);
  }

  get comment(): string | null {
    return this.get(ProjectTimeRecordFieldKey.COMMENT, null);
  }

  set comment(value: string | null) {
    this.set(ProjectTimeRecordFieldKey.COMMENT, value);
  }

  protected setupValues(data: ProjectTimeRecordData, exclusive: boolean): ProjectTimeRecordData {
    if (exclusive) {
      this.setupDefaultValue(data, ProjectTimeRecordFieldKey.DATE, null);
      this.setupDefaultValue(data, ProjectTimeRecordFieldKey.TIME, null);
      this.setupDefaultValue(data, ProjectTimeRecordFieldKey.HOURS, null);
      this.setupDefaultValue(data, ProjectTimeRecordFieldKey.ACTIVITY, null);
      this.setupDefaultValue(data, ProjectTimeRecordFieldKey.HOLIDAY, null);
      this.setupDefaultValue(data, ProjectTimeRecordFieldKey.COMMENT, null);
    }

    return super.setupValues(data, exclusive) as ProjectTimeRecordData;
  }
}
