import { Injectable } from '@angular/core';
import { ViewService } from '@angular-kit/view';
import { LogbookViewData, LogbookViewFieldKey, LogbookViewModel } from '../view-model/logbook.view-model';
import { EmployeeService } from '../../shared/service/employee.service';
import { Employee } from '@teamworks/global';
import { ApplicationError, ChangedEvent, ObservableList, ObserveModes } from '@typescript-kit/core';
import { LogbookEntryService } from '../service/logbook-entry.service';
import { LogbookService } from '@teamworks/global';
import { LogbookEntry, LogbookEntryFieldKey, LogbookEntryModel } from '@teamworks/global';
import { ShellViewService } from '../../shell/view/shell.view-service';
import { EntityService } from '../../shared/service/entity.service';
import { SortOrders } from '@typescript-kit/data';

@Injectable()
export class LogbookSharedViewService extends ViewService<LogbookViewModel, any> {
  // private readonly localeService = this.injector.get(LocaleService);
  private readonly entityService = this.injector.get(EntityService);
  private readonly employeeService = this.injector.get(EmployeeService);
  private readonly logbookEntryService = this.injector.get(LogbookEntryService);
  private readonly logbookService = this.injector.get(LogbookService);

  private readonly shellViewService = this.injector.get(ShellViewService);

  private refreshSummaryTimeout: any;

  initialize(viewData?: LogbookViewData) {
    const viewModel = viewData instanceof LogbookViewModel ? viewData : new LogbookViewModel(viewData);
    super.initialize(viewModel);
    this.refreshEmployeeList();
  }

  finalize() {
    super.finalize();
  }

  protected onViewModelPropertyChanged(event: ChangedEvent) {
    if (event.originalEvent !== event) {
      const originalEvent = event.originalEvent;
      if (originalEvent.source === this.viewModel.logbookList) {
        this.onLogbookEntryListItemChanged(originalEvent);
      }
      return;
    }
    const changes = event.changes;
    if (
      changes[LogbookViewFieldKey.EMPLOYEE_ID] ||
      changes[LogbookViewFieldKey.YEAR] ||
      changes[LogbookViewFieldKey.MONTH]
    ) {
      if (changes[LogbookViewFieldKey.YEAR] || changes[LogbookViewFieldKey.MONTH]) {
        this.refreshEmployeeList();
      }
      this.refreshLogbookEntryList()
        .catch(() => {
          // DO NOTHING
        });
    }
    if (changes[LogbookViewFieldKey.LOGBOOK_LIST]) {
      this.scheduleRefreshSummary();
    }
  }

  private onLogbookEntryListItemChanged(event: ChangedEvent) {
    if (event.originalEvent !== event) {
      if (event.originalEvent.source instanceof LogbookEntryModel) {
        this.onLogbookEntryPropertyChanged(event.originalEvent);
      }
      return;
    }
    // TODO save, insert, update, delete
    // this.scheduleUpdateLogbookEntryList();
    this.scheduleRefreshSummary();
  }

  private onLogbookEntryPropertyChanged(event: ChangedEvent) {
    const model: LogbookEntryModel = event.source;
    const date = event.changes[LogbookEntryFieldKey.DATE] ? event.changes[LogbookEntryFieldKey.DATE].originalValue : model.date;
    // noinspection JSIgnoredPromiseFromCall
    this.saveLogbookEntry(date, model);
    this.scheduleRefreshSummary();
  }

  async saveLogbookEntry(date: string, model: LogbookEntryModel) {
    const savedData = await this.entityService.scheduleModify(
      model, () => !model.modified
        ? this.logbookEntryService.insertLogbookEntry(model)
        : this.logbookEntryService.updateLogbookEntry(model.employeeId, date, model.index, model)
    );
    if (!savedData) {
      return;
    }
    model.setValues({ index: savedData.index, date: savedData.date }, { silent: true });
  }

  async deleteLogbookEntry(model: LogbookEntryModel) {
    await this.entityService.scheduleModify(
      model, () => this.logbookEntryService.deleteLogbookEntry(model)
    );
  }

  private async loadLogbookEntryList(): Promise<LogbookEntry[]> {
    const employeeId = this.viewModel.employeeId;
    const year = this.viewModel.year;
    const month = this.viewModel.month;
    if (!employeeId || !year || !month) {
      return undefined;
    }
    return this.logbookEntryService.loadLogbookEntryListByMonth(employeeId, year, month)
      .catch((error: ApplicationError) => {
        if (!this.isFinalized) {
          this.alertService.alertError(error);
        }
        return undefined;
      });
  }

  private createLogbookEntryObservableList(logbookEntryList: LogbookEntry[]): ObservableList<LogbookEntryModel> {
    if (!logbookEntryList) {
      return null;
    }
    const logbookEntryModels: LogbookEntryModel[] = [];
    if (logbookEntryList.length === 0) {
      logbookEntryModels.push(new LogbookEntryModel({
        employeeId: this.viewModel.employeeId, date: null, kilometer: null, costRate: null, comment: null
      }));
    }
    for (const logbookEntry of logbookEntryList) {
      logbookEntryModels.push(new LogbookEntryModel(logbookEntry));
    }
    return new ObservableList<LogbookEntryModel>(logbookEntryModels, { observeMode: ObserveModes.FLAT });
  }

  private async refreshLogbookEntryList(): Promise<void> {
    this.shellViewService.incrementLoadingCounter();
    this.loadLogbookEntryList()
      .then((logbookList: LogbookEntry[]) => {
        if (this.isFinalized) {
          return;
        }
        this.viewModel.logbookList = this.createLogbookEntryObservableList(logbookList);
      })
      .catch((error) => {
        console.error('Unexpected error', error);
      })
      .then(() => {
        this.shellViewService.decrementLoadingCounter();
      });
  }

  private scheduleRefreshSummary() {
    this.alertService.discardAll();
    if (this.refreshSummaryTimeout) {
      clearTimeout(this.refreshSummaryTimeout);
    }
    this.refreshSummaryTimeout = setTimeout(() => {
      this.refreshSummaryTimeout = null;
      if (this.isFinalized) {
        return;
      }
      this.refreshSummary();
    }, 0);
  }

  private refreshSummary() {
    this.viewModel.summary = this.logbookService.calculateSummary(this.viewModel.logbookList);
  }

  private refreshEmployeeList() {
    if (!this.viewModel.year || !this.viewModel.month) {
      this.viewModel.employeeList = [];
      return;
    }
    this.employeeService.loadEmployeeListByMonth(this.viewModel.year, this.viewModel.month, { sort: { name: SortOrders.ASC } })
      .then((employeeList: Employee[]) => {
        if (this.isFinalized) {
          return;
        }
        this.viewModel.employeeList = employeeList;
          // .sort((a: Employee, b: Employee) => {
          //   return (a.name || '').localeCompare((b.name || ''), this.localeService.localeKey, { sensitivity: 'accent' });
          // });
      })
      .catch((error: ApplicationError) => {
        if (this.isFinalized) {
          return;
        }
        this.viewModel.employeeList = null;
        this.alertService.alertError(error);
      });
  }
}
