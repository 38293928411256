import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy, Input,
} from '@angular/core';
import { ActionModel, ImageModel, ViewComponentKey } from '@typescript-kit/view';
import { SharedSvgKey } from '../../../shared/key/shared-svg.key';
import { Subscription } from 'rxjs';
import { ChangedEvent } from '@typescript-kit/core';
import { EmployeeTimeRecordingSharedViewService } from '../../view-service/employee-time-recording-shared.view-service';
import { ProjectData } from '@teamworks/global';
import { ProjectAutoCompleteInputModel } from '../../component-model/project-auto-complete-input.component-model';

@Component({
  selector: 'app-project-input',
  templateUrl: './project-input.component.html',
  styleUrls: ['./project-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectInputComponent {
  private _model: ProjectAutoCompleteInputModel;
  private modelPropertyChangedSubscription: Subscription;

  constructor(private sharedViewService: EmployeeTimeRecordingSharedViewService) { }

  public confirmProjectAction = new ActionModel({
    tags: ['app-confirm-project-action'],
    content: new ImageModel({
      type: ViewComponentKey.SVG_SYMBOL,
      source: SharedSvgKey.ADD_DOCUMENT
    }),
    isHidden: true,
    onClick: async () => {
      this.model.context = await this.sharedViewService.saveProject({
        ...this.model.context,
        isConfirmed: true
      });
      await this.sharedViewService.refreshProjectList();
    }
  });

  @Input()
  set model(model: ProjectAutoCompleteInputModel) {
    if (this.model === model) {
      return;
    }
    if (this._model) {
      this.modelPropertyChangedSubscription.unsubscribe();
      this.modelPropertyChangedSubscription = null;
    }
    this._model = model;
    if (this._model) {
      this.modelPropertyChangedSubscription = model.changedEvent.subscribe(event => this.onModelPropertyChanged(event));
    }
    this.refreshConfirmProjectAction();
  }

  get model(): ProjectAutoCompleteInputModel {
    return this._model;
  }

  private onModelPropertyChanged(event: ChangedEvent) {
    if (event.changes.context) {
      this.refreshConfirmProjectAction();
    }
  }

  private refreshConfirmProjectAction() {
    this.confirmProjectAction.isHidden = !this.sharedViewService.viewModel.isProjectAdmin
      || !this.model?.context
      || (this.model.context as ProjectData).isConfirmed;
  }
}
