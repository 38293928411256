import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import {
  AlertService,
  CoreInitializer,
  CoreServiceKey,
  LocaleService,
  MessageService,
  ResourceService,
  ServiceProvider,
  TextService
} from '@typescript-kit/core';
import { ViewInitializer } from '@typescript-kit/view';
import { DataInitializer } from '@typescript-kit/data';
import { AuthenticationInitializer } from '@typescript-kit/authentication';

import { AppModule } from './app/app.module';
import { TimeRecordingInjectionToken } from './app/time-recording/injection-token/time-recording.injection-token';
import { environment } from './environments/environment';
import { getMetaData } from '@angular-kit/core';

console.timeStamp('main:start');

export function getBaseUrl() {
  return document.getElementsByTagName('base')[0].href;
}

if (environment.production) {
  enableProdMode();
}

async function run() {
  CoreInitializer.initialize();
  ViewInitializer.initialize();
  DataInitializer.initialize();
  AuthenticationInitializer.initialize();

  const localeService: LocaleService = ServiceProvider.resolveService(CoreServiceKey.LOCALE);
  const messageService: MessageService = ServiceProvider.resolveService(CoreServiceKey.MESSAGE);
  const textService: TextService = ServiceProvider.resolveService(CoreServiceKey.TEXT);
  const resourceService: ResourceService = ServiceProvider.resolveService(CoreServiceKey.RESOURCE);
  const alertService: AlertService = ServiceProvider.resolveService(CoreServiceKey.ALERT);

  const providers = [
    { provide: TimeRecordingInjectionToken.BASE_URL, useFactory: getBaseUrl, deps: [] },
    { provide: TimeRecordingInjectionToken.VERSION, useFactory: () => getMetaData('app-version'), deps: [] },
    { provide: LocaleService, useValue: localeService },
    { provide: MessageService, useValue: messageService },
    { provide: TextService, useValue: textService },
    { provide: ResourceService, useValue: resourceService },
    { provide: AlertService, useValue: alertService },
  ];

  await localeService.setLocale('de');
  await platformBrowserDynamic(providers).bootstrapModule(AppModule);
}

run().catch((error) => {
  console.error(error);
});
