import { Entity, EntityData, EntityFieldKey, EntityModel } from '@typescript-kit/data';
import { Change, ChangeOptions } from '@typescript-kit/core';

export type SubscriptionType = 'trial' | 'normal';

export class SubscriptionFieldKey extends EntityFieldKey {
  static readonly ID: keyof SubscriptionData = 'id';
  static readonly COMPANY_ID: keyof SubscriptionData = 'companyId';
  static readonly TYPE: keyof SubscriptionData = 'type';
  static readonly PRICE_PER_ACTIVE_EMPLOYEE: keyof SubscriptionData = 'pricePerActiveEmployee';
  static readonly BEGIN: keyof SubscriptionData = 'begin';
  static readonly END: keyof SubscriptionData = 'end';
}

export interface SubscriptionData extends EntityData {
  id?: string;
  companyId?: string;
  type?: SubscriptionType;
  pricePerActiveEmployee?: number;
  begin?: string;
  end?: string;
}

export class Subscription extends Entity implements SubscriptionData {
  readonly id: string;
  readonly companyId: string;
  readonly type: SubscriptionType;
  readonly pricePerActiveEmployee: number;
  readonly begin: string;
  readonly end: string;

  constructor(data: SubscriptionData) {
    super(data);
    this.id = data.id;
    this.companyId = data.companyId;
    this.type = data.type;
    this.pricePerActiveEmployee = data.pricePerActiveEmployee;
    this.begin = data.begin;
    this.end = data.end;
  }
}

export class SubscriptionModel extends EntityModel implements SubscriptionData {
  static readonly KEYS = EntityModel.KEYS.concat(
    Object.keys(SubscriptionFieldKey).map(key => SubscriptionFieldKey[key])
  );

  constructor(data: SubscriptionData) {
    super(data, SubscriptionModel.KEYS);
  }

  get companyId(): string {
    return this.get(SubscriptionFieldKey.COMPANY_ID);
  }

  get id(): string {
    return this.get(SubscriptionFieldKey.ID);
  }

  set id(value: string) {
    this.set(SubscriptionFieldKey.ID, value);
  }

  get type(): SubscriptionType {
    return this.get(SubscriptionFieldKey.TYPE);
  }

  get pricePerActiveEmployee(): number {
    return this.get(SubscriptionFieldKey.PRICE_PER_ACTIVE_EMPLOYEE);
  }

  get begin(): string {
    return this.get(SubscriptionFieldKey.BEGIN);
  }

  get end(): string {
    return this.get(SubscriptionFieldKey.END);
  }

  setValues(values: SubscriptionData, options?: ChangeOptions): { [p: string]: Change<any> } {
    return super.setValues(values, options);
  }
}
