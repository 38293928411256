import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { LogbookTableViewService } from '../../view-service/logbook-table.view-service';
import { TableModel } from '@typescript-kit/view';
import { LogbookSharedViewService } from '../../view-service/logbook-shared.view-service';
import { LogbookShellMenuViewService } from '../../view-service/logbook-shell-menu.view-service';
import { LogbookSummaryViewService } from '../../view-service/logbook-summary.view-service';
import { ShellViewService } from '../../../shell/view/shell.view-service';

@Component({
  selector: 'app-logbook-content',
  templateUrl: './logbook-content.component.html',
  styleUrls: ['./logbook-content.component.scss'],
  providers: [
    LogbookSharedViewService,
    LogbookShellMenuViewService,
    LogbookSummaryViewService,
    LogbookTableViewService
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogbookContentComponent implements OnInit, OnDestroy {

  constructor(
    private readonly shellViewService: ShellViewService,
    private readonly sharedViewService: LogbookSharedViewService,
    private readonly shellMenuViewService: LogbookShellMenuViewService,
    private readonly summaryViewService: LogbookSummaryViewService,
    private readonly tableViewService: LogbookTableViewService,
  ) {}

  get model(): TableModel {
    return this.tableViewService.componentModel;
  }

  ngOnInit(): void {
    this.sharedViewService.initialize();
    this.shellMenuViewService.initialize();
    this.summaryViewService.initialize();
    this.tableViewService.initialize();
    this.shellViewService.setMenu(this.shellMenuViewService.componentModel);
    this.shellViewService.setSidebar(this.summaryViewService.componentModel);
  }

  ngOnDestroy(): void {
    this.shellViewService.restoreSidebar();
    this.shellViewService.restoreMenu();
    this.tableViewService.finalize();
    this.summaryViewService.finalize();
    this.shellMenuViewService.finalize();
    this.sharedViewService.finalize();
  }

}
