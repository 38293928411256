import { Injectable } from '@angular/core';

import { ObservableMap } from '@typescript-kit/core';
import { ViewService } from '@angular-kit/view';
import { TextModel } from '@typescript-kit/view';

import { ProjectTimeRecordingDetailComponentModel } from '../component-model/project-time-recording-detail.component-model';
import { ProjectCommonViewModel } from '../view-model/project-common.view-model';
import { ProjectCommonViewService } from './project-common-shared.view-service';
import { ProjectTimeRecordingTableViewService } from './project-time-recording-table.view-service';

@Injectable()
export class ProjectTimeRecordingDetailViewService extends ViewService<ProjectCommonViewModel, ProjectTimeRecordingDetailComponentModel> {
  private readonly sharedViewService = this.injector.get(ProjectCommonViewService);
  private readonly tableViewService = new ProjectTimeRecordingTableViewService(this.injector);

  initialize(): void {
    const viewModel = this.sharedViewService.viewModel;
    this.tableViewService.initialize(viewModel);
    super.initialize(viewModel);
  }

  finalize(): void {
    this.tableViewService.finalize();
    super.finalize();
  }

  protected createComponentModel(): ProjectTimeRecordingDetailComponentModel {
    return new ProjectTimeRecordingDetailComponentModel({
      items: new ObservableMap({
        projectName: this.createProjectNameModel(),
        monthText: this.createMonthTextModel(),
        table: this.tableViewService.componentModel
      })
    });
  }

  protected refreshComponentModel(componentModel: ProjectTimeRecordingDetailComponentModel) {
    this.refreshProjectNameModel(componentModel.projectName);
    this.refreshMonthTextModel(componentModel.monthText);
  }

  private createProjectNameModel() {
    const model = new TextModel({
      key: 'title', tags: ['app-project-time-recording-title'],
    });
    this.refreshProjectNameModel(model);
    return model;
  }

  private refreshProjectNameModel(model: TextModel) {
    model.setValues({
      isHidden: !this.viewModel.printView,
      text: this.viewModel.project?.name,
    });
  }

  private createMonthTextModel(): TextModel {
    const textModel = new TextModel({});
    this.refreshMonthTextModel(textModel);
    return textModel;
  }

  private refreshMonthTextModel(model: TextModel) {
    const month = this.viewModel.month ? this.textService.getText(`shared/model/month/value/${this.viewModel.month}`) : '';
    const year = this.viewModel.year ? this.viewModel.year.toString() : '';
    model.setValues({
      isHidden: !this.viewModel.printView,
      text: `${month} ${year}`,
    });
  }
}
