import { Injectable } from '@angular/core';
import { DataErrorKey, EntityData, EntityModel } from '@typescript-kit/data';
import { AlertService, ApplicationError } from '@typescript-kit/core';
import { Observable, Subject } from 'rxjs';
import { EntityEvent, EntityEventTypes } from '../model/entity-event.model';
import { ComponentModel } from '@typescript-kit/view';
import { DialogService } from './dialog.service';

interface EntitySchedule {
  entity: EntityData;
  pendingResolver?: (result?) => void;
  activePromise?: Promise<void>;
  timeout?: any;
}

@Injectable()
export class EntityService {

  private readonly scheduleMap = new Map<EntityData, EntitySchedule>();

  private readonly entityEventSubject = new Subject<EntityEvent<any>>();

  constructor(private alertService: AlertService, private dialogService: DialogService) {
  }

  get entityEvent(): Observable<EntityEvent<any>> {
    return this.entityEventSubject.asObservable();
  }

  scheduleModify<TEntity extends EntityData, TResult extends EntityData>(
    entity: TEntity, delegate: (entity: EntityData) => Promise<TResult>, delay = 0
  ): Promise<TResult> {
    this.alertService.discardAll();
    return new Promise<TResult>((resolve, reject) => {
      let scheduleItem = this.scheduleMap.get(entity);
      if (!scheduleItem) {
        // no task is scheduled or started
        this.entityEventSubject.next({ type: EntityEventTypes.SCHEDULED, entity });
        this.scheduleMap.set(entity, scheduleItem = { entity });
      } else if (scheduleItem.pendingResolver) {
        // pending task is scheduled but not started (override pending task)
        if (scheduleItem.timeout) {
          clearTimeout(scheduleItem.timeout);
        }
        scheduleItem.pendingResolver();
      }
      scheduleItem.pendingResolver = resolve;
      scheduleItem.timeout = setTimeout(async () => {
        scheduleItem.timeout = null;
        if (!scheduleItem.activePromise) {
          this.entityEventSubject.next({ type: EntityEventTypes.ACTIVE, entity });
        } else {
          // active task is already started (wait until active task is completed)
          await scheduleItem.activePromise;
        }
        if (scheduleItem.pendingResolver !== resolve) {
          // current task is overwritten by another task
          return;
        }
        if (scheduleItem.activePromise) {
          // no task must be active at this point
          throw new Error('Invalid state');
        }
        scheduleItem.pendingResolver = null;
        scheduleItem.activePromise = delegate(entity)
          .then((savedEntity: TResult) => {
            if (entity instanceof EntityModel) {
              entity.setValues({ editorId: savedEntity.editorId, modified: savedEntity.modified }, { silent: true });
            } else {
              entity.editorId = savedEntity.editorId;
              entity.modified = savedEntity.modified;
            }
            resolve(savedEntity);
          })
          .catch((error) => {
            const applicationError = ApplicationError.from(error);
            if (applicationError.arguments?.error != null && applicationError.arguments?.error.key === DataErrorKey.ENTITY_MODIFIED) {
              this.dialogService.openErrorDialog(null,
                [
                  {text: `#(shared/view/error-dialog/kit-data/error/entity-modified/message0)`},
                  {text: `#(shared/view/error-dialog/kit-data/error/entity-modified/message1)`},
                  {text: `#(shared/view/error-dialog/kit-data/error/entity-modified/message2)`}
                ], {text: `#(shared/view/error-dialog/kit-data/error/entity-modified/cancelAction)`},
                {text: `#(shared/view/error-dialog/kit-data/error/entity-modified/confirmAction)`})
                .then((reload: boolean) => {
                  if (reload) {
                    window.location.reload();
                  }
                });
            } else {
              this.alertService.alertError(applicationError);
            }
            reject(applicationError);
            return applicationError;
          })
          .then((error) => {
            scheduleItem.activePromise = null;
            if (!scheduleItem.pendingResolver) {
              this.scheduleMap.delete(entity);
              if (!error) {
                this.entityEventSubject.next({ type: EntityEventTypes.SUCCESS, entity });
              } else {
                this.entityEventSubject.next({ type: EntityEventTypes.ERROR, entity, error });
              }
            } else if (scheduleItem.timeout) {
              this.entityEventSubject.next({ type: EntityEventTypes.SCHEDULED, entity });
            }
          });
      }, delay);
    });
  }


  public setEntityEventTag(model: ComponentModel, event: EntityEvent<any>) {
    switch (event.type) {
      case EntityEventTypes.SCHEDULED:
        model.tags.set('app-entity-state', 'modified');
        break;
      case EntityEventTypes.ACTIVE:
        model.tags.set('app-entity-state', 'saving');
        break;
      case EntityEventTypes.SUCCESS:
        model.tags.set('app-entity-state', 'saved');
        break;
      case EntityEventTypes.ERROR:
        model.tags.set('app-entity-state', 'error');
    }
  }


}
