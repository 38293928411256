import { Subscription } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import { ViewService } from '@angular-kit/view';
import { EmployeeCommonViewData, EmployeeCommonViewFieldKey, EmployeeCommonViewModel } from '../view-model/employee-common.view-model';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { EntityService } from '../../shared/service/entity.service';
import { ShellViewService } from '../../shell/view/shell.view-service';
import { ChangedEvent, hasValue, ObservableList, ObserveModes } from '@typescript-kit/core';
import { EmployeeModel } from '@teamworks/global';
import { EmployeeService } from '../../shared/service/employee.service';

@Injectable()
export class EmployeeCommonViewService extends ViewService<EmployeeCommonViewModel, any> {

  private routeChangedSubscription: Subscription;

  constructor(
    injector: Injector,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly entityService: EntityService,
    private readonly employeeService: EmployeeService,
    private readonly shellViewService: ShellViewService,
  ) {
    super(injector);
  }

  initialize(viewData?: EmployeeCommonViewData): void {
    const viewModel = viewData instanceof EmployeeCommonViewModel
      ? viewData
      : new EmployeeCommonViewModel(viewData);
    super.initialize(viewModel);
    this.routeChangedSubscription = this.activatedRoute.paramMap
      .subscribe((paramMap) => this.onRouteChanged(paramMap));
    // noinspection JSIgnoredPromiseFromCall
    this.refreshEmployeeList();
    if (!this.viewModel.employeeId) {
      this.shellViewService.componentModel.isSidebarVisible = true;
    }
  }

  finalize(): void {
    this.routeChangedSubscription.unsubscribe();
    super.finalize();
  }

  protected onViewModelPropertyChanged(event: ChangedEvent) {
    if (event.originalEvent !== event) {
      const originalEvent = event.originalEvent;
      if (originalEvent.source === this.viewModel.employeeList) {
        this.onEmployeeChanged(originalEvent);
      }
      return;
    }
    const changes = event.changes;
    if (changes[EmployeeCommonViewFieldKey.EMPLOYEE_ID] || changes[EmployeeCommonViewFieldKey.EMPLOYEE_LIST]) {
      if (changes[EmployeeCommonViewFieldKey.EMPLOYEE_ID]) {
        this.updateRoute();
      }
      const employeeList = this.viewModel.employeeList;
      const employeeId = this.viewModel.employeeId;
      this.viewModel.employee = employeeList && employeeList.firstValue(employee => employee.id === employeeId) || null;
      if (employeeList && !this.viewModel.employee) {
        this.viewModel.employeeId = undefined;
      }
    }
  }

  private onEmployeeChanged(event: ChangedEvent) {
    if (event.originalEvent !== event) {
      if (event.originalEvent.source instanceof EmployeeModel) {
        this.onEmployeePropertyChanged(event.originalEvent);
      }
      return;
    }
  }

  private onEmployeePropertyChanged(event: ChangedEvent) {
    const entityModel: EmployeeModel = event.source;
    // noinspection JSIgnoredPromiseFromCall
    this.saveEmployee(entityModel);
  }

  private onRouteChanged(paramMap: ParamMap) {
    const employeeId = paramMap.get('employeeId');
    if (!hasValue(employeeId) || employeeId === '-') {
      this.viewModel.employeeId = undefined;   // no entry
    } else if (employeeId === '~') {
      this.viewModel.employeeId = null;        // new entry
    } else {
      this.viewModel.employeeId = employeeId;  // existing entry
    }
    // this.viewModel.employeeId = (hasValue(employeeId) && employeeId !== '-') ? employeeId : null;
  }

  private updateRoute() {
    let employeeId: string;
    if (this.viewModel.employeeId === undefined) {
      employeeId = '-';                        // no entry
    } else if (this.viewModel.employeeId === null) {
      employeeId = '~';                        // new entry
    } else {
      employeeId = this.viewModel.employeeId;  // existing entry
    }
    this.router.navigate(['..', employeeId], { relativeTo: this.activatedRoute, queryParamsHandling: 'preserve' }
    ).catch((error) => {
      this.alertService.alertError(error);
    });
  }

  public async refreshEmployeeList(): Promise<void> {
    try {
      this.shellViewService.incrementLoadingCounter();
      const employeeList = await this.employeeService.loadEmployeeList();
      this.viewModel.employeeList = new ObservableList<EmployeeModel>(
        employeeList.map(employee => new EmployeeModel(employee)), { observeMode: ObserveModes.FLAT }
      );
    } catch (error) {
      if (this.isFinalized) {
        return;
      }
      this.alertService.alertError(error);
    } finally {
      this.shellViewService.decrementLoadingCounter();
    }
  }

  public async createEmployee(): Promise<EmployeeModel> {
    if (this.viewModel.employeeList.firstValue((employee) => employee.id === null)) {
      this.viewModel.employeeId = null;
      return;
    }
    let employeeModel = new EmployeeModel({ id: null, isActive: true, name: '' });
    const employee = await this.employeeService.saveEmployee(employeeModel);
    employeeModel = new EmployeeModel(employee);
    this.viewModel.employeeList.insert(0, employeeModel);
    this.viewModel.employeeId = employeeModel.id;
    return employeeModel;
  }

  public async saveEmployee(entityModel: EmployeeModel) {
    const savedEntity = await this.entityService.scheduleModify(
      entityModel, () => this.employeeService.saveEmployee(entityModel)
    );
    if (this.isFinalized) {
      return;
    }
    entityModel.setValues({ id: savedEntity.id }, { silent: true });
    if (this.viewModel.employee === entityModel) {
      this.viewModel.employeeId = savedEntity.id;
    }
  }

  public async deleteEmployee(entityModel: EmployeeModel) {
    if (!entityModel) {
      return;
    }
    if (entityModel.modified) {
      await this.entityService.scheduleModify(
        entityModel, () => this.employeeService.deleteEmployee(entityModel.id)
      );
    }
    if (this.isFinalized) {
      return;
    }
    const entityIndex = this.viewModel.employeeList.firstIndex(entity => entity.id === entityModel.id);
    if (entityIndex < 0) {
      return;
    }
    this.viewModel.employeeList.remove(entityIndex);
    if (this.viewModel.employeeId === entityModel.id) {
      this.viewModel.employeeId = undefined;
    }
  }

}
