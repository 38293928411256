import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';

import { TextInputDirective } from '../../directive/text-input.directive';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'textarea[kitModel]',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextAreaComponent extends TextInputDirective {

  get hostClass(): string {
    return 'kit-text-area';
  }

  protected onEnter(event: KeyboardEvent) {
    // prevent autoselect and set value on enter
  }

}
