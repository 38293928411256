<div class="app-page-margin-top app-first-page">
  <ng-container *ngIf="logo==='signum-master-business'">
    <img class="app-logo-master-business" src="assets/image/meisterbetrieb.svg">
    <img class="app-logo" src="assets/image/logo.svg">
  </ng-container>
  <img *ngIf="logo==='signum'" class="app-logo" src="assets/image/logo.svg">
  <img *ngIf="logo==='signum-software'" class="app-logo" src="assets/image/signum-software-logo.svg">
</div>

<div class="app-page-margin-left app-first-page">
  <div class="app-folding-mark app-folding-mark-1"></div>
  <div class="app-folding-mark app-folding-mark-2"></div>
  <div class="app-folding-mark app-folding-mark-3"></div>
</div>

<div class="app-page-margin-left">
  <div class="app-folding-mark app-folding-mark-1"></div>
  <div class="app-folding-mark app-folding-mark-2"></div>
  <div class="app-folding-mark app-folding-mark-3"></div>
</div>

<div [kitContainer]="model"></div>
