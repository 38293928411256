import { Injectable } from '@angular/core';
import { TextData } from '@typescript-kit/view';
import { MatDialog } from '@angular/material/dialog';
import { QuestionDialogComponent } from '../component/question-dialog/question-dialog.component.component';
import { ApplicationError } from '@typescript-kit/core';
import { ErrorDialogComponent } from '../component/error-dialog/error-dialog.component';

@Injectable({ providedIn: 'root' })
export class DialogService {

  constructor(private readonly matDialogService: MatDialog) {
  }

  openYesNoQuestion(question: TextData): Promise<boolean | undefined> {
    const dialog = this.matDialogService.open(QuestionDialogComponent, {
      data: { question }
    });
    return new Promise<boolean | undefined>((resolve, reject) => {
      dialog.afterClosed().toPromise()
        .then(data => resolve(data))
        .catch(error => reject(error));
    });
  }

  openErrorDialog(applicationError: ApplicationError, messages: TextData[], cancelText: TextData,
    confirmText: TextData): Promise<boolean | undefined> {
    const dialog = this.matDialogService.open(ErrorDialogComponent, {
      data: { messages, cancelText, confirmText }
    });
    return new Promise<boolean | undefined>((resolve, reject) => {
      dialog.afterClosed().toPromise()
        .then(data => resolve(data))
        .catch(error => reject(error));
    });
  }
}
