import { getJsonExport, LocaleProvider, ValidationProvider } from '@typescript-kit/core';
import { LogbookValidationKey } from './key/logbook-validation.key';
import { dayValidationPredicate } from './validation/day.validation-predicate';

import * as texts from './text/logbook.de.text.json';

export class LogbookInitializer {

  static initialize() {
    LogbookInitializer.registerLocales();
    LogbookInitializer.registerValidationPredicates();
  }

  static registerLocales() {
    LocaleProvider.registerPackageLocale('de', 'logbook', {
      texts: getJsonExport(texts)
    });
  }

  static registerValidationPredicates() {
    ValidationProvider.registerPredicate(LogbookValidationKey.DAY, dayValidationPredicate);
  }
}
