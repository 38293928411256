import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoreModule } from '@angular-kit/core';
import { AuthenticationModule } from '@angular-kit/authentication';
import { ViewModule } from '@angular-kit/view';
import { BsViewModule } from '@angular-kit/bs-view';

import { TimeRecordingRoutingModule } from './time-recording-routing.module';

import { SharedModule } from '../shared/shared.module';
import { ShellModule } from '../shell/shell.module';

import { TimeRecordingService } from './service/time-recording.service';
import { PublicHolidayService } from './service/public-holiday.service';
import { EmployeeService } from '../shared/service/employee.service';
import { WorkingHoursService } from './service/working-hours.service';
import { TimeRecordService } from './service/time-record.service';
import { CarryoverService } from './service/carryover.service';
import { CommentService } from './service/comment.service';
import { TimeRecordingSidebarComponent } from './component/time-recording-sidebar/time-recording-sidebar.component';
import { TimeRecordingSummaryComponent } from './component/time-recording-summary/time-recording-summary.component';
import { TimeRecordingInitializer } from './time-recording.initializer';
import { ProjectService } from './service/project.service';
import { EmployeeTimeRecordingComponent } from './component/employee-time-recording/employee-time-recording.component';
import { TargetHoursService } from './service/target-hours.service';
import { BalanceService } from './service/balance.service';
import { EmployeeDetailComponent } from './component/employee-detail/employee-detail.component';
import { EmployeeSidebarComponent } from './component/employee-sidebar/employee-sidebar.component';
import { TagService } from './service/tag.service';
import { AssignTokenDialogComponent } from './component/assign-token-dialog/assign-token-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ValidityPeriodService } from './service/validity-period.service';
import { AssignPinDialogComponent } from './component/assign-pin-dialog/assign-pin-dialog.component';
import { EmploymentDurationComponent } from './component/employment-duration/employment-duration.component';
import { ProjectInputComponent } from './component/project-input/project-input.component';
import { ProjectSidebarComponent } from './component/project-sidebar/project-sidebar.component';
import { ProjectTimeRecordingComponent } from './component/project-time-recording/project-time-recording.component';
import { DeleteProjectDialogComponent } from './component/delete-project-dialog/delete-project-dialog.component';
import { ProjectOverviewComponent } from './component/project-overview/project-overview.component';
import { ProjectManagementComponent } from './component/project-management/project-management.component';
import { ProjectActivitiesComponent } from './component/project-activities/project-activities.component';
import { ProjectAutoCompleteInputComponent } from './component/project-auto-complete-input/project-auto-complete-input.component';
import { EditActivityTokensDialogComponent } from './component/edit-activity-tokens-dialog/edit-activity-tokens-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    ViewModule,
    BsViewModule,
    AuthenticationModule,
    SharedModule,
    ShellModule,
    TimeRecordingRoutingModule,
    MatDialogModule,
  ],
  declarations: [
    TimeRecordingSidebarComponent,
    TimeRecordingSummaryComponent,
    EmployeeTimeRecordingComponent,
    EmployeeDetailComponent,
    EmployeeSidebarComponent,
    AssignTokenDialogComponent,
    AssignPinDialogComponent,
    EmploymentDurationComponent,
    ProjectAutoCompleteInputComponent,
    ProjectInputComponent,
    ProjectSidebarComponent,
    ProjectTimeRecordingComponent,
    ProjectOverviewComponent,
    ProjectManagementComponent,
    ProjectActivitiesComponent,
    DeleteProjectDialogComponent,
    EditActivityTokensDialogComponent
  ],
  entryComponents: [
    TimeRecordingSidebarComponent,
    EmployeeSidebarComponent,
    AssignTokenDialogComponent,
    AssignPinDialogComponent,
    EmploymentDurationComponent
  ],
  providers: [
    TimeRecordingService,
    PublicHolidayService,
    EmployeeService,
    ProjectService,
    WorkingHoursService,
    TimeRecordService,
    TargetHoursService,
    BalanceService,
    CarryoverService,
    CommentService,
    TagService,
    ValidityPeriodService
  ]
})
export class TimeRecordingModule {

  constructor() {
    TimeRecordingInitializer.initialize();
  }

}
