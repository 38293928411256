import { ComponentFieldKey } from '@typescript-kit/view';
import { Project } from '@teamworks/global';
import { TimeRecordingComponentKey } from '../key/time-recording-component.key';
import { AutoCompleteInputData, AutoCompleteInputModel } from '../../shared/component-model/auto-complete-input.model';

export interface ProjectAutoCompleteInputData extends AutoCompleteInputData {
  projects?: Project[];
}

export class ProjectAutoCompleteInputFieldKey {
  static readonly PROJECTS = 'projects';
}

export class ProjectAutoCompleteInputModel extends AutoCompleteInputModel implements ProjectAutoCompleteInputData {
  static readonly KEYS = AutoCompleteInputModel.KEYS.concat(
    Object.keys(ProjectAutoCompleteInputFieldKey).map((key) => ProjectAutoCompleteInputFieldKey[key])
  );

  constructor(data?: ProjectAutoCompleteInputData, keys?: string[]) {
    super(data, keys || ProjectAutoCompleteInputModel.KEYS);
  }

  get projects(): Project[] {
    return this.get(ProjectAutoCompleteInputFieldKey.PROJECTS);
  }

  set projects(value: Project[]) {
    this.set(ProjectAutoCompleteInputFieldKey.PROJECTS, value);
  }

  protected setupValues(values: ProjectAutoCompleteInputData, exclusive: boolean): ProjectAutoCompleteInputData {
    if (exclusive && !(ComponentFieldKey.TYPE in values)) {
      values.type = TimeRecordingComponentKey.PROJECT_AUTO_COMPLETE_INPUT;
    }
    return super.setupValues(values, exclusive) as ProjectAutoCompleteInputData;
  }
}
