import { EntityData } from '@typescript-kit/data';
import { ApplicationError } from '@typescript-kit/core';

export type EntityEventType = 'scheduled' | 'active' | 'success' | 'error';

export class EntityEventTypes {
  static readonly SCHEDULED: EntityEventType = 'scheduled';
  static readonly ACTIVE: EntityEventType = 'active';
  static readonly SUCCESS: EntityEventType = 'success';
  static readonly ERROR: EntityEventType = 'error';
}

export interface EntityEvent<TEntity extends EntityData> {
  readonly type: EntityEventType;
  readonly entity: TEntity;
  readonly error?: ApplicationError;
}
