import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from '@angular-kit/authentication';
import { AccountingDetailComponent } from './component/accounting-detail/accounting-detail.component';


const routes: Routes = [
  {
    path: 'accounting',
    canActivate: [AuthenticationGuard],
    children: [
      { path: ':year/:month/:id', component: AccountingDetailComponent },
      { path: ':year/:month', redirectTo: ':year/:month/-' },
      { path: ':year', redirectTo: ':year/-/-' },
      { path: '**', redirectTo: '-/-/-' },
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class AccountingRoutingModule {
}
