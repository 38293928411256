import { Injectable } from '@angular/core';
import { ViewService } from '@angular-kit/view';
import { ChangedEvent, CoreFormatKey, CoreValidationKey, hasValue, ObservableList } from '@typescript-kit/core';
import {
  ActionModel,
  Alignments,
  ComponentModel,
  ContainerModel,
  ContextBindings,
  FormFieldModel,
  KeyCodes,
  SelectInputModel,
  TableCellModel,
  TableColumnModel,
  TableModel,
  TableRowModel,
  TextInputModel,
  TextModel,
  ValueData,
  ValueModel,
  ViewComponentKey
} from '@typescript-kit/view';
import { AccountingViewKey } from '../key/accounting-view.key';
import { AccountingLogoTypes, AccountingRecordFieldKey, AccountingRecordTypes } from '@teamworks/global';
import { AccountingRecordItemFieldKey, AccountingRecordItemModel, } from '@teamworks/global';
import { AccountingCommonViewFieldKey, AccountingCommonViewModel } from '../view-model/accounting-common.view-model';
import { AccountingDetailComponentModel } from '../component-model/accounting-detail.component-model';
import { LetterHeaderViewService } from '../../shared/view/letter-header.view-service';
import { ShellViewService } from '../../shell/view/shell.view-service';
import { AccountingModelKey } from '@teamworks/global';
import { AccountingCommonViewService } from './accounting-common.view-service';

class ColumnIndex {
  static readonly INDEX = 0;
  static readonly DESCRIPTION = 1;
  static readonly AMOUNT = 2;
  static readonly UNIT = 3;
  static readonly UNIT_COSTS = 4;
  static readonly TOTAL_PRICE = 5;
  static readonly ACTION = 6;
}

@Injectable()
export class AccountingDetailViewService extends ViewService<AccountingCommonViewModel, AccountingDetailComponentModel> {
  private readonly shellViewService = this.injector.get(ShellViewService);
  private readonly accountingCommonViewService = this.injector.get(AccountingCommonViewService);
  private readonly letterHeaderViewService =  new LetterHeaderViewService(this.injector);

  private readonly accountingRecordTypeSelectOptions = {
    values: [null].concat(
      Object.keys(AccountingRecordTypes)
        .map(key => AccountingRecordTypes[key])
    ),
    formattedValues: ['-'].concat(
      Object.keys(AccountingRecordTypes)
        .map((key) => `${AccountingModelKey.ACCOUNTING_RECORD_TYPE}/values/${AccountingRecordTypes[key]}`)
    )
  };

  private readonly logoSelectOptions = {
    values: Object.keys(AccountingLogoTypes)
      .map((key) => AccountingLogoTypes[key]),
    formattedValues: Object.keys(AccountingLogoTypes)
      .map((key) => `${AccountingModelKey.ACCOUNTING_LOGO_TYPE}/values/${AccountingLogoTypes[key]}`)
  };

  initialize() {
    const viewModel = this.accountingCommonViewService.viewModel;
    this.letterHeaderViewService.initialize({
      letterHeader: viewModel.accountingRecord && viewModel.accountingRecord.letterHeader
    });
    super.initialize(viewModel);
  }

  finalize() {
    super.finalize();
    this.letterHeaderViewService.finalize();
  }

  protected onViewModelPropertyChanged(event: ChangedEvent): void {
    if (event.originalEvent !== event) {
      if (event.originalEvent.source === this.viewModel.accountingEntity) {
        this.onAccountingEntityPropertyChanged(event.originalEvent);
      }
      return;
    }
    const changes = event.changes;
    if (changes[AccountingCommonViewFieldKey.ACCOUNTING_ENTITY]) {
      this.onAccountingEntityChanged(event);
    }
    if (changes[AccountingCommonViewFieldKey.TOTAL_ALLOCATED_COSTS]) {
      this.refreshSalesTax();
    }
    if (changes[AccountingCommonViewFieldKey.TOTAL_ALLOCATED_COSTS] || changes[AccountingCommonViewFieldKey.SALES_TAX]) {
      this.refreshInvoiceAmount();
    }
    if (changes[AccountingCommonViewFieldKey.TOTAL_OPERATING_COSTS]) {
      this.refreshInverseOperatingCosts();
    }
    if (changes[AccountingCommonViewFieldKey.TOTAL_ALLOCATED_COSTS] || changes[AccountingCommonViewFieldKey.INVERSE_OPERATING_COSTS]) {
      this.refreshContributionMargin();
    }
    if (changes[AccountingCommonViewFieldKey.CONTRIBUTION_MARGIN]) {
      this.refreshContributionMarginRate();
    }
  }

  private onAccountingEntityChanged(event: ChangedEvent) {
    this.refreshComponentModel(this.componentModel);
    const accountingRecord = this.viewModel.accountingRecord;
    this.letterHeaderViewService.viewModel.letterHeader = accountingRecord && accountingRecord.letterHeader;
    this.refreshTotalAllocatedCosts();
    this.refreshTotalOperatingCosts();
  }

  private onAccountingEntityPropertyChanged(event: ChangedEvent) {
    if (event.originalEvent !== event) {
      if (event.originalEvent.source === this.viewModel.accountingRecord) {
        this.onAccountingRecordPropertyChanged(event.originalEvent);
      }
      return;
    }
  }

  private onAccountingRecordPropertyChanged(event: ChangedEvent) {
    if (event.originalEvent !== event) {
      if (event.originalEvent.source === this.viewModel.accountingRecord.allocatedCosts) {
        this.onAllocatedCostsItemChanged(event.originalEvent);
      }
      if (event.originalEvent.source === this.viewModel.accountingRecord.operatingCosts) {
        this.onOperatingCostsItemChanged(event.originalEvent);
      }
      return;
    }
    const changes = event.changes;
    if (changes[AccountingRecordFieldKey.TYPE]) {
      this.refreshComponentModel(this.componentModel);
    }
    if (changes[AccountingRecordFieldKey.ALLOCATED_COSTS]) {
      this.refreshAllocatedCostsTable(this.componentModel.items.get(`${AccountingRecordFieldKey.ALLOCATED_COSTS}Table`) as TableModel);
      this.refreshTotalAllocatedCosts();
    }
    if (changes[AccountingRecordFieldKey.SALES_TAX_RATE]) {
      this.refreshSalesTax();
    }
    if (changes[AccountingRecordFieldKey.OPERATING_COSTS]) {
      this.refreshOperatingCostsTable(this.componentModel.items.get(`${AccountingRecordFieldKey.OPERATING_COSTS}Table`) as TableModel);
      this.refreshTotalOperatingCosts();
    }
    if (changes[AccountingRecordFieldKey.LETTER_HEADER]) {
      this.letterHeaderViewService.viewModel.letterHeader = this.viewModel.accountingRecord.letterHeader;
    }
  }

  private onAllocatedCostsItemChanged(event: ChangedEvent) {
    if (event.originalEvent !== event) {
      if (event.originalEvent.source instanceof AccountingRecordItemModel) {
        this.onAllocatedCostsItemPropertyChanged(event.originalEvent);
      }
      return;
    }
    this.refreshAllocatedCostsTable(this.componentModel.items.get(`${AccountingRecordFieldKey.ALLOCATED_COSTS}Table`) as TableModel);
    this.refreshTotalAllocatedCosts();
  }

  private onAllocatedCostsItemPropertyChanged(event: ChangedEvent) {
    const changes = event.changes;
    if (changes[AccountingRecordItemFieldKey.DESCRIPTION]
      || changes[AccountingRecordItemFieldKey.AMOUNT]
      || changes[AccountingRecordItemFieldKey.UNIT_COSTS]
    ) {
      const item = event.source as AccountingRecordItemModel;
      const items = this.viewModel.accountingRecord.allocatedCosts;
      const tableModel = this.componentModel.items.get('allocatedCostsTable') as TableModel;
      const row = items.indexOf(item) + 1;
      const indexValueCellModel = tableModel.getCell(row, ColumnIndex.INDEX);
      this.refreshIndexValueCell(indexValueCellModel, item);
      const unitPriceInputCellModel = tableModel.getCell(row, ColumnIndex.UNIT_COSTS);
      this.refreshUnitPriceInputCell(unitPriceInputCellModel, item);
      const itemTotalValueCellModel = tableModel.getCell(row, ColumnIndex.TOTAL_PRICE);
      this.refreshItemTotalValueCell(itemTotalValueCellModel, item);
      this.refreshTotalAllocatedCosts();
    }
  }

  private onOperatingCostsItemChanged(event: ChangedEvent) {
    if (event.originalEvent !== event) {
      if (event.originalEvent.source instanceof AccountingRecordItemModel) {
        this.onOperatingCostsItemPropertyChanged(event.originalEvent);
      }
      return;
    }
    this.refreshOperatingCostsTable(this.componentModel.items.get(`${AccountingRecordFieldKey.OPERATING_COSTS}Table`) as TableModel);
    this.refreshTotalOperatingCosts();
  }

  private onOperatingCostsItemPropertyChanged(event: ChangedEvent) {
    const changes = event.changes;
    if (changes[AccountingRecordItemFieldKey.AMOUNT] || changes[AccountingRecordItemFieldKey.UNIT_COSTS]) {
      const item = event.source as AccountingRecordItemModel;
      const items = this.viewModel.accountingRecord.operatingCosts;
      const tableModel = this.componentModel.items.get('operatingCostsTable') as TableModel;
      const itemTotalValueCellModel = tableModel.getCell(items.indexOf(item) + 1, ColumnIndex.TOTAL_PRICE);
      this.refreshItemTotalValueCell(itemTotalValueCellModel, item);
      this.refreshTotalOperatingCosts();
    }
  }

  private refreshTotalAllocatedCosts() {
    this.viewModel.totalAllocatedCosts = this.calculateTotalCosts(AccountingRecordFieldKey.ALLOCATED_COSTS);
  }

  private refreshTotalOperatingCosts() {
    this.viewModel.totalOperatingCosts = this.calculateTotalCosts(AccountingRecordFieldKey.OPERATING_COSTS);
  }

  private calculateTotalCosts(itemsKey: string) {
    if (!this.viewModel.accountingRecord) {
      return null;
    }
    const items = this.viewModel.accountingRecord.get(itemsKey) as ObservableList<AccountingRecordItemModel>;
    return items.toArray().reduce((total, item) => {
      return (hasValue(item.amount) && hasValue(item.unitCosts)) ? total + (item.amount * item.unitCosts) : total;
    }, 0);
  }

  private refreshSalesTax() {
    this.viewModel.salesTax = this.calculateSalesTax();
  }

  private calculateSalesTax() {
    return this.viewModel.accountingRecord
    && hasValue(this.viewModel.totalAllocatedCosts)
    && hasValue(this.viewModel.accountingRecord.salesTaxRate)
      ? this.viewModel.totalAllocatedCosts * this.viewModel.accountingRecord.salesTaxRate / 100
      : null;
  }

  private refreshInvoiceAmount() {
    this.viewModel.invoiceAmount = hasValue(this.viewModel.totalAllocatedCosts) && hasValue(this.viewModel.salesTax)
      ? this.viewModel.totalAllocatedCosts + this.viewModel.salesTax
      : null;
  }

  private refreshInverseOperatingCosts() {
    this.viewModel.inverseOperatingCosts = hasValue(this.viewModel.totalOperatingCosts)
      ? -this.viewModel.totalOperatingCosts
      : null;
  }

  private refreshContributionMargin() {
    this.viewModel.contributionMargin = hasValue(this.viewModel.totalAllocatedCosts) && hasValue(this.viewModel.inverseOperatingCosts)
      ? this.viewModel.totalAllocatedCosts + this.viewModel.inverseOperatingCosts
      : null;
  }

  private refreshContributionMarginRate() {
    this.viewModel.contributionMarginRate = this.viewModel.totalAllocatedCosts && hasValue(this.viewModel.contributionMargin)
      ? this.viewModel.contributionMargin * 100 / this.viewModel.totalAllocatedCosts
      : null;
  }

  protected createComponentModel(): AccountingDetailComponentModel {
    const model = new AccountingDetailComponentModel({
      tags: ['app-accounting-detail-container']
    });
    this.refreshComponentModel(model);
    return model;
  }

  protected refreshComponentModel(model: AccountingDetailComponentModel): void {
    model.items.clear();
    if (!this.viewModel.accountingRecord) {
      return;
    }
    const items = {
      type: this.createInputFieldModel(AccountingRecordFieldKey.TYPE, (controlData) => new SelectInputModel({
        options: this.accountingRecordTypeSelectOptions.values,
        formattedOptions: this.accountingRecordTypeSelectOptions.formattedValues,
        isFocused: true,
        ...controlData,
      })),
      logo: this.createInputFieldModel(AccountingRecordFieldKey.LOGO, (controlData) => new SelectInputModel({
        options: this.logoSelectOptions.values,
        formattedOptions: this.logoSelectOptions.formattedValues,
        ...controlData,
      })),
      letterHeader: this.letterHeaderViewService.componentModel,
    };
    if (this.viewModel.accountingRecord.type) {
      Object.assign(items, {
        headerContainer: this.createHeaderContainer(),
        allocatedCostsTitle: this.createTitleText(AccountingRecordFieldKey.ALLOCATED_COSTS),
        allocatedCostsTable: this.createAllocatedCostsTable(),
      });
    }
    if (!this.shellViewService.viewModel.isPrinting && (
      this.viewModel.accountingRecord.type === AccountingRecordTypes.INVOICE ||
      this.viewModel.accountingRecord.type === AccountingRecordTypes.DELIVERY_NOTE
    )) {
      Object.assign(items, {
        operatingCostsTitle: this.createTitleText(AccountingRecordFieldKey.OPERATING_COSTS),
        operatingCostsTable: this.createOperatingCostsTable(),
        resultTitle: this.createTitleText('result'),
        resultTable: this.createResultTable(),
      });
    }
    Object.assign(items, {
      price: this.createInputFieldModel(AccountingRecordFieldKey.PRICE, data => new TextInputModel(data)),
      paymentTerms: this.createInputFieldModel(AccountingRecordFieldKey.PAYMENT_TERMS, data => new TextInputModel({
        type: ViewComponentKey.TEXT_AREA, autoselect: false, ...data
      })),
    });
    if (this.viewModel.accountingRecord.type === AccountingRecordTypes.OFFERING && this.shellViewService.viewModel.isPrinting) {
      Object.assign(items, {
        orderConfirmation: this.createOrderConfirmationModel(),
      });
    }
    Object.assign(items, {
      complimentaryClose: this.createInputFieldModel(AccountingRecordFieldKey.COMPLIMENTARY_CLOSE, data => new TextInputModel({
        type: ViewComponentKey.TEXT_AREA, autoselect: false, ...data
      }))
    });
    Object.assign(items, {
      pageFooter: this.createInputFieldModel(AccountingRecordFieldKey.PAGE_FOOTER, data => new TextInputModel({
        type: ViewComponentKey.TEXT_AREA, autoselect: false, ...data
      }))
    });
    model.items.setValues(items, { exclusive: true });

  }

  private createHeaderContainer(): ContainerModel {
    const accountingRecord = this.viewModel.accountingRecord;
    return new ContainerModel({
      tags: ['app-accounting-header-container'],
      items: [
        new FormFieldModel({
          key: '0',
          label: true,
          control: new TextInputModel({
            key: AccountingRecordFieldKey.DESIGNATION,
            scope: `${AccountingViewKey.ACCOUNTING_DETAIL}/headerContainer`,
            context: accountingRecord,
            contextBinding: ContextBindings.TWO_WAY,
            onKeydown: (event, model: TextInputModel) => this.onInputModelKeydown(event, model)
          })
        }),
        new FormFieldModel({
          key: '1',
          label: true,
          control: new TextInputModel({
            key: AccountingRecordFieldKey.CUSTOMER,
            scope: `${AccountingViewKey.ACCOUNTING_DETAIL}/headerContainer`,
            validation: { [CoreValidationKey.REQUIRED]: true },
            context: accountingRecord,
            contextBinding: ContextBindings.TWO_WAY,
            onKeydown: (event, model: TextInputModel) => this.onInputModelKeydown(event, model)
          })
        }),
        new FormFieldModel({
          key: '2',
          label: true,
          control: new TextInputModel({
            key: AccountingRecordFieldKey.OFFER_DATE,
            scope: `${AccountingViewKey.ACCOUNTING_DETAIL}/headerContainer`,
            format: CoreFormatKey.DATE,
            context: accountingRecord,
            contextBinding: ContextBindings.TWO_WAY,
            onKeydown: (event, model: TextInputModel) => this.onInputModelKeydown(event, model)
          })
        }),
        new FormFieldModel({
          key: '3',
          label: true,
          control: new TextInputModel({
            key: AccountingRecordFieldKey.EXECUTION_DATE,
            scope: `${AccountingViewKey.ACCOUNTING_DETAIL}/headerContainer`,
            format: CoreFormatKey.DATE,
            context: accountingRecord,
            contextBinding: ContextBindings.TWO_WAY,
            onKeydown: (event, model: TextInputModel) => this.onInputModelKeydown(event, model)
          })
        }),
        new FormFieldModel({
          key: '4',
          label: true,
          control: new TextInputModel({
            key: AccountingRecordFieldKey.INVOICE,
            scope: `${AccountingViewKey.ACCOUNTING_DETAIL}/headerContainer`,
            context: accountingRecord,
            contextBinding: ContextBindings.TWO_WAY,
            onKeydown: (event, model: TextInputModel) => this.onInputModelKeydown(event, model)
          })
        }),
      ]
    });
  }

  private createTitleText(key: string): TextModel {
    return new TextModel({
      key: `${key}Title`, tags: ['app-accounting-detail-title'],
      text: `${AccountingViewKey.ACCOUNTING_DETAIL}/${key}`
    });
  }

  private createAllocatedCostsTable(): TableModel {
    const model = new TableModel({
      key: AccountingRecordFieldKey.ALLOCATED_COSTS,
      columns: this.createTableColumnModels(),
      columnHeaderCount: 1,
      rowHeaderCount: 1
    });
    this.refreshAllocatedCostsTable(model);
    return model;
  }

  private refreshAllocatedCostsTable(model: TableModel) {
    model.setValues({
      rows: this.createAllocatedCostsTableRowModels(),
      cells: this.createAllocatedCostsTableCellModels(),
    });
  }

  private createAllocatedCostsTableRowModels(): TableRowModel[] {
    if (this.shellViewService.viewModel.isPrinting && this.viewModel.accountingRecord.type !== AccountingRecordTypes.INVOICE) {
      return [];
    }
    const itemsCount = this.viewModel.accountingRecord.allocatedCosts.size;
    return [
      new TableRowModel({ index: itemsCount + 1, tags: ['app-table-footer-row', 'app-subtotal-row'] }),
      new TableRowModel({ index: itemsCount + 2, tags: ['app-table-footer-row'] }),
      new TableRowModel({ index: itemsCount + 3, tags: ['app-table-footer-row', 'app-invoice-amount-row'] })
    ];
  }

  private createOperatingCostsTable(): TableModel {
    const model = new TableModel({
      key: AccountingRecordFieldKey.OPERATING_COSTS,
      columns: this.createTableColumnModels(),
      columnHeaderCount: 1,
      rowHeaderCount: 1
    });
    this.refreshOperatingCostsTable(model);
    return model;
  }

  private refreshOperatingCostsTable(model: TableModel) {
    model.setValues({
      rows: [],
      cells: this.createOperatingCostsTableCellModels(),
    });
  }

  private createResultTable(): TableModel {
    return new TableModel({
      tags: ['app-result-table'],
      columnCount: 7,
      columns: this.createTableColumnModels(),
      cells: this.createResultCellModels()
    });
  }

  private createTableColumnModels() {
    return [
      new TableColumnModel({ index: ColumnIndex.INDEX, tags: ['app-index-column'] }),
      new TableColumnModel({ index: ColumnIndex.AMOUNT, tags: ['app-amount-column'] }),
      new TableColumnModel({ index: ColumnIndex.DESCRIPTION, tags: ['app-description-column'] }),
      new TableColumnModel({ index: ColumnIndex.UNIT, tags: ['app-unit-column'] }),
      new TableColumnModel({ index: ColumnIndex.UNIT_COSTS, tags: ['app-unit-costs-column'] }),
      new TableColumnModel({ index: ColumnIndex.TOTAL_PRICE, tags: ['app-total-price-column'] }),
      new TableColumnModel({ index: ColumnIndex.ACTION, tags: ['app-action-column'] }),
    ];
  }

  private createAllocatedCostsTableCellModels(): TableCellModel[] {
    let tableCellModels = this.createHeaderTableCellModels(AccountingCommonViewFieldKey.TOTAL_ALLOCATED_COSTS)
      .concat(this.createRecordItemTableCellModels(AccountingRecordFieldKey.ALLOCATED_COSTS));
    if (!this.shellViewService.viewModel.isPrinting || this.viewModel.accountingRecord.type === AccountingRecordTypes.INVOICE
    ) {
      tableCellModels = tableCellModels
        .concat(this.createCostsTotalCellModels(AccountingCommonViewFieldKey.TOTAL_ALLOCATED_COSTS))
        .concat(this.createSalesTaxCellModels())
        .concat(this.createInvoiceAmountCellModels());
    }
    return tableCellModels;
  }

  private createOperatingCostsTableCellModels(): TableCellModel[] {
    return this.createHeaderTableCellModels(AccountingCommonViewFieldKey.TOTAL_OPERATING_COSTS)
      .concat(this.createRecordItemTableCellModels(AccountingRecordFieldKey.OPERATING_COSTS))
      .concat(this.createCostsTotalCellModels(AccountingCommonViewFieldKey.TOTAL_OPERATING_COSTS));
  }

  private createHeaderTableCellModels(key: string): TableCellModel[] {
    return [
      new TableCellModel({
        row: 0, column: ColumnIndex.INDEX,
        content: `${AccountingViewKey.ACCOUNTING_DETAIL}/recordItem/index`
      }),
      new TableCellModel({
        row: 0, column: ColumnIndex.AMOUNT,
        content: `${AccountingViewKey.ACCOUNTING_DETAIL}/recordItem/amount`
      }),
      new TableCellModel({
        row: 0, column: ColumnIndex.DESCRIPTION,
        content: `${AccountingViewKey.ACCOUNTING_DETAIL}/recordItem/description`
      }),
      new TableCellModel({
        row: 0, column: ColumnIndex.UNIT,
        content: `${AccountingViewKey.ACCOUNTING_DETAIL}/recordItem/unit`
      }),
      new TableCellModel({
        row: 0, column: ColumnIndex.UNIT_COSTS,
        content: `${AccountingViewKey.ACCOUNTING_DETAIL}/recordItem/unitPrice`
      }),
      new TableCellModel({
        row: 0, column: ColumnIndex.TOTAL_PRICE,
        content: `${AccountingViewKey.ACCOUNTING_DETAIL}/recordItem/totalPrice`
      }),
      new TableCellModel({
        row: 0, column: ColumnIndex.ACTION,
        content: new ActionModel({
          tags: ['app-form-btn', 'btn-sm', 'btn-outline-secondary'],
          content: '+',
          onClick: () => {
            let items: ObservableList<AccountingRecordItemModel>;
            let tableModel: TableModel;
            switch (key) {
              case AccountingCommonViewFieldKey.TOTAL_ALLOCATED_COSTS:
                items = this.viewModel.accountingRecord.allocatedCosts;
                tableModel = this.componentModel.items.get('allocatedCostsTable') as TableModel;
                break;
              case AccountingCommonViewFieldKey.TOTAL_OPERATING_COSTS:
                items = this.viewModel.accountingRecord.operatingCosts;
                tableModel = this.componentModel.items.get('operatingCostsTable') as TableModel;
                break;
              default:
                return;
            }
            items.push(new AccountingRecordItemModel({}));
            const descriptionInputModel = tableModel.getCell(items.size, ColumnIndex.DESCRIPTION).content as TextInputModel;
            descriptionInputModel.isFocused = true;
          },
          onKeydown: (event, model: ActionModel) => this.onInputModelKeydown(event, model)
        })
      }),
    ];
  }

  private createRecordItemTableCellModels(key: string): TableCellModel[] {
    const cells: TableCellModel[] = [];
    const items = this.viewModel.accountingRecord.get(key) as ObservableList<AccountingRecordItemModel>;
    items.forEach((item, index) => {
      const row = index + 1;
      cells.push(...[
        this.createIndexValueCell(row, ColumnIndex.INDEX, item),
        this.createDescriptionInputCell(row, ColumnIndex.DESCRIPTION, item),
        this.createAmountInputCell(row, ColumnIndex.AMOUNT, item),
        this.createUnitInputCell(row, ColumnIndex.UNIT, item),
        this.createUnitPriceInputCell(row, ColumnIndex.UNIT_COSTS, item),
        this.createItemTotalValueCell(row, ColumnIndex.TOTAL_PRICE, item),
        this.createRemoveRowCellModel(row, ColumnIndex.ACTION, items)
      ]);
    });
    return cells;
  }

  private createIndexValueCell(row: number, column: number, item: AccountingRecordItemModel): TableCellModel {
    const model = new TableCellModel({
      row, column, content: new ValueModel({
        value: row,
        format: CoreFormatKey.NUMBER_0,
        alignment: Alignments.RIGHT
      })
    });
    this.refreshIndexValueCell(model, item);
    return model;
  }

  private refreshIndexValueCell(model: TableCellModel, item: AccountingRecordItemModel) {
    model.content.isHidden = !item.description;
  }

  private createDescriptionInputCell(row: number, column: number, item: AccountingRecordItemModel): TableCellModel {
    return this.createInputCell(row, column, new TextInputModel({
      key: AccountingRecordItemFieldKey.DESCRIPTION,
      type: this.shellViewService.viewModel.isPrinting ? ViewComponentKey.VALUE : ViewComponentKey.TEXT_INPUT,
      context: item, contextBinding: ContextBindings.TWO_WAY,
    }));
  }

  private createAmountInputCell(row: number, column: number, item: AccountingRecordItemModel): TableCellModel {
    return this.createInputCell(row, column, new TextInputModel({
      key: AccountingRecordItemFieldKey.AMOUNT,
      format: 'input-number-2', alignment: Alignments.RIGHT,
      context: item, contextBinding: ContextBindings.TWO_WAY,
      validation: {
        [CoreValidationKey.TYPE]: { type: 'number', strict: true }
      },
    }));
  }

  private createUnitInputCell(row: number, column: number, item: AccountingRecordItemModel): TableCellModel {
    return this.createInputCell(row, column, new TextInputModel({
      key: AccountingRecordItemFieldKey.UNIT,
      context: item, contextBinding: ContextBindings.TWO_WAY,
    }));
  }

  private createUnitPriceInputCell(row: number, column: number, item: AccountingRecordItemModel): TableCellModel {
    const model = this.createInputCell(row, column, new TextInputModel({
      key: AccountingRecordItemFieldKey.UNIT_COSTS,
      format: 'input-number-2', alignment: Alignments.RIGHT,
      suffix: hasValue(item.unitCosts) ? ' €' : ' ',
      context: item, contextBinding: ContextBindings.TWO_WAY,
      validation: {
        [CoreValidationKey.TYPE]: { type: 'number' }
      },
      onValueChanged: () => this.refreshUnitPriceInputCell(model, item)
    }));
    this.refreshUnitPriceInputCell(model, item);
    return model;
  }

  private refreshUnitPriceInputCell(model: TableCellModel, item: AccountingRecordItemModel) {
    model.content.setValues({
      suffix: hasValue(item.unitCosts) ? ' €' : '  '
    });
  }

  private createItemTotalValueCell(row: number, column: number, record: AccountingRecordItemModel): TableCellModel {
    const model = new TableCellModel({
      row, column, content: new ValueModel({
        tags: ['app-form-value'],
        format: 'input-number-2', alignment: Alignments.RIGHT,
        onValueChanged: () => this.refreshUnitPriceInputCell(model, record),
      })
    });
    this.refreshItemTotalValueCell(model, record);
    return model;
  }

  private refreshItemTotalValueCell(model: TableCellModel, item: AccountingRecordItemModel): void {
    const valueModel = model.content as ValueModel;
    if (!hasValue(item.amount) || !hasValue(item.unitCosts)) {
      valueModel.value = undefined;
    } else {
      valueModel.value = item.amount * item.unitCosts;
    }
    valueModel.suffix = hasValue(valueModel.value) ? ' €' : '  ';
  }

  private createRemoveRowCellModel(row: number, column: number, items: ObservableList<AccountingRecordItemModel>) {
    return new TableCellModel({
      row, column, content: new ActionModel({
        tags: ['app-form-btn', 'btn-sm', 'btn-outline-secondary'],
        content: '-',
        isHidden: false,
        onClick: () => {
          items.remove(row - 1);
        },
        onKeydown: (event, model: ActionModel) => this.onInputModelKeydown(event, model)
      })
    });
  }

  private createCostsTotalCellModels(key: string): TableCellModel[] {
    let description: string;
    let items: ObservableList<AccountingRecordItemModel>;
    switch (key) {
      case AccountingCommonViewFieldKey.TOTAL_ALLOCATED_COSTS:
        description = `${AccountingViewKey.ACCOUNTING_DETAIL}/subtotal`;
        items = this.viewModel.accountingRecord.allocatedCosts;
        break;
      case AccountingCommonViewFieldKey.TOTAL_OPERATING_COSTS:
        description = `${AccountingViewKey.ACCOUNTING_DETAIL}/total`;
        items = this.viewModel.accountingRecord.operatingCosts;
        break;
    }
    const row = items.size + 1;
    return [
      new TableCellModel({
        row, column: ColumnIndex.DESCRIPTION,
        tags: ['app-table-footer-description'],
        content: description
      }),
      new TableCellModel({
        row, column: ColumnIndex.TOTAL_PRICE, content: new ValueModel({
          key, tags: ['app-form-value'],
          format: CoreFormatKey.NUMBER_2, alignment: Alignments.RIGHT,
          suffix: ' €',
          context: this.viewModel,
          contextBinding: ContextBindings.TWO_WAY
        })
      })
    ];
  }

  private createSalesTaxCellModels() {
    const row = this.viewModel.accountingRecord.allocatedCosts.size + 2;
    return [
      new TableCellModel({
        row, column: ColumnIndex.DESCRIPTION,
        tags: ['app-table-footer-description', 'app-sales-tax-label-cell'],
        content: `${AccountingViewKey.ACCOUNTING_DETAIL}/salesTax`
      }),
      this.createInputCell(row, ColumnIndex.UNIT_COSTS, new TextInputModel({
        key: AccountingRecordFieldKey.SALES_TAX_RATE,
        format: CoreFormatKey.NUMBER_0, alignment: Alignments.RIGHT,
        suffix: ' %',
        validation: [CoreValidationKey.REQUIRED],
        context: this.viewModel.accountingRecord,
        contextBinding: ContextBindings.TWO_WAY
      })),
      new TableCellModel({
        row, column: ColumnIndex.TOTAL_PRICE, content: new ValueModel({
          key: AccountingCommonViewFieldKey.SALES_TAX, tags: ['app-form-value'],
          format: CoreFormatKey.NUMBER_2, alignment: Alignments.RIGHT,
          suffix: ' €',
          context: this.viewModel,
          contextBinding: ContextBindings.TWO_WAY
        })
      }),
    ];
  }

  private createInvoiceAmountCellModels() {
    const row = this.viewModel.accountingRecord.allocatedCosts.size + 3;
    return [
      new TableCellModel({
        row, column: ColumnIndex.DESCRIPTION,
        tags: ['app-table-footer-description', 'app-invoice-amount-label-cell'],
        content: `${AccountingViewKey.ACCOUNTING_DETAIL}/invoiceAmount`
      }),
      new TableCellModel({
        row, column: ColumnIndex.TOTAL_PRICE, content: new ValueModel({
          key: AccountingCommonViewFieldKey.INVOICE_AMOUNT, tags: ['app-form-value'],
          format: CoreFormatKey.NUMBER_2, alignment: Alignments.RIGHT,
          suffix: ' €',
          context: this.viewModel,
          contextBinding: ContextBindings.TWO_WAY
        })
      }),
    ];
  }

  private createResultCellModels(): TableCellModel[] {
    return [
      new TableCellModel({
        row: 0, column: ColumnIndex.DESCRIPTION,
        content: `${AccountingViewKey.ACCOUNTING_DETAIL}/allocatedCosts`
      }),
      new TableCellModel({
        row: 0, column: ColumnIndex.TOTAL_PRICE, content: new ValueModel({
          key: AccountingCommonViewFieldKey.TOTAL_ALLOCATED_COSTS, tags: ['app-form-value'],
          format: CoreFormatKey.NUMBER_2, alignment: Alignments.RIGHT,
          suffix: ' €',
          context: this.viewModel,
          contextBinding: ContextBindings.TWO_WAY
        })
      }),
      new TableCellModel({
        row: 1, column: ColumnIndex.DESCRIPTION,
        content: `- #(${AccountingViewKey.ACCOUNTING_DETAIL}/operatingCosts)`
      }),
      new TableCellModel({
        row: 1, column: ColumnIndex.TOTAL_PRICE, content: new ValueModel({
          key: AccountingCommonViewFieldKey.INVERSE_OPERATING_COSTS, tags: ['app-form-value'],
          format: CoreFormatKey.NUMBER_2, alignment: Alignments.RIGHT,
          suffix: ' €',
          context: this.viewModel,
          contextBinding: ContextBindings.TWO_WAY
        })
      }),
      new TableCellModel({
        row: 2, column: ColumnIndex.DESCRIPTION,
        content: `= #(${AccountingViewKey.ACCOUNTING_DETAIL}/contributionMargin)`
      }),
      new TableCellModel({
        row: 2, column: ColumnIndex.UNIT_COSTS, content: new ValueModel({
          key: AccountingCommonViewFieldKey.CONTRIBUTION_MARGIN_RATE, tags: ['app-form-value'],
          format: CoreFormatKey.NUMBER_2, alignment: Alignments.RIGHT,
          suffix: ' %',
          context: this.viewModel,
          contextBinding: ContextBindings.TWO_WAY
        })
      }),
      new TableCellModel({
        row: 2, column: ColumnIndex.TOTAL_PRICE, content: new ValueModel({
          key: AccountingCommonViewFieldKey.CONTRIBUTION_MARGIN, tags: ['app-form-value'],
          format: CoreFormatKey.NUMBER_2, alignment: Alignments.RIGHT,
          suffix: ' €',
          context: this.viewModel,
          contextBinding: ContextBindings.TWO_WAY
        })
      }),
    ];
  }

  private createInputCell(row: number, column: number, inputModel: ValueModel): TableCellModel {
    inputModel.setValues({
      // placeholder: inputModel.isFocused ? '' : ' - ',
      // onChanged: (event: ChangedEvent, model: ValueModel) => {
      //   if (event.changes[ComponentFieldKey.IS_FOCUSED]) {
      //     model.setValues({ placeholder: model.isFocused ? '' : ' - ' });
      //   }
      // },
      onKeydown: (event, model: ValueModel) => this.onInputModelKeydown(event, model)
    });
    return new TableCellModel({ row, column, content: inputModel });
  }

  private onInputModelKeydown(event: KeyboardEvent, model: ComponentModel) {
    if (event.key === KeyCodes.UP.key
      || event.key === KeyCodes.DOWN.key
      || event.key === KeyCodes.RIGHT.key
      || event.key === KeyCodes.LEFT.key
    ) {
      let focusModel: ComponentModel;
      if (event.key === KeyCodes.UP.key) {
        focusModel = this.getAboveInputModel(model);
      } else if (event.key === KeyCodes.DOWN.key) {
        focusModel = this.getBelowInputModel(model);
      } else if (event.key === KeyCodes.RIGHT.key) {
        const modelElement = event.currentTarget as HTMLElement;
        if (event.ctrlKey && event.altKey
          || modelElement.tagName.toLowerCase() !== 'input'
          || modelElement['selectionStart'] === modelElement['value'].length
        ) {
          focusModel = this.getRightInputModel(model);
        }
      } else if (event.key === KeyCodes.LEFT.key) {
        const modelElement = event.currentTarget as HTMLElement;
        if (event.ctrlKey && event.altKey
          || modelElement.tagName.toLowerCase() !== 'input'
          || modelElement['selectionEnd'] === 0
        ) {
          focusModel = this.getLeftInputModel(model);
        }
      }
      if (focusModel) {
        focusModel.isFocused = true;
        event.preventDefault();
      }
    }
  }

  private getAboveInputModel(model: ComponentModel): ComponentModel {
    if (model.parent instanceof FormFieldModel) {
      const formFieldModel = model.parent;
      const headerContainer = this.componentModel.headerContainer;
      if (formFieldModel.parent === headerContainer) {
        const formFieldIndex = +formFieldModel.key;
        if (formFieldIndex > 0) {
          return headerContainer.items.get(`${formFieldIndex - 1}`).get('control');
        }
        const operatingCostsTable = this.componentModel.operatingCostsTable;
        if (operatingCostsTable.rowCount > 2) {
          return operatingCostsTable.getCell(operatingCostsTable.rowCount - 2, ColumnIndex.DESCRIPTION).content;
        } else {
          return operatingCostsTable.getCell(operatingCostsTable.rowCount - 2, ColumnIndex.ACTION).content;
        }
      }
    } else if (model.parent instanceof TableCellModel) {
      const tableCellModel = model.parent;
      const tableModel = tableCellModel.parent as TableModel;
      const tableItems = this.viewModel.accountingRecord.get(tableModel.key) as ObservableList<AccountingRecordItemModel>;
      const row = tableCellModel.row;
      const headerContainerItems = this.componentModel.headerContainer.items;
      if (row === 0) {
        if (tableModel.key === AccountingRecordFieldKey.ALLOCATED_COSTS) {
          return headerContainerItems.get(`${headerContainerItems.size - 1}`).get('control');
        } else if (tableModel.key === AccountingRecordFieldKey.OPERATING_COSTS) {
          const allocatedCostsTable = this.componentModel.allocatedCostsTable;
          return allocatedCostsTable.getCell(allocatedCostsTable.rowCount - 2, ColumnIndex.UNIT_COSTS).content;
        } else {
          return null;
        }
      }
      if (row === 1) {
        return tableModel.getCell(0, ColumnIndex.ACTION).content;
      }
      if (row <= tableItems.size) {
        return tableModel.getCell(row - 1, tableCellModel.column).content;
      }
      if (tableModel.key === AccountingRecordFieldKey.ALLOCATED_COSTS) {
        return tableModel.getCell(tableItems.size, ColumnIndex.DESCRIPTION).content;
      }
    }
    return null;
  }

  private getBelowInputModel(model: ComponentModel): ComponentModel {
    if (model.parent instanceof FormFieldModel) {
      const formFieldModel = model.parent;
      const headerContainer = this.componentModel.headerContainer;
      if (formFieldModel.parent === headerContainer) {
        const formFieldIndex = +formFieldModel.key;
        return formFieldIndex < (headerContainer.items.size - 1)
          ? headerContainer.items.get(`${formFieldIndex + 1}`).get('control')
          : this.componentModel.allocatedCostsTable.getCell(0, ColumnIndex.ACTION).content;
      }
    } else if (model.parent instanceof TableCellModel) {
      const tableCellModel = model.parent;
      const tableModel = tableCellModel.parent as TableModel;
      const tableItems = this.viewModel.accountingRecord.get(tableModel.key) as ObservableList<AccountingRecordItemModel>;
      const row = tableCellModel.row;
      if (row < tableItems.size) {
        if (row === 0) {
          return tableModel.getCell(row + 1, ColumnIndex.DESCRIPTION).content;
        } else {
          return tableModel.getCell(row + 1, tableCellModel.column).content;
        }
      }
      if (tableModel.key === AccountingRecordFieldKey.ALLOCATED_COSTS) {
        if (row === tableItems.size) {
          return tableModel.getCell(row + 2, ColumnIndex.UNIT_COSTS).content;
        } else {
          return this.componentModel.operatingCostsTable.getCell(0, ColumnIndex.ACTION).content;
        }
      } else if (tableModel.key === AccountingRecordFieldKey.OPERATING_COSTS) {
        return this.componentModel.headerContainer.items.get('0').get('control');
      }
    }
    return null;
  }

  private getRightInputModel(model: ComponentModel): ComponentModel {
    if (model.parent instanceof TableCellModel) {
      const tableCellModel = model.parent;
      const tableModel = tableCellModel.parent as TableModel;
      const tableItems = this.viewModel.accountingRecord.get(tableModel.key) as ObservableList<AccountingRecordItemModel>;
      if (tableCellModel.row > 0 && tableCellModel.row <= tableItems.size) {
        switch (tableCellModel.column) {
          case ColumnIndex.DESCRIPTION:
          case ColumnIndex.AMOUNT:
          case ColumnIndex.UNIT:
            return tableModel.getCell(tableCellModel.row, tableCellModel.column + 1).content;
          case ColumnIndex.UNIT_COSTS:
            return tableModel.getCell(tableCellModel.row, ColumnIndex.ACTION).content;
          case ColumnIndex.ACTION:
            return tableModel.getCell(tableCellModel.row, ColumnIndex.DESCRIPTION).content;
        }
      }
    }
    return null;
  }

  private getLeftInputModel(model: ComponentModel): ComponentModel {
    if (model.parent instanceof TableCellModel) {
      const tableCellModel = model.parent;
      const tableModel = tableCellModel.parent as TableModel;
      const tableItems = this.viewModel.accountingRecord.get(tableModel.key) as ObservableList<AccountingRecordItemModel>;
      if (tableCellModel.row > 0 && tableCellModel.row <= tableItems.size) {
        switch (tableCellModel.column) {
          case ColumnIndex.AMOUNT:
          case ColumnIndex.UNIT:
          case ColumnIndex.UNIT_COSTS:
            return tableModel.getCell(tableCellModel.row, tableCellModel.column - 1).content;
          case ColumnIndex.ACTION:
            return tableModel.getCell(tableCellModel.row, ColumnIndex.UNIT_COSTS).content;
          case ColumnIndex.DESCRIPTION:
            return tableModel.getCell(tableCellModel.row, ColumnIndex.ACTION).content;
        }
      }
    }
    return null;
  }

  private createInputFieldModel(key: string, modelFactory: (data: ValueData) => ValueModel): FormFieldModel {
    if (this.shellViewService.viewModel.isPrinting) {
      modelFactory = data => new ValueModel({ type: ViewComponentKey.VALUE, ...data });
    }
    const valueData: ValueData = {
      key, scope: AccountingViewKey.ACCOUNTING_DETAIL,
      tags: ['form-control', 'form-control-sm', `app-accounting-record-${key}-control`],
      context: this.viewModel.accountingRecord,
      contextBinding: ContextBindings.TWO_WAY,
    };
    return new FormFieldModel({
      key: `${key}Field`,
      tags: [`app-accounting-${key}-field`],
      label: true,
      control: modelFactory(valueData)
    });
  }

  private createOrderConfirmationModel(): ContainerModel {
    return new ContainerModel({
      tags: ['app-accounting-order-confirmation'],
      items: [
        new TextModel({
          tags: ['app-accounting-order-confirmation-message'],
          text: `${AccountingViewKey.ACCOUNTING_DETAIL}/orderConfirmation/message`
        }),
        new TextModel({
          tags: ['app-accounting-order-confirmation-locationAndDate'],
          text: `${AccountingViewKey.ACCOUNTING_DETAIL}/orderConfirmation/locationAndDate`
        }),
        new TextModel({
          tags: ['app-accounting-order-confirmation-uidNumber'],
          text: `${AccountingViewKey.ACCOUNTING_DETAIL}/orderConfirmation/uidNumber`
        }),
        new TextModel({
          tags: ['app-accounting-order-confirmation-customer'],
          text: `${AccountingViewKey.ACCOUNTING_DETAIL}/orderConfirmation/customer`
        }),
        new TextModel({
          tags: ['app-accounting-order-confirmation-startDate-label'],
          text: `${AccountingViewKey.ACCOUNTING_DETAIL}/orderConfirmation/desiredStartDate`
        }),
        new TextModel({
          tags: ['app-accounting-order-confirmation-startDate-value'],
          text: ` `
        }),
      ]
    });
  }

}
