import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

import { AlertService, ApplicationError, CoreActionKey, isEmpty, TextService } from '@typescript-kit/core';
import { ActionModel, CheckboxModel, ContextBindings, KeyCodes, SelectInputModel, TextInputModel } from '@typescript-kit/view';
import { TextInputComponent } from '@angular-kit/view';

import { ShellGlobalTextKey } from '../../key/shell-global-text.key';
import { ShellViewService } from '../../view/shell.view-service';
import { ShellImageKey } from '../../key/shell-image.key';
import { ShellViewKey } from '../../key/shell-view.key';
import { RegisterCompanyDto } from '@teamworks/global';
import { CompanyService } from '../../../shared/service/company.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RegisterComponent implements OnInit, AfterViewInit {
  private companyInfo: RegisterCompanyDto;

  public logoKey: string;
  public titleText: string;
  public vendorName: string;
  public developedByText: string;
  public userInfoText: string;
  public companyInfoText: string;

  public form: {
    nameInput: TextInputModel,
    userNameInput: TextInputModel,
    emailInput: TextInputModel
  };

  public privacyPolicyCheck: CheckboxModel;
  public jobProcessingContractCheck: CheckboxModel;
  public termsAndConditionsCheck: CheckboxModel;

  public registerAction: ActionModel;
  public loginAction: ActionModel;

  @ViewChild('name', {static: true}) nameInputComponent: TextInputComponent;
  @ViewChild('userName', {static: true}) userNameInputComponent: TextInputComponent;
  @ViewChild('email', {static: true}) emailInputComponent: TextInputComponent;

  constructor(
    private readonly router: Router,
    private readonly companyService: CompanyService,
    private readonly alertService: AlertService,
    private readonly shellViewService: ShellViewService,
    private readonly textService: TextService
  ) {
  }

  ngOnInit() {
    this.companyInfo = {
      nameOfUser: '',
      userName: '',
      email: '',
      companyName: '',
      city: '',
      countryCode: 'austria',
      street: '',
      zipCode: '',
      taxId: ''
    };

    this.logoKey = ShellImageKey.VENDOR_LOGO;
    this.titleText = this.shellViewService.viewModel.appTitle;
    this.vendorName = ShellGlobalTextKey.VENDOR;
    this.developedByText = `${ShellViewKey.REGISTER}/developed-by`;

    this.form = {
      nameInput: new TextInputModel({
        key: 'nameOfUser',
        scope: `${ShellViewKey.REGISTER}/form`,
        context: this.companyInfo,
        contextBinding: ContextBindings.MODEL_TO_CONTEXT,
        autocompleteToken: 'name',
        placeholder: true,
        onKeydown: (event) => this.onKeyDown(event, this.form.nameInput)
      }),
      userNameInput: new TextInputModel({
        key: 'userName',
        scope: `${ShellViewKey.REGISTER}/form`,
        context: this.companyInfo,
        contextBinding: ContextBindings.MODEL_TO_CONTEXT,
        placeholder: true,
        autocompleteToken: 'username',
        onKeydown: (event) => this.onKeyDown(event, this.form.userNameInput)
      }),
      emailInput: new TextInputModel({
        key: 'email',
        scope: `${ShellViewKey.REGISTER}/form`,
        context: this.companyInfo,
        contextBinding: ContextBindings.MODEL_TO_CONTEXT,
        placeholder: true,
        autocompleteToken: 'email',
        onKeydown: (event) => this.onKeyDown(event, this.form.emailInput)
      })
    };
    this.privacyPolicyCheck = new CheckboxModel({
      key: `${ShellViewKey.REGISTER}/agreements/privacy-policy`
    });
    this.jobProcessingContractCheck = new CheckboxModel({
      key: `${ShellViewKey.REGISTER}/agreements/job-processing-contract`
    });
    this.termsAndConditionsCheck = new CheckboxModel({
      key: `${ShellViewKey.REGISTER}/agreements/terms-and-conditions`
    });
    this.registerAction = new ActionModel({
      content: CoreActionKey.REGISTER,
      onClick: () => this.register()
    });
    this.loginAction = new ActionModel({
      content: `${ShellViewKey.REGISTER}/login`,
      onClick: () => this.login()
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.form.nameInput.isFocused = true, 200);
  }

  register() {
    if (!this.privacyPolicyCheck.checked || !this.jobProcessingContractCheck.checked || !this.termsAndConditionsCheck.checked) {
      this.alertService.discardAll();

      this.alertCheckboxNotCheked(this.jobProcessingContractCheck);
      this.alertCheckboxNotCheked(this.termsAndConditionsCheck);
      this.alertCheckboxNotCheked(this.privacyPolicyCheck);
      return;
    }

    // TODO: Work-around chrome autofill
    this.form.nameInput.value = this.nameInputComponent.inputElement.value;
    this.form.userNameInput.value = this.userNameInputComponent.inputElement.value;
    this.form.emailInput.value = this.emailInputComponent.inputElement.value;

    this.companyService.registerCompany(this.companyInfo)
      .then(() => {
        const redirect = this.router.routerState.snapshot.root.queryParamMap.get('redirect');
        return this.router.navigate([redirect || ''])
          .then(() => {
            const message = this.textService.getText(`${ShellViewKey.REGISTER}/register-notification`, {email: this.companyInfo.email});
            this.alertService.alert({message});
          });
      })
      .catch((error: ApplicationError) => {
        this.alertService.discardAll();
        this.alertService.alertError(error);
      });
  }

  login() {
    return this.router.navigate(['/login']);
  }

  getAgreementLabel(agreement: string): string {
    const question = this.textService.getText(`${ShellViewKey.REGISTER}/agreements/${agreement}/question`);

    const linkMatches = question.match(/\[(.+)\]\((.+)\)/);
    if (linkMatches.length < 3) {
      return question;
    }

    return question.replace(linkMatches[0], `<a href="${linkMatches[2]}" target="_blank">${linkMatches[1]}</a>`);
  }

  private onKeyDown(event: KeyboardEvent, source: TextInputModel | SelectInputModel): void {
    if (event.key !== KeyCodes.ENTER.key) {
      return;
    }

    event.preventDefault();

    setTimeout(() => {
      const inputs = Object.values(this.form);
      if (inputs.every((i) => !isEmpty(i.value))) {
        this.register();
      } else if (source.value) {
        const textInputs = inputs.filter((i) => i instanceof TextInputModel);
        const sourceIndex = textInputs.indexOf(source as any);
        let i = (sourceIndex <= 0 ? 0 : sourceIndex + 1) % textInputs.length;
        while (textInputs[i].value) {
          i++;
          i %= textInputs.length;
        }

        textInputs[i].isFocused = true;
      }
    }, 0);
  }

  private alertCheckboxNotCheked(checkboxModel: CheckboxModel): void {
    if (checkboxModel.checked) {
      return;
    }
    this.alertService.alertError(new ApplicationError({
      key: `${checkboxModel.key}/warning`,
    }));
  }
}
