import { CoreValidationKey, getJsonExport, LocaleProvider, ValidationProvider } from '@typescript-kit/core';
import * as resources from './resource/shared.resource.json';
import * as texts from './text/shared.de.text.json';
import { SharedModelKey } from './key/shared-model.key';

export class SharedInitializer {

  static initialize() {
    SharedInitializer.registerLocales();
    SharedInitializer.registerValidationRules();
  }

  static registerLocales() {
    LocaleProvider.registerPackageLocale('de', 'shared', {
      texts: getJsonExport(texts),
      resources: getJsonExport(resources)
    });
  }

  static registerValidationRules() {
    ValidationProvider.registerObjectRules(SharedModelKey.EMPLOYEE, {
      id: CoreValidationKey.REQUIRED,
      name: CoreValidationKey.HAS_VALUE,
    });
  }
}
