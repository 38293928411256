import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ProjectCommonViewService } from '../../view-service/project-common-shared.view-service';
import { ProjectCommonViewFieldKey, ProjectCommonViewModel } from '../../view-model/project-common.view-model';
import { Subscription } from 'rxjs';
import { ChangedEvent } from '@typescript-kit/core';
import { ProjectModel } from '@teamworks/global';
import { TableModel } from '@typescript-kit/view';
import { ProjectActivitiesTableViewService } from '../../view-service/project-activities-table.view-service';

@Component({
  selector: 'app-project-activities',
  templateUrl: './project-activities.component.html',
  styleUrls: ['./project-activities.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectActivitiesComponent implements OnInit, OnDestroy {
  activitiesTableModel: TableModel;

  private viewModelChangedSubscription: Subscription;

  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly projectTimeRecordingSharedViewService: ProjectCommonViewService,
    private readonly projectActivitiesTableViewService: ProjectActivitiesTableViewService
  ) {
  }

  get viewModel(): ProjectCommonViewModel {
    return this.projectTimeRecordingSharedViewService.viewModel;
  }

  get componentModel(): TableModel {
    return this.projectActivitiesTableViewService.componentModel;
  }

  ngOnInit() {
    this.projectActivitiesTableViewService.initialize(this.viewModel);
    this.viewModelChangedSubscription = this.viewModel.changedEvent.subscribe((event) => this.onViewModelChanged(event));
    this.createComponentModels();
  }

  ngOnDestroy(): void {
    this.viewModelChangedSubscription.unsubscribe();
    this.projectActivitiesTableViewService.finalize();
  }

  private onViewModelChanged(event: ChangedEvent) {
    if (event.originalEvent !== event) {
      if (event.originalEvent.source === this.viewModel.projectList) {
        this.onProjectChanged(event.originalEvent);
      }
      return;
    }
    if (event.changes[ProjectCommonViewFieldKey.PROJECT]
      || event.changes[ProjectCommonViewFieldKey.IS_PROJECT_ADMIN]) {
      this.refreshComponentModels();
    }
  }

  private onProjectChanged(event: ChangedEvent) {
    if (event.originalEvent !== event && event.originalEvent.source instanceof ProjectModel) {
      this.refreshComponentModels();
      this.changeDetector.markForCheck();
    }
  }

  private createComponentModels() {
    this.refreshComponentModels();
  }

  private refreshComponentModels() {
    this.changeDetector.markForCheck();
  }
}
