import { EntityData, EntityFieldKey } from '@typescript-kit/data';

export class TagMappingTargetTypes {
  static readonly EMPLOYEE = 'employee';
  static readonly PROJECT = 'project';
}

export class TagMappingTagTypes {
  static readonly NFC = 'nfc';
  static readonly PIN = 'pin';
}


export class TagMappingFieldKey extends EntityFieldKey {
  static readonly TAG_TYPE = 'tagType';
  static readonly TAG_ID = 'tagId';
  static readonly COMPANY_ID = 'companyId';
  static readonly TARGET_TYPE = 'targetType';
  static readonly TARGET_ID = 'targetId';
  static readonly IS_ACTIVE = 'isActive';
}

export interface TagMapping extends EntityData {
  readonly tagType: string;
  readonly tagId: string;
  readonly companyId?: string;
  readonly targetType: string;
  readonly targetId: string;
  readonly isActive: boolean;
}
