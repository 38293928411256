import {
  Component,
  OnInit,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  AfterViewInit,
  Injector,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';

import { CoreActionKey, ApplicationError, AlertService } from '@typescript-kit/core';
import { TextInputModel, ContextBindings, ActionModel, KeyCodes } from '@typescript-kit/view';
import { CredentialsData, AuthenticationModelKey } from '@typescript-kit/authentication';
import { TextInputComponent } from '@angular-kit/view';
import { AuthenticationService } from '@angular-kit/authentication';

import { ShellGlobalTextKey } from '../../key/shell-global-text.key';
import { ShellViewService } from '../../view/shell.view-service';
import { ShellImageKey } from '../../key/shell-image.key';
import { ShellViewKey } from '../../key/shell-view.key';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginComponent implements OnInit, AfterViewInit {
  private credentials: CredentialsData;

  public logoKey: string;
  public titleText: string;
  public vendorName: string;
  public developedByText: string;
  public userNameInput: TextInputModel;
  public passwordInput: TextInputModel;
  public loginAction: ActionModel;
  public resetPasswordAction: ActionModel;
  public registerAction: ActionModel;

  @ViewChild('username', { static: true }) usernameInputComponent: TextInputComponent;
  @ViewChild('password', { static: true }) passwordInputComponent: TextInputComponent;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private shellViewService: ShellViewService
  ) { }

  ngOnInit() {
    this.credentials = {};
    this.logoKey = ShellImageKey.VENDOR_LOGO;
    this.titleText = this.shellViewService.viewModel.appTitle;
    this.vendorName = ShellGlobalTextKey.VENDOR;
    this.developedByText = `${ShellViewKey.LOGIN}/developed-by`;
    this.userNameInput = new TextInputModel({
      key: 'userName',
      scope: `${AuthenticationModelKey.CREDENTIALS}/field`,
      context: this.credentials,
      contextBinding: ContextBindings.MODEL_TO_CONTEXT,
      autocompleteToken: 'username',
      placeholder: true,
      onKeydown: (event: KeyboardEvent) => {
        if (event.key === KeyCodes.ENTER.key) {
          setTimeout(() => {
            if (this.userNameInput.value && this.passwordInput.value) {
              this.login();
            } else if (this.userNameInput.value) {
              console.log('x');
              this.passwordInput.isFocused = true;
            }
          }, 0);
        }
      }
    });
    this.passwordInput = new TextInputModel({
      key: 'password',
      scope: `${AuthenticationModelKey.CREDENTIALS}/field`,
      context: this.credentials,
      contextBinding: ContextBindings.MODEL_TO_CONTEXT,
      placeholder: true,
      autocompleteToken: 'current-password',
      isObscured: true,
      onKeydown: (event: KeyboardEvent) => {
        if (event.key === KeyCodes.ENTER.key) {
          setTimeout(() => {
            if (this.userNameInput.value && this.passwordInput.value) {
              this.login();
            } else if (this.passwordInput.value) {
              this.userNameInput.isFocused = true;
            }
          }, 0);
        }
      }
    });
    this.loginAction = new ActionModel({
      content: CoreActionKey.LOGIN,
      onClick: () => this.login()
    });
    this.resetPasswordAction = new ActionModel({
      content: `${ShellViewKey.LOGIN}/reset-password`,
      onClick: () => this.resetPassword()
    });
    this.registerAction = new ActionModel({
      content: `${ShellViewKey.LOGIN}/register`,
      onClick: () => this.register()
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.userNameInput.isFocused = true;
    }, 200);
  }

  login() {
    // TODO: Work-around chrome autofill
    this.userNameInput.value = this.usernameInputComponent.inputElement.value;
    this.passwordInput.value = this.passwordInputComponent.inputElement.value;
    this.authenticationService.login(this.credentials)
      .then(() => {
        const redirect = this.router.routerState.snapshot.root.queryParamMap.get('redirect');
        return this.router.navigate([redirect || '']);
      })
      .catch((error: ApplicationError) => {
        this.alertService.discardAll();
        this.alertService.alertError(error);
      });
  }

  resetPassword() {
    return this.router.navigate(['/reset-password']);
  }

  register() {
    return this.router.navigate(['/register']);
  }
}
