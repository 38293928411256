import { ComponentData, ComponentModel, ComponentFieldKey } from '../model/component.model';
import { ObserveModes } from '@typescript-kit/core';
import { ViewComponentKey } from '../key/view-component.key';
import { ContainerModel } from './container.component-model';
import { TextModel } from './text.component-model';

export interface TabData extends ComponentData {
  content?: ContainerModel;
  text?: TextModel | string;
}

export class TabFieldKey {
  static readonly CONTENT = 'content';
  static readonly TEXT = 'text';
}

export class TabModel extends ComponentModel implements TabData {
  static readonly KEYS = ComponentModel.KEYS.concat(
    Object.keys(TabFieldKey).map((key) => TabFieldKey[key])
  );

  constructor(data?: TabData, keys?: string[]) {
    super(data, keys || TabModel.KEYS, { observeMode: ObserveModes.FLAT });
  }

  get content(): ContainerModel {
    return this.get(TabFieldKey.CONTENT);
  }

  set content(value: ContainerModel) {
    this.set(TabFieldKey.CONTENT, value);
  }

  get text(): TextModel | string {
    return this.get(TabFieldKey.TEXT);
  }

  set text(value: TextModel | string) {
    this.set(TabFieldKey.TEXT, value);
  }

  protected setupValues(values: TabData, exclusive: boolean): TabData {
    if (exclusive && !(ComponentFieldKey.TYPE in values)) {
      values.type = ViewComponentKey.TAB;
    }

    return super.setupValues(values, exclusive) as TabData;
  }
}
