import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TagService } from '../../service/tag.service';
import { TimeRecordingComponentKey } from '../../key/time-recording-component.key';
import { ActionModel, FormFieldModel, TextInputModel, TextModel } from '@typescript-kit/view';
import { AlertService } from '@typescript-kit/core';
import { TagMapping } from '@teamworks/global';


@Component({
  selector: 'app-assign-token-dialog',
  template: `
    <h3 mat-dialog-title [kitText]="titleText"></h3>
    <div mat-dialog-content>
      <div [kitText]="introText"></div>
      <kit-bs-form-field [kitModel]="tokenField"></kit-bs-form-field>
    </div>
    <div mat-dialog-actions>
      <kit-bs-button [kitModel]="cancelAction"></kit-bs-button>
      <kit-bs-button [kitModel]="confirmAction"></kit-bs-button>
    </div>
  `,
  styleUrls: ['assign-token-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssignTokenDialogComponent implements OnInit {
  public readonly titleText: string;
  public readonly confirmText: string;
  public readonly cancelText: string;
  public readonly labelText: string;
  public readonly introText: string;
  public readonly targetType: string;
  public readonly targetId: string;
  public tokenField: FormFieldModel;
  public confirmAction: ActionModel;
  public cancelAction: ActionModel;

  constructor(
    private readonly dialog: MatDialogRef<AssignTokenDialogComponent>,
    private readonly tagService: TagService,
    private readonly alertService: AlertService,
    @Inject(MAT_DIALOG_DATA) private readonly data
  ) {
    this.targetId = data.targetId;
    this.targetType = data.targetType;
    this.cancelText = `${TimeRecordingComponentKey.ASSIGN_TOKEN_DIALOG}/actions/cancel`;
    this.confirmText = `${TimeRecordingComponentKey.ASSIGN_TOKEN_DIALOG}/actions/confirm`;
    this.titleText = `${TimeRecordingComponentKey.ASSIGN_TOKEN_DIALOG}/title`;
    this.labelText = `${TimeRecordingComponentKey.ASSIGN_TOKEN_DIALOG}/content/label`;
    this.introText = `${TimeRecordingComponentKey.ASSIGN_TOKEN_DIALOG}/intro`;
  }

  async ngOnInit(): Promise<void> {
    this.tokenField = new FormFieldModel({
      label: new TextModel({ text: `#(${this.labelText})` }),
      control: new TextInputModel({
        key: 'token',
        label: true,
        tags: ['form-control', 'form-control-sm'],
        onValueChanged: (value) => {
          this.confirmAction.isDisabled = !value;
        }
      })
    });
    this.confirmAction = new ActionModel({
      tags: ['app-form-btn', 'btn-sm', 'btn-outline-secondary'],
      content: new TextModel({text: `#(${this.confirmText})`}),
      isDisabled: true,
      onClick: async () => {
        const token = this.tokenField.value;
        if (token !== undefined) {
          this.tagService.assignTagToken(token, this.targetType, this.targetId)
            .then((value: TagMapping) => this.dialog.close(value))
            .catch((error) => this.alertService.alertError(error));
        }
      }
    });
    this.cancelAction = new ActionModel({
      tags: ['app-form-btn', 'btn-sm', 'btn-outline-secondary'],
      content: new TextModel({text: `#(${this.cancelText})`}),
      onClick: async () => {
        this.dialog.close();
      }
    });
  }

}
