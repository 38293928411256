import { ContainerModel, TableModel, TextModel } from '@typescript-kit/view';

export class ProjectTimeRecordingDetailComponentModel extends ContainerModel {
  get projectName(): TextModel {
    return this.items.get('projectName') as TextModel;
  }

  get monthText(): TextModel {
    return this.items.get('monthText') as TextModel;
  }

  get tableModel(): TableModel {
    return this.items.get('table') as TableModel;
  }
}
