import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActionModel, TextData, TextModel } from '@typescript-kit/view';
import { SharedViewKey } from '../../key/shared-view.key';

export interface QuestionDialogData {
  readonly question: TextData;
}

@Component({
  selector: 'app-question-dialog.component',
  template: `
    <div mat-dialog-content>
      <div [kitText]="questionText" ></div>
    </div>
    <div mat-dialog-actions>
      <kit-bs-button [kitModel]="noAction"></kit-bs-button>
      <kit-bs-button [kitModel]="yesAction"></kit-bs-button>
    </div>
  `,
  styleUrls: ['./question-dialog.component.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuestionDialogComponent implements OnInit {
  public readonly questionText: TextModel;
  public readonly yesText: string;
  public readonly noText: string;
  public yesAction: ActionModel;
  public noAction: ActionModel;

  constructor(private readonly dialog: MatDialogRef<QuestionDialogComponent>, @Inject(MAT_DIALOG_DATA) private readonly data) {
    this.questionText = new TextModel(data.question);
    this.yesText = `${SharedViewKey.QUESTION_DIALOG}/yes`;
    this.noText = `${SharedViewKey.QUESTION_DIALOG}/no`;
  }

  ngOnInit(): void {
    this.yesAction = new ActionModel({
      tags: ['app-form-btn', 'btn-sm', 'btn-outline-secondary'],
      content: new TextModel({text: `#(${this.yesText})`}),
      onClick: () => {
        this.dialog.close(true);
      }
    });
    this.noAction = new ActionModel({
      tags: ['app-form-btn', 'btn-sm', 'btn-outline-secondary'],
      content: new TextModel({text: `#(${this.noText})`}),
      onClick: () => {
        this.dialog.close(false);
      }
    });
  }
}
