import { Subscription } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ChangedEvent, isEmpty, ObservableMap } from '@typescript-kit/core';
import { ViewService } from '@angular-kit/view';
import { PersistenceService } from '@angular-kit/core';
import { MonthSelectViewData, MonthSelectViewFieldKey, MonthSelectViewModel } from './month-select.view-model';
import { ActionModel, Alignments, ContainerModel, DropdownModel, Orientations, TextModel, ViewComponentKey } from '@typescript-kit/view';
import { SharedComponentKey } from '../key/shared-component.key';
import { RouteBindingMode, RouteBindingModeKey } from '../model/route-binding-mode.model';
import { SharedPersistenceKey } from '../key/shared-persistence.key';

@Injectable()
export class MonthSelectViewService extends ViewService<MonthSelectViewModel, ContainerModel> {
  private readonly router: Router;
  private readonly activatedRoute: ActivatedRoute;
  private readonly persistenceService: PersistenceService;

  private routeBindingMode: RouteBindingMode;
  private routeChangedSubscription: Subscription;

  constructor(
    injector: Injector,
  ) {
    super(injector);
    this.router = injector.get(Router);
    this.activatedRoute = injector.get(ActivatedRoute);
    this.persistenceService = injector.get(PersistenceService);
  }

  initialize(viewData?: MonthSelectViewData, routeBindingMode: RouteBindingMode = RouteBindingModeKey.TWO_WAY): void {
    this.routeBindingMode = routeBindingMode || RouteBindingModeKey.NONE;
    const viewModel = viewData instanceof MonthSelectViewModel
      ? viewData
      : new MonthSelectViewModel(viewData);
    super.initialize(viewModel);
    if (this.routeBindingMode === RouteBindingModeKey.TWO_WAY || this.routeBindingMode === RouteBindingModeKey.ROUTE_TO_MODEL) {
      this.routeChangedSubscription = this.activatedRoute.paramMap
        .subscribe((paramMap: ParamMap) => this.onRouteChanged(paramMap));
    }
  }

  finalize(): void {
    if (this.routeChangedSubscription) {
      this.routeChangedSubscription.unsubscribe();
    }
    super.finalize();
  }

  protected onViewModelPropertyChanged(event: ChangedEvent) {
    if (event.originalEvent && event.originalEvent.source !== event.source) {
      return;
    }
    const changes = event.changes;
    if (changes[MonthSelectViewFieldKey.YEAR] || changes[MonthSelectViewFieldKey.MONTH]) {
      if (this.routeBindingMode === RouteBindingModeKey.TWO_WAY || this.routeBindingMode === RouteBindingModeKey.MODEL_TO_ROUTE) {
        this.updateRoute();
      }
      this.refreshComponentModel(this.componentModel);
    }
  }

  private onRouteChanged(paramMap: ParamMap) {
    const now = new Date();
    let year = +paramMap.get('year');
    let month = +paramMap.get('month');
    if (!year || isNaN(year) || year < 2000 || year > now.getFullYear() + 100) {
      year = this.persistenceService.getValue(SharedPersistenceKey.YEAR, now.getFullYear());
    }
    if (!month || isNaN(month) || month < 1 || month > 12) {
      month = this.persistenceService.getValue(SharedPersistenceKey.MONTH, now.getMonth() + 1);
    }
    this.persistenceService.setValue(SharedPersistenceKey.YEAR, year);
    this.persistenceService.setValue(SharedPersistenceKey.MONTH, month);
    this.viewModel.setValues({
      year: year.toString(),
      month: month.toString(),
    });
  }

  protected updateRoute() {
    if (this.viewModel.year && this.viewModel.month) {
      const path = this.activatedRoute.routeConfig.path
        .replace(/(^|\/):([^\/]+)/g, (match, prefix, paramKey) => {
          switch (paramKey) {
            case MonthSelectViewFieldKey.YEAR:
              return `${prefix}${this.viewModel.year}`;
            case MonthSelectViewFieldKey.MONTH:
              return `${prefix}${this.viewModel.month}`;
            default:
              return `${prefix}${this.activatedRoute.snapshot.paramMap.get(paramKey)}`;
          }
        });
      this.router
        .navigate([path], { relativeTo: this.activatedRoute.parent, queryParamsHandling: 'preserve' })
        .catch((error) => {
          this.alertService.alertError(error);
        });
    }
  }

  protected createComponentModel(): ContainerModel {
    return new ContainerModel({
      key: 'monthSelectContainer',
      type: SharedComponentKey.MONTH_SELECT,
      tags: ['app-month-select-container'],
      orientation: Orientations.SIDE_BY_SIDE, alignment: Alignments.RIGHT,
      items: new ObservableMap({
        previousMonthAction: this.createPreviousMonthActionModel(),
        monthDropdown: this.createMonthDropdownModel(),
        nextMonthAction: this.createNextMonthActionModel(),
        // saveDropdown: this.createSaveDropdownModel()
      })
    });
  }

  protected refreshComponentModel(componentModel: ContainerModel): void {
    this.refreshPreviousMonthActionModel(componentModel.items.get('previousMonthAction') as ActionModel);
    this.refreshMonthDropdownModel(componentModel.items.get('monthDropdown') as DropdownModel);
    this.refreshNextMonthActionModel(componentModel.items.get('nextMonthAction') as ActionModel);
  }

  private createMonthDropdownModel() {
    const model = new DropdownModel({
      key: 'monthDropdown', tags: ['app-month-dropdown'],
      trigger: new TextModel({ text: null }),
      scrollToActiveItem: true,
    });
    this.refreshMonthDropdownModel(model);
    return model;
  }

  private refreshMonthDropdownModel(model: DropdownModel) {
    const hasValue = !isEmpty(this.viewModel.month) && !isEmpty(this.viewModel.year);
    model.setValues({
      isHidden: !hasValue,
      items: hasValue ? this.createMonthSelectDropdownItemList() : []
    });
    const triggerModel = model.trigger as TextModel;
    triggerModel.text = hasValue ? `#(shared/model/month/value/${this.viewModel.month}) ${this.viewModel.year}` : null;
  }

  private createMonthSelectDropdownItemList(): ActionModel[] {
    const dropdownItems: ActionModel[] = [];
    let year = +this.viewModel.year;
    let month = +this.viewModel.month;
    if (isNaN(year) || isNaN(month)) {
      return dropdownItems;
    }
    const endYear = year + 3;
    const endMonth = month;
    year = year - 3;
    while (year < endYear || year === endYear && month <= endMonth) {
      dropdownItems.push(this.createMonthSelectDropDownAction(year.toString(), month.toString()));
      month++;
      if (month > 12) {
        month = 1;
        year++;
      }
    }
    return dropdownItems;
  }

  private createMonthSelectDropDownAction(year: string, month: string) {
    const isActive = year === this.viewModel.year && month === this.viewModel.month;
    return new ActionModel({
      key: `${year}-${month}`, tags: { 'active': isActive },
      type: ViewComponentKey.LINK,
      isActive,
      content: `#(shared/model/month/value/${month}) ${year}`,
      onClick: () => {
        if (this.viewModel.year === year && this.viewModel.month === month) {
          return;
        }
        this.viewModel.setValues({ year, month });
      }
    });
  }

  private createPreviousMonthActionModel() {
    return new ActionModel({
      key: 'previousMonthAction', tags: ['app-previous-month-action'],
      // content: '⯇', // Unicode U+2BC7
      content: '◀', // Unicode U+25C0
      isHidden: !this.viewModel.year || !this.viewModel.month,
      onClick: () => {
        let previousMonth = +this.viewModel.month - 1;
        let previousYear = +this.viewModel.year;
        if (previousMonth <= 0) {
          previousMonth = 12;
          previousYear--;
        }
        this.viewModel.setValues({
          year: previousYear.toString(),
          month: previousMonth.toString()
        });
      }
    });
  }

  private refreshPreviousMonthActionModel(model: ActionModel) {
    model.isHidden = !this.viewModel.year || !this.viewModel.month;
  }

  private createNextMonthActionModel() {
    return new ActionModel({
      key: 'nextMonthAction', tags: ['app-next-month-action'],
      // content: '⯈', // Unicode U+2BC8
      content: '▶', // Unicode U+25B6
      isHidden: !this.viewModel.year || !this.viewModel.month,
      onClick: () => {
        let nextMonth = +this.viewModel.month + 1;
        let nextYear = +this.viewModel.year;
        if (nextMonth > 12) {
          nextMonth = 1;
          nextYear++;
        }
        this.viewModel.setValues({
          year: nextYear.toString(),
          month: nextMonth.toString()
        });
      }
    });
  }

  private refreshNextMonthActionModel(model: ActionModel) {
    model.isHidden = !this.viewModel.year || !this.viewModel.month;
  }

}
