import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActionModel, ComponentFieldKey, ImageModel, TextModel, ViewComponentKey } from '@typescript-kit/view';
import { EmployeeCommonViewService } from '../../view-service/employee-common.view-service';
import { ChangedEvent, LocaleService, toArray } from '@typescript-kit/core';
import { Subscription } from 'rxjs';
import { SharedSvgKey } from '../../../shared/key/shared-svg.key';
import { EmployeeCommonViewFieldKey, EmployeeCommonViewModel } from '../../view-model/employee-common.view-model';
import { EmployeeFieldKey, EmployeeModel } from '@teamworks/global';
import { DialogService } from '../../../shared/service/dialog.service';
import { TimeRecordingViewKey } from '../../key/time-recording-view.key';

@Component({
  selector: 'app-employee-sidebar',
  templateUrl: './employee-sidebar.component.html',
  styleUrls: ['./employee-sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmployeeSidebarComponent implements OnInit, OnDestroy {

  private viewModelChangedSubscription: Subscription;

  public titleText: TextModel;
  public addEmployeeAction: ActionModel;
  public removeEmployeeAction: ActionModel;
  public employeeActionList: ActionModel[];

  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly localeService: LocaleService,
    private readonly viewService: EmployeeCommonViewService,
    private readonly dialogService: DialogService
  ) {
  }

  get viewModel(): EmployeeCommonViewModel {
    return this.viewService.viewModel;
  }

  ngOnInit(): void {
    this.viewModelChangedSubscription = this.viewModel.changedEvent.subscribe(event => this.onViewModelChanged(event));
    this.createComponentModels();
  }

  ngOnDestroy(): void {
    this.viewModelChangedSubscription.unsubscribe();
  }

  private onViewModelChanged(event: ChangedEvent) {
    if (event.originalEvent !== event) {
      if (event.originalEvent.source === this.viewModel.employeeList) {
        this.onEmployeeChanged(event.originalEvent);
      }
      return;
    }
    if (event.changes[EmployeeCommonViewFieldKey.EMPLOYEE_LIST]) {
      this.onEmployeeListChanged();
    }
    if (event.changes[EmployeeCommonViewFieldKey.EMPLOYEE_ID]) {
      this.onEmployeeIdChanged();
    }
  }

  private onEmployeeListChanged() {
    this.refreshEmployeeActionModels();
    this.changeDetector.markForCheck();
  }

  private onEmployeeIdChanged() {
    const selectedIndex = this.employeeActionList.findIndex(item => item.isActive);
    const actionIndex = this.employeeActionList.findIndex(item => item.key === this.viewModel.employeeId);
    if (actionIndex === selectedIndex) {
      return;
    }
    if (selectedIndex >= 0) {
      this.employeeActionList[selectedIndex].isActive = false;
    }
    if (actionIndex >= 0) {
      this.employeeActionList[actionIndex].isActive = true;
    }
  }

  private onEmployeeChanged(event: ChangedEvent) {
    if (event.originalEvent !== event) {
      if (event.originalEvent.source instanceof EmployeeModel) {
        this.onEmployeePropertyChanged(event.originalEvent);
      }
      return;
    }
    this.refreshEmployeeActionModels();
    this.changeDetector.markForCheck();
  }

  private onEmployeePropertyChanged(event: ChangedEvent) {
    if (event.changes[EmployeeFieldKey.name]) {
      // re-order list
      this.refreshEmployeeActionModels();
    } else {
      const employeeModel = event.source as EmployeeModel;
      const actionKey = event.changes[EmployeeFieldKey.ID] ? event.changes[EmployeeFieldKey.ID].originalValue : employeeModel.id;
      const actionIndex = this.employeeActionList.findIndex(item => item.key === actionKey);
      if (actionIndex >= 0) {
        this.employeeActionList[actionIndex] = this.createEmployeeActionModel(employeeModel);
      }
    }
    this.changeDetector.markForCheck();
  }

  private createComponentModels(): void {
    this.refreshHeaderActionModels();
    this.refreshEmployeeActionModels();
  }

  private refreshHeaderActionModels(): void {
    this.titleText = new TextModel({
      text: `#(${TimeRecordingViewKey.EMPLOYEE}/name)`
    });
    this.addEmployeeAction = new ActionModel({
      content: new ImageModel({
        type: ViewComponentKey.SVG_SYMBOL,
        source: SharedSvgKey.ADD_DOCUMENT
      }),
      onClick: () => this.viewService.createEmployee()
    });
    this.removeEmployeeAction = new ActionModel({
      content: new ImageModel({
        type: ViewComponentKey.SVG_SYMBOL,
        source: SharedSvgKey.DELETE_DOCUMENT
      }),
      onClick: async () => {
        if (this.viewModel.employeeId === undefined) {
          return;
        }

        const isDeleteAllowed = this.viewModel.employeeId === null || await this.dialogService.openYesNoQuestion(
          {
            text: '#(time-recording/view/employee-sidebar/delete-employee-dialog/question)',
            textArgs: { employee: this.viewModel.employee.name }
          },
        );
        if (isDeleteAllowed) {
          await this.viewService.deleteEmployee(this.viewModel.employee);
        }
      }
    });
  }

  private refreshEmployeeActionModels(): void {
    this.employeeActionList = toArray(this.viewModel.employeeList)
      .sort((a: EmployeeModel, b: EmployeeModel) => {
        return (a.name || '').localeCompare((b.name || ''), this.localeService.localeKey, { sensitivity: 'accent' });
      })
      .map((entityModel: EmployeeModel) => this.createEmployeeActionModel(entityModel));
  }

  private createEmployeeActionModel(entityModel: EmployeeModel): ActionModel {
    const id = entityModel.id;
    const isSelected = this.viewModel.employeeId === id;
    const model = new ActionModel({
      type: ViewComponentKey.BUTTON,
      tags: { active: isSelected, 'app-employee-state': entityModel.isActive ? 'active' : 'inactive' },
      key: entityModel.id,
      content: entityModel.name || '-',
      isActive: isSelected,
      onClick: () => {
        this.viewModel.employeeId = entityModel.id;
      },
      onChanged: (event: ChangedEvent) => {
        if (event.changes[ComponentFieldKey.IS_ACTIVE]) {
          model.tags.set('active', event.changes[ComponentFieldKey.IS_ACTIVE].currentValue);
        }
      }
    });
    return model;
  }
}
