import { ComponentProvider } from '@angular-kit/view';
import { AccountingInitializer as GlobalInitializer } from '@teamworks/global';
import { AccountingComponentKey } from './key/accounting-component.key';
import { AccountingListComponent } from './component/accounting-list/accounting-list.component';
import { AccountingShellMenuComponent } from './component/accounting-shell-menu/accounting-shell-menu.component';

export class AccountingInitializer {

  static initialize() {
    GlobalInitializer.initialize();
    AccountingInitializer.registerComponents();
  }

  static registerComponents() {
    ComponentProvider.registerComponentType(AccountingComponentKey.ACCOUNTING_SHELL_MENU, AccountingShellMenuComponent);
    ComponentProvider.registerComponentType(AccountingComponentKey.ACCOUNTING_LIST, AccountingListComponent);
  }

}
