import { ObservableModel } from '@typescript-kit/core';

export class LogbookSummaryKey {
  static readonly TOTAL_KILOMETERS = 'totalKilometers';
  static readonly TOTAL_COSTS = 'totalCosts';
  static readonly INFO_PER_COST_RATE = 'infoPerCostRate';
}

export interface CostInfo {
  costRate: number;
  kilometer: number;
  costs: number;
}

export interface LogbookSummaryData {
  totalKilometers: number;
  totalCosts: number;
  infoPerCostRate: { [costRate: number]: CostInfo };
}

export class LogbookSummary {
  readonly totalKilometers: number;
  readonly totalCosts: number;
  readonly infoPerCostRate: { [costRate: number]: CostInfo };

  constructor(data: LogbookSummaryData) {
    this.totalKilometers = data.totalKilometers;
    this.totalCosts = data.totalCosts;
    this.infoPerCostRate = data.infoPerCostRate;
  }
}

export class LogbookSummaryModel extends ObservableModel implements LogbookSummaryData {
  static readonly KEYS = Object.keys(LogbookSummaryKey).map((key) => LogbookSummaryKey[key]);

  constructor(data: LogbookSummaryData) {
    super(data, LogbookSummaryModel.KEYS);
  }

  get totalKilometers(): number {
    return this.get(LogbookSummaryKey.TOTAL_KILOMETERS);
  }

  set totalKilometers(value: number) {
    this.set(LogbookSummaryKey.TOTAL_KILOMETERS, value);
  }

  get totalCosts(): number {
    return this.get(LogbookSummaryKey.TOTAL_COSTS);
  }

  set totalCosts(value: number) {
    this.set(LogbookSummaryKey.TOTAL_COSTS, value);
  }

  get infoPerCostRate(): { [costRate: number]: CostInfo } {
    return this.get(LogbookSummaryKey.INFO_PER_COST_RATE);
  }

  set infoPerCostRate(value: { [costRate: number]: CostInfo }) {
    this.set(LogbookSummaryKey.INFO_PER_COST_RATE, value);
  }
}
