import { Injectable } from '@angular/core';
import { ViewService } from '@angular-kit/view';
import { TimeRecordingViewData, TimeRecordingViewFieldKey, TimeRecordingViewModel } from '../view-model/time-recording.view-model';
import { Employee, Project } from '@teamworks/global';
import { ApplicationError, ChangedEvent } from '@typescript-kit/core';
import { EmployeeService } from '../../shared/service/employee.service';
import { ProjectService } from '../service/project.service';
import { ActivatedRoute } from '@angular/router';
import { DataQuery, SortOrders } from '@typescript-kit/data';

@Injectable()
export class TimeRecordingSharedViewService extends ViewService<TimeRecordingViewModel, any> {
  private readonly activatedRoute = this.injector.get(ActivatedRoute);
  // private readonly localeService = this.injector.get(LocaleService);
  private readonly employeeService = this.injector.get(EmployeeService);
  private readonly projectService = this.injector.get(ProjectService);

  // TODO: move to shell
  // private readonly printMediaQueryList: MediaQueryList = window.matchMedia('print');
  // private readonly printMediaQueryEventListener = (event) => this.onPrintMediaQueryChanged(event);

  initialize(viewData?: TimeRecordingViewData) {
    const viewModel = viewData instanceof TimeRecordingViewModel ? viewData : new TimeRecordingViewModel(viewData);
    // viewModel.printView = this.printMediaQueryList.matches;
    // TODO: Remove activatedRoute and query parameter
    viewModel.printView = this.activatedRoute.snapshot.queryParamMap.get('print') === 'true';
    super.initialize(viewModel);
    // if (typeof this.printMediaQueryList.addEventListener === 'function') {
    //   this.printMediaQueryList.addEventListener('change', this.printMediaQueryEventListener);
    // } else {
    //   // noinspection JSDeprecatedSymbols
    //   this.printMediaQueryList.addListener(this.printMediaQueryEventListener);
    // }
    this.refreshEmployeeList();
    this.refreshProjectList();
  }

  finalize() {
    // if (typeof this.printMediaQueryList.removeEventListener === 'function') {
    //   this.printMediaQueryList.removeEventListener('change', this.printMediaQueryEventListener);
    // } else {
    //   // noinspection JSDeprecatedSymbols
    //   this.printMediaQueryList.removeListener(this.printMediaQueryEventListener);
    // }
    super.finalize();
  }

  protected onViewModelPropertyChanged(event: ChangedEvent) {
    if (event.originalEvent !== event) {
      return;
    }
    const changes = event.changes;
    if (changes[TimeRecordingViewFieldKey.YEAR] || changes[TimeRecordingViewFieldKey.MONTH]) {
      this.refreshEmployeeList();
    }
  }

  // protected onPrintMediaQueryChanged(event: MediaQueryListEvent) {
  //   this.viewModel.printView = event.matches;
  // }

  public async refreshEmployeeList() {
    if (!this.viewModel.year || !this.viewModel.month) {
      this.viewModel.employeeList = [];
      return;
    }

    try {
      const employeeList = await this.employeeService.loadEmployeeListByMonth(this.viewModel.year, this.viewModel.month, { sort: { name: SortOrders.ASC } });
      if (!this.isFinalized) {
        this.viewModel.employeeList = employeeList;
      }
    } catch (error) {
      if (!this.isFinalized && error instanceof ApplicationError) {
        this.viewModel.employeeList = null;
        this.alertService.alertError(error);
      }
    }
  }

  public async refreshProjectList() {
    try {
      const projectList = await this.projectService.loadProjectList({
        filter: { isActive: '*' },
        sort: { name: SortOrders.ASC }
      });
      if (!this.isFinalized) {
        this.viewModel.projectList = projectList;
      }
    } catch (error) {
      if (!this.isFinalized && error instanceof ApplicationError) {
        this.viewModel.projectList = null;
        this.alertService.alertError(error);
      }
    }
  }
}
