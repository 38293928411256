import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { ShellViewService } from '../../../shell/view/shell.view-service';
import { ProjectCommonViewService } from '../../view-service/project-common-shared.view-service';
import { TemplateModel } from '@angular-kit/view';
import { ProjectCommonViewFieldKey, ProjectCommonViewModel } from '../../view-model/project-common.view-model';
import { ActionModel, CheckboxModel, ContextBindings, FormFieldModel, TextInputModel, TextModel } from '@typescript-kit/view';
import { Subscription } from 'rxjs';
import { ChangedEvent } from '@typescript-kit/core';
import { ProjectModel, SharedModelKey } from '@teamworks/global';
import { ProjectTimeRecordingDetailComponentModel } from '../../component-model/project-time-recording-detail.component-model';
import { ProjectTimeRecordingDetailViewService } from '../../view-service/project-time-recording-detail.view-service';
import { ProjectCommonShellMenuViewService } from '../../view-service/project-common-shell-menu.view-service';
import { TimeRecordingViewKey } from '../../key/time-recording-view.key';

@Component({
  selector: 'app-project-time-recording',
  templateUrl: './project-time-recording.component.html',
  styleUrls: ['./project-time-recording.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectTimeRecordingComponent implements OnInit, OnDestroy {
  public nameField: FormFieldModel;
  public isActiveCheckbox: CheckboxModel;
  public confirmAction: ActionModel;

  private viewModelChangedSubscription: Subscription;

  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly shellViewService: ShellViewService,
    private readonly projectTimeRecordingSharedViewService: ProjectCommonViewService,
    private readonly projectTimeRecordingShellMenuViewService: ProjectCommonShellMenuViewService,
    private readonly projectTimeRecordingDetailViewService: ProjectTimeRecordingDetailViewService
  ) {
  }

  get viewModel(): ProjectCommonViewModel {
    return this.projectTimeRecordingSharedViewService.viewModel;
  }

  get componentModel(): ProjectTimeRecordingDetailComponentModel {
    return this.projectTimeRecordingDetailViewService.componentModel;
  }

  ngOnInit() {
    this.shellViewService.setMenu(this.projectTimeRecordingShellMenuViewService.componentModel);
    this.viewModelChangedSubscription = this.viewModel.changedEvent.subscribe((event) => this.onViewModelChanged(event));
    this.createComponentModels();
  }

  ngOnDestroy(): void {
    this.viewModelChangedSubscription.unsubscribe();
    this.shellViewService.restoreMenu();
  }

  private onViewModelChanged(event: ChangedEvent) {
    if (event.originalEvent !== event) {
      if (event.originalEvent.source === this.viewModel.projectList) {
        this.onProjectChanged(event.originalEvent);
      }
      return;
    }
    if (event.changes[ProjectCommonViewFieldKey.PROJECT]
      || event.changes[ProjectCommonViewFieldKey.IS_PROJECT_ADMIN]) {
      this.refreshComponentModels();
    }
  }

  private onProjectChanged(event: ChangedEvent) {
    if (event.originalEvent !== event && event.originalEvent.source instanceof ProjectModel) {
      this.refreshComponentModels();
      this.changeDetector.markForCheck();
    }
  }

  private createComponentModels() {
    this.nameField = new FormFieldModel({
      label: true,
      control: new TextInputModel({
        key: 'name',
        scope: `${SharedModelKey.PROJECT}/field`,
        tags: ['form-control', 'form-control-sm', `app-project-name`],
        contextBinding: ContextBindings.TWO_WAY
      }),
      isDisabled: !this.viewModel.isProjectAdmin
    });
    this.isActiveCheckbox = new CheckboxModel({
      label: true,
      tags: [`app-project-is-active`],
      key: `${SharedModelKey.PROJECT}/field/isActive`,
      isDisabled: !this.viewModel.isProjectAdmin,
      onCheckedChanged: (isChecked) => this.viewModel.project?.setValues({ isActive: isChecked })
    });
    this.confirmAction = new ActionModel({
      tags: ['app-form-btn', 'btn-sm', 'btn-outline-secondary', 'app-confirm-project'],
      content: new TextModel({
        text: `#(${TimeRecordingViewKey.PROJECT}/confirm-project)`
      }),
      onClick: () => this.viewModel.project.setValues({ isConfirmed: true }),
      isDisabled: !this.viewModel.isProjectAdmin
    });
    this.refreshComponentModels();
  }

  private refreshComponentModels() {
    const projectModel = this.viewModel.project;
    const updateValues = {
      context: projectModel,
      isHidden: !projectModel,
      isDisabled: !this.viewModel.isProjectAdmin
    };
    this.nameField.setValues({ ...updateValues });
    this.isActiveCheckbox.setValues({
      isHidden: !projectModel?.isConfirmed,
      isDisabled: updateValues.isDisabled,
      checked: projectModel?.isActive
    });
    this.confirmAction.setValues({
      isHidden: !this.viewModel.isProjectAdmin || !projectModel || projectModel.isConfirmed,
      isDisabled: updateValues.isDisabled
    });
    this.changeDetector.markForCheck();
  }
}
