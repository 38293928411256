import { EntityData } from '@typescript-kit/data';

export type ValidityPeriodTargetType = 'employee';

export class ValidityPeriodTargetTypes {
  static readonly EMPLOYEE = 'employee';
}

export interface ValidityPeriod extends EntityData {
  readonly companyId?: string;
  readonly id?: string;
  readonly targetType: ValidityPeriodTargetType;
  readonly targetId: string;
  readonly begin?: string;
  readonly end?: string;
}
