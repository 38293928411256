import { AfterViewChecked, ChangeDetectionStrategy, Component, HostListener, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { TextInputDirective } from '@angular-kit/view';
import { ChangeModes, TextInputModel } from '@typescript-kit/view';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'textarea[appModel]',
  templateUrl: './resize-text-area.component.html',
  styleUrls: ['./resize-text-area.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResizeTextAreaComponent extends TextInputDirective implements AfterViewChecked, OnInit {
  private changeDelayTimeout: any;

  get hostClass(): string {
    return 'app-resize-text-area';
  }

  get element(): HTMLTextAreaElement {
    return this.elementRef.nativeElement;
  }

  @Input()
  set appModel(model: TextInputModel) {
    this.model = model;
  }

  get appModel(): TextInputModel {
    return this.model;
  }

  @HostListener('window:resize')
  onWindowResize() {
    this.updateHeight();
  }

  ngOnInit(): void {
    this.element.style.height = '0';
  }

  ngAfterViewChecked(): void {
    this.ngZone.runOutsideAngular(() => {
      this.scheduleUpdateHeight();
    });
  }

  scheduleUpdateHeight() {
    setTimeout(() => {
      this.ngZone.run(() => {
        this.updateHeight();
      });
    }, 0);
  }

  updateHeight() {
    this.element.style.height = '0';
    this.element.style.height = `${Math.max(this.element.scrollHeight + 2, 25)}px`;
  }

  protected onFocus(event: FocusEvent) {
    super.onFocus(event);
    this.registerInputHandler();
  }

  protected onBlur(event: Event) {
    super.onBlur(event);
    this.deregisterInputHandler();
  }

  protected onEnter(event: KeyboardEvent) {
    // prevent autoselect and set value on enter
  }

  protected onInput() {
    this.updateHeight();
    switch (this.model.changeMode) {
      case ChangeModes.IMMEDIATE:
        this.refreshModelValue();
        break;
      case ChangeModes.DELAYED:
        if (this.changeDelayTimeout) {
          clearTimeout(this.changeDelayTimeout);
        }
        this.changeDelayTimeout = setTimeout(() => {
          this.changeDelayTimeout = null;
          this.refreshModelValue();
        }, this.model.changeDelay);
        break;
    }
  }

}
