import { ObservableCollectionOptions, ObservableModel } from '@typescript-kit/core';
import { Employee } from '@teamworks/global';

export interface EmployeeSelectViewData {
  employeeList?: Employee[];
  employeeId?: string;
}

export class EmployeeSelectViewFieldKey {
  static readonly EMPLOYEE_LIST = 'employeeList';
  static readonly EMPLOYEE_ID = 'employeeId';
}

export class EmployeeSelectViewModel extends ObservableModel implements EmployeeSelectViewData {
  static readonly KEYS = Object.keys(EmployeeSelectViewFieldKey).map(key => EmployeeSelectViewFieldKey[key]);

  constructor(data: EmployeeSelectViewData, allowedKeys?: string[], observeOptions?: ObservableCollectionOptions) {
    super(data, allowedKeys || EmployeeSelectViewModel.KEYS, observeOptions);
  }

  get employeeList(): Employee[] {
    return this.get(EmployeeSelectViewFieldKey.EMPLOYEE_LIST);
  }

  set employeeList(value: Employee[]) {
    this.set(EmployeeSelectViewFieldKey.EMPLOYEE_LIST, value);
  }

  get employeeId(): string {
    return this.get(EmployeeSelectViewFieldKey.EMPLOYEE_ID);
  }

  set employeeId(value: string) {
    this.set(EmployeeSelectViewFieldKey.EMPLOYEE_ID, value);
  }

  protected setupValues(values: EmployeeSelectViewData, exclusive: boolean): EmployeeSelectViewData {
    return super.setupValues(values, exclusive) as EmployeeSelectViewData;
  }
}
