import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthenticationGuard } from '@angular-kit/authentication';

import { SalaryOverviewComponent } from './component/salary-overview/salary-overview.component';

const routes: Routes = [
  {
    path: 'salary-overview',
    canActivate: [AuthenticationGuard],
    children: [
      { path: ':year/:month', component: SalaryOverviewComponent },
      { path: ':year', redirectTo: ':year/-' },
      { path: '**', redirectTo: '-/-' },
    ]
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class SalaryRoutingModule {

}
