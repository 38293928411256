import { Component, ViewEncapsulation, ChangeDetectionStrategy, Input } from '@angular/core';
import { ContainerModel } from '@typescript-kit/view';

@Component({
  selector: 'app-month-select',
  templateUrl: './month-select.component.html',
  styleUrls: ['./month-select.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MonthSelectComponent {

  @Input()
  public model: ContainerModel;

}
