import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@angular-kit/authentication';
import { UserData } from '@typescript-kit/authentication';
import { UserService } from '../../../shared/service/user.service';
import {
  ActionModel,
  ChangeModes,
  CheckboxModel,
  ComponentModel,
  FormFieldModel,
  KeyCodes,
  TextInputModel,
  TextModel,
  ValueModel
} from '@typescript-kit/view';
import { CoreValidationKey, TextService } from '@typescript-kit/core';
import { WelcomeValidationKey } from '@teamworks/global';
import { Router } from '@angular/router';
import { WelcomeComponentKey } from '../../key/welcome-component.key';
import { ShellViewKey } from '../../../shell/key/shell-view.key';

@Component({
  selector: 'app-setup-user',
  templateUrl: './setup-user.component.html',
  styleUrls: ['./setup-user.component.scss'],
  providers: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SetupUserComponent implements OnInit {
  public user: UserData;
  public intro: TextModel;
  public usernameLabelValue: TextModel;
  public usernameValue: ValueModel;
  public emailValueLabel: TextModel;
  public emailValue: ValueModel;
  public passwordField: FormFieldModel;
  public passwordConfirmField: FormFieldModel;
  public actionButton: ActionModel;
  public invalidOrExpiredTokenText: TextModel;
  public passwordFieldHint: TextModel;
  public privacyPolicyCheck: CheckboxModel;
  public jobProcessingContractCheck: CheckboxModel;
  private isPasswordValid: boolean = false;

  constructor(private readonly changeDetector: ChangeDetectorRef,
              private readonly authenticationService: AuthenticationService,
              private readonly userService: UserService,
              private readonly textService: TextService,
              private readonly router: Router) {
    this.privacyPolicyCheck = new CheckboxModel({
      key: `${ShellViewKey.REGISTER}/agreements/privacy-policy`,
      onChanged: event => this.refreshActionButtonState()
    });
    this.jobProcessingContractCheck = new CheckboxModel({
      key: `${ShellViewKey.REGISTER}/agreements/job-processing-contract`,
      onChanged: event => this.refreshActionButtonState()
    });
  }

  async ngOnInit(): Promise<void> {
    this.createComponentModels();
    this.user = await this.authenticationService.resolveCurrentUser();
    this.refreshComponentModels();
  }

  private createComponentModels() {
    /*  let password: string = null;
      let passwordConfirm: string = null;*/
    this.invalidOrExpiredTokenText = new TextModel({text: `#(${WelcomeComponentKey.SETUP_USER}/invalid-or-expired-token)`});
    this.intro = new TextModel({text: `#(${WelcomeComponentKey.SETUP_USER}/intro)`});
    this.usernameValue = new ValueModel({
      key: 'username'
    });
    this.usernameLabelValue = new TextModel({text: `#(${WelcomeComponentKey.SETUP_USER}/form/username)`});
    this.emailValue = new ValueModel({
      key: 'email'
    });
    this.emailValueLabel = new TextModel({text: `#(${WelcomeComponentKey.SETUP_USER}/form/email)`});
    this.passwordField = new FormFieldModel({
      control: new TextInputModel({
        key: 'password',
        label: true,
        isObscured: true,
        placeholder: `#(${WelcomeComponentKey.SETUP_USER}/form/password)`,
        tags: ['form-control-lg'],
        changeMode: ChangeModes.IMMEDIATE,
        validation: {
          [CoreValidationKey.REQUIRED]: true,
          [WelcomeValidationKey.PASSWORD_REQUIREMENT]: true,
        },
        onValueChanged: async (value, originalValue, model) => {
          this.isPasswordValid = await model.validate();
          this.refreshActionButtonState();
        },
        onKeydown: async (event, model: ValueModel) => this.onInputModelKeydown(event, model)
      })
    });
    this.passwordFieldHint = new TextModel({text: `#(${WelcomeComponentKey.SETUP_USER}/form/password-hint)`});
    /*  this.passwordConfirmField = new FormFieldModel({
        label: new TextModel({text: `TODO: Passwort wiederholen`}),
        control: new TextInputModel({
          key: 'confirmPassword',
          label: true,
          isObscured: true,
          tags: ['form-control'],
          validation: {
            [CoreValidationKey.REQUIRED]: true,
            [CoreValidationKey.EQUAL]: (value) => equals(value, this.passwordField.value)
          },
          onValueChanged: async (value, originalValue, model) => {
            passwordConfirm = value;
            await validate();
          }
        })
      });*/

    this.actionButton = new ActionModel({
      tags: ['mt-4', 'btn', 'btn-lg', 'btn-primary', 'btn-block'],
      isDisabled: true,
      content: new TextModel({text: `#(${WelcomeComponentKey.SETUP_USER}/form/save)`}),
      onClick: async () => {
        await this.updatePassword();
      }
    });
    /*const validate = async () => {
      await this.passwordField.control.resolveIsValid();
      await this.passwordConfirmField.control.resolveIsValid();
      if (await this.passwordConfirmField.control.validate() && await this.passwordField.control.validate()) {
        this.actionButton.isDisabled = false;
      } else {
        this.actionButton.isDisabled = true;
      }
    };*/

  }

  getAgreementLabel(agreement: string): string {
    const question = this.textService.getText(`${ShellViewKey.REGISTER}/agreements/${agreement}/question`);

    const linkMatches = question.match(/\[(.+)\]\((.+)\)/);
    if (linkMatches.length < 3) {
      return question;
    }

    return question.replace(linkMatches[0], `<a href="${linkMatches[2]}" target="_blank">${linkMatches[1]}</a>`);
  }

  private async updatePassword() {
    const chosenPassword = this.passwordField.value;
    if (chosenPassword !== undefined) {
      const data = {password: chosenPassword};
      if (this.user.hasAcceptedTermsAndConditions) {
        await this.userService.updatePassword(this.user.id, data);
      } else {
        await this.userService.confirmUser(this.user.id, data);
      }
      await this.router.navigate(['/']);
    }
  }

  private refreshComponentModels() {
    if (this.user) {
      this.usernameValue.value = this.user.userName;
      this.emailValue.value = this.user.email;
    }
    this.refreshActionButtonState();
    this.changeDetector.markForCheck();
  }

  private async onInputModelKeydown(event: KeyboardEvent, model: ComponentModel) {
    if (event.key === KeyCodes.ENTER.key) {
      await this.updatePassword();
    }
  }

  private refreshActionButtonState(): void {
    this.actionButton.isDisabled = !this.isPasswordValid || !(this.user?.hasAcceptedTermsAndConditions || this.jobProcessingContractCheck.checked && this.privacyPolicyCheck.checked);
  }
}
