<h2 class="app-user-detail-title" *ngIf="viewModel.user">{{displayName}} </h2>

<kit-bs-form-field [kitModel]="nameField"></kit-bs-form-field>
<kit-bs-form-field [kitModel]="userNameField"></kit-bs-form-field>
<kit-bs-form-field [kitModel]="emailField"></kit-bs-form-field>

<span class="app-user-detail-permissions-title" [kitText]="permissionsText" *ngIf="viewModel.user && !creatingNewUser"></span>

<div *ngFor="let model of permissionModels" [kitContainer]="model.container"></div>

<kit-bs-button [kitModel]="sendPasswordEmailAction"></kit-bs-button>

<kit-bs-button [kitModel]="saveNewUserAction"></kit-bs-button>

<ng-template #sidebarTemplate>
  <app-user-sidebar></app-user-sidebar>
</ng-template>
