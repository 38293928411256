import { ObservableList, ObserveModes } from '@typescript-kit/core';
import { LogbookEntryModel } from '@teamworks/global';
import { LogbookSummary } from '@teamworks/global';
import { Employee } from '@teamworks/global';
import { MonthSelectViewData, MonthSelectViewFieldKey, MonthSelectViewModel } from '../../shared/view/month-select.view-model';

export interface LogbookViewData extends MonthSelectViewData {
  employeeList?: Employee[];
  employeeId?: string;
  logbookList?: ObservableList<LogbookEntryModel>;
  summary?: LogbookSummary;
}

export class LogbookViewFieldKey extends MonthSelectViewFieldKey {
  static readonly EMPLOYEE_LIST = 'employeeList';
  static readonly EMPLOYEE_ID = 'employeeId';
  static readonly LOGBOOK_LIST = 'logbookList';
  static readonly SUMMARY = 'summary';
}

export class LogbookViewModel extends MonthSelectViewModel implements LogbookViewData {
  static readonly KEYS = MonthSelectViewModel.KEYS.concat(
    Object.keys(LogbookViewFieldKey).map((key: string) => LogbookViewFieldKey[key])
  );

  constructor(data?: LogbookViewData) {
    super(data, LogbookViewModel.KEYS, { observeMode: ObserveModes.FLAT });
  }

  get employeeList(): Employee[] {
    return this.get(LogbookViewFieldKey.EMPLOYEE_LIST);
  }

  set employeeList(value: Employee[]) {
    this.set(LogbookViewFieldKey.EMPLOYEE_LIST, value);
  }

  get employeeId(): string {
    return this.get(LogbookViewFieldKey.EMPLOYEE_ID);
  }

  set employeeId(value: string) {
    this.set(LogbookViewFieldKey.EMPLOYEE_ID, value);
  }

  get logbookList(): ObservableList<LogbookEntryModel> {
    return this.get(LogbookViewFieldKey.LOGBOOK_LIST);
  }

  set logbookList(value: ObservableList<LogbookEntryModel>) {
    this.set(LogbookViewFieldKey.LOGBOOK_LIST, value);
  }

  get summary(): LogbookSummary {
    return this.get(LogbookViewFieldKey.SUMMARY);
  }

  set summary(value: LogbookSummary) {
    this.set(LogbookViewFieldKey.SUMMARY, value);
  }
}
