import { Entity, EntityData, EntityFieldKey, EntityModel } from '@typescript-kit/data';

export type TargetHoursType = 'work';

export class TargetHoursTypes {
  static readonly WORK: TargetHoursType = 'work';
}

export class TargetHoursFieldKey extends EntityFieldKey {
  static readonly COMPANY_ID = 'companyId';
  static readonly EMPLOYEE_ID = 'employeeId';
  static readonly TYPE = 'type';
  static readonly DATE = 'date';
  static readonly VALUE = 'value';
}

export interface TargetHoursData extends EntityData {
  companyId?: string;
  employeeId?: string;
  type?: string;
  date?: string;
  value?: number;
}

export class TargetHours extends Entity implements TargetHoursData {
  readonly companyId: string;
  readonly employeeId: string;
  readonly type: string;
  readonly date: string;
  readonly value: number;

  constructor(data: TargetHoursData) {
    super(data);
    this.companyId = data.companyId;
    this.employeeId = data.employeeId;
    this.type = data.type;
    this.date = data.date;
    this.value = data.value;
  }
}

export class TargetHoursModel extends EntityModel implements TargetHoursData {
  static readonly KEYS = EntityModel.KEYS.concat(
    Object.keys(TargetHoursFieldKey).map((key) => TargetHoursFieldKey[key])
  );

  constructor(data: TargetHoursData) {
    super(data, TargetHoursModel.KEYS);
  }

  get companyId(): string {
    return this.get(TargetHoursFieldKey.COMPANY_ID);
  }

  get employeeId(): string {
    return this.get(TargetHoursFieldKey.EMPLOYEE_ID);
  }

  get type(): string {
    return this.get(TargetHoursFieldKey.TYPE);
  }

  set type(value: string) {
    this.set(TargetHoursFieldKey.TYPE, value);
  }

  get date(): string {
    return this.get(TargetHoursFieldKey.DATE);
  }

  set date(value: string) {
    this.set(TargetHoursFieldKey.DATE, value);
  }

  get value(): number {
    return this.get(TargetHoursFieldKey.VALUE);
  }

  set value(value: number) {
    this.set(TargetHoursFieldKey.VALUE, value);
  }
}
