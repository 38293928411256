import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { Subscription } from 'rxjs';
import { ChangedEvent, TextService } from '@typescript-kit/core';
import { SharedComponentKey } from '../../key/shared-component.key';
import { SubscriptionViewFieldKey, SubscriptionViewModel } from '../../view/subscription.view-model';
import { SubscriptionViewService } from '../../view/subscription.view-service';
import { ActionModel, TextData, TextModel, ValueModel } from '@typescript-kit/view';
import { DialogService } from '../../service/dialog.service';
import moment from 'moment';

@Component({
  selector: 'app-current-subscription',
  templateUrl: './current-subscription.component.html',
  styleUrls: ['./current-subscription.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CurrentSubscriptionComponent implements OnInit, OnDestroy {
  public readonly titleText = `${SharedComponentKey.CURRENT_SUBSCRIPTION}/title`;
  public readonly currentPlanText = `${SharedComponentKey.CURRENT_SUBSCRIPTION}/current-plan`;

  public currentPlanValueModel: ValueModel;
  public currentPlanModel: TextModel;
  public currentPlanExpirationModel: TextModel;
  public deleteCompanyAction: ActionModel;

  private viewModelChangedSubscription: Subscription;

  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly viewService: SubscriptionViewService,
    private readonly textService: TextService,
    private readonly dialogService: DialogService
  ) {
  }

  get viewModel(): SubscriptionViewModel {
    return this.viewService.viewModel;
  }

  async ngOnInit(): Promise<void> {
    this.viewModelChangedSubscription = this.viewModel.changedEvent.subscribe(event => this.onViewModelChanged(event));
    await this.createComponentModels();
  }

  ngOnDestroy() {
    this.viewModelChangedSubscription.unsubscribe();
  }

  private async onViewModelChanged(event: ChangedEvent) {
    if (event.changes[SubscriptionViewFieldKey.CURRENT_SUBSCRIPTIONS]
      || event.changes[SubscriptionViewFieldKey.TRIAL_SUBSCRIPTION]
      || event.changes[SubscriptionViewFieldKey.REGULAR_SUBSCRIPTION]) {
      await this.refreshComponentModels();
    }
  }

  private async createComponentModels() {
    this.currentPlanValueModel = new ValueModel({
      key: `${SharedComponentKey.CURRENT_SUBSCRIPTION}/current-plan`,
      value: this.getCurrentPlanText()
    });
    this.currentPlanExpirationModel = new TextModel(this.getCurrentPlanExpirationData());
    this.deleteCompanyAction = new ActionModel({
      tags: ['app-form-btn', 'btn-sm', 'btn-outline-secondary'],
      content: new TextModel({
        text: `#(${SharedComponentKey.CURRENT_SUBSCRIPTION}/delete-company)`
      }),
      onClick: async () => {
        const isDeleteAllowed = await this.dialogService.openYesNoQuestion(
          {
            text: `#(${SharedComponentKey.CURRENT_SUBSCRIPTION}/delete-company-dialog/question)`,
            textArgs: { company: this.viewModel.company.name }
          },
        );
        if (isDeleteAllowed) {
          await this.viewService.deleteCompany();
        }
      }
    });

    await this.refreshComponentModels();
  }

  private async refreshComponentModels() {
    this.currentPlanValueModel.value = this.getCurrentPlanText();
    this.currentPlanExpirationModel.setValues(this.getCurrentPlanExpirationData());

    this.changeDetector.markForCheck();
  }

  private getCurrentPlanText(): string {
    if (this.viewModel.trialSubscription) {
      return this.textService.getText(
        `${SharedComponentKey.CURRENT_SUBSCRIPTION}/trial-plan`,
        { duration: moment(this.viewModel.trialSubscription.end).diff(this.viewModel.trialSubscription.begin, 'days').toFixed(0) }
      );
    }

    if (!this.viewModel.regularSubscription) {
      return this.textService.getText(`${SharedComponentKey.CURRENT_SUBSCRIPTION}/no-plan`);
    }

    return this.textService.getText(
      `${SharedComponentKey.CURRENT_SUBSCRIPTION}/regular-plan`,
      { price: this.viewModel.regularSubscription?.pricePerActiveEmployee }
    );
  }

  private getCurrentPlanExpirationData(): TextData {
    if (this.viewModel.trialSubscription) {
      const end = moment(this.viewModel.trialSubscription.end);
      return {
        text: this.viewModel.regularSubscription
          ? `${SharedComponentKey.CURRENT_SUBSCRIPTION}/trial-plan-continues-with-regular`
          : `${SharedComponentKey.CURRENT_SUBSCRIPTION}/trial-plan-end`,
        textArgs: {
          endDate: end.format('D. MMMM YYYY'),
          remainingDays: end.diff(moment(), 'days').toFixed(0)
        }
      };
    }

    if (!this.viewModel.regularSubscription) {
      return {
        text: `${SharedComponentKey.CURRENT_SUBSCRIPTION}/no-plan-text`
      };
    }

    if (this.viewModel.regularSubscription.end) {
      return {
        text: `${SharedComponentKey.CURRENT_SUBSCRIPTION}/regular-plan-end`,
        textArgs: {
          endDate: moment(this.viewModel.regularSubscription.end).format('D. MMMM YYYY')
        }
      };
    }

    return {
      text: `${SharedComponentKey.CURRENT_SUBSCRIPTION}/next-regular-payment`,
      textArgs: {
        paymentDate: moment().endOf('month').format('D. MMMM YYYY')
      }
    };
  }
}
