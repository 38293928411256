import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewModule } from '@angular-kit/view';

import { BsShellComponent } from '../component/bs-shell/bs-shell.component';
import { BsTextInputComponent } from '../component/bs-text-input/bs-text-input.component';
import { BsButtonComponent } from '../component/bs-button/bs-button.component';
import { BsTableComponent } from '../component/bs-table/bs-table.component';
import { BsDropdownComponent } from '../component/bs-dropdown/bs-dropdown.component';
import { BsSelectInputComponent } from '../component/bs-select-input/bs-select-input.component';
import { BsFormFieldComponent } from '../component/bs-form-field/bs-form-field.component';
import { BsAlertComponent } from '../component/bs-alert/bs-alert.component';
import { BsCheckboxComponent } from '../component/bs-checkbox/bs-checkbox.component';
import { BsTabsComponent } from '../component/bs-tabs/bs-tabs.component';

// REQUIRES THE FOLLOWING SCRIPTS
// EITHER
//  import 'bootstrap' (e.g., in main.ts)
// OR ADD THE FOLLOWING LINES TO ANGULAR.JSON
// "scripts": [
//   "node_modules/jquery/dist/jquery.slim.js",
//   "node_modules/popper.js/dist/umd/popper.js",
//   "node_modules/bootstrap/dist/js/bootstrap.js"
// ]

@NgModule({
  declarations: [
    BsButtonComponent,
    BsDropdownComponent,
    BsTableComponent,
    BsTextInputComponent,
    BsSelectInputComponent,
    BsFormFieldComponent,
    BsShellComponent,
    BsAlertComponent,
    BsCheckboxComponent,
    BsTabsComponent
  ],
  imports: [
    CommonModule,
    ViewModule
  ],
  entryComponents: [
    BsButtonComponent,
    BsDropdownComponent,
    BsTableComponent,
    BsTextInputComponent,
    BsSelectInputComponent,
    BsFormFieldComponent,
    BsShellComponent,
    BsAlertComponent,
    BsCheckboxComponent,
    BsTabsComponent
  ],
  exports: [
    BsButtonComponent,
    BsDropdownComponent,
    BsTableComponent,
    BsTextInputComponent,
    BsSelectInputComponent,
    BsFormFieldComponent,
    BsShellComponent,
    BsAlertComponent,
    BsCheckboxComponent,
    BsTabsComponent
  ]
})
export class BsViewModule { }
