import { Component, ViewEncapsulation, ChangeDetectionStrategy, HostBinding } from '@angular/core';
import { ContainerModel } from '@typescript-kit/view';
import { BaseDirective } from '@angular-kit/view';

@Component({
  selector: 'app-time-recording-summary',
  templateUrl: './time-recording-summary.component.html',
  styleUrls: ['./time-recording-summary.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimeRecordingSummaryComponent extends BaseDirective<ContainerModel> {}
