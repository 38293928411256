import { Component, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';
import { CheckboxComponent } from '@angular-kit/view';

@Component({
  selector: 'kit-bs-checkbox',
  templateUrl: './bs-checkbox.component.html',
  styleUrls: ['./bs-checkbox.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BsCheckboxComponent extends CheckboxComponent {
  get hostClass(): string {
    return 'kit-checkbox form-group form-check';
  }
}
