import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActionModel, TextModel } from '@typescript-kit/view';

@Component({
  selector: 'app-error-dialog',
  template: `
    <div mat-dialog-content>
      <ng-container *ngFor="let message of messages">
        <div  [kitText]="message"></div>
      </ng-container>
    </div>
    <div mat-dialog-actions>
      <kit-bs-button [kitModel]="cancelAction"></kit-bs-button>
      <kit-bs-button [kitModel]="confirmAction"></kit-bs-button>
    </div>
  `,
  styleUrls: ['./error-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrorDialogComponent implements OnInit {
  public readonly messages: TextModel[];
  public readonly confirmText: TextModel;
  public readonly cancelText: TextModel;
  public confirmAction: ActionModel;
  public cancelAction: ActionModel;
  constructor(private readonly dialog: MatDialogRef<ErrorDialogComponent>, @Inject(MAT_DIALOG_DATA) private readonly data) {
    this.messages = [];
    for (const message of data.messages) {
      this.messages.push(new TextModel(message));
    }
    this.confirmText = new TextModel(data.confirmText);
    this.cancelText = new TextModel(data.cancelText);
  }

  ngOnInit(): void {
    this.confirmAction = new ActionModel({
      tags: ['app-form-btn', 'btn-sm', 'btn-outline-secondary'],
      content: this.confirmText,
      onClick: () => {
        this.dialog.close(true);
      }
    });
    this.cancelAction = new ActionModel({
      tags: ['app-form-btn', 'btn-sm', 'btn-outline-secondary'],
      content: this.cancelText,
      onClick: () => {
        this.dialog.close(false);
      }
    });
  }


}
