import {
  Component,
  OnInit,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  AfterViewInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';

import { CoreActionKey, ApplicationError, AlertService, TextService } from '@typescript-kit/core';
import { TextInputModel, ActionModel, KeyCodes } from '@typescript-kit/view';
import { TextInputComponent } from '@angular-kit/view';
import { AuthenticationService } from '@angular-kit/authentication';

import { ShellGlobalTextKey } from '../../key/shell-global-text.key';
import { ShellViewService } from '../../view/shell.view-service';
import { ShellImageKey } from '../../key/shell-image.key';
import { ShellViewKey } from '../../key/shell-view.key';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResetPasswordComponent implements OnInit, AfterViewInit {
  public logoKey: string;
  public titleText: string;
  public vendorName: string;
  public developedByText: string;
  public emailInput: TextInputModel;
  public resetPasswordAction: ActionModel;
  public loginAction: ActionModel;

  @ViewChild('email', { static: true }) emailInputComponent: TextInputComponent;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private shellViewService: ShellViewService,
    private textService: TextService
  ) { }

  ngOnInit() {
    this.logoKey = ShellImageKey.VENDOR_LOGO;
    this.titleText = this.shellViewService.viewModel.appTitle;
    this.vendorName = ShellGlobalTextKey.VENDOR;
    this.developedByText = `${ShellViewKey.RESET_PASSWORD}/developed-by`;
    this.emailInput = new TextInputModel({
      key: `${ShellViewKey.RESET_PASSWORD}/email`,
      autocompleteToken: 'email',
      placeholder: true,
      onKeydown: (event: KeyboardEvent) => {
        if (event.key === KeyCodes.ENTER.key) {
          setTimeout(async () => this.resetPassword(), 0);
        }
      }
    });
    this.resetPasswordAction = new ActionModel({
      content: CoreActionKey.RESET_PASSWORD,
      onClick: () => this.resetPassword()
    });
    this.loginAction = new ActionModel({
      content: `${ShellViewKey.RESET_PASSWORD}/login`,
      onClick: () => this.login()
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.emailInput.isFocused = true;
    }, 200);
  }

  async resetPassword() {
    // TODO: Work-around chrome autofill
    this.emailInput.value = this.emailInputComponent.inputElement.value;
    this.authenticationService.resetPassword(this.emailInput.value)
      .then(() => this.login())
      .then(() => {
        this.alertService.alert({
          message: this.textService.getText(`${ShellViewKey.RESET_PASSWORD}/message`, { email: this.emailInput.value })
        });
      })
      .catch((error: ApplicationError) => {
        this.alertService.discardAll();
        this.alertService.alertError(error);
      });
  }

  login() {
    return this.router.navigate(['/login']);
  }
}
