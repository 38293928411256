import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';

import { TableComponent } from '@angular-kit/view';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'table[kitModel][app-custom-table]',
  templateUrl: './custom-table.component.html',
  styleUrls: ['./custom-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomTableComponent extends TableComponent implements AfterViewInit, AfterViewChecked, OnDestroy {
  // TODO: add explicit constructor


  private unlistenScroll: () => void;
  private scrollbarWidth = 0;
  private scrollbarHeight = 0;
  private refreshScrollbarSizeTimeout: any;

  @ViewChild('headCenterElement', { static: true })
  private headCenterElementRef: ElementRef;

  @ViewChild('bodyLeftElement', { static: true })
  private bodyLeftElementRef: ElementRef;

  @ViewChild('bodyCenterElement', { static: true })
  private bodyCenterElementRef: ElementRef;

  get headCenterElement(): HTMLTableHeaderCellElement {
    return this.headCenterElementRef.nativeElement;
  }

  get bodyLeftElement(): HTMLTableDataCellElement {
    return this.bodyLeftElementRef.nativeElement;
  }

  get bodyCenterElement(): HTMLTableDataCellElement {
    return this.bodyCenterElementRef.nativeElement;
  }

  get hostClass(): string {
    return 'app-custom-table';
  }

  @HostListener('window:resize')
  onWindowResize() {
    this.scheduleRefreshScrollbarSize();
  }

  ngAfterViewChecked(): void {
    this.scheduleRefreshScrollbarSize();
    super.ngAfterViewChecked();
  }

  ngAfterViewInit(): void {
    this.unlistenScroll = this.renderer.listen(this.bodyCenterElement, 'scroll', () => this.onScroll());
  }

  ngOnDestroy() {
    this.unlistenScroll();
    if (this.refreshScrollbarSizeTimeout) {
      clearTimeout(this.refreshScrollbarSizeTimeout);
    }
    super.ngOnDestroy();
  }

  private onScroll() {
    this.headCenterElement.scrollLeft = this.bodyCenterElement.scrollLeft;
    this.bodyLeftElement.scrollTop = this.bodyCenterElement.scrollTop;
  }

  private scheduleRefreshScrollbarSize() {
    if (this.refreshScrollbarSizeTimeout) {
      clearTimeout(this.refreshScrollbarSizeTimeout);
    }
    this.refreshScrollbarSizeTimeout = setTimeout(() => {
      this.refreshScrollbarSizeTimeout = null;
      this.refreshScrollbarSize();
    }, 0);
  }

  private refreshScrollbarSize() {
    const scrollbarWidth = this.bodyCenterElement.offsetWidth - this.bodyCenterElement.clientWidth;
    if (this.scrollbarWidth !== scrollbarWidth) {
      this.scrollbarWidth = scrollbarWidth;
      this.headCenterElement.style.marginRight = scrollbarWidth > 0 ? `${scrollbarWidth}px` : '0';
    }
    const scrollbarHeight = this.bodyCenterElement.offsetHeight - this.bodyCenterElement.clientHeight;
    if (this.scrollbarHeight !== scrollbarHeight) {
      this.scrollbarHeight = scrollbarHeight;
      this.bodyLeftElement.style.marginBottom = scrollbarHeight > 0 ? `${scrollbarHeight}px` : '0';
    }
  }
}
