import { ObservableModel, ObservableCollectionOptions } from '@typescript-kit/core';

export class EntityFieldKey {
  static readonly CREATED = 'created';
  static readonly MODIFIED = 'modified';
  static readonly EDITOR_ID = 'editorId';
}

export interface EntityData {
  created?: string;
  modified?: string;
  editorId?: string;
}

export class Entity implements EntityData {
  public readonly created: string;
  public readonly modified: string;
  public readonly editorId: string;

  constructor(data?: EntityData) {
    this.created = data.created;
    this.modified = data.modified;
    this.editorId = data.editorId;
  }
}

export class EntityModel extends ObservableModel implements EntityData {
  static readonly KEYS = Object.keys(EntityFieldKey).map((key) => EntityFieldKey[key]);

  constructor(data: EntityData, keys: string[] = null, observeOptions?: ObservableCollectionOptions) {
    super(data, keys, observeOptions);
  }

  get created(): string {
    return this.get(EntityFieldKey.CREATED);
  }

  get modified(): string {
    return this.get(EntityFieldKey.MODIFIED);
  }

  get editorId(): string {
    return this.get(EntityFieldKey.EDITOR_ID);
  }

}
