import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { ShellViewService } from '../../../shell/view/shell.view-service';
import { ProjectCommonViewService } from '../../view-service/project-common-shared.view-service';
import { TemplateModel } from '@angular-kit/view';
import { ProjectCommonViewFieldKey, ProjectCommonViewModel } from '../../view-model/project-common.view-model';
import { Subscription } from 'rxjs';
import { ChangedEvent } from '@typescript-kit/core';
import { ProjectTimeRecordingDetailViewService } from '../../view-service/project-time-recording-detail.view-service';
import { ProjectCommonShellMenuViewService } from '../../view-service/project-common-shell-menu.view-service';
import { TimeRecordingComponentKey } from '../../key/time-recording-component.key';
import { TabModel, TabsModel, ContainerModel } from '@typescript-kit/view';
import { ProjectActivitiesTableViewService } from '../../view-service/project-activities-table.view-service';

@Component({
  selector: 'app-project-overview',
  templateUrl: './project-overview.component.html',
  styleUrls: ['./project-overview.component.scss'],
  providers: [
    ProjectCommonViewService,
    ProjectCommonShellMenuViewService,
    ProjectTimeRecordingDetailViewService,
    ProjectActivitiesTableViewService
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectOverviewComponent implements OnInit, OnDestroy {
  public tabsModel: TabsModel;

  @ViewChild('timeRecordingTemplate', { static: true })
  private timeRecordingTemplate: TemplateRef<any>;

  @ViewChild('managementTemplate', { static: true })
  private managementTemplate: TemplateRef<any>;

  @ViewChild('activitiesTemplate', { static: true })
  private activitiesTemplate: TemplateRef<any>;

  @ViewChild('sidebarTemplate', { static: true })
  private sidebarTemplate: TemplateRef<any>;

  private viewModelChangedSubscription: Subscription;

  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly shellViewService: ShellViewService,
    private readonly projectTimeRecordingSharedViewService: ProjectCommonViewService,
    private readonly projectTimeRecordingShellMenuViewService: ProjectCommonShellMenuViewService,
    private readonly projectTimeRecordingDetailViewService: ProjectTimeRecordingDetailViewService
  ) {
  }

  get viewModel(): ProjectCommonViewModel {
    return this.projectTimeRecordingSharedViewService.viewModel;
  }

  ngOnInit() {
    this.projectTimeRecordingSharedViewService.initialize();
    this.projectTimeRecordingShellMenuViewService.initialize();
    this.projectTimeRecordingDetailViewService.initialize();
    this.shellViewService.setSidebar(new TemplateModel({ template: this.sidebarTemplate }));
    this.viewModelChangedSubscription = this.viewModel.changedEvent.subscribe((event) => this.onViewModelChanged(event));
    this.createComponentModels();
  }

  ngOnDestroy(): void {
    this.viewModelChangedSubscription.unsubscribe();
    this.shellViewService.restoreSidebar();
    this.projectTimeRecordingDetailViewService.finalize();
    this.projectTimeRecordingShellMenuViewService.finalize();
    this.projectTimeRecordingSharedViewService.finalize();
  }

  onTabSwitch(newTab: TabModel) {
    const text = newTab.text;
    if (typeof text !== 'string') {
      return;
    }

    if (text.includes(TimeRecordingComponentKey.PROJECT_TIME_RECORDING)) {
      this.viewModel.currentTab = 'time-recording';
    } else if (text.includes(TimeRecordingComponentKey.PROJECT_MANAGEMENT)) {
      this.viewModel.currentTab = 'management';
    } else if (text.includes(TimeRecordingComponentKey.PROJECT_ACTIVITIES)) {
      this.viewModel.currentTab = 'activities';
    }
  }

  private onViewModelChanged(event: ChangedEvent) {
    if (event.changes[ProjectCommonViewFieldKey.PROJECT]
      || event.changes[ProjectCommonViewFieldKey.IS_PROJECT_ADMIN]
      || event.changes[ProjectCommonViewFieldKey.CAN_SEE_OVERVIEW]) {
      this.refreshComponentModels();
    }
  }

  private createComponentModels() {
    this.tabsModel = new TabsModel({
      tabs: [],
      isHidden: true
    });

    this.refreshComponentModels();
  }

  private refreshComponentModels() {
    const tabs: TabModel[] = [];
    const currentPath = this.viewModel.currentTab;
    let currentTab: TabModel | undefined;

    if (this.viewModel.canSeeOverview) {
      const overviewTab = new TabModel({
        content: new ContainerModel({ items: [new TemplateModel({ template: this.timeRecordingTemplate })] }),
        text: TimeRecordingComponentKey.PROJECT_TIME_RECORDING + '/tab-title'
      });

      tabs.push(overviewTab);
      if (currentTab === undefined || currentPath === 'time-recording') {
        currentTab = overviewTab;
      }
    }

    if (this.viewModel.isProjectAdmin) {
      const managementTab = new TabModel({
        content: new ContainerModel({ items: [new TemplateModel({ template: this.managementTemplate })] }),
        text: TimeRecordingComponentKey.PROJECT_MANAGEMENT + '/tab-title'
      });

      tabs.push(managementTab);
      if (currentTab === undefined || currentPath === 'management') {
        currentTab = managementTab;
      }
    }

    const activitiesTab = new TabModel({
      content: new ContainerModel({ items: [new TemplateModel({ template: this.activitiesTemplate })] }),
      text: TimeRecordingComponentKey.PROJECT_ACTIVITIES + '/tab-title'
    });

    tabs.push(activitiesTab);
    if (currentTab === undefined || currentPath === 'activities') {
      currentTab = activitiesTab;
    }

    this.tabsModel.setValues({
      tabs,
      currentTab,
      isHidden: !this.viewModel.project
    });

    this.changeDetector.markForCheck();
  }
}
