<div class="fullscreen-center-container">
  <div *ngIf="user">
    <h2 [kitText]="intro"></h2>
    <div>
      <span [kitText]="usernameLabelValue"></span> <span [kitValue]="usernameValue"></span>
    </div>

    <div>
      <span [kitText]="emailValueLabel"></span> <span [kitValue]="emailValue"></span>
    </div>

    <kit-bs-form-field [kitModel]="passwordField"></kit-bs-form-field>
    <div [kitText]="passwordFieldHint"></div>

    <ng-container *ngIf="!user.hasAcceptedTermsAndConditions">
      <div class="agreement-check">
        <kit-checkbox [kitCheckbox]="jobProcessingContractCheck" id="checkboxJobProcessingContract"></kit-checkbox>
        <label for="checkboxJobProcessingContract" innerHtml="{{getAgreementLabel('job-processing-contract')}}"></label>
      </div>

      <div class="agreement-check">
        <kit-checkbox [kitCheckbox]="privacyPolicyCheck" id="checkboxPrivacyPolicy"></kit-checkbox>
        <label for="checkboxPrivacyPolicy" innerHtml="{{getAgreementLabel('privacy-policy')}}"></label>
      </div>
    </ng-container>

    <!--<kit-bs-form-field [kitModel]="passwordConfirmField"></kit-bs-form-field>-->
    <kit-bs-button [kitModel]="actionButton"></kit-bs-button>
  </div>
  <div *ngIf="user === null">
    <div [kitText]="invalidOrExpiredTokenText"></div>
  </div>
</div>
