import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ProjectModel, TagMapping, TagMappingTargetTypes } from '@teamworks/global';
import { TimeRecordingViewKey } from '../../key/time-recording-view.key';
import { TagTableModel } from '../../../shared/component-model/tag-table.model';
import { ActionModel, TextModel } from '@typescript-kit/view';
import { TimeRecordingComponentKey } from '../../key/time-recording-component.key';
import { AssignTokenDialogComponent } from '../assign-token-dialog/assign-token-dialog.component';
import { ObservableList, ObserveModes } from '@typescript-kit/core';

@Component({
  selector: 'app-edit-activity-tokens-dialog',
  templateUrl: 'edit-activity-tokens-dialog.component.html',
  styleUrls: ['edit-activity-tokens-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditActivityTokensDialogComponent implements OnInit {
  public readonly titleText = `${TimeRecordingViewKey.EDIT_ACITIVTY_TOKENS_DIALOG}/title`;
  public readonly noTagsText = `${TimeRecordingViewKey.EDIT_ACITIVTY_TOKENS_DIALOG}/no-tags`;

  public tagTable: TagTableModel;
  public assignTokenAction: ActionModel;

  private readonly initialTagMappings: TagMapping[];
  private readonly activity: ProjectModel;

  constructor(
    private readonly dialog: MatDialogRef<EditActivityTokensDialogComponent>,
    private readonly matDialogService: MatDialog,
    @Inject(MAT_DIALOG_DATA) private readonly data
  ) {
    this.initialTagMappings = this.data.tagMappings;
    this.activity = this.data.activity;
  }

  ngOnInit(): void {
    this.dialog.backdropClick().subscribe((c) => {
      c.preventDefault();
      this.dialog.close(this.tagTable.tagList.toArray());
    });

    this.tagTable = new TagTableModel();
    this.assignTokenAction = new ActionModel({
      tags: ['app-form-btn', 'btn-sm', 'btn-outline-secondary'],
      content: new TextModel({
        text: `#(${TimeRecordingComponentKey.ASSIGN_TOKEN_DIALOG}/title)`
      }),
      onClick: async () => {
        const dialog = this.matDialogService.open(AssignTokenDialogComponent, {
          width: '400px',
          data: { targetId: this.activity.id, targetType: TagMappingTargetTypes.PROJECT }
        });
        const newTagMapping = await dialog.afterClosed().toPromise();
        if (newTagMapping !== null && newTagMapping !== undefined) {
          this.refreshTagTable([newTagMapping, ...this.tagTable.tagList.toArray()]);
        }
      }
    });

    this.refreshTagTable(this.initialTagMappings);
  }

  private refreshTagTable(tagList: TagMapping[]): void {
    this.tagTable.setValues({ tagList: new ObservableList(tagList, { observeMode: ObserveModes.FLAT }) });
  }
}
