<ng-template [ngIf]="viewModel">
  <div class="row">
    <app-current-subscription></app-current-subscription>

    <app-manage-subscription></app-manage-subscription>
  </div>

  <div class="row">
    <app-company-detail></app-company-detail>

    <app-payment-methods></app-payment-methods>
  </div>
</ng-template>
