import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoreModule } from '@angular-kit/core';
import { AuthenticationModule } from '@angular-kit/authentication';
import { ViewModule } from '@angular-kit/view';
import { BsViewModule } from '@angular-kit/bs-view';

import { SharedModule } from '../shared/shared.module';
import { ShellModule } from '../shell/shell.module';
import { SalaryRoutingModule } from './salary-routing.module';

import { SalaryInitializer } from './salary.initializer';
import { SalaryOverviewComponent } from './component/salary-overview/salary-overview.component';
import { SalaryService } from './service/salary.service';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    ViewModule,
    BsViewModule,
    AuthenticationModule,
    SharedModule,
    ShellModule,
    SalaryRoutingModule,
  ],
  declarations: [
    SalaryOverviewComponent,
  ],
  providers: [
    SalaryService
  ]
})
export class SalaryModule {

  constructor() {
    SalaryInitializer.initialize();
  }

}
