import { Change, ChangeOptions, ObservableList, ObservableModel, ObserveModes, toArray } from '@typescript-kit/core';
import { Entity, EntityData, EntityFieldKey, EntityModel } from '@typescript-kit/data';
import {
  AccountingRecordItem,
  AccountingRecordItemData,
  AccountingRecordItemModel
} from './accounting-record-item.model';
import { LetterHeader, LetterHeaderData, LetterHeaderModel } from '../../shared/model/letter-header.model';

export type AccountingRecordType = 'offering' | 'invoice' | 'delivery-note' | 'order-confirmation';

export class AccountingRecordTypes {
  static readonly OFFERING: AccountingRecordType = 'offering';
  static readonly ORDER_CONFIRMATION: AccountingRecordType = 'order-confirmation';
  static readonly DELIVERY_NOTE: AccountingRecordType = 'delivery-note';
  static readonly INVOICE: AccountingRecordType = 'invoice';
}

export type AccountingLogoType = 'signum' | 'signum-software' | 'signum-master-business';

export class AccountingLogoTypes {
  static readonly SIGNUM: AccountingLogoType = 'signum';
  static readonly SIGNUM_SOFTWARE: AccountingLogoType = 'signum-software';
  static readonly SIGNUM_MASTER_BUSINESS: AccountingLogoType = 'signum-master-business';
}

export class AccountingEntityFieldKey extends EntityFieldKey {
  static readonly COMPANY_ID = 'companyId';
  static readonly DATE = 'date';
  static readonly INDEX = 'index';
  static readonly RECORD = 'record';
}

export interface AccountingEntityData extends EntityData {
  companyId?: string;
  date?: string;
  index?: number;
  record?: AccountingRecordData;
}

export class AccountingEntity extends Entity {
  readonly companyId: string;
  readonly date: string;
  readonly index: number;
  readonly record: AccountingRecord;

  constructor(data: AccountingEntityData) {
    super(data);
    this.companyId = data.companyId;
    this.date = data.date;
    this.index = data.index;
    this.record = (!data.record || data.record instanceof AccountingRecord)
      ? data.record as AccountingRecord
      : new AccountingRecord(data.record);
  }
}

export class AccountingEntityModel extends EntityModel implements AccountingEntityData {
  static readonly KEYS = EntityModel.KEYS.concat(
    Object.keys(AccountingEntityFieldKey).map(key => AccountingEntityFieldKey[key])
  );

  constructor(data: AccountingEntityData) {
    super(data, AccountingEntityModel.KEYS, { observeMode: ObserveModes.FLAT });
  }

  get companyId(): string {
    return this.get(AccountingEntityFieldKey.COMPANY_ID);
  }

  get date(): string {
    return this.get(AccountingEntityFieldKey.DATE);
  }

  get index(): number {
    return this.get(AccountingEntityFieldKey.INDEX);
  }

  get record(): AccountingRecordModel {
    return this.get(AccountingEntityFieldKey.RECORD);
  }

  protected setupValues(values: AccountingEntityData, exclusive: boolean): AccountingEntityData {
    this.setupAccountingRecord(values, exclusive);
    return super.setupValues(values, exclusive);
  }

  protected setupAccountingRecord(data: AccountingEntityData, exclusive: boolean) {
    if ((exclusive || AccountingEntityFieldKey.RECORD in data) && !(data.record instanceof AccountingRecordModel)) {
      const originalValue = this.record;
      if (originalValue) {
        originalValue.setValues(data.record, { exclusive: true });
        data.record = originalValue;
      } else {
        data.record = new AccountingRecordModel(data.record);
      }
    }
  }

}

export interface AccountingRecordData extends EntityData {
  type: string;
  logo: string;
  designation: string;
  customer: string;
  offerDate: Date;
  executionDate: Date;
  invoice: string;
  salesTaxRate: number;
  allocatedCosts: Iterable<AccountingRecordItemData>;
  operatingCosts: Iterable<AccountingRecordItemData>;
  letterHeader: LetterHeaderData;
  pageFooter: string;
  price: string;
  paymentTerms: string;
  complimentaryClose: string;
}

export class AccountingRecordFieldKey {
  static readonly LOGO = 'logo';
  static readonly TYPE = 'type';
  static readonly DESIGNATION = 'designation';
  static readonly CUSTOMER = 'customer';
  static readonly OFFER_DATE = 'offerDate';
  static readonly EXECUTION_DATE = 'executionDate';
  static readonly INVOICE = 'invoice';
  static readonly SALES_TAX_RATE = 'salesTaxRate';
  static readonly ALLOCATED_COSTS = 'allocatedCosts';
  static readonly OPERATING_COSTS = 'operatingCosts';
  static readonly LETTER_HEADER = 'letterHeader';
  static readonly PAGE_FOOTER = 'pageFooter';
  static readonly PRICE = 'price';
  static readonly PAYMENT_TERMS = 'paymentTerms';
  static readonly COMPLIMENTARY_CLOSE = 'complimentaryClose';
}

export class AccountingRecord implements AccountingRecordData {
  readonly logo: string;
  readonly type: string;
  readonly designation: string;
  readonly customer: string;
  readonly offerDate: Date;
  readonly executionDate: Date;
  readonly invoice: string;
  readonly salesTaxRate: number;
  readonly allocatedCosts: AccountingRecordItem[];
  readonly operatingCosts: AccountingRecordItem[];
  readonly letterHeader: LetterHeader;
  readonly pageFooter: string;
  readonly price: string;
  readonly paymentTerms: string;
  readonly complimentaryClose: string;

  constructor(data: AccountingRecordData) {
    this.logo = data.logo;
    this.type = data.type;
    this.designation = data.designation;
    this.customer = data.customer;
    this.offerDate = data.offerDate;
    this.executionDate = data.executionDate;
    this.invoice = data.invoice;
    this.salesTaxRate = data.salesTaxRate;
    this.allocatedCosts = toArray(data.allocatedCosts)
      .map((itemData) => itemData instanceof AccountingRecordItem
        ? itemData
        : new AccountingRecordItem(itemData)
      );
    this.operatingCosts = toArray(data.operatingCosts)
      .map((itemData) => itemData instanceof AccountingRecordItem
        ? itemData
        : new AccountingRecordItem(itemData)
      );
    this.letterHeader = (!data.letterHeader || data.letterHeader instanceof LetterHeader)
      ? data.letterHeader as LetterHeader
      : new LetterHeader(data.letterHeader);
    this.pageFooter = data.pageFooter;
    this.price = data.price;
    this.paymentTerms = data.paymentTerms;
    this.complimentaryClose = data.complimentaryClose;
  }
}

export class AccountingRecordModel extends ObservableModel implements AccountingRecordData {
  static readonly KEYS = Object.keys(AccountingRecordFieldKey).map((key: string) => AccountingRecordFieldKey[key]);

  constructor(data: AccountingRecordData) {
    super(data, AccountingRecordModel.KEYS, { observeMode: ObserveModes.FLAT });
  }

  get type(): string {
    return this.get(AccountingRecordFieldKey.TYPE);
  }

  set type(value: string) {
    this.set(AccountingRecordFieldKey.TYPE, value);
  }

  get logo(): string {
    return this.get(AccountingRecordFieldKey.LOGO);
  }

  set logo(value: string) {
    this.set(AccountingRecordFieldKey.LOGO, value);
  }

  get designation(): string {
    return this.get(AccountingRecordFieldKey.DESIGNATION);
  }

  set designation(value: string) {
    this.set(AccountingRecordFieldKey.DESIGNATION, value);
  }

  get customer(): string {
    return this.get(AccountingRecordFieldKey.CUSTOMER);
  }

  set customer(value: string) {
    this.set(AccountingRecordFieldKey.CUSTOMER, value);
  }

  get offerDate(): Date {
    return this.get(AccountingRecordFieldKey.OFFER_DATE);
  }

  set offerDate(value: Date) {
    this.set(AccountingRecordFieldKey.OFFER_DATE, value);
  }

  get executionDate(): Date {
    return this.get(AccountingRecordFieldKey.EXECUTION_DATE);
  }

  set executionDate(value: Date) {
    this.set(AccountingRecordFieldKey.EXECUTION_DATE, value);
  }

  get invoice(): string {
    return this.get(AccountingRecordFieldKey.INVOICE);
  }

  set invoice(value: string) {
    this.set(AccountingRecordFieldKey.INVOICE, value);
  }

  get salesTaxRate(): number {
    return this.get(AccountingRecordFieldKey.SALES_TAX_RATE);
  }

  set salesTaxRate(value: number) {
    this.set(AccountingRecordFieldKey.SALES_TAX_RATE, value);
  }

  get allocatedCosts(): ObservableList<AccountingRecordItemModel> {
    return this.get(AccountingRecordFieldKey.ALLOCATED_COSTS);
  }

  set allocatedCosts(value: ObservableList<AccountingRecordItemModel>) {
    this.set(AccountingRecordFieldKey.ALLOCATED_COSTS, value);
  }

  get operatingCosts(): ObservableList<AccountingRecordItemModel> {
    return this.get(AccountingRecordFieldKey.OPERATING_COSTS);
  }

  set operatingCosts(value: ObservableList<AccountingRecordItemModel>) {
    this.set(AccountingRecordFieldKey.OPERATING_COSTS, value);
  }

  get letterHeader(): LetterHeaderModel {
    return this.get(AccountingRecordFieldKey.LETTER_HEADER);
  }

  set letterHeader(value: LetterHeaderModel) {
    this.set(AccountingRecordFieldKey.LETTER_HEADER, value);
  }

  get pageFooter(): string {
    return this.get(AccountingRecordFieldKey.PAGE_FOOTER);
  }

  set pageFooter(value: string) {
    this.set(AccountingRecordFieldKey.PAGE_FOOTER, value);
  }

  get paymentTerms(): string {
    return this.get(AccountingRecordFieldKey.PAYMENT_TERMS);
  }

  set paymentTerms(value: string) {
    this.set(AccountingRecordFieldKey.PAYMENT_TERMS, value);
  }

  get price(): string {
    return this.get(AccountingRecordFieldKey.PRICE);
  }

  set price(value: string) {
    this.set(AccountingRecordFieldKey.PRICE, value);
  }

  get complimentaryClose(): string {
    return this.get(AccountingRecordFieldKey.COMPLIMENTARY_CLOSE);
  }

  set complimentaryClose(value: string) {
    this.set(AccountingRecordFieldKey.COMPLIMENTARY_CLOSE, value);
  }

  setValues(values: AccountingRecordData, options?: ChangeOptions): { [key: string]: Change<any> } {
    return super.setValues(values, options);
  }

  protected setupValues(values: AccountingRecordData, exclusive: boolean): AccountingRecordData {
    // if (exclusive || (AccountingRecordFieldKey.TYPE in values)) {
    //   if (values.type !== this.type) {
    //     if (!(AccountingRecordFieldKey.DESIGNATION in values)) {
    //       values.designation = null;
    //     }
    //     if (!(AccountingRecordFieldKey.CUSTOMER in values)) {
    //       values.customer = null;
    //     }
    //     if (!(AccountingRecordFieldKey.OFFER_DATE in values)) {
    //       values.offerDate = null;
    //     }
    //     if (!(AccountingRecordFieldKey.EXECUTION_DATE in values)) {
    //       values.executionDate = null;
    //     }
    //     if (!(AccountingRecordFieldKey.INVOICE in values)) {
    //       values.invoice = null;
    //     }
    //     if (!(AccountingRecordFieldKey.OPERATING_COSTS in values)) {
    //       values.operatingCosts = null;
    //     }
    //     if (!(AccountingRecordFieldKey.PRICE in values)) {
    //       values.price = null;
    //     }
    //     if (!(AccountingRecordFieldKey.PAYMENT_TERMS in values)) {
    //       values.paymentTerms = null;
    //     }
    //   }
    // }
    this.setupObservableList(AccountingRecordFieldKey.ALLOCATED_COSTS, values, exclusive);
    this.setupObservableList(AccountingRecordFieldKey.OPERATING_COSTS, values, exclusive);
    this.setupLetterHeader(values, exclusive);
    return super.setupValues(values, exclusive) as AccountingRecordData;
  }

  private setupObservableList(key: string, values: AccountingRecordData, exclusive: boolean) {
    if (!exclusive && !(key in values) || values[key] instanceof ObservableList) {
      return;
    }
    const itemModels = toArray(values[key])
      .map((itemData) => itemData instanceof AccountingRecordItemModel
        ? itemData
        : new AccountingRecordItemModel(itemData)
      );
    const currentValue = this.get(key) as ObservableList<AccountingRecordItemModel>;
    if (currentValue) {
      currentValue.setValues(itemModels, { exclusive: true });
      values[key] = currentValue;
    } else {
      values[key] = new ObservableList(itemModels, { observeMode: ObserveModes.FLAT });
    }
  }

  private setupLetterHeader(values: AccountingRecordData, exclusive: boolean) {
    if (!exclusive && !(AccountingRecordFieldKey.LETTER_HEADER in values) || values.letterHeader instanceof LetterHeaderModel) {
      return;
    }
    values.letterHeader = new LetterHeaderModel(values.letterHeader);
  }
}
