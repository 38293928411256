<h2 class="app-current-subscription-title" [kitText]="titleText"></h2>

<div class="app-current-subscription-plan">
  <span class="app-current-subscription-plan-label" [kitValueLabel]="currentPlanValueModel"></span>
  <span class="app-current-subscription-plan-value" [kitValue]="currentPlanValueModel"></span>
</div>

<span class="app-current-subscription-expirement" [kitText]="currentPlanExpirationModel"></span>

<kit-bs-button [kitModel]="deleteCompanyAction"></kit-bs-button>
