<div>

  <h1><strong>Datenschutzerklärung</strong></h1>
  <p><strong>Name der Datenverarbeitung</strong><br>Arbeitszeitaufzeichnung und Lohnverrechnung mit dem Produkt
    TeamWorks
  </p>
  <p><strong>Rechtsgrundlage Vertragserfüllung</strong><br>Die von Ihnen bereit gestellten Daten sind zur
    Vertragserfüllung bzw. zur Durchführung vorvertraglicher Maßnahmen erforderlich. Ohne diese Daten können wir den
    Vertrag mit Ihnen nicht abschließen. Zweck der Datenverarbeitung ist die Arbeitszeitaufzeichnung und Lohnverrechnung
    mit dem Produkt TeamWorks.</p>
  <p><strong>Speicherdauer/Löschungsfrist</strong><br>Wir speichern Ihre Daten bis zum Vertragsende. Ihre Daten werden
    nicht an Dritte weitergegeben.</p>
  <p><strong>Kontakt</strong><br>Sie erreichen uns unter folgenden Kontaktdaten:<br>Signum Plus GmbH, <a
    href="mailto:office@signum-plus.at" target="_blank" rel="noopener noreferrer">mailto:office@signum-plus.at</a>, +43
    732 90225950</p>
  <p>Unseren Datenschutzbeauftragten erreichen Sie unter:<br>Dr. Markus Löberbauer, <a
    href="mailto:office@signum-plus.at"
    target="_blank"
    rel="noopener noreferrer">mailto:office@signum-plus.at</a>,
    +43 732 90225950</p>
  <p><strong>Rechtsbehelfsbelehrung</strong><br>Ihnen stehen grundsätzlich die Rechte auf Auskunft, Berichtigung,
    Löschung, Einschränkung, Datenübertragbarkeit und Widerspruch zu. Dafür wenden Sie sich an uns. Wenn Sie glauben,
    dass
    die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche sonst in
    einer Weise verletzt worden sind, können Sie sich bei der Aufsichtsbehörde beschweren. In Österreich ist die
    Datenschutzbehörde zuständig.</p>
  <p><strong>Kontaktadressen</strong><br>Aufsichtsbehörde für die Einhaltung datenschutzrechtlicher Bestimmungen in
    Österreich:<br>Österreichische Datenschutzbehörde<br>Barichgasse 40-42<br>1030 Wien<br>Telefon: +43 1 52 152-0<br>E:
    <a href="mailto:dsb@dsb.gv.at" target="_blank" rel="noopener noreferrer">mailto:dsb@dsb.gv.at</a><br>W: <a
      href="http://www.dsb.gv.at/" target="_blank" rel="noopener noreferrer">http://www.dsb.gv.at/</a><br>Kontaktadresse
    rund um das Thema Datenschutzrecht:<br>Wirtschaftskammer Oberösterreich<br>Servicecenter<br>Hessenplatz 3<br>4024
    Linz<br>T
    +43(0)5/90909<br>E <a href="mailto:service@wkooe.at" target="_blank"
                          rel="noopener noreferrer">mailto:service@wkooe.at</a><br>W <a href="http://wko.at/ooe"
                                                                                        target="_blank"
                                                                                        rel="noopener noreferrer">http://wko.at/ooe</a>
  </p>
  <p>Bei allen personenbezogenen Bezeichnungen gilt die gewählte Form für beide Geschlechter.</p>
  <p>Datum 09.09.2021</p>
</div>
