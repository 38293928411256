<form class="app-login-form" novalidate>
  <h1 class="h2 mt-2 font-weight-normal" kitText="{{titleText}}"></h1>
  <p class="text-muted app-developed-by" kitText="{{developedByText}}"></p>
  <img class="mb-5 app-logo" src="{{logoKey}}" alt="">


  <label [kitValueLabel]="userNameInput" for="inputUserName" class="sr-only"></label>
  <input #username [kitModel]="userNameInput" id="inputUserName" class="form-control">

  <label [kitValueLabel]="passwordInput" for="inputPassword" class="sr-only">Password</label>
  <input #password [kitModel]="passwordInput" id="inputPassword" class="form-control">

  <button [kitModel]="loginAction" class="mt-4 btn btn-lg btn-primary btn-block" type="submit"></button>

  <a href [kitModel]="resetPasswordAction" class="app-reset-password"></a>

  <a href [kitModel]="registerAction" class="app-register"></a>
  
</form>
<app-teamworks-promotion></app-teamworks-promotion>
