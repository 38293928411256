<div class="accordion" id="teamworks-promotion-accordion">
  <div class="card">
    <div class="card-header">
      <h2>
        <button class="btn btn-lg btn-block text-left" type="button" data-toggle="collapse"
                data-target="#teamworks-promotion-why-teamworks" aria-expanded="true">
          Warum TeamWorks?
        </button>
      </h2>
    </div>

    <div id="teamworks-promotion-why-teamworks" class="collapse show" data-parent="#teamworks-promotion-accordion">
      <div class="card-body">
        Arbeitszeitaufzeichnung für Freiberufler und kleine Teams

        <ul>
          <li>bequemer als eine selbstgemachte Excel-Stundenliste</li>
          <li> minimaler Einarbeitungsaufwand dank Reduktion aufs Wesentliche</li>
          <li> sofort startbereit ohne komplexe Konfiguration</li>
          <li> unterstützt Angestellte mit Gehältern und Arbeiter mit Stundenlöhnen</li>
          <li> erfüllt die gesetzliche Aufzeichnungspflicht</li>
        </ul>
      </div>
    </div>
  </div>

  <div class="card">
    <div class="card-header">
      <h2>
        <button class="btn btn-lg btn-block text-left collapsed" type="button" data-toggle="collapse"
                data-target="#teamworks-promotion-features">
          Features
        </button>
      </h2>
    </div>
    <div id="teamworks-promotion-features" class="collapse" data-parent="#teamworks-promotion-accordion">
      <div class="card-body">

        <ul>
          <li> Kommen/Gehen buchen mit Chip am Wandgerät</li>
          <li>Zeitstempel in der Web-App bearbeiten und Projekten zuordnen</li>
          <li> monatlichen Stundenzettel als PDF downloaden</li>
          <li> Kilometergelder im Fahrtenbuch erfassen</li>
          <li> Ist-Stunden mit Sollzeit vergleichen</li>
          <li> Gleitzeitkonto und Resturlaube verwalten</li>
          <li> Daten als CSV für Lohnverrechnung exportieren</li>
        </ul>
      </div>
    </div>
  </div>

  <div class="card">
    <div class="card-header">
      <h2>
        <button class="btn btn-lg btn-block text-left collapsed" type="button" data-toggle="collapse"
                data-target="#teamworks-promotion-price">
          Preise
        </button>
      </h2>
    </div>
    <div id="teamworks-promotion-price" class="collapse" data-parent="#teamworks-promotion-accordion">
      <div class="card-body">
        <ul>
          <li>60 Tage kostenlos und unverbindlich testen</li>
          <li> danach 10 € zzgl. 20% USt. (12€ inkl. USt.) monatlich pro Mitarbeiter</li>
          <li> monatlich kündbar</li>
        </ul>
      </div>
    </div>
  </div>

  <div class="card">
    <div class="card-header">
      <h2>
        <button class="btn btn-lg btn-block text-left collapsed" type="button" data-toggle="collapse"
                data-target="#teamworks-promotion-data-protection">
          Datenschutz
        </button>
      </h2>
    </div>
    <div id="teamworks-promotion-data-protection" class="collapse" data-parent="#teamworks-promotion-accordion">
      <div class="card-body">
        Ihre Daten sind bei uns sicher:  Datenschutzerklärung.
      </div>
    </div>
  </div>
</div>
