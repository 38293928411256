import { Big } from 'big.js';
import { Change, ChangeOptions, hasValue } from '@typescript-kit/core';
import { SalaryRecordData, SalaryRecordFieldKey } from './salary-record.model';
import { EntityData, Entity, EntityModel } from '@typescript-kit/data';

export interface SalaryOverviewRecordData extends EntityData {
  employeeId?: string;
  date?: string;
  employeeName?: string;
  work?: number;
  publicHoliday?: number;
  sickLeave?: number;
  shortTimeWork?: number;
  holiday?: number;
  compTime?: number;
  actualHours?: number;
  expectedHours?: number;
  salariedHours?: number;
  overtime?: number;
  overtime50?: number;
  overtime100?: number;
  totalHours?: number;
  hourRate?: string;
  hourWage?: string;
  bonus25?: string;
  bonus50?: string;
  bonus100?: string;
  wage?: string;
  salary?: string;
  holidaySubvention?: string;
  christmasRemuneration?: string;
  holidayCompensation?: string;
  travelCostsRemuneration?: string;
  travelCostsCompensation?: string;
  kilometreAllowance?: string;
  salaryTransformation?: string;
  nonCashBenefitsCar?: string;
  nonCashBenefitsOther?: string;
  flextime?: number;
  flextimeBalance?: number;
  holidayBalance?: number;
}

// noinspection DuplicatedCode
export class SalaryOverviewRecord extends Entity implements SalaryOverviewRecordData {
  readonly employeeId: string;
  readonly date: string;
  readonly employeeName: string;
  readonly work: number;
  readonly publicHoliday: number;
  readonly sickLeave: number;
  readonly shortTimeWork: number;
  readonly holiday: number;
  readonly compTime: number;
  readonly actualHours: number;
  readonly expectedHours: number;
  readonly salariedHours: number;
  readonly overtime: number;
  readonly overtime50: number;
  readonly overtime100: number;
  readonly totalHours: number;
  readonly hourRate: string;
  readonly hourWage: string;
  readonly bonus25: string;
  readonly bonus50: string;
  readonly bonus100: string;
  readonly wage: string;
  readonly salary: string;
  readonly holidaySubvention: string;
  readonly christmasRemuneration: string;
  readonly holidayCompensation: string;
  readonly travelCostsRemuneration: string;
  readonly travelCostsCompensation: string;
  readonly kilometreAllowance: string;
  readonly salaryTransformation: string;
  readonly nonCashBenefitsCar: string;
  readonly nonCashBenefitsOther: string;
  readonly flextime: number;
  readonly flextimeBalance: number;
  readonly holidayBalance: number;

  constructor(data: SalaryOverviewRecordData) {
    super(data);
    this.employeeId = data.employeeId;
    this.date = data.date;
    this.employeeName = data.employeeName;
    this.work = data.work;
    this.publicHoliday = data.publicHoliday;
    this.sickLeave = data.sickLeave;
    this.shortTimeWork = data.shortTimeWork;
    this.holiday = data.holiday;
    this.compTime = data.compTime;
    this.actualHours = data.actualHours;
    this.expectedHours = data.expectedHours;
    this.salariedHours = data.salariedHours;
    this.overtime = data.overtime;
    this.overtime50 = data.overtime50;
    this.overtime100 = data.overtime100;
    this.totalHours = data.totalHours;
    this.hourRate = data.hourRate;
    this.hourWage = data.hourWage;
    this.bonus25 = data.bonus25;
    this.bonus50 = data.bonus50;
    this.bonus100 = data.bonus100;
    this.wage = data.wage;
    this.salary = data.salary;
    this.holidaySubvention = data.holidaySubvention;
    this.christmasRemuneration = data.christmasRemuneration;
    this.holidayCompensation = data.holidayCompensation;
    this.travelCostsRemuneration = data.travelCostsRemuneration;
    this.travelCostsCompensation = data.travelCostsCompensation;
    this.kilometreAllowance = data.kilometreAllowance;
    this.salaryTransformation = data.salaryTransformation;
    this.nonCashBenefitsCar = data.nonCashBenefitsCar;
    this.nonCashBenefitsOther = data.nonCashBenefitsOther;
    this.flextime = data.flextime;
    this.flextimeBalance = data.flextimeBalance;
    this.holidayBalance = data.holidayBalance;
  }
}

export class SalaryOverviewRecordFieldKey {
  static readonly EMPLOYEE_ID = 'employeeId';
  static readonly DATE = 'date';
  static readonly EMPLOYEE_NAME = 'employeeName';
  static readonly WORK = 'work';
  static readonly PUBLIC_HOLIDAY = 'publicHoliday';
  static readonly SICK_LEAVE = 'sickLeave';
  static readonly SHORT_TIME_WORK = 'shortTimeWork';
  static readonly HOLIDAY = 'holiday';
  static readonly COMP_TIME = 'compTime';
  static readonly ACTUAL_HOURS = 'actualHours';
  static readonly EXPECTED_HOURS = 'expectedHours';
  static readonly SALARIED_HOURS = 'salariedHours';
  static readonly OVERTIME = 'overtime';
  static readonly OVERTIME_50 = 'overtime50';
  static readonly OVERTIME_100 = 'overtime100';
  static readonly TOTAL_HOURS = 'totalHours';
  static readonly HOUR_RATE = 'hourRate';
  static readonly HOUR_WAGE = 'hourWage';
  static readonly BONUS_25 = 'bonus25';
  static readonly BONUS_50 = 'bonus50';
  static readonly BONUS_100 = 'bonus100';
  static readonly WAGE = 'wage';
  static readonly SALARY = 'salary';
  static readonly HOLIDAY_SUBVENTION = 'holidaySubvention';
  static readonly CHRISTMAS_REMUNERATION = 'christmasRemuneration';
  static readonly HOLIDAY_COMPENSATION = 'holidayCompensation';
  static readonly TRAVEL_COSTS_REMUNERATION = 'travelCostsRemuneration';
  static readonly TRAVEL_COSTS_COMPENSATION = 'travelCostsCompensation';
  static readonly KILOMETRE_ALLOWANCE = 'kilometreAllowance';
  static readonly SALARY_TRANSFORMATION = 'salaryTransformation';
  static readonly NON_CASH_BENEFITS_CAR = 'nonCashBenefitsCar';
  static readonly NON_CASH_BENEFITS_OTHER = 'nonCashBenefitsOther';
  static readonly FLEXTIME = 'flextime';
  static readonly FLEXTIME_BALANCE = 'flextimeBalance';
  static readonly HOLIDAY_BALANCE = 'holidayBalance';
}

export class SalaryOverviewRecordModel extends EntityModel implements SalaryOverviewRecordData {
  static readonly KEYS = EntityModel.KEYS.concat(
    Object.keys(SalaryOverviewRecordFieldKey).map((key) => SalaryOverviewRecordFieldKey[key])
  );

  constructor(data: SalaryOverviewRecordData) {
    super(data, SalaryOverviewRecordModel.KEYS);
  }

  get employeeId(): string {
    return this.get(SalaryOverviewRecordFieldKey.EMPLOYEE_ID);
  }

  get date(): string {
    return this.get(SalaryOverviewRecordFieldKey.DATE);
  }

  get employeeName(): string {
    return this.get(SalaryOverviewRecordFieldKey.EMPLOYEE_NAME);
  }

  set employeeName(value: string) {
    this.set(SalaryOverviewRecordFieldKey.EMPLOYEE_NAME, value);
  }

  get work(): number {
    return this.get(SalaryOverviewRecordFieldKey.WORK);
  }

  set work(value: number) {
    this.set(SalaryOverviewRecordFieldKey.WORK, value);
  }

  get publicHoliday(): number {
    return this.get(SalaryOverviewRecordFieldKey.PUBLIC_HOLIDAY);
  }

  set publicHoliday(value: number) {
    this.set(SalaryOverviewRecordFieldKey.PUBLIC_HOLIDAY, value);
  }

  get sickLeave(): number {
    return this.get(SalaryOverviewRecordFieldKey.SICK_LEAVE);
  }

  set sickLeave(value: number) {
    this.set(SalaryOverviewRecordFieldKey.SICK_LEAVE, value);
  }

  get shortTimeWork(): number {
    return this.get(SalaryOverviewRecordFieldKey.SHORT_TIME_WORK);
  }

  set shortTimeWork(value: number) {
    this.set(SalaryOverviewRecordFieldKey.SHORT_TIME_WORK, value);
  }

  get holiday(): number {
    return this.get(SalaryOverviewRecordFieldKey.HOLIDAY);
  }

  set holiday(value: number) {
    this.set(SalaryOverviewRecordFieldKey.HOLIDAY, value);
  }

  get compTime(): number {
    return this.get(SalaryOverviewRecordFieldKey.COMP_TIME);
  }

  set compTime(value: number) {
    this.set(SalaryOverviewRecordFieldKey.COMP_TIME, value);
  }

  get actualHours(): number {
    return this.get(SalaryOverviewRecordFieldKey.ACTUAL_HOURS);
  }

  set actualHours(value: number) {
    this.set(SalaryOverviewRecordFieldKey.ACTUAL_HOURS, value);
  }

  get expectedHours(): number {
    return this.get(SalaryOverviewRecordFieldKey.EXPECTED_HOURS);
  }

  set expectedHours(value: number) {
    this.set(SalaryOverviewRecordFieldKey.EXPECTED_HOURS, value);
  }

  get salariedHours(): number {
    return this.get(SalaryOverviewRecordFieldKey.SALARIED_HOURS);
  }

  set salariedHours(value: number) {
    this.set(SalaryOverviewRecordFieldKey.SALARIED_HOURS, value);
  }

  get overtime(): number {
    return this.get(SalaryOverviewRecordFieldKey.OVERTIME);
  }

  set overtime(value: number) {
    this.set(SalaryOverviewRecordFieldKey.OVERTIME, value);
  }

  get overtime50(): number {
    return this.get(SalaryOverviewRecordFieldKey.OVERTIME_50);
  }

  set overtime50(value: number) {
    this.set(SalaryOverviewRecordFieldKey.OVERTIME_50, value);
  }

  get overtime100(): number {
    return this.get(SalaryOverviewRecordFieldKey.OVERTIME_100);
  }

  set overtime100(value: number) {
    this.set(SalaryOverviewRecordFieldKey.OVERTIME_100, value);
  }

  get totalHours(): number {
    return this.get(SalaryOverviewRecordFieldKey.TOTAL_HOURS);
  }

  set totalHours(value: number) {
    this.set(SalaryOverviewRecordFieldKey.TOTAL_HOURS, value);
  }

  get hourRate(): string {
    return this.get(SalaryOverviewRecordFieldKey.HOUR_RATE);
  }

  set hourRate(value: string) {
    this.set(SalaryOverviewRecordFieldKey.HOUR_RATE, value);
  }

  get hourWage(): string {
    return this.get(SalaryOverviewRecordFieldKey.HOUR_WAGE);
  }

  set hourWage(value: string) {
    this.set(SalaryOverviewRecordFieldKey.HOUR_WAGE, value);
  }

  get bonus25(): string {
    return this.get(SalaryOverviewRecordFieldKey.BONUS_25);
  }

  set bonus25(value: string) {
    this.set(SalaryOverviewRecordFieldKey.BONUS_25, value);
  }

  get bonus50(): string {
    return this.get(SalaryOverviewRecordFieldKey.BONUS_50);
  }

  set bonus50(value: string) {
    this.set(SalaryOverviewRecordFieldKey.BONUS_50, value);
  }

  get bonus100(): string {
    return this.get(SalaryOverviewRecordFieldKey.BONUS_100);
  }

  set bonus100(value: string) {
    this.set(SalaryOverviewRecordFieldKey.BONUS_100, value);
  }

  get wage(): string {
    return this.get(SalaryOverviewRecordFieldKey.WAGE);
  }

  set wage(value: string) {
    this.set(SalaryOverviewRecordFieldKey.WAGE, value);
  }

  get salary(): string {
    return this.get(SalaryOverviewRecordFieldKey.SALARY);
  }

  set salary(value: string) {
    this.set(SalaryOverviewRecordFieldKey.SALARY, value);
  }

  get holidaySubvention(): string {
    return this.get(SalaryOverviewRecordFieldKey.HOLIDAY_SUBVENTION);
  }

  set holidaySubvention(value: string) {
    this.set(SalaryOverviewRecordFieldKey.HOLIDAY_SUBVENTION, value);
  }

  get christmasRemuneration(): string {
    return this.get(SalaryOverviewRecordFieldKey.CHRISTMAS_REMUNERATION);
  }

  set christmasRemuneration(value: string) {
    this.set(SalaryOverviewRecordFieldKey.CHRISTMAS_REMUNERATION, value);
  }

  get holidayCompensation(): string {
    return this.get(SalaryOverviewRecordFieldKey.HOLIDAY_COMPENSATION);
  }

  set holidayCompensation(value: string) {
    this.set(SalaryOverviewRecordFieldKey.HOLIDAY_COMPENSATION, value);
  }

  get travelCostsRemuneration(): string {
    return this.get(SalaryOverviewRecordFieldKey.TRAVEL_COSTS_REMUNERATION);
  }

  set travelCostsRemuneration(value: string) {
    this.set(SalaryOverviewRecordFieldKey.TRAVEL_COSTS_REMUNERATION, value);
  }

  get travelCostsCompensation(): string {
    return this.get(SalaryOverviewRecordFieldKey.TRAVEL_COSTS_COMPENSATION);
  }

  set travelCostsCompensation(value: string) {
    this.set(SalaryOverviewRecordFieldKey.TRAVEL_COSTS_COMPENSATION, value);
  }

  get kilometreAllowance(): string {
    return this.get(SalaryOverviewRecordFieldKey.KILOMETRE_ALLOWANCE);
  }

  set kilometreAllowance(value: string) {
    this.set(SalaryOverviewRecordFieldKey.KILOMETRE_ALLOWANCE, value);
  }

  get salaryTransformation(): string {
    return this.get(SalaryOverviewRecordFieldKey.SALARY_TRANSFORMATION);
  }

  set salaryTransformation(value: string) {
    this.set(SalaryOverviewRecordFieldKey.SALARY_TRANSFORMATION, value);
  }

  get nonCashBenefitsCar(): string {
    return this.get(SalaryOverviewRecordFieldKey.NON_CASH_BENEFITS_CAR);
  }

  set nonCashBenefitsCar(value: string) {
    this.set(SalaryOverviewRecordFieldKey.NON_CASH_BENEFITS_CAR, value);
  }

  get nonCashBenefitsOther(): string {
    return this.get(SalaryOverviewRecordFieldKey.NON_CASH_BENEFITS_OTHER);
  }

  set nonCashBenefitsOther(value: string) {
    this.set(SalaryOverviewRecordFieldKey.NON_CASH_BENEFITS_OTHER, value);
  }

  get flextime(): number {
    return this.get(SalaryOverviewRecordFieldKey.FLEXTIME);
  }

  set flextime(value: number) {
    this.set(SalaryOverviewRecordFieldKey.FLEXTIME, value);
  }

  get flextimeBalance(): number {
    return this.get(SalaryOverviewRecordFieldKey.FLEXTIME_BALANCE);
  }

  set flextimeBalance(value: number) {
    this.set(SalaryOverviewRecordFieldKey.FLEXTIME_BALANCE, value);
  }

  get holidayBalance(): number {
    return this.get(SalaryOverviewRecordFieldKey.HOLIDAY_BALANCE);
  }

  set holidayBalance(value: number) {
    this.set(SalaryOverviewRecordFieldKey.HOLIDAY_BALANCE, value);
  }

  setValues(values: SalaryOverviewRecordData, options?: ChangeOptions): { [p: string]: Change<any> } {
    return super.setValues(values, options);
  }

  protected setupValues(values: SalaryOverviewRecordData, exclusive: boolean): SalaryOverviewRecordData {
    this.setupHourRateValues(values, exclusive);
    this.setupInputValue(SalaryRecordFieldKey.HOUR_RATE, values, exclusive);
    this.setupInputValue(SalaryRecordFieldKey.SALARY, values, exclusive);
    this.setupInputValue(SalaryRecordFieldKey.HOLIDAY_SUBVENTION, values, exclusive);
    this.setupInputValue(SalaryRecordFieldKey.CHRISTMAS_REMUNERATION, values, exclusive);
    this.setupInputValue(SalaryRecordFieldKey.HOLIDAY_COMPENSATION, values, exclusive);
    this.setupInputValue(SalaryRecordFieldKey.TRAVEL_COSTS_REMUNERATION, values, exclusive);
    this.setupInputValue(SalaryRecordFieldKey.TRAVEL_COSTS_COMPENSATION, values, exclusive);
    this.setupInputValue(SalaryRecordFieldKey.SALARY_TRANSFORMATION, values, exclusive);
    this.setupInputValue(SalaryRecordFieldKey.NON_CASH_BENEFITS_CAR, values, exclusive);
    this.setupInputValue(SalaryRecordFieldKey.NON_CASH_BENEFITS_OTHER, values, exclusive);
    return super.setupValues(values, exclusive) as SalaryOverviewRecordData;
  }

  private setupHourRateValues(values: SalaryOverviewRecordData, exclusive: boolean): void {
    if (!(SalaryOverviewRecordFieldKey.HOUR_RATE in values) && !exclusive) {
      return;
    }
    if (hasValue(values.hourRate)) {
      const hourRate: Big = new Big(values.hourRate).round(2);
      const salariedHours = new Big(this.getSetupValue(values, SalaryOverviewRecordFieldKey.SALARIED_HOURS, exclusive) || 0).round(2);
      const overtime =  new Big(this.getSetupValue(values, SalaryOverviewRecordFieldKey.OVERTIME, exclusive) || 0).round(2);
      const overtime50 = new Big(this.getSetupValue(values, SalaryOverviewRecordFieldKey.OVERTIME_50, exclusive) || 0).round(2);
      const overtime100 = new Big(this.getSetupValue(values, SalaryOverviewRecordFieldKey.OVERTIME_100, exclusive) || 0).round(2);
      const hourWage: Big = salariedHours.plus(overtime).plus(overtime50).plus(overtime100).times(hourRate).round(2);
      const bonus25: Big = overtime.times(hourRate).times(0.25).round(2);
      const bonus50: Big = overtime50.times(hourRate).times(0.5).round(2);
      const bonus100: Big = overtime100.times(hourRate).times(1).round(2);
      const wage: Big = hourWage.plus(bonus25).plus(bonus50).plus(bonus100);
      values.hourRate = hourRate.valueOf();
      values.hourWage = hourWage.valueOf();
      values.bonus25 = bonus25.valueOf();
      values.bonus50 = bonus50.valueOf();
      values.bonus100 = bonus100.valueOf();
      values.wage = wage.valueOf();
    } else {
      values.hourRate = null;
      values.hourWage = null;
      values.bonus25 = null;
      values.bonus50 = null;
      values.bonus100 = null;
      values.wage = null;
    }
  }

  private setupInputValue(key: string, values: SalaryRecordData, exclusive: boolean) {
    if (!(key in values) && !exclusive) {
      return;
    }
    values[key] = hasValue(values[key]) ? new Big(values[key]).toFixed(2) : null;
  }
}

