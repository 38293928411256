import { Injectable } from '@angular/core';
import { ViewService } from '@angular-kit/view';
import {
  ActionModel,
  Alignments,
  ContainerModel,
  DropdownModel,
  ImageModel,
  Orientations,
  TextModel,
  ViewComponentKey
} from '@typescript-kit/view';
import { MonthSelectViewModel } from '../../shared/view/month-select.view-model';
import { MonthSelectViewService } from '../../shared/view/month-select.view-service';
import { SharedSvgKey } from '../../shared/key/shared-svg.key';
import { SalaryService } from '../service/salary.service';
import { SharedActionKey } from '../../shared/key/shared-action.key';
import { SalaryCommonViewService } from './salary-common.view-service';

@Injectable()
export class SalaryShellMenuViewService extends ViewService<MonthSelectViewModel, ContainerModel> {
  protected readonly salaryService = this.injector.get(SalaryService);
  protected readonly salaryCommonViewService = this.injector.get(SalaryCommonViewService);
  protected readonly monthSelectViewService = new MonthSelectViewService(this.injector);

  initialize() {
    const viewModel = this.salaryCommonViewService.viewModel;
    this.monthSelectViewService.initialize(viewModel);
    super.initialize(viewModel);
  }

  finalize() {
    super.finalize();
    this.monthSelectViewService.finalize();
  }

  protected createComponentModel(): ContainerModel {
    return new ContainerModel({
      tags: ['app-salary-shell-menu'],
      orientation: Orientations.SIDE_BY_SIDE, alignment: Alignments.RIGHT,
      items: [
        this.monthSelectViewService.componentModel,
        this.createDownloadDropdownModel()
      ]
    });
  }

  protected refreshComponentModel(componentModel: ContainerModel) {
    super.refreshComponentModel(componentModel);
  }

  private createDownloadDropdownModel(): DropdownModel {
    return new DropdownModel({
      key: 'downloadDropdown',
      tags: ['app-download-dropdown'],
      trigger: new ImageModel({
        type: ViewComponentKey.SVG_SYMBOL,
        source: SharedSvgKey.DOWNLOAD_DOCUMENT
      }),
      items: [
        new ActionModel({
          key: 'to-csv-action',
          type: ViewComponentKey.LINK,
          content: new TextModel({ text: SharedActionKey.TO_CSV }),
          onClick: () => {
            return this.salaryService.downloadSalaryOverviewAsCSV(this.viewModel.year, this.viewModel.month);
          }
        }),
      ]
    });
  }
}

