import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import Popper from 'popper.js';

import { AutoCompleteInputFieldKey, AutoCompleteInputModel } from '../../component-model/auto-complete-input.model';
import { TextInputDirective } from '@angular-kit/view';
import { ChangedEvent, escapeRegex, hasValue, isEmpty } from '@typescript-kit/core';
import { ChangeModes, KeyCodes } from '@typescript-kit/view';

export interface AutoCompleteOptionData {
  index: number;
  prefix?: string;
  match?: string;
  suffix?: string;
}

@Component({
  selector: 'app-auto-complete-input',
  templateUrl: './auto-complete-input.component.html',
  styleUrls: ['./auto-complete-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AutoCompleteInputComponent extends TextInputDirective implements AfterViewChecked {

  private _visibleOptions: AutoCompleteOptionData[];
  private _selectedOptionIndex: number;
  private _visibleOptionsPopper: Popper;

  @ViewChild('inputElement', { static: true })
  private inputElementRef: ElementRef;

  @ViewChild('optionPopperElement', { static: true })
  private optionPopperElementRef: ElementRef;

  get hostClass(): string {
    return 'app-auto-complete-input';
  }

  @Input()
  set model(model: AutoCompleteInputModel) {
    super.model = model;
  }

  get model(): AutoCompleteInputModel {
    return super.model as AutoCompleteInputModel;
  }

  get focusElement(): HTMLElement {
    return this.inputElementRef.nativeElement;
  }

  get inputElement(): HTMLInputElement {
    return this.inputElementRef.nativeElement;
  }

  get optionPopperElement(): HTMLDivElement {
    return this.optionPopperElementRef.nativeElement;
  }

  get optionListElement(): HTMLDivElement {
    return this.optionPopperElement.firstElementChild as HTMLDivElement;
  }

  public get visibleOptions(): AutoCompleteOptionData[] {
    return this._visibleOptions;
  }

  public get selectedOptionIndex(): number {
    return this._selectedOptionIndex;
  }

  ngAfterViewChecked(): void {
    if (this._visibleOptionsPopper) {
      this._visibleOptionsPopper.scheduleUpdate();
    }
  }

  protected onModelChanged(model: AutoCompleteInputModel) {
    super.onModelChanged(model);
    this.onOptionsChanged();
  }

  protected onModelPropertyChanged(event: ChangedEvent) {
    super.onModelPropertyChanged(event);
    if (event.changes[AutoCompleteInputFieldKey.OPTIONS]) {
      this.onOptionsChanged();
    }
  }

  protected onOptionsChanged() {
    this.refreshInputHandler();
    if (this.visibleOptions) {
      this.refreshVisibleOptions();
    }
  }

  protected onFocus(event: FocusEvent) {
    super.onFocus(event);
    this.refreshInputHandler();
  }

  protected onBlur(event: Event) {
    if (hasValue(this.selectedOptionIndex)) {
      this.setOption(this.visibleOptions[this.selectedOptionIndex].index);
    }
    this.clearVisibleOptions();
    super.onBlur(event);
    this.refreshInputHandler();
  }

  protected onInput() {
    if (this.model.changeMode !== ChangeModes.TRIGGERED) {
      super.onInput();
    }
    this.refreshVisibleOptions();
  }

  protected onKeydown(event: KeyboardEvent) {
    if (!this._visibleOptions) {
      super.onKeydown(event);
      return;
    }
    switch (event.key) {
      case KeyCodes.ESC.key:
        super.onKeydown(event);
        this.clearVisibleOptions();
        break;
      case KeyCodes.ENTER.key:
        if (hasValue(this.selectedOptionIndex)) {
          this.setOption(this.visibleOptions[this.selectedOptionIndex].index);
        }
        this.clearVisibleOptions();
        if (this.model.autoselectAfterOptionSelect) {
          super.onKeydown(event);
        }
        break;
      case KeyCodes.DOWN.key:
        this.updateSelectedOptionIndex(1);
        event.preventDefault();
        break;
      case KeyCodes.UP.key:
        this.updateSelectedOptionIndex(-1);
        event.preventDefault();
        break;
      default:
        super.onKeydown(event);
        break;
    }
  }

  protected onSpace(event: KeyboardEvent) {
    super.onSpace(event);
    if (event.ctrlKey) {
      this.refreshVisibleOptions();
    }
  }

  public onOptionMousedown(event: MouseEvent, index: number) {
    event.preventDefault();
    if (event.button === 0) {
      // main button (usually left)
      this.setOption(index);
      this.refreshModelValue();
      if (this.model.autoselectAfterOptionSelect) {
        this.autoselect();
      }
    }
  }

  protected setOption(index: number): void {
    this.inputElement.value = this.model.options[index];
    this.clearVisibleOptions();
  }

  protected refreshInputHandler() {
    if (this.model.isFocused && this.model.options && this.model.options.length > 0) {
      super.registerInputHandler();
    } else {
      super.refreshInputHandler();
    }
  }

  protected refreshVisibleOptions(): void {
    let visibleOptions: AutoCompleteOptionData[];
    if (!this.model.options || this.model.options.length === 0) {
      this.clearVisibleOptions();
      return;
    }
    if (isEmpty(this.inputElement.value)) {
      visibleOptions = this.model.options.map((option, index) => {
        return { index, prefix: option, match: null, suffix: null };
      });
    } else {
      const value = this.inputElement.value;
      visibleOptions = this.model.options.map((option, index) => {
        const regex = new RegExp(escapeRegex(value), 'i');
        const match = regex.exec(option);
        if (!match) {
          return null;
        }
        const prefix = option.substring(0, match.index);
        const suffix = option.substring(match.index + match[0].length);
        return { index, prefix, match: match[0], suffix };
      }).filter((option) => !!option);
    }
    if (this._visibleOptions === visibleOptions) {
      return;
    }
    if (!this._visibleOptionsPopper) {
      this._visibleOptionsPopper = new Popper(this.inputElement, this.optionPopperElement, {
        placement: 'bottom-start',
        modifiers: {
          preventOverflow: { enabled: true },
          flip: { enabled: true, behavior: 'flip' },
          keepTogether: { enabled: true },
          offset: { offset: `0, 2px` }
        }
      });
    }
    this._visibleOptions = visibleOptions;
    this._selectedOptionIndex = null;
    this.changeDetector.markForCheck();
  }

  private clearVisibleOptions(): void {
    if (this._visibleOptions === null) {
      return;
    }
    this._visibleOptions = null;
    this._selectedOptionIndex = null;
    if (this._visibleOptionsPopper) {
      this._visibleOptionsPopper.destroy();
      this._visibleOptionsPopper = null;
    }
    this.changeDetector.markForCheck();
  }

  private updateSelectedOptionIndex(step: number): void {
    let selectedOptionIndex: number;
    if (this._visibleOptions.length === 0) {
      selectedOptionIndex = null;
    } else if (!hasValue(this._selectedOptionIndex)) {
      selectedOptionIndex = step > 0 ? 0 : this._visibleOptions.length - 1;
    } else {
      selectedOptionIndex = this._selectedOptionIndex + step;
      if (selectedOptionIndex < 0) {
        selectedOptionIndex = this._visibleOptions.length - 1;
      } else if (selectedOptionIndex >= this._visibleOptions.length) {
        selectedOptionIndex = 0;
      }
    }
    if (this._selectedOptionIndex === selectedOptionIndex) {
      return;
    }
    this._selectedOptionIndex = selectedOptionIndex;
    this.scrollToSelectedOption();
    this.changeDetector.markForCheck();
  }

  private scrollToSelectedOption(): void {
    if (!hasValue(this.selectedOptionIndex)) {
      return;
    }
    const listElement = this.optionListElement;
    const activeElement = listElement.children[this.selectedOptionIndex] as HTMLDivElement;
    listElement.scrollTop = activeElement.offsetTop
      - Math.round(listElement.offsetHeight / 2) + Math.round(activeElement.offsetHeight / 2);
  }

}
