<div class="app-time-recording-menu">
  <div *ngIf="!componentModel.menuModel.employeeName.isHidden"
       class="app-time-recording-menu-row app-time-recording-title">
    <h3 class="app-employee-name" [kitText]="componentModel.menuModel.employeeName"></h3>
    <h3 class="app-month-text" [kitText]="componentModel.menuModel.monthText"></h3>
  </div>

  <div *ngIf="componentModel.menuModel.employeeName.isHidden || componentModel.menuModel.monthComment.value"
       class="app-time-recording-menu-row app-time-recording-comment">
    <label [kitValueLabel]="componentModel.menuModel.monthComment"></label>
    <textarea [appModel]="componentModel.menuModel.monthComment"
              class="form-control form-control-sm app-month-comment">
    </textarea>
  </div>
</div>


<kit-bs-table [kitModel]="componentModel.tableModel"></kit-bs-table>

<div *ngIf="componentModel.summaryModel">
  <app-time-recording-summary [kitModel]="componentModel.summaryModel"></app-time-recording-summary>
</div>
