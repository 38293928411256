import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActionModel, CheckboxModel, TextModel } from '@typescript-kit/view';
import { AlertService } from '@typescript-kit/core';
import { EmployeePermission } from '../../view/user-common.view-model';
import { EmployeeModel } from '@teamworks/global';
import { SharedComponentKey } from '../../key/shared-component.key';
import { UserService } from '../../service/user.service';

@Component({
  selector: 'app-employee-permissions-dialog',
  templateUrl: 'employee-permissions-dialog.component.html',
  styleUrls: ['employee-permissions-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmployeePermissionsDialogComponent implements OnInit {
  public readonly titleText: string;
  private readonly employees: EmployeeModel[];
  private readonly userId: string;

  public employeeFields: CheckboxModel[];
  public confirmAction: ActionModel;
  public cancelAction: ActionModel;

  private employeePermissions: EmployeePermission[] | 'all';

  constructor(
    private readonly dialog: MatDialogRef<EmployeePermissionsDialogComponent>,
    private readonly alertService: AlertService,
    private readonly userService: UserService,
    @Inject(MAT_DIALOG_DATA) private readonly data
  ) {
    this.userId = data.userId;
    this.employees = data.employees
      .filter((e: EmployeeModel) => e.name)
      .sort((a: EmployeeModel, b: EmployeeModel) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0);
    this.employeePermissions = data.employeePermissions;
    this.titleText = `${SharedComponentKey.EMPLOYEE_PERMISSIONS_DIALOG}/title`;
  }

  ngOnInit(): void {
    this.confirmAction = new ActionModel({
      tags: ['app-form-btn', 'btn-sm', 'btn-outline-secondary'],
      content: new TextModel({
        text: `#(${SharedComponentKey.EMPLOYEE_PERMISSIONS_DIALOG}/actions/confirm)`
      }),
      onClick: async () => {
        try {
          await this.userService.setEmployeePermissions(this.userId, this.employeePermissions === 'all' ? ['*'] : this.employeePermissions.map((p) => p.id));
          this.dialog.close();
        } catch (error) {
          this.alertService.alertError(error);
        }
      }
    });
    this.cancelAction = new ActionModel({
      tags: ['app-form-btn', 'btn-sm', 'btn-outline-secondary'],
      content: new TextModel({
        text: `#(${SharedComponentKey.EMPLOYEE_PERMISSIONS_DIALOG}/actions/cancel)`
      }),
      onClick: () => this.dialog.close(null)
    });

    this.refreshEmployeeFields();
  }

  private refreshEmployeeFields(): void {
    this.employeeFields = [
      new CheckboxModel({
        checked: this.employeePermissions === 'all',
        label: new TextModel({
          text: `${SharedComponentKey.EMPLOYEE_PERMISSIONS_DIALOG}/all`
        }),
        labelPosition: 'left',
        onCheckedChanged: (isChecked) => this.setPermission('all', isChecked)
      }),
      ...this.employees.map((employee) => new CheckboxModel({
        checked: this.employeePermissions !== 'all' && this.employeePermissions.find((e) => e.id === employee.id) !== undefined,
        label: employee.name,
        labelPosition: 'left',
        onCheckedChanged: (isChecked) => this.setPermission(employee, isChecked)
      }))
    ];
  }

  private setPermission(employee: EmployeeModel | 'all', granted: boolean) {
    if (employee === 'all') {
      this.employeePermissions = 'all';
    } else {
      if (this.employeePermissions === 'all') {
        this.employeePermissions = [];
      }

      const permissionIndex = this.employeePermissions.findIndex((p) => p.id === employee.id);
      if (granted && permissionIndex < 0) {
        this.employeePermissions.push(employee);
      } else if (!granted && permissionIndex >= 0) {
        this.employeePermissions.splice(permissionIndex, 1);
      }
    }

    this.refreshEmployeeFields();
  }
}
