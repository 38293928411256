import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from '@angular-kit/authentication';
import { LogbookContentComponent } from './component/logbook-content/logbook-content.component';

const routes: Routes = [{
  path: 'logbook',
  canActivate: [AuthenticationGuard],
  children: [
    { path: ':employeeId/:year/:month', component: LogbookContentComponent },
    { path: ':employeeId/:year', redirectTo: ':employeeId/:year/-' },
    { path: ':employeeId', redirectTo: ':employeeId/-/-' },
    { path: '**', redirectTo: '-/-/-' },
  ]
}];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class LogbookRoutingModule {}
