
import * as texts from './text/accounting.de.text.json';
import { getJsonExport, LocaleProvider } from '@typescript-kit/core';

export class AccountingInitializer {

  static initialize() {
    AccountingInitializer.registerLocales();
  }

  static registerLocales() {
    LocaleProvider.registerPackageLocale('de', 'accounting', {
      texts: getJsonExport(texts)
    });
  }
}
