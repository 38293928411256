import { ComponentData, ComponentFieldKey, ComponentModel } from '@typescript-kit/view';
import { TemplateRef } from '@angular/core';
import { ViewComponentKey } from '../key/view-component.key';

export interface TemplateData extends ComponentData {
  template?: TemplateRef<any>;
}

export class TemplateFieldKey {
  static readonly TEMPLATE = 'template';
}

export class TemplateModel extends ComponentModel implements TemplateData {
  static readonly KEYS = [...ComponentModel.KEYS, ...Object.keys(TemplateFieldKey).map(key => TemplateFieldKey[key])];

  constructor(data?: TemplateData, keys?: string[]) {
    super(data, keys || TemplateModel.KEYS);
  }

  get template(): TemplateRef<any> {
    return this.get(TemplateFieldKey.TEMPLATE);
  }

  set template(value: TemplateRef<any>) {
    this.set(TemplateFieldKey.TEMPLATE, value);
  }

  protected setupValues(values: TemplateData, exclusive: boolean): TemplateData {
    if (exclusive && !(ComponentFieldKey.TYPE in values)) {
      values.type = ViewComponentKey.TEMPLATE;
    }
    return super.setupValues(values, exclusive) as TemplateData;
  }

}
