import { Component, ViewEncapsulation, ChangeDetectionStrategy, Input } from '@angular/core';
import { BaseDirective } from '../../directive/base.directive';
import { TemplateModel } from '../../component-model/template.component-model';


@Component({
  // tslint:disable-next-line: component-selector
  selector: 'kit-template,[kitTemplate]',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplateComponent extends BaseDirective<TemplateModel> {

  get hostClass(): string {
    return 'kit-template';
  }

  @Input()
  set kitTemplate(model: TemplateModel) {
    this.model = model;
  }

  get kitTemplate(): TemplateModel {
    return this.model;
  }

}
