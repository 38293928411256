import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ProjectCommonViewService } from '../../view-service/project-common-shared.view-service';
import { ProjectCommonViewFieldKey, ProjectCommonViewModel } from '../../view-model/project-common.view-model';
import { ActionModel, CheckboxModel, ContextBindings, FormFieldModel, TextInputModel, TextModel } from '@typescript-kit/view';
import { Subscription } from 'rxjs';
import { ChangedEvent } from '@typescript-kit/core';
import { ProjectModel, SharedModelKey } from '@teamworks/global';
import { TimeRecordingViewKey } from '../../key/time-recording-view.key';

@Component({
  selector: 'app-project-management',
  templateUrl: './project-management.component.html',
  styleUrls: ['./project-management.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectManagementComponent implements OnInit, AfterViewInit, OnDestroy {
  public nameField: FormFieldModel;
  public isActiveCheckbox: CheckboxModel;
  public confirmAction: ActionModel;

  private viewModelChangedSubscription: Subscription;

  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly projectTimeRecordingSharedViewService: ProjectCommonViewService,
    private readonly elementRef: ElementRef<HTMLElement>
  ) {
  }

  get viewModel(): ProjectCommonViewModel {
    return this.projectTimeRecordingSharedViewService.viewModel;
  }

  ngOnInit() {
    this.viewModelChangedSubscription = this.viewModel.changedEvent.subscribe((event) => this.onViewModelChanged(event));
    this.createComponentModels();
  }

  ngAfterViewInit(): void {
    (this.elementRef?.nativeElement?.querySelector('input.app-project-name') as HTMLInputElement | null)?.focus();
  }

  ngOnDestroy(): void {
    this.viewModelChangedSubscription.unsubscribe();
  }

  private onViewModelChanged(event: ChangedEvent) {
    if (event.originalEvent !== event) {
      if (event.originalEvent.source === this.viewModel.projectList) {
        this.onProjectChanged(event.originalEvent);
      }
      return;
    }
    if (event.changes[ProjectCommonViewFieldKey.PROJECT]
      || event.changes[ProjectCommonViewFieldKey.IS_PROJECT_ADMIN]) {
      this.refreshComponentModels();
    }
  }

  private onProjectChanged(event: ChangedEvent) {
    if (event.originalEvent !== event && event.originalEvent.source instanceof ProjectModel) {
      this.refreshComponentModels();
      this.changeDetector.markForCheck();
    }
  }

  private createComponentModels() {
    this.nameField = new FormFieldModel({
      label: true,
      control: new TextInputModel({
        key: 'name',
        scope: `${SharedModelKey.PROJECT}/field`,
        tags: ['form-control', 'form-control-sm', `app-project-name`],
        contextBinding: ContextBindings.TWO_WAY
      })
    });
    this.isActiveCheckbox = new CheckboxModel({
      label: true,
      tags: [`app-project-is-active`],
      key: `${SharedModelKey.PROJECT}/field/isActive`,
      onCheckedChanged: (isChecked) => this.viewModel.project?.setValues({ isActive: isChecked })
    });
    this.confirmAction = new ActionModel({
      tags: ['app-form-btn', 'btn-sm', 'btn-outline-secondary', 'app-confirm-project'],
      content: new TextModel({
        text: `#(${TimeRecordingViewKey.PROJECT}/confirm-project)`
      }),
      onClick: () => this.viewModel.project.setValues({ isConfirmed: true })
    });

    this.refreshComponentModels();
  }

  private refreshComponentModels() {
    const isDisabled = !this.viewModel.isProjectAdmin;
    const projectModel = this.viewModel.project;

    this.nameField.setValues({
      isDisabled,
      context: projectModel,
      isHidden: !projectModel
    });
    this.isActiveCheckbox.setValues({
      isDisabled,
      isHidden: !projectModel?.isConfirmed,
      checked: projectModel?.isActive
    });
    this.confirmAction.setValues({
      isDisabled,
      isHidden: isDisabled || !projectModel || projectModel.isConfirmed
    });

    this.changeDetector.markForCheck();
  }
}
