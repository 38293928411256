export type WeekDay = 1 | 2 | 3 | 4 | 5 | 6 | 7;

export class WeekDays {
  static readonly MONDAY: WeekDay = 1;
  static readonly TUESDAY: WeekDay = 2;
  static readonly WEDNESDAY: WeekDay = 3;
  static readonly THURSDAY: WeekDay = 4;
  static readonly FRIDAY: WeekDay = 5;
  static readonly SATURDAY: WeekDay = 6;
  static readonly SUNDAY: WeekDay = 7;
}
