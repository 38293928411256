import { ViewService } from '@angular-kit/view';
import {
  ActionModel,
  Alignments,
  ContainerModel,
  DropdownModel,
  ImageModel,
  Orientations,
  TextModel,
  ViewComponentKey
} from '@typescript-kit/view';
import { Injectable, Injector } from '@angular/core';
import { AccountingService } from '../service/accounting.service';
import { MonthSelectViewService } from '../../shared/view/month-select.view-service';
import { AccountingComponentKey } from '../key/accounting-component.key';
import { SharedSvgKey } from '../../shared/key/shared-svg.key';
import { AccountingActionKey } from '../key/accounting-action.key';
import { AccountingCommonViewModel } from '../view-model/accounting-common.view-model';
import { AccountingModelKey } from '@teamworks/global';
import { AccountingCommonViewService } from './accounting-common.view-service';

@Injectable()
export class AccountingShellMenuViewService extends ViewService<AccountingCommonViewModel, ContainerModel> {
  protected readonly monthSelectViewService: MonthSelectViewService;

  constructor(
    injector: Injector,
    protected readonly accountingService: AccountingService,
    protected readonly accountingCommonViewService: AccountingCommonViewService,
  ) {
    super(injector);
    this.monthSelectViewService = new MonthSelectViewService(injector);
  }

  initialize() {
    const viewModel = this.accountingCommonViewService.viewModel;
    this.monthSelectViewService.initialize(viewModel);
    super.initialize(viewModel);
  }

  finalize() {
    super.finalize();
    this.monthSelectViewService.finalize();
  }

  protected createComponentModel(): ContainerModel {
    return new ContainerModel({
      type: AccountingComponentKey.ACCOUNTING_SHELL_MENU,
      tags: ['app-accounting-shell-menu'],
      orientation: Orientations.SIDE_BY_SIDE, alignment: Alignments.RIGHT,
      items: [
        this.monthSelectViewService.componentModel,
        this.createSaveDropdownModel()
      ]
    });
  }

  private createSaveDropdownModel(): DropdownModel {
    return new DropdownModel({
      key: 'saveDropdown',
      tags: ['app-save-dropdown'],
      trigger: new ImageModel({
        type: ViewComponentKey.SVG_SYMBOL,
        source: SharedSvgKey.SAVE_DOCUMENT
      }),
      items: [
        // new ActionModel({
        //   key: 'pdf-offer-action',
        //   type: ViewComponentKey.LINK,
        //   content: new TextModel({ text: AccountingActionKey.CREATE_PDF_OFFER }),
        //   // isDisabled: true,
        //   onClick: (event) => {
        //     event.preventDefault();
        //     if (!this.viewModel.accountingRecord) {
        //       return;
        //     }
        //     const year = this.viewModel.year;
        //     const month = this.viewModel.month;
        //     const id = this.viewModel.accountingRecordId;
        //     const header = this.viewModel.accountingRecord.invoice;
        //     this.accountingService.createPdfOffer(year, month, id, header);
        //   }
        // }),
        new ActionModel({
          key: 'pdf-invoice-action',
          type: ViewComponentKey.LINK,
          content: new TextModel({ text: AccountingActionKey.CREATE_PDF }),
          onClick: () => {
            const accountingRecord = this.viewModel.accountingRecord;
            if (!accountingRecord) {
              return;
            }
            const year = this.viewModel.year;
            const month = this.viewModel.month;
            const id = this.viewModel.accountingEntityId;
            const header = accountingRecord.letterHeader.subject;
            const footer = accountingRecord.pageFooter;
            const fileName: string[] = [
              this.textService.getText(`${AccountingModelKey.ACCOUNTING_RECORD_TYPE}/values/${accountingRecord.type}`)
            ];
            if (accountingRecord.designation) {
              fileName.push(accountingRecord.designation);
            }
            if (accountingRecord.customer) {
              fileName.push(`(${accountingRecord.customer})`);
            }
            return this.accountingService.createPdfInvoice(year, month, id, header, accountingRecord.logo, footer, `${fileName.join(' ')}.pdf`);
          }
        }),
      ]
    });
  }
}
