import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { ActionModel, TableCellModel, TableColumnModel, TableModel, TextModel } from '@typescript-kit/view';
import { TagTableFieldKey, TagTableModel } from '../../component-model/tag-table.model';
import { Subscription } from 'rxjs';
import { ChangedEvent } from '@typescript-kit/core';
import { TagMapping, TagMappingTagTypes } from '@teamworks/global';
import { TagService } from '../../../time-recording/service/tag.service';
import { SharedComponentKey } from '../../key/shared-component.key';

@Component({
  selector: 'app-tag-table',
  template: '<table [kitModel]="tableModel"></table>',
  styleUrls: ['tag-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TagTableComponent {
  readonly tableModel = new TableModel({
    key: 'tagTable',
    tags: ['app-tag-table'],
    columnHeaderCount: 0,
    columns: [
      new TableColumnModel({ index: 0, tags: ['app-tag-type'] }),
      new TableColumnModel({ index: 1, tags: ['app-tag-value'] }),
      new TableColumnModel({ index: 2, tags: ['app-tag-action'] })
    ]
  });

  private _model: TagTableModel;

  private modelPropertyChangedSubscription: Subscription;

  constructor(private readonly tagService: TagService) {
  }

  @Input()
  set model(model: TagTableModel) {
    if (this.model === model) {
      return;
    }

    if (this._model) {
      this.modelPropertyChangedSubscription.unsubscribe();
      this.modelPropertyChangedSubscription = null;
    }

    this._model = model;

    if (this._model) {
      this.modelPropertyChangedSubscription = this._model.changedEvent
        .subscribe((event) => this.onModelPropertyChanged(event));
    }

    this.refreshTableModel();
  }

  get model(): TagTableModel {
    return this._model;
  }

  private onModelPropertyChanged(event: ChangedEvent) {
    if (event.changes[TagTableFieldKey.TAG_LIST]) {
      this.refreshTableModel();
    }
  }

  private refreshTableModel(): void {
    this.tableModel.setValues({ cells: this.createTagTableCellModels() });
  }

  private createTagTableCellModels(): TableCellModel[] {
    const tableCellModels: TableCellModel[] = [];

    this.model.tagList.forEach((m: TagMapping, i: number) => {
      tableCellModels.push(...this.createTagTableRow(i, m));
    });

    return tableCellModels;
  }

  private createTagTableRow(index: number, tag: TagMapping): TableCellModel[] {
    return [
      new TableCellModel({
        row: index, column: 0,
        content: new TextModel({
          text: tag.tagType === TagMappingTagTypes.PIN
            ? `#(${SharedComponentKey.TAG_TABLE}/pin)`
            : `#(${SharedComponentKey.TAG_TABLE}/nfc)`
        })
      }),
      new TableCellModel({
        row: index, column: 1,
        content: new TextModel({
          text: tag.tagId
        })
      }),
      new TableCellModel({
        row: index, column: 2,
        content: new ActionModel({
          tags: ['app-form-btn', 'btn-sm', 'btn-outline-secondary'],
          content: `#(${SharedComponentKey.TAG_TABLE}/delete)`,
          onClick: async () => {
            await this.tagService.deleteTagMapping(tag.tagType, tag.tagId);
            this.model.tagList.remove(this.model.tagList.indexOf(tag));
            this.refreshTableModel();
          }
        })
      })
    ];
  }
}
