import { Injectable } from '@angular/core';

import { DataQuery } from '@typescript-kit/data';
import { HttpService } from '@angular-kit/core';

import { TagMapping } from '@teamworks/global';

@Injectable()
export class TagService {

  constructor(private httpService: HttpService) {
  }

  async loadTagMappingList(dataQuery?: DataQuery): Promise<TagMapping[]> {
    return this.httpService.get(`api/time-recording/tag/tag-mapping-list`, { 'data-query': dataQuery });
  }

  async loadTagMapping(tagType: string, tagId: string, dataQuery?: DataQuery): Promise<TagMapping> {
    return this.httpService.get(`api/time-recording/tag/tag-mapping/${tagType}/${tagId}`, { 'data-query': dataQuery });
  }

  async saveTagMapping(data: TagMapping): Promise<TagMapping> {
    return this.httpService.post(`api/time-recording/tag/tag-mapping`, data);
  }

  async deleteTagMapping(tagType: string, tagId: string): Promise<TagMapping> {
    return this.httpService.delete(`api/time-recording/tag/tag-mapping/${tagType}/${tagId}`);
  }

  async assignTagToken(token: string, targetType: string, targetId: string): Promise<TagMapping> {
    return this.httpService.post(`api/time-recording/tag/assign-tag-token/${token}/${targetType}/${targetId}`);
  }
}
