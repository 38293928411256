import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Output,
  EventEmitter
} from '@angular/core';

import { TabModel, TabsModel } from '@typescript-kit/view';
import { BaseDirective } from '../../directive/base.directive';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'tabs[kitModel]',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabsComponent extends BaseDirective<TabsModel> {
  @Output()
  tabSwitch = new EventEmitter<TabModel>();

  get hostClass(): string {
    return 'kit-tabs';
  }

  setTab(tab: TabModel) {
    this.model.currentTab = tab;
    this.tabSwitch.emit(tab);
  }
}
