import moment from 'moment';
import { Injectable, Injector } from '@angular/core';

import { ChangedEvent, hasValue } from '@typescript-kit/core';
import {
  Alignments,
  ComponentModel,
  ContainerModel,
  TableCellModel,
  TableColumnModel,
  TableModel,
  TableRowModel,
  TextModel,
  ValueModel
} from '@typescript-kit/view';
import { ViewService } from '@angular-kit/view';

import { TimeRecordingComponentKey as ComponentKey } from '../key/time-recording-component.key';
import { Subscription } from 'rxjs';
import { EntityService } from '../../shared/service/entity.service';
import { ProjectCommonViewFieldKey, ProjectCommonViewModel } from '../view-model/project-common.view-model';
import { ProjectTimeRecordActivities, ProjectTimeRecordFieldKey, ProjectTimeRecordModel, PublicHoliday, TimeRecordModel } from '@teamworks/global';
import { ProjectCommonViewService } from './project-common-shared.view-service';

class ColumnIndex {
  static readonly DAY = 0;
  static readonly TIME = 1;
  static readonly EMPLOYEE = 2;
  static readonly ACTIVITY = 3;
  static readonly HOURS = 4;
  static readonly DAY_ACTUAL = 5;
  static readonly WEEK_HOURS = 6;
  static readonly COMMENT = 7;
}

class ColumnKey {
  static readonly [ColumnIndex.DAY] = `${ComponentKey.PROJECT_TIME_RECORDING_TABLE}/column/day`;
  static readonly [ColumnIndex.TIME] = `${ComponentKey.PROJECT_TIME_RECORDING_TABLE}/column/time`;
  static readonly [ColumnIndex.EMPLOYEE] = `${ComponentKey.PROJECT_TIME_RECORDING_TABLE}/column/employee`;
  static readonly [ColumnIndex.ACTIVITY] = `${ComponentKey.PROJECT_TIME_RECORDING_TABLE}/column/activity`;
  static readonly [ColumnIndex.HOURS] = `${ComponentKey.PROJECT_TIME_RECORDING_TABLE}/column/hours`;
  static readonly [ColumnIndex.DAY_ACTUAL] = `${ComponentKey.PROJECT_TIME_RECORDING_TABLE}/column/actual`;
  static readonly [ColumnIndex.WEEK_HOURS] = `${ComponentKey.PROJECT_TIME_RECORDING_TABLE}/column/weekHours`;
  static readonly [ColumnIndex.COMMENT] = `${ComponentKey.PROJECT_TIME_RECORDING_TABLE}/column/comment`;
}

@Injectable()
export class ProjectTimeRecordingTableViewService extends ViewService<ProjectCommonViewModel, TableModel> {
  private readonly entityService: EntityService;

  private readonly sharedViewService = this.injector.get(ProjectCommonViewService);

  private readonly refreshingRows: Set<number>;

  private entityEventSubscription: Subscription;

  constructor(injector: Injector) {
    super(injector);
    this.entityService = injector.get(EntityService);

    this.refreshingRows = new Set<number>();
  }

  initialize(viewModel?: ProjectCommonViewModel): void {
    super.initialize(viewModel);
    this.entityEventSubscription = this.entityService.entityEvent.subscribe((event) => {
      if (event.entity instanceof ProjectTimeRecordModel) {
        const timeRecordIndex = this.viewModel.timeRecordList.indexOf(event.entity);
        if (timeRecordIndex < 0) {
          return;
        }
        const rowModel = this.componentModel.getRow(timeRecordIndex + 1);
        this.entityService.setEntityEventTag(rowModel, event);
      }
    });
  }

  finalize(): void {
    this.entityEventSubscription.unsubscribe();
    super.finalize();
  }

  protected onViewModelPropertyChanged(event: ChangedEvent): void {
    if (event.originalEvent !== event) {
      if (event.originalEvent.source === this.viewModel.timeRecordList) {
        this.onTimeRecordingListPropertyChanged(event.originalEvent);
      }
      return;
    }
    if (event.changes[ProjectCommonViewFieldKey.TIME_RECORD_LIST]) {
      this.refreshComponentModel(this.componentModel);
    }
  }

  private onTimeRecordingListPropertyChanged(event: ChangedEvent) {
    if (event.originalEvent !== event) {
      if (event.originalEvent.source instanceof ProjectTimeRecordModel) {
        this.onTimeRecordPropertyChanged(event.originalEvent);
      }
      return;
    }
    this.refreshComponentModel(this.componentModel);
  }

  private onTimeRecordPropertyChanged(event: ChangedEvent) {
    const timeRecord = event.source as ProjectTimeRecordModel;
    const recordIndex = this.viewModel.timeRecordList.indexOf(timeRecord);
    if (recordIndex < 0) {
      return;
    }
    const firstRecordOfDayIndex = this.getIndexOfFirstRecordOfDay(timeRecord.date);
    const isFirstRecordOfDay = recordIndex === firstRecordOfDayIndex;
    const firstRecordOfWeekIndex = this.getIndexOfFirstRecordOfWeek(moment(timeRecord.date).isoWeek());
    const isFirstRecordOfWeek = recordIndex === firstRecordOfWeekIndex;
    this.refreshRow(timeRecord, recordIndex, isFirstRecordOfDay, isFirstRecordOfWeek);
    if (event.changes[ProjectTimeRecordFieldKey.HOURS]
      || event.changes[ProjectTimeRecordFieldKey.ACTIVITY]
    ) {
      if (event.changes[ProjectTimeRecordFieldKey.HOURS]) {
        if (typeof timeRecord.hours !== 'number') {
          if (hasValue(timeRecord.activity)) {
            timeRecord.activity = null;
          }
        } else if (!hasValue(timeRecord.activity)) {
          timeRecord.activity = ProjectTimeRecordActivities.TOTAL_DAY;
        }
      }
      if (!isFirstRecordOfDay) {
        const firstRecordOfDay = this.viewModel.timeRecordList.get(firstRecordOfDayIndex);
        this.refreshRow(firstRecordOfDay, firstRecordOfDayIndex, true, firstRecordOfDayIndex === firstRecordOfWeekIndex);
      }
      if (!isFirstRecordOfWeek && firstRecordOfDayIndex !== firstRecordOfWeekIndex) {
        const firstRecordOfWeek = this.viewModel.timeRecordList.get(firstRecordOfWeekIndex);
        this.refreshRow(firstRecordOfWeek, firstRecordOfWeekIndex, true, true);
      }
    }
  }

  protected createComponentModel(): TableModel {
    return new TableModel({
      tags: ['app-project-time-record-table'],
      isHidden: !this.viewModel.timeRecordList,
      columns: this.createColumnModels(),
      rows: this.createRowModels(),
      cells: this.createCellModels(),
      columnHeaderCount: 1,
      rowHeaderCount: 1
    });
  }

  protected refreshComponentModel(tableModel: TableModel) {
    tableModel.setValues({
      isHidden: !this.viewModel.timeRecordList,
      columns: this.createColumnModels(),
      rows: this.createRowModels(),
      cells: this.createCellModels()
    });
  }

  private createColumnModels() {
    return [
      new TableColumnModel({ index: ColumnIndex.DAY, tags: ['app-day'] }),
      new TableColumnModel({ index: ColumnIndex.TIME, tags: ['app-time'] }),
      new TableColumnModel({ index: ColumnIndex.EMPLOYEE, tags: ['app-employee'] }),
      new TableColumnModel({ index: ColumnIndex.ACTIVITY, tags: ['app-activity'] }),
      new TableColumnModel({ index: ColumnIndex.HOURS, tags: ['app-hours'] }),
      new TableColumnModel({ index: ColumnIndex.DAY_ACTUAL, tags: ['app-actual'] }),
      new TableColumnModel({ index: ColumnIndex.WEEK_HOURS, tags: ['app-week-hours'] }),
      new TableColumnModel({ index: ColumnIndex.COMMENT, tags: ['app-comment'] })
    ];
  }

  private createRowModels() {
    const rows = [
      new TableRowModel({ index: 0, tags: ['app-time-record-table-header'] })
    ];
    const timeRecordList = this.viewModel.timeRecordList;
    if (!timeRecordList || !this.viewModel.year || !this.viewModel.month) {
      return rows;
    }
    let previousTimeRecord: ProjectTimeRecordModel = null;
    timeRecordList.forEach((timeRecord, index) => {
      const dayOfWeek = this.getDayOfWeek(timeRecord.date);
      const tags = [`app-weekday-${dayOfWeek}`];
      if (this.isStartOfWeek(dayOfWeek, previousTimeRecord)) {
        tags.push('app-start-of-week');
      }
      if (this.getPublicHoliday(timeRecord.date) !== null) {
        tags.push('app-public-holiday');
      }
      rows.push(new TableRowModel({ index: index + 1, tags }));
      previousTimeRecord = timeRecord;
    });
    return rows;
  }

  private createCellModels() {
    const cells = [
      new TableCellModel({ row: 0, column: ColumnIndex.DAY, content: ColumnKey[ColumnIndex.DAY] }),
      new TableCellModel({ row: 0, column: ColumnIndex.TIME, content: ColumnKey[ColumnIndex.TIME] }),
      new TableCellModel({ row: 0, column: ColumnIndex.EMPLOYEE, content: ColumnKey[ColumnIndex.EMPLOYEE] }),
      new TableCellModel({ row: 0, column: ColumnIndex.ACTIVITY, content: ColumnKey[ColumnIndex.ACTIVITY] }),
      new TableCellModel({ row: 0, column: ColumnIndex.HOURS, content: ColumnKey[ColumnIndex.HOURS] }),
      new TableCellModel({ row: 0, column: ColumnIndex.DAY_ACTUAL, content: ColumnKey[ColumnIndex.DAY_ACTUAL] }),
      new TableCellModel({ row: 0, column: ColumnIndex.WEEK_HOURS, content: ColumnKey[ColumnIndex.WEEK_HOURS] }),
      new TableCellModel({ row: 0, column: ColumnIndex.COMMENT, content: ColumnKey[ColumnIndex.COMMENT] })
    ];
    const timeRecordList = this.viewModel.timeRecordList;
    if (!timeRecordList) {
      return cells;
    }
    timeRecordList.forEach((timeRecord, index) => {
      const previousTimeRecord = index > 0 ? timeRecordList.get(index - 1) : null;
      const isFirstRecordOfDay = !previousTimeRecord || previousTimeRecord.date !== timeRecord.date;
      const isFirstRecordOfWeek = this.isStartOfWeek(this.getDayOfWeek(timeRecord.date), previousTimeRecord);
      const row = index + 1;
      const rowCells: TableCellModel[] = [
        this.createDayValueCell(row, ColumnIndex.DAY, timeRecord, isFirstRecordOfDay, isFirstRecordOfWeek),
        this.createTimeValueCell(row, ColumnIndex.TIME),
        this.createEmployeeValueCell(row, ColumnIndex.EMPLOYEE),
        this.createActivityValueCell(row, ColumnIndex.ACTIVITY, timeRecord),
        this.createHoursValueCell(row, ColumnIndex.HOURS),
        this.createActualDayHoursValueCell(row, ColumnIndex.DAY_ACTUAL, isFirstRecordOfDay),
        this.createActualWeekHoursValueCell(row, ColumnIndex.WEEK_HOURS, isFirstRecordOfDay),
        this.createCommentValueCell(row, ColumnIndex.COMMENT)
      ];
      cells.push(...rowCells);
      this.refreshRowCells(row, rowCells, timeRecord, isFirstRecordOfDay, isFirstRecordOfWeek || !previousTimeRecord);
    });
    return cells;
  }

  private createDayValueCell(
    row: number, column: number, timeRecord: ProjectTimeRecordModel, isFirstRecordOfDay: boolean, isFirstRecordOfWeek
  ): TableCellModel {
    const momentDate = moment(timeRecord.date);
    const items: ComponentModel[] = [
      new ValueModel({
        tags: ['app-form-value'],
        value: isFirstRecordOfDay ? momentDate.toDate() : null,
        format: 'table-day',
        alignment: Alignments.RIGHT,
      })
    ];
    if (isFirstRecordOfWeek) {
      items.push(new TextModel({
        tags: ['app-week-of-year'],
        text: `KW ${momentDate.isoWeek()}`
      }));
    }
    return new TableCellModel({
      row, column, content: new ContainerModel({ items })
    });
  }

  private createTimeValueCell(row: number, column: number): TableCellModel {
    return new TableCellModel({
      row, column, content: new ValueModel({
        tags: ['app-form-value', 'app-is-empty'],
        formattedValue: null,
        format: 'time',
        alignment: Alignments.RIGHT,
        onValueChanged: (value, originalValue, model) => {
          model.tags.set('app-is-empty', !hasValue(value));
        }
      })
    });
  }

  private createEmployeeValueCell(row: number, column: number): TableCellModel {
    return new TableCellModel({
      row, column, content: new ValueModel({
        tags: ['app-form-value', 'app-is-empty'],
        value: null,
        format: {
          format: (value) => hasValue(value) ? value : '-'
        },
        onValueChanged: (value, originalValue, model: ValueModel) => {
          model.tags.set('app-is-empty', !hasValue(value));
        }
      })
    });
  }

  private createActivityValueCell(row: number, column: number, timeRecord: ProjectTimeRecordModel): TableCellModel {
    return new TableCellModel({
      row, column, content: new ValueModel({
        tags: ['app-form-value', 'app-is-empty'],
        value: null,
        format: {
          format: (value) => hasValue(value)
            ? this.textService.getText(`time-recording/model/project-time-record-activity/value/${value}`)
            : '-'
        },
        onValueChanged: (value, originalValue, model) => {
          model.tags.set('app-is-empty', !hasValue(value));
        }
      })
    });
  }

  private createHoursValueCell(row: number, column: number): TableCellModel {
    return new TableCellModel({
      row, column, content: new ValueModel({
        tags: ['app-form-value', 'app-is-empty'],
        value: null,
        format: 'number-2',
        suffix: ' ',
        alignment: Alignments.RIGHT,
        onValueChanged: (value, originalValue, model) => {
          model.tags.set('app-is-empty', !hasValue(value));
          model.suffix = hasValue(value) ? 'h' : ' ';
        }
      })
    });
  }

  private createActualDayHoursValueCell(row: number, column: number, isFirstRecordOfDay: boolean): TableCellModel {
    return new TableCellModel({
      row, column, content: new ValueModel({
        tags: ['app-form-value', 'app-is-empty'],
        value: null,
        format: 'number-2',
        suffix: ' ',
        alignment: Alignments.RIGHT,
        isHidden: !isFirstRecordOfDay,
        onValueChanged: (value, originalValue, model: ValueModel) => {
          model.tags.set('app-is-empty', !hasValue(value));
          model.suffix = hasValue(value) ? 'h' : ' ';
        },
      })
    });
  }

  private createActualWeekHoursValueCell(row: number, column: number, isFirstRecordOfWeek: boolean): TableCellModel {
    return new TableCellModel({
      row, column, content: new ValueModel({
        tags: ['app-form-value', 'app-is-empty'],
        value: null,
        format: 'number-2',
        suffix: ' ',
        alignment: Alignments.RIGHT,
        isHidden: !isFirstRecordOfWeek,
        onValueChanged: (value, originalValue, model: ValueModel) => {
          model.tags.set('app-is-empty', !hasValue(value));
          model.suffix = hasValue(value) ? 'h' : ' ';
        },
      })
    });
  }

  private createCommentValueCell(row: number, column: number): TableCellModel {
    return new TableCellModel({
      row, column, content: new ValueModel({
        tags: ['app-form-value'],
        value: ''
      })
    });
  }

  private isStartOfWeek(dayOfWeek: number, previousRecord: ProjectTimeRecordModel): boolean {
    if (dayOfWeek !== 1) {
      return false;
    }
    if (!previousRecord) {
      return true;
    }
    const previousDayOfWeek = this.getDayOfWeek(previousRecord.date);
    return previousDayOfWeek !== dayOfWeek;
  }

  private getDayOfWeek(date: string): number {
    // iso day of week: 1 -> Monday, ..., 7 -> Sunday
    return moment(date).isoWeekday();
  }

  private getPublicHoliday(date: string): PublicHoliday {
    if (!this.viewModel.publicHolidayList) {
      return null;
    }
    return this.viewModel.publicHolidayList.find((item) => item.date === date) || null;
  }

  private refreshRow(
    recordModel: ProjectTimeRecordModel, recordIndex: number, isFirstRowOfDay: boolean, isFirstRowOfWeek: boolean
  ) {
    const row = recordIndex + 1;
    if (row >= this.componentModel.cellArray.length) {
      return;
    }
    this.refreshRowCells(row, this.componentModel.cellArray[row], recordModel, isFirstRowOfDay, isFirstRowOfWeek);
  }

  private refreshRowCells(
    row: number, rowCells: TableCellModel[], model: ProjectTimeRecordModel, isFirstRowOfDay: boolean, isFirstRowOfWeek: boolean
  ) {
    this.refreshingRows.add(row);
    rowCells[ColumnIndex.TIME].content.set('formattedValue', hasValue(model.time) ? model.time : '-');
    const employee = this.sharedViewService.viewModel.employeeList.find((e) => e.id === model.employeeId);
    rowCells[ColumnIndex.EMPLOYEE].content.set('value', employee?.name);
    rowCells[ColumnIndex.ACTIVITY].content.set('value', model.activity);
    rowCells[ColumnIndex.HOURS].content.set('value', model.hours);
    const record = this.viewModel.timeRecordList.get(row - 1);
    if (isFirstRowOfDay) {
      rowCells[ColumnIndex.DAY_ACTUAL].content.set('value', this.calculateActualDayHours(record.date));
    }
    if (isFirstRowOfWeek) {
      rowCells[ColumnIndex.WEEK_HOURS].content.set('value', this.calculateActualWeekHours(moment(record.date).isoWeek()));
    }
    rowCells[ColumnIndex.COMMENT].content.set('value', `${model.holiday?.name ?? ''} ${model.comment ?? ''}`.trim());
    this.refreshingRows.delete(row);
    rowCells.forEach((cell) => {
      if (cell.content instanceof ValueModel) {
        cell.content.validate();
      }
    });
  }

  private calculateActualDayHours(date: string): number {
    const hours = this.getRecordsOfDay(date).reduce((acc, cur) => acc + cur.hours, 0);
    return hours > 0 ? hours : null;
  }

  private calculateActualWeekHours(isoWeek: number): number {
    const hours = this.getRecordsOfWeek(isoWeek).reduce((acc, cur) => acc + cur.hours, 0);
    return hours > 0 ? hours : null;
  }

  private getRecordsOfDay(date: string): ProjectTimeRecordModel[] {
    return this.viewModel.timeRecordList.findValues((r) => r.date === date);
  }

  private getIndexOfFirstRecordOfDay(date: string): number {
    return this.viewModel.timeRecordList.firstIndex((r) => r.date === date);
  }

  private getRecordsOfWeek(isoWeek: number): ProjectTimeRecordModel[] {
    return this.viewModel.timeRecordList.findValues((r) => moment(r.date).isoWeek() === isoWeek);
  }

  private getIndexOfFirstRecordOfWeek(isoWeek: number): number {
    return this.viewModel.timeRecordList.firstIndex((r) => moment(r.date).isoWeek() === isoWeek);
  }
}
