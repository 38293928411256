import { LogbookEntryData } from '../model/logbook-entry.model';
import { LogbookSummary, LogbookSummaryData } from '../model/logbook-summary.model';

export class LogbookService {
  calculateCosts(kilometer: number, costRate: number): number {
    if (kilometer === null || costRate === null) {
      return null;
    }
    return kilometer * costRate;
  }

  calculateSummary(logbookEntryList: Iterable<LogbookEntryData>): LogbookSummary {
    const summary: LogbookSummaryData = {
      totalKilometers: null,
      totalCosts: null,
      infoPerCostRate: null
    };
    if (logbookEntryList) {
      summary.infoPerCostRate = {};
      for (const logbookEntry of logbookEntryList) {
        const costs = this.calculateCosts(logbookEntry.kilometer, logbookEntry.costRate);
        const alreadyExisting = summary.infoPerCostRate[logbookEntry.costRate];
        if (costs != null) {
          summary.totalCosts = (summary.totalCosts || 0) + costs;
          summary.totalKilometers = (summary.totalKilometers || 0) + logbookEntry.kilometer;
          summary.infoPerCostRate[logbookEntry.costRate] = {
            costRate: logbookEntry.costRate,
            kilometer: (alreadyExisting && alreadyExisting.kilometer || 0) + logbookEntry.kilometer,
            costs: (alreadyExisting && alreadyExisting.costs || 0) + costs
          };
        } else {
          summary.infoPerCostRate[logbookEntry.costRate] = {
            costRate: logbookEntry.costRate,
            kilometer: (alreadyExisting && alreadyExisting.kilometer || 0) + logbookEntry.kilometer || 0,
            costs: (alreadyExisting && alreadyExisting.costs || 0)
          };
        }
      }
    }
    return new LogbookSummary(summary);
  }
}
