import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild } from '@angular/router';

import { UserData } from '@typescript-kit/authentication';

import { AuthenticationService } from '../service/authentication.service';

@Injectable()
export class AuthenticationGuard implements CanActivate, CanActivateChild {

  constructor(private router: Router, private authenticationService: AuthenticationService) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const user: UserData = await this.authenticationService.resolveCurrentUser();
    if (this.isLoginOrRegisterUrl(state.url)) {
      if (user) {
        // noinspection JSIgnoredPromiseFromCall
        this.router.navigate(['']);
        return false;
      }
    } else {
      if (!user) {
        // noinspection JSIgnoredPromiseFromCall
        this.router.navigate(['/login'], { queryParams: { redirect: state.url } });
        return false;
      }
    }
    return true;

  }

  async canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return this.canActivate(route, state);
  }

  private isLoginOrRegisterUrl(url: string): boolean {
    return /^\/(login|register|reset-password)(?:[\/?#].*)?$/.test(url);
  }

}
