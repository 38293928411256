import { NgModule } from '@angular/core';
import { WelcomeInitializer } from './welcome.initializer';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@angular-kit/core';
import { ViewModule } from '@angular-kit/view';
import { BsViewModule } from '@angular-kit/bs-view';
import { AuthenticationModule } from '@angular-kit/authentication';
import { SetupUserComponent } from './component/setup-user/setup-user.component';
import { WelcomeRoutingModule } from './welcome-routing.module';

@NgModule({
  imports: [ CommonModule,
    CoreModule,
    ViewModule,
    BsViewModule,
    AuthenticationModule,
    WelcomeRoutingModule
  ],
  declarations: [SetupUserComponent],
  providers: []
})
export class WelcomeModule {

  constructor() {
    WelcomeInitializer.initialize();
  }
}
