import { Subscription } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import { ViewService } from '@angular-kit/view';
import { EmployeePermission, ProjectPermissions, UserCommonViewData, UserCommonViewFieldKey, UserCommonViewModel, UserPermission } from './user-common.view-model';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { EntityService } from '../../shared/service/entity.service';
import { ShellViewService } from '../../shell/view/shell.view-service';
import { ApplicationError, ChangedEvent, CoreErrorKey, hasValue, ObservableList, ObserveModes, toArray } from '@typescript-kit/core';
import { UserService } from '../service/user.service';
import { UserModel } from '@typescript-kit/authentication';
import { DevicePermissionKey, EmployeeModel, ProjectModel, SalaryPermissionKey, SharedPermissionKey } from '@teamworks/global';
import { EmployeeService } from '../service/employee.service';

@Injectable()
export class UserCommonViewService extends ViewService<UserCommonViewModel, any> {
  private readonly permissionKeys: string[] = [
    SharedPermissionKey.COMPANY_ADMIN,
    SharedPermissionKey.USER_ADMIN,
    SharedPermissionKey.EMPLOYEE_ADMIN,
    SharedPermissionKey.PROJECT_ADMIN,
    DevicePermissionKey.ADMIN,
    SalaryPermissionKey.OVERVIEW
  ];

  private routeChangedSubscription: Subscription;

  constructor(
    injector: Injector,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly entityService: EntityService,
    private readonly userService: UserService,
    private readonly employeeService: EmployeeService,
    private readonly shellViewService: ShellViewService
  ) {
    super(injector);
  }

  initialize(viewData?: UserCommonViewData): void {
    const viewModel = viewData instanceof UserCommonViewModel
      ? viewData
      : new UserCommonViewModel(viewData);
    super.initialize(viewModel);
    this.routeChangedSubscription = this.activatedRoute.paramMap
      .subscribe((paramMap) => this.onRouteChanged(paramMap));
    // noinspection JSIgnoredPromiseFromCall
    this.refreshUserList();
    // noinspection JSIgnoredPromiseFromCall
    this.refreshEmployeeList();
    // noinspection JSIgnoredPromiseFromCall
    this.refreshProjectList();
    // noinspection JSIgnoredPromiseFromCall
    this.refreshPermissions();
    if (!this.viewModel.userId) {
      this.shellViewService.componentModel.isSidebarVisible = true;
    }
  }

  finalize(): void {
    this.routeChangedSubscription.unsubscribe();
    super.finalize();
  }

  protected onViewModelPropertyChanged(event: ChangedEvent) {
    if (event.originalEvent !== event) {
      const originalEvent = event.originalEvent;
      if (originalEvent.source === this.viewModel.userList) {
        this.onUserChanged(originalEvent);
      }
      return;
    }
    const changes = event.changes;
    if (changes[UserCommonViewFieldKey.USER_ID] || changes[UserCommonViewFieldKey.USER_LIST]) {
      if (changes[UserCommonViewFieldKey.USER_ID]) {
        this.updateRoute();
      }
      const userList = this.viewModel.userList;
      const userId = this.viewModel.userId;
      this.viewModel.user = userList && userList.firstValue(user => user.id === userId) || null;
      if (userList && !this.viewModel.user) {
        this.viewModel.userId = undefined;
      }
      // noinspection JSIgnoredPromiseFromCall
      this.refreshPermissions();
    }
  }

  private onUserChanged(event: ChangedEvent) {
    if (event.originalEvent !== event) {
      if (event.originalEvent.source instanceof UserModel) {
        this.onUserPropertyChanged(event.originalEvent);
      }
      return;
    }
  }

  private onUserPropertyChanged(event: ChangedEvent) {
    const entityModel: UserModel = event.source;
    // noinspection JSIgnoredPromiseFromCall
    this.saveUser(entityModel);
  }

  private onRouteChanged(paramMap: ParamMap) {
    const userId = paramMap.get('userId');
    if (!hasValue(userId) || userId === '-') {
      this.viewModel.userId = undefined;
    } else if (userId === '~') {
      this.viewModel.userId = null;
    } else {
      this.viewModel.userId = userId;
    }
  }

  private updateRoute() {
    let userId: string;
    if (this.viewModel.userId === undefined) {
      userId = '-';
    } else if (this.viewModel.userId === null) {
      userId = '~';
    } else {
      userId = this.viewModel.userId;
    }
    this.router.navigate(['..', userId], { relativeTo: this.activatedRoute, queryParamsHandling: 'preserve' }
    ).catch((error) => {
      this.alertService.alertError(error);
    });
  }

  public async refreshUserList(): Promise<void> {
    try {
      this.shellViewService.incrementLoadingCounter();
      const userList = await this.userService.loadUserList();
      this.viewModel.userList = new ObservableList<UserModel>(
        userList.map(user => new UserModel(user)), { observeMode: ObserveModes.FLAT }
      );
    } catch (error) {
      if (this.isFinalized) {
        return;
      }
      this.alertService.alertError(error);
    } finally {
      this.shellViewService.decrementLoadingCounter();
    }
  }

  public async refreshEmployeeList(): Promise<void> {
    try {
      this.shellViewService.incrementLoadingCounter();
      const employeeList = await this.employeeService.loadEmployeeList();
      this.viewModel.employeeList = new ObservableList<EmployeeModel>(
        employeeList.map(employee => new EmployeeModel(employee)), { observeMode: ObserveModes.FLAT }
      );
    } catch (error) {
      if (this.isFinalized) {
        return;
      }
      this.alertService.alertError(error);
    } finally {
      this.shellViewService.decrementLoadingCounter();
    }
  }

  public async refreshProjectList(): Promise<void> {
    try {
      this.shellViewService.incrementLoadingCounter();
      const projectList = await this.userService.loadUserAdminProjectList({ filter: { isConfirmed: true, isActive: true } });
      this.viewModel.projectList = new ObservableList<ProjectModel>(
        projectList.map((project) => new ProjectModel(project)), { observeMode: ObserveModes.FLAT }
      );
    } catch (error) {
      if (this.isFinalized) {
        return;
      }
      this.alertService.alertError(error);
    } finally {
      this.shellViewService.decrementLoadingCounter();
    }
  }

  public async refreshPermissions(): Promise<void> {
    await this.refreshPermissionList();
    await this.refreshEmployeePermissionList();
    await this.refreshProjectPermissions();
  }

  public async refreshPermissionList(): Promise<void> {
    if (!this.viewModel.userId) {
      this.viewModel.permissionList = null;
      return;
    }

    try {
      this.shellViewService.incrementLoadingCounter();
      const permissionList = await this.userService.getPermissions(this.viewModel.userId);
      const permissionSet = new Set(permissionList);
      this.viewModel.permissionList = new ObservableList<UserPermission>(
        this.permissionKeys.map((key) => ({ key, granted: permissionSet.has(key) })), { observeMode: ObserveModes.FLAT }
      );
    } catch (error) {
      if (this.isFinalized) {
        return;
      }
      this.alertService.alertError(error);
    } finally {
      this.shellViewService.decrementLoadingCounter();
    }
  }

  public async refreshEmployeePermissionList(): Promise<void> {
    if (!this.viewModel.userId) {
      this.viewModel.employeePermissionList = null;
      return;
    }

    try {
      this.shellViewService.incrementLoadingCounter();
      const employeeIds = await this.userService.getEmployeePermissions(this.viewModel.userId);
      if (employeeIds.includes('*')) {
        this.viewModel.employeePermissionList = 'all';
      } else {
        const employeePermissions = toArray(this.viewModel.employeeList).filter((e) => employeeIds.includes(e.id));
        this.viewModel.employeePermissionList = new ObservableList<EmployeePermission>(
          employeePermissions, { observeMode: ObserveModes.FLAT }
        );
      }
    } catch (error) {
      if (this.isFinalized) {
        return;
      }
      this.alertService.alertError(error);
    } finally {
      this.shellViewService.decrementLoadingCounter();
    }
  }

  public async refreshProjectPermissions(): Promise<void> {
    if (!this.viewModel.userId) {
      this.viewModel.projectPermissions = null;
      return;
    }

    try {
      this.shellViewService.incrementLoadingCounter();
      const projectPermissions = await this.userService.getProjectPermissions(this.viewModel.userId);
      const permissions: ProjectPermissions = {};

      Object
        .entries(projectPermissions)
        .forEach(([id, permission]) => {
          const project = this.viewModel.projectList?.firstValue((p) => p.id === id);
          if (project || id === '*') {
            permissions[id] = { name: id === '*' ? '*' : project.name, permission };
          }
        });

      this.viewModel.projectPermissions = permissions;
    } catch (error) {
      if (this.isFinalized) {
        return;
      }
      this.alertService.alertError(error);
    } finally {
      this.shellViewService.decrementLoadingCounter();
    }
  }

  public async sendPasswordEmail(): Promise<void> {
    await this.userService.sendPasswordEmail(this.viewModel.userId);
  }

  public createEmptyUser(): void {
    if (!this.viewModel.userList.firstValue((user) => user.id === null)) {
      this.viewModel.userList.insert(0, new UserModel({ id: null }));
    }

    this.viewModel.userId = null;
  }

  public async saveNewUser(entityModel: UserModel) {
    try {
      const user = await this.userService.saveUser(entityModel);
      const userModel = new UserModel(user);
      const emptyUserIndex = this.viewModel.userList.firstIndex((user) => user.id === null);
      if (emptyUserIndex >= 0) {
        this.viewModel.userList.remove(emptyUserIndex);
      }

      this.viewModel.userList.insert(0, userModel);
      this.viewModel.userId = userModel.id;
    } catch (error) {
      this.alertService.discardAll();
      this.alertService.alertError(error);
    }
  }

  public async saveUser(entityModel: UserModel) {
    if (this.viewModel.userId === null) {
      return;
    }

    const savedEntity = await this.entityService.scheduleModify(
      entityModel, () => this.userService.saveUser(entityModel)
    );
    if (this.isFinalized) {
      return;
    }
    entityModel.setValues({ id: savedEntity.id }, { silent: true });
    if (this.viewModel.user === entityModel) {
      this.viewModel.userId = savedEntity.id;
    }
  }

  public async deleteUser(entityModel: UserModel) {
    if (!entityModel) {
      return;
    }
    try {
      if (entityModel.modified) {
        await this.entityService.scheduleModify(
          entityModel, () => this.userService.deleteUser(entityModel.id)
        );
      }
      if (this.isFinalized) {
        return;
      }
      const entityIndex = this.viewModel.userList.firstIndex(entity => entity.id === entityModel.id);
      if (entityIndex < 0) {
        return;
      }
      this.viewModel.userList.remove(entityIndex);
      if (this.viewModel.userId === entityModel.id) {
        this.viewModel.userId = undefined;
      }
    } catch (error) {
      const appError = error.error;
      if (appError instanceof ApplicationError && appError.key === CoreErrorKey.CUSTOM_TEXT_ERROR) {
        this.alertService.discardAll();
        this.alertService.alertError(appError);
      }
    }
  }

  public async updatePermissions(permissions: string[]): Promise<void> {
    try {
      await this.userService.setPermissions(this.viewModel.userId, permissions);
    } catch (error) {
      const applicationError = ApplicationError.from(error);
      this.alertService.alertError(applicationError);
    } finally {
      await this.refreshPermissionList();
    }
  }
}
