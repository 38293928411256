import { ObservableList, ObservableModel, ObserveModes } from '@typescript-kit/core';
import { UserData, UserModel } from '@typescript-kit/authentication';
import { EmployeeData, EmployeeModel, ProjectData, ProjectModel } from '@teamworks/global';

export class UserCommonViewFieldKey {
  static readonly USER_LIST = 'userList';
  static readonly USER_ID = 'userId';
  static readonly USER = 'user';
  static readonly PERMISSION_LIST = 'permissionList';
  static readonly EMPLOYEE_PERMISSION_LIST = 'employeePermissionList';
  static readonly PROJECT_PERMISSIONS = 'projectPermissions';
  static readonly EMPLOYEE_LIST = 'employeeList';
  static readonly PROJECT_LIST = 'projectList';
}

export interface UserPermission {
  key: string;
  granted: boolean;
}

export interface EmployeePermission {
  id: string;
  name: string;
}

export interface ProjectPermissions {
  [id: string]: {
    name: string;
    permission?: 'visible' | 'overview';
  };
}

export interface UserCommonViewData {
  userList?: Iterable<UserData>;
  userId?: string;
  user: UserData;
  permissionList?: Iterable<UserPermission>;
  employeePermissionList?: Iterable<EmployeePermission> | 'all';
  projectPermissions?: ProjectPermissions;
  employeeList?: Iterable<EmployeeData>;
  projectList?: Iterable<ProjectData>;
}

export class UserCommonViewModel extends ObservableModel implements UserCommonViewData {
  static readonly KEYS = Object.keys(UserCommonViewFieldKey).map((key) => UserCommonViewFieldKey[key]);

  constructor(values?: UserCommonViewData) {
    super(values, UserCommonViewModel.KEYS, { observeMode: ObserveModes.FLAT });
  }

  get userList(): ObservableList<UserModel> {
    return this.get(UserCommonViewFieldKey.USER_LIST);
  }

  set userList(value: ObservableList<UserModel>) {
    this.set(UserCommonViewFieldKey.USER_LIST, value);
  }

  get userId(): string {
    return this.get(UserCommonViewFieldKey.USER_ID);
  }

  set userId(value: string) {
    this.set(UserCommonViewFieldKey.USER_ID, value);
  }

  get user(): UserModel {
    return this.get(UserCommonViewFieldKey.USER);
  }

  set user(value: UserModel) {
    this.set(UserCommonViewFieldKey.USER, value);
  }

  get permissionList(): ObservableList<UserPermission> {
    return this.get(UserCommonViewFieldKey.PERMISSION_LIST);
  }

  set permissionList(value: ObservableList<UserPermission>) {
    this.set(UserCommonViewFieldKey.PERMISSION_LIST, value);
  }

  get employeePermissionList(): ObservableList<EmployeePermission> | 'all' {
    return this.get(UserCommonViewFieldKey.EMPLOYEE_PERMISSION_LIST);
  }

  set employeePermissionList(value: ObservableList<EmployeePermission> | 'all') {
    this.set(UserCommonViewFieldKey.EMPLOYEE_PERMISSION_LIST, value);
  }

  get projectPermissions(): ProjectPermissions {
    return this.get(UserCommonViewFieldKey.PROJECT_PERMISSIONS);
  }

  set projectPermissions(value: ProjectPermissions) {
    this.set(UserCommonViewFieldKey.PROJECT_PERMISSIONS, value);
  }

  get employeeList(): ObservableList<EmployeeModel> {
    return this.get(UserCommonViewFieldKey.EMPLOYEE_LIST);
  }

  set employeeList(value: ObservableList<EmployeeModel>) {
    this.set(UserCommonViewFieldKey.EMPLOYEE_LIST, value);
  }

  get projectList(): ObservableList<ProjectModel> {
    return this.get(UserCommonViewFieldKey.PROJECT_LIST);
  }

  set projectList(value: ObservableList<ProjectModel>) {
    this.set(UserCommonViewFieldKey.PROJECT_LIST, value);
  }
}
