<div class="app-project-sidebar-header">
  <div class="app-project-sidebar-header-title" [kitText]="titleText"></div>
  <kit-bs-select-input class="app-project-type-select" [kitModel]="typeSelectInput"></kit-bs-select-input>
  <kit-bs-button [kitModel]="addProjectAction" class="app-project-sidebar-header-action"></kit-bs-button>
  <kit-bs-button [kitModel]="removeProjectAction" class="app-project-sidebar-header-action"></kit-bs-button>
</div>
<div class="app-project-sidebar-items list-group list-group-flush">
  <button *ngFor="let item of projectActionList" [kitModel]="item" type="button"
    class="list-group-item list-group-item-action">
  </button>
</div>
