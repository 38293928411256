import { Injectable } from '@angular/core';
import { HttpService } from '@angular-kit/core';
import { DataQuery } from '@typescript-kit/data';
import { LogbookEntry, LogbookEntryData } from '@teamworks/global';

@Injectable()
export class LogbookEntryService {

  constructor(private httpService: HttpService) {
  }

  async loadLogbookEntryListByMonth(employeeId: string, year: string, month: string, dataQuery?: DataQuery): Promise<LogbookEntry[]> {
    return this.httpService.get(`api/logbook/logbook-entry-list/${employeeId}/${year}/${month}`, { 'data-query': dataQuery })
      .then((loadedData: LogbookEntryData[]) => loadedData.map((data) => new LogbookEntry(data)));
  }

  async loadLogbookEntry(employeeId: string, date: string, index: number): Promise<LogbookEntry> {
    return this.httpService.get(`api/logbook/logbook-entry/${employeeId}/${date}/${index}`)
      .then((loadedData: LogbookEntryData) => loadedData && new LogbookEntry(loadedData));
  }

  async insertLogbookEntry(data: LogbookEntryData): Promise<LogbookEntry> {
    return this.httpService.post(`api/logbook/logbook-entry`, data)
      .then((savedData: LogbookEntryData) => new LogbookEntry(savedData));
  }

  async updateLogbookEntry(employeeId: string, date: string, index: number, data: LogbookEntryData): Promise<LogbookEntry> {
    const version = encodeURIComponent(data.modified);
    return this.httpService.put(`api/logbook/logbook-entry/${employeeId}/${date}/${index}/${version}`, data)
      .then((savedData: LogbookEntryData) => new LogbookEntry(savedData));
  }

  async deleteLogbookEntry(data: LogbookEntryData): Promise<LogbookEntry> {
    return this.httpService.delete(`api/logbook/logbook-entry/${data.employeeId}/${data.date}/${data.index}`)
      .then((deletedData: LogbookEntryData) => new LogbookEntry(deletedData));
  }
}
