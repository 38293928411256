import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthenticationGuard } from '@angular-kit/authentication';

import { EmployeeTimeRecordingComponent } from './component/employee-time-recording/employee-time-recording.component';
import { EmployeeDetailComponent } from './component/employee-detail/employee-detail.component';
import { UserDetailComponent } from '../shared/component/user-detail/user-detail.component';
import { SubscriptionDetailComponent } from '../shared/component/subscription-detail/subscription-detail.component';
import { ProjectOverviewComponent } from './component/project-overview/project-overview.component';

const routes: Routes = [
  {
    path: 'employee-time-recording',
    canActivate: [AuthenticationGuard],
    children: [
      { path: ':employeeId/:year/:month', component: EmployeeTimeRecordingComponent },
      { path: ':employeeId/:year', redirectTo: ':employeeId/:year/-' },
      { path: ':employeeId', redirectTo: ':employeeId/-/-' },
      { path: '**', redirectTo: '-/-/-' },
    ]
  },
  {
    path: 'projects',
    canActivate: [AuthenticationGuard],
    children: [
      {
        path: ':projectId',
        children: [
          {
            path: ':currentTab/:year/:month',
            component: ProjectOverviewComponent
          },
          { path: ':currentTab/:year', redirectTo: ':currentTab/:year/-' },
          { path: ':currentTab', redirectTo: ':currentTab/-/-' },
          { path: '**', redirectTo: 'time-recording/-/-' },
        ]
      },
      { path: '**', redirectTo: '-' }
    ]
  },
  {
    path: 'employee',
    canActivate: [AuthenticationGuard],
    children: [
      { path: ':employeeId', component: EmployeeDetailComponent },
      { path: '**', redirectTo: '-' },
    ]
  },
  {
    path: 'user',
    canActivate: [AuthenticationGuard],
    children: [
      { path: ':userId', component: UserDetailComponent },
      { path: '**', redirectTo: '-' },
    ]
  },
  {
    path: 'subscription',
    canActivate: [AuthenticationGuard],
    component: SubscriptionDetailComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class TimeRecordingRoutingModule {
}
