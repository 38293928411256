<h1><strong>Impressum</strong></h1>
<p>
  Signum Plus GmbH <br>
  Steyrer Straße 31/1<br>
  4501 Neuhofen a. d. Krems<br>
  Österreich<br>
  Firmenbuch: FN 419103 d,
  Landesgericht Linz<br>
  UID-Nummer: ATU68870901<br>
  Mitglied der WKÖ und WKOÖ
</p>
