<h3 mat-dialog-title [kitText]="titleText"></h3>
<div mat-dialog-content>
  <span class="column-1" [kitText]="projectText"></span>
  <span class="column-2" [kitText]="viewPermissionText"></span>
  <span class="column-3" [kitText]="overviewPermissionText"></span>

  <ng-container *ngFor="let projectField of projectFields">
    <span class="column-1" [ngClass]="{ 'sub-project': projectField[2] === undefined }">{{projectField[0]}}</span>
    <kit-checkbox class="column-2" [kitModel]="projectField[1]"></kit-checkbox>
    <kit-checkbox class="column-3" [kitModel]="projectField[2]" *ngIf="projectField[2]"></kit-checkbox>
  </ng-container>
</div>
<div mat-dialog-actions>
  <kit-bs-button [kitModel]="cancelAction"></kit-bs-button>
  <kit-bs-button [kitModel]="confirmAction"></kit-bs-button>
</div>
