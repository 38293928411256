import { ApplicationError, CoreErrorKey, HttpMethod } from '@typescript-kit/core';

export class HttpApplicationError extends ApplicationError {
  constructor(public readonly method: HttpMethod,
              public readonly url: string,
              public readonly error: ApplicationError,
              public readonly status: number,
              public readonly capturedStack?: string) {
    super({
      key: CoreErrorKey.HTTP_ERROR,
      arguments: {
        method,
        url,
        error,
        status,
        capturedStack
      }
    });
  }

  public static IsNetworkError(error: any): boolean {
    return error instanceof HttpApplicationError && error.error.key === CoreErrorKey.NETWORK_ERROR;
  }

  public static IsConnectionError(error: any): boolean {
    return error instanceof HttpApplicationError
      && (error.error.key === CoreErrorKey.NETWORK_ERROR
        || error.status === 502 || error.status === 503 || error.status === 504);

  }

}
