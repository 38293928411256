export class SharedModelKey {
  static readonly COMPANY = 'time-recording/model/company';
  static readonly SUBSCRIPTION = 'shared/model/subscription';
  static readonly USER_COMPANY_MAPPING = 'time-recording/model/user-company-mapping';
  static readonly EMPLOYEE = 'time-recording/model/employee';
  static readonly PROJECT = 'time-recording/model/project';
  static readonly LOCATION = 'time-recording/model/location';
  static readonly VALIDITY_PERIOD = 'time-recording/model/validity-period';
  static readonly COUNTRY_CODE = 'shared/country';
}
