import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { Subscription } from 'rxjs';
import { ChangedEvent, hasValue } from '@typescript-kit/core';
import { SubscriptionViewFieldKey, SubscriptionViewModel } from '../../view/subscription.view-model';
import { SubscriptionViewService } from '../../view/subscription.view-service';
import { SharedComponentKey } from '../../key/shared-component.key';
import { ActionModel, CheckboxModel, TextModel } from '@typescript-kit/view';
import moment from 'moment';

@Component({
  selector: 'app-manage-subscription',
  templateUrl: './manage-subscription.component.html',
  styleUrls: ['./manage-subscription.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ManageSubscriptionComponent implements OnInit, OnDestroy {
  public titleModel: TextModel;
  public contentModel: TextModel;
  public footnoteModel: TextModel;
  public checkboxModel: CheckboxModel;
  public actionModel: ActionModel;

  private readonly startPrefix = `${SharedComponentKey.MANAGE_SUBSCRIPTION}/start-subscription`;
  private readonly extendPrefix = `${SharedComponentKey.MANAGE_SUBSCRIPTION}/extend-subscription`;
  private readonly cancelPrefix = `${SharedComponentKey.MANAGE_SUBSCRIPTION}/cancel-subscription`;

  private viewModelChangedSubscription: Subscription;

  constructor(
    private readonly viewService: SubscriptionViewService
  ) {
  }

  get viewModel(): SubscriptionViewModel {
    return this.viewService.viewModel;
  }

  async ngOnInit(): Promise<void> {
    this.viewModelChangedSubscription = this.viewModel.changedEvent.subscribe(event => this.onViewModelChanged(event));
    await this.createComponentModels();
  }

  ngOnDestroy() {
    this.viewModelChangedSubscription.unsubscribe();
  }

  private async onViewModelChanged(event: ChangedEvent) {
    if (event.changes[SubscriptionViewFieldKey.CURRENT_SUBSCRIPTIONS]
      || event.changes[SubscriptionViewFieldKey.REGULAR_SUBSCRIPTION]
      || event.changes[SubscriptionViewFieldKey.TRIAL_SUBSCRIPTION]
      || event.changes[SubscriptionViewFieldKey.COMPANY_VALID]) {
      await this.refreshComponentModels();
    }
  }

  private async createComponentModels() {
    this.titleModel = new TextModel();
    this.contentModel = new TextModel();
    this.footnoteModel = new TextModel();
    this.checkboxModel = new CheckboxModel({
      key: `${this.extendPrefix}/action`,
      label: true,
      isHidden: !this.viewModel.trialSubscription,
      isDisabled: !this.viewModel.trialSubscription,
      checked: hasValue(this.viewModel.regularSubscription),
      onCheckedChanged: async (isChecked, model) => {
        if (!this.viewModel.validationVisible) {
          this.viewModel.validationVisible = true;
        }

        if (!this.viewModel.companyValid) {
          model.isDisabled = true;
          if (isChecked) {
            model.checked = false;
          }
          return;
        }

        if (!model.isDisabled) {
          model.isDisabled = true;
          await this.viewService.setSubscriptionState(isChecked);
        }
      }
    });
    this.actionModel = new ActionModel({
      tags: ['app-form-btn', 'btn-sm', 'btn-outline-secondary'],
      isHidden: !this.checkboxModel.isHidden,
      onClick: async (_, model) => {
        if (!this.viewModel.validationVisible) {
          this.viewModel.validationVisible = true;
        }

        model.isDisabled = true;

        const isActive = !this.viewModel.regularSubscription || !!this.viewModel.regularSubscription.end;
        if (isActive && !this.viewModel.companyValid) {
          return;
        }

        await this.viewService.setSubscriptionState(isActive);
      }
    });

    await this.refreshComponentModels();
  }

  private async refreshComponentModels() {
    const prefix = this.viewModel.trialSubscription
      ? this.extendPrefix
      : this.viewModel.regularSubscription
        ? this.cancelPrefix
        : this.startPrefix;
    const subscription = this.viewModel.trialSubscription ?? this.viewModel.regularSubscription;
    const endDate = (subscription?.end ? moment(subscription.end) : moment().endOf('month')).format('D. MMMM YYYY');

    this.titleModel.setValues({
      text: `${prefix}/title`
    });
    this.contentModel.setValues({
      text: this.viewModel.regularSubscription?.end
        ? `${this.cancelPrefix}/revert-content`
        : `${prefix}/content`,
      textArgs: { endDate }
    });
    this.footnoteModel.setValues({
      text: `${prefix}/footnote`,
      textArgs: { endDate }
    });
    this.checkboxModel.setValues({
      isHidden: !this.viewModel.trialSubscription,
      isDisabled: !this.viewModel.trialSubscription || !this.viewModel.regularSubscription && this.viewModel.validationVisible && !this.viewModel.companyValid,
      checked: hasValue(this.viewModel.regularSubscription)
    });
    this.actionModel.setValues({
      isHidden: !this.checkboxModel.isHidden,
      content: new TextModel({
        text: this.viewModel.regularSubscription
          ? this.viewModel.regularSubscription.end
            ? `${this.cancelPrefix}/revert-action`
            : `${this.cancelPrefix}/action`
          : `${this.startPrefix}/action`
      }),
      isDisabled: !this.viewModel.regularSubscription && this.viewModel.validationVisible && !this.viewModel.companyValid
    });
  }
}
