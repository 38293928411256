import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { NavigationStart, Router, RouterEvent } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AlertService, CoreServiceKey, LocaleService, ServiceProvider } from '@typescript-kit/core';
import { CoreModule, NoCacheInterceptor } from '@angular-kit/core';
import { AuthenticationInterceptor, AuthenticationModule } from '@angular-kit/authentication';

import { AppInitializer } from './app.initializer';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { TimeRecordingModule } from './time-recording/time-recording.module';
import { TimeClockModule } from './time-clock/time-clock.module';
import { ShellModule } from './shell/shell.module';
import { LogbookModule } from './logbook/logbook.module';
import { AccountingModule } from './accounting/accounting.module';
import { SalaryModule } from './salary/salary.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { WelcomeModule } from './welcome/welcome.module';


export function resolveLocales(): () => Promise<void> {
  return () => ServiceProvider.resolveService<LocaleService>(CoreServiceKey.LOCALE).resolveLocales();
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,  // import CoreModule (with HttpClientModule) after BrowserModule
    AuthenticationModule,
    SharedModule,
    ShellModule,
    TimeRecordingModule,
    TimeClockModule,
    LogbookModule,
    AccountingModule,
    SalaryModule,
    WelcomeModule,
    AppRoutingModule,
  ],
  providers: [
    { provide: APP_INITIALIZER, useFactory: resolveLocales, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: NoCacheInterceptor, multi: true},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private router: Router, private alertService: AlertService) {
    AppInitializer.initialize();
    this.router.events.subscribe((routerEvent: RouterEvent) => {
      if (routerEvent instanceof NavigationStart) {
        alertService.discardAll();
      }
    });
  }
}

