import { ViewService } from '@angular-kit/view';
import {
  AccountingCommonViewData,
  AccountingCommonViewFieldKey,
  AccountingCommonViewModel
} from '../view-model/accounting-common.view-model';
import { Subscription } from 'rxjs';
import {
  AccountingEntity,
  AccountingEntityData,
  AccountingEntityFieldKey,
  AccountingEntityModel
} from '@teamworks/global';
import { Injectable, Injector } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { PersistenceService } from '@angular-kit/core';
import { AccountingService } from '../service/accounting.service';
import { ShellViewService } from '../../shell/view/shell.view-service';
import { ApplicationError, ChangedEvent, hasValue } from '@typescript-kit/core';
import { AccountingViewKey } from '../key/accounting-view.key';
import moment from 'moment';
import { EntityService } from '../../shared/service/entity.service';

@Injectable()
export class AccountingCommonViewService extends ViewService<AccountingCommonViewModel, any> {

  private routeChangedSubscription: Subscription;
  private _isLoadingCounter: number;

  constructor(
    injector: Injector,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly persistenceService: PersistenceService,
    private readonly entityService: EntityService,
    private readonly accountingService: AccountingService,
    private readonly shellViewService: ShellViewService,
  ) {
    super(injector);
    this.isLoadingCounter = 0;
  }

  get isLoadingCounter(): number {
    return this._isLoadingCounter;
  }

  set isLoadingCounter(value: number) {
    this._isLoadingCounter = value;
    this.shellViewService.viewModel.isLoading = value > 0;
  }

  initialize(viewData?: AccountingCommonViewData): void {
    const viewModel = viewData instanceof AccountingCommonViewModel
      ? viewData
      : new AccountingCommonViewModel(viewData);
    super.initialize(viewModel);
    this.routeChangedSubscription = this.activatedRoute.paramMap
      .subscribe((paramMap) => this.onRouteChanged(paramMap));
  }

  finalize(): void {
    this.routeChangedSubscription.unsubscribe();
    super.finalize();
  }

  protected onViewModelPropertyChanged(event: ChangedEvent) {
    if (event.originalEvent && event.originalEvent.source !== event.source) {
      const originalEvent = event.originalEvent;
      if (originalEvent.source === this.viewModel.accountingEntityList) {
        this.onAccountingEntityChanged(originalEvent);
      }
      return;
    }
    const changes = event.changes;
    if (changes[AccountingCommonViewFieldKey.YEAR] || changes[AccountingCommonViewFieldKey.MONTH]) {
      this.refreshAccountingList();
    }
    if (changes[AccountingCommonViewFieldKey.ACCOUNTING_ENTITY_ID]) {
      this.updateRoute();
    }
    super.onViewModelPropertyChanged(event);
  }

  private onAccountingEntityChanged(event: ChangedEvent) {
    if (event.originalEvent !== event) {
      if (event.originalEvent.source instanceof AccountingEntityModel) {
        this.onAccountingEntityPropertyChanged(event.originalEvent);
      }
      return;
    }
  }

  private onAccountingEntityPropertyChanged(event: ChangedEvent) {
    const entityModel: AccountingEntityModel = event.source;
    if (event.changes[AccountingEntityFieldKey.INDEX] || event.changes[AccountingEntityFieldKey.DATE]) {
      return;
    }
    this.entityService.scheduleModify(entityModel, () => this.accountingService.saveAccountingRecord(entityModel))
      .then((savedData) => {
        entityModel.setValues({ date: savedData.date, index: savedData.index });
        if (this.viewModel.accountingEntity === entityModel) {
          this.viewModel.accountingEntityId = savedData.index;
        }
      });
  }

  private onRouteChanged(paramMap: ParamMap) {
    const accountingEntityId = paramMap.get('id');
    this.viewModel.accountingEntityId = (hasValue(accountingEntityId) && accountingEntityId !== '-') ? +accountingEntityId : null;
  }

  private updateRoute() {
    this.router.navigate(
      ['..', hasValue(this.viewModel.accountingEntityId) ? this.viewModel.accountingEntityId : '-'],
      { relativeTo: this.activatedRoute, queryParamsHandling: 'preserve' }
    ).catch((error) => {
      this.alertService.alertError(error);
    });
  }

  public createAccountingEntity(): AccountingEntityModel {
    if (this.viewModel.accountingEntityList.firstIndex((record) => record.index === 0) >= 0) {
      return;
    }
    const accountingEntity = new AccountingEntityModel({
      date: `${this.viewModel.year}-${this.viewModel.month}-1`,
      index: 0,
      record: {
        type: null,
        logo: 'signum',
        designation: null,
        customer: null,
        offerDate: null,
        executionDate: null,
        invoice: null,
        salesTaxRate: 20,
        allocatedCosts: null,
        operatingCosts: null,
        letterHeader: null,
        pageFooter: null,
        price: null,
        paymentTerms: null,
        complimentaryClose: null
      }
    });
    this.viewModel.accountingEntityList.insert(0, accountingEntity);
    this.viewModel.accountingEntityId = accountingEntity.index;
    return accountingEntity;
  }

  public copyAccountingEntity(originalEntity: AccountingEntityModel) {
    const originalData = originalEntity.toJSON() as AccountingEntityData;
    const copyText = this.textService.getText(`${AccountingViewKey.ACCOUNTING}/copy`);
    const copiedEntity = new AccountingEntityModel({ ...originalData, editorId: null, modified: null, index: 0 });
    copiedEntity.record.designation = originalData.record.designation ? `${originalData.record.designation} ${copyText}` : copyText;
    this.viewModel.accountingEntityList.insert(0, copiedEntity);
    this.viewModel.accountingEntityId = copiedEntity.index;
    this.entityService.scheduleModify(copiedEntity, () => this.accountingService.insertAccountingRecord(copiedEntity))
      .then((savedData) => {
        copiedEntity.setValues({ date: savedData.date, index: savedData.index });
        if (this.viewModel.accountingEntity === copiedEntity) {
          this.viewModel.accountingEntityId = savedData.index;
        }
      });
  }

  public async moveAccountingEntityForward(entityModel: AccountingEntityModel) {
    const nextMonth = moment(entityModel.date).add(1, 'months');
    const entityData = entityModel.toJSON() as AccountingEntityData;
    entityData.date = nextMonth.format('YYYY-MM-DD');
    entityData.index = null;
    this.entityService.scheduleModify(entityModel, () => {
      return this.accountingService.updateAccountingRecord(entityModel.date, entityModel.index, entityModel.modified, entityData);
    }).then((savedData) => {
      entityModel.setValues({ date: savedData.date, index: savedData.index }, { silent: true });
      this.router.navigate(
        ['../../..', nextMonth.format('YYYY'), nextMonth.format('M'), savedData.index],
        { relativeTo: this.activatedRoute, queryParamsHandling: 'preserve' }
      ).catch((error) => {
        this.alertService.alertError(error);
      });
    });
  }

  public async moveAccountingEntityBackward(entityModel: AccountingEntityModel) {
    const previousMonth = moment(entityModel.date).subtract(1, 'months');
    const entityData = entityModel.toJSON() as AccountingEntityData;
    entityData.date = previousMonth.format('YYYY-MM-DD');
    entityData.index = null;
    this.entityService.scheduleModify(entityModel, () => {
      return this.accountingService.updateAccountingRecord(entityModel.date, entityModel.index, entityModel.modified, entityData);
    }).then((savedData) => {
      entityModel.setValues({ date: savedData.date, index: savedData.index }, { silent: true });
      this.router.navigate(
        ['../../..', previousMonth.format('YYYY'), previousMonth.format('M'), savedData.index],
        { relativeTo: this.activatedRoute, queryParamsHandling: 'preserve' }
      ).catch((error) => {
        this.alertService.alertError(error);
      });
    });
  }

  public deleteAccountingEntity(entityModel: AccountingEntityModel) {
    this.entityService.scheduleModify<AccountingEntityData, AccountingEntityData>(entityModel, () => !entityModel.modified
      ? Promise.resolve(entityModel)
      : this.accountingService.deleteAccountingRecord(entityModel.date, entityModel.index)
    )
      .then(() => {
        const recordIndex = this.viewModel.accountingEntityList.firstIndex((item) => item.index === entityModel.index);
        if (recordIndex < 0) {
          return;
        }
        this.viewModel.accountingEntityList.remove(recordIndex);
        if (entityModel.index === this.viewModel.accountingEntityId) {
          this.viewModel.accountingEntityId = null;
        }
      });
  }

  private refreshAccountingList(): void {
    this.loadAccountingEntityList()
      .then((accountingEntityList: AccountingEntity[]) => {
        if (this.isFinalized) {
          return;
        }
        this.viewModel.setValues({ accountingEntityList });
      });
  }

  private loadAccountingEntityList(): Promise<AccountingEntity[]> {
    const year = this.viewModel.year;
    const month = this.viewModel.month;
    if (!year || !month) {
      return Promise.resolve(undefined);
    }
    this.isLoadingCounter++;
    return this.accountingService.loadAccountingRecordListByMonth(year, month)
      .catch((error: ApplicationError) => {
        if (!this.isFinalized) {
          this.alertService.alertError(error);
        }
        return undefined;
      })
      .then((accountingEntityList: AccountingEntity[]) => {
        this.isLoadingCounter--;
        return accountingEntityList;
      });
  }

}
