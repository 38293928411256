<form class="app-register-form" novalidate onsubmit="javascript: event.preventDefault()">
  <h1 class="h2 mt-2 font-weight-normal" [kitText]="titleText"></h1>
  <p class="text-muted app-developed-by" [kitText]="developedByText"></p>
  <img class="mb-5 app-logo" src="{{logoKey}}" alt="">


  <label [kitValueLabel]="form.nameInput" for="inputName" class="sr-only"></label>
  <input #name [kitModel]="form.nameInput" id="inputName" class="form-control border-bottom-r-0">

  <label [kitValueLabel]="form.userNameInput" for="inputUserName" class="sr-only"></label>
  <input #userName [kitModel]="form.userNameInput" id="inputUserName" class="form-control border-r-0">

  <label [kitValueLabel]="form.emailInput" for="inputEmail" class="sr-only"></label>
  <input #email [kitModel]="form.emailInput" id="inputEmail" class="form-control border-top-r-0">

  <div class="agreement-check">
    <kit-checkbox [kitCheckbox]="jobProcessingContractCheck" id="checkboxJobProcessingContract"></kit-checkbox>
    <label for="checkboxJobProcessingContract" innerHtml="{{getAgreementLabel('job-processing-contract')}}"></label>
  </div>

  <div class="agreement-check">
    <kit-checkbox [kitCheckbox]="termsAndConditionsCheck" id="checkboxTermsAndConditions"></kit-checkbox>
    <label for="checkboxTermsAndConditions" innerHtml="{{getAgreementLabel('terms-and-conditions')}}"></label>
  </div>

  <div class="agreement-check">
    <kit-checkbox [kitCheckbox]="privacyPolicyCheck" id="checkboxPrivacyPolicy"></kit-checkbox>
    <label for="checkboxPrivacyPolicy" innerHtml="{{getAgreementLabel('privacy-policy')}}"></label>
  </div>


  <button [kitModel]="registerAction" class="mt-4 btn btn-lg btn-primary btn-block" type="submit"></button>

  <a href [kitModel]="loginAction" class="app-login"></a>

</form>
<app-teamworks-promotion></app-teamworks-promotion>
