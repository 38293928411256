<kit-bs-tabs [kitModel]="tabsModel" (tabSwitch)="onTabSwitch($event)"></kit-bs-tabs>

<ng-template #timeRecordingTemplate>
  <app-project-time-recording></app-project-time-recording>
</ng-template>

<ng-template #managementTemplate>
  <app-project-management></app-project-management>
</ng-template>

<ng-template #activitiesTemplate>
  <app-project-activities></app-project-activities>
</ng-template>

<ng-template #sidebarTemplate>
  <app-project-sidebar></app-project-sidebar>
</ng-template>
