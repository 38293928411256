
export class HttpHeaders {
  static readonly AUTHORIZATION: string = 'authorization';
  static readonly CONTENT_TYPE: string = 'content-type';
  static readonly CACHE_CONTROL: string = 'cache-control';
  static readonly EXPIRES: string = 'expires';
  static readonly USER_AGENT: string = 'user-agent';
  static readonly REFERER: string = 'referer';
  static readonly ACCESS_CONTROL_ALLOW_CREDENTIALS: string = 'access-control-allow-credentials';
  static readonly ACCESS_CONTROL_ALLOW_HEADERS: string = 'access-control-allow-headers';
  static readonly ACCESS_CONTROL_ALLOW_METHODS: string = 'access-control-allow-methods';
  static readonly ACCESS_CONTROL_ALLOW_ORIGIN: string = 'access-control-allow-origin';
  static readonly ACCESS_CONTROL_EXPOSE_HEADERS: string = 'access-control-expose-headers';
  static readonly ACCESS_CONTROL_MAX_AGE: string = 'access-control-max-age';
  static readonly ACCESS_CONTROL_REQUEST_HEADERS: string = 'access-control-request-headers';
  static readonly ACCESS_CONTROL_REQUEST_METHOD: string = 'access-control-request-method';
}
