import { Component, ViewEncapsulation, ChangeDetectionStrategy, Input, ElementRef, ViewChild } from '@angular/core';
import { TextInputDirective } from '@angular-kit/view';
import { TextInputModel } from '@typescript-kit/view';

@Component({
  selector: 'kit-bs-text-input',
  templateUrl: './bs-text-input.component.html',
  styleUrls: ['./bs-text-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BsTextInputComponent {
  private _model: TextInputModel;

  // @ViewChild(TextInputDirective, { static: true }) textInputDirective: TextInputDirective;

  @Input('kitModel')
  set model(model: TextInputModel) {
    // this.textInputDirective.model = model;
    this._model = model;
  }

  get model(): TextInputModel {
    // return this.textInputDirective.model;
    return this._model;
  }
}
