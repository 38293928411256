import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoreModule } from '@angular-kit/core';
import { ViewModule } from '@angular-kit/view';
import { BsViewModule } from '@angular-kit/bs-view';
import { AuthenticationModule } from '@angular-kit/authentication';
import { ShellRoutingModule } from './shell-routing.module';

import { ShellComponent } from './component/shell/shell.component';
import { LoginComponent } from './component/login/login.component';
import { RegisterComponent } from './component/register/register.component';
import { ResetPasswordComponent } from './component/reset-password/reset-password.component';

import { ShellInitializer } from './shell.initializer';
import { TeamworksPromotionComponent } from './component/teamworks-promotion/teamworks-promotion.component';
import { ImprintComponent } from './component/imprint/imprint.component';
import { PrivacyDeclarationComponent } from './component/privacy-declaration/privacy-declaration.component';
import { DataProcessingComponent } from './component/data-processing/data-processing.component';

@NgModule({
  declarations: [
    ShellComponent,
    LoginComponent,
    RegisterComponent,
    ResetPasswordComponent,
    TeamworksPromotionComponent,
    ImprintComponent,
    PrivacyDeclarationComponent,
    DataProcessingComponent
  ],
  imports: [
    CommonModule,
    CoreModule,
    ViewModule,
    BsViewModule,
    AuthenticationModule,
    ShellRoutingModule
  ],
  exports: [
    ShellComponent
  ]
})
export class ShellModule {
  constructor() {
    ShellInitializer.initialize();
  }
}
