import { ViewService } from '@angular-kit/view';
import { Injectable } from '@angular/core';
import { MonthSelectViewData, MonthSelectViewModel } from '../../shared/view/month-select.view-model';

@Injectable()
export class SalaryCommonViewService extends ViewService<MonthSelectViewModel, any> {

  initialize(viewData?: MonthSelectViewData): void {
    const viewModel = viewData instanceof MonthSelectViewModel ? viewData : new MonthSelectViewModel(viewData);
    super.initialize(viewModel);
  }

}
