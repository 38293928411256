import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-privacy-declaration',
  templateUrl: './privacy-declaration.component.html',
  styleUrls: ['./privacy-declaration.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrivacyDeclarationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
