import { Injectable } from '@angular/core';
import { ViewService } from '@angular-kit/view';
import { LogbookViewFieldKey, LogbookViewModel } from '../view-model/logbook.view-model';
import { Alignments, ContainerModel, FormFieldModel, TextModel, ValueModel } from '@typescript-kit/view';
import { ChangedEvent, CoreFormatKey, formatValue, ObservableMap } from '@typescript-kit/core';
import { LogbookViewKey } from '../key/logbook-view.key';
import { CostInfo } from '@teamworks/global';
import { LogbookSharedViewService } from './logbook-shared.view-service';

@Injectable()
export class LogbookSummaryViewService extends ViewService<LogbookViewModel, ContainerModel> {
  protected readonly sharedViewService = this.injector.get(LogbookSharedViewService);

  initialize(): void {
    super.initialize(this.sharedViewService.viewModel);
  }

  finalize(): void {
    super.finalize();
  }

  protected onViewModelPropertyChanged(event: ChangedEvent): void {
    const changes = event.changes;
    if (changes[LogbookViewFieldKey.SUMMARY]) {
      this.refreshComponentModel(this.componentModel);
    }
  }

  protected createComponentModel(): ContainerModel {
    const componentModel = new ContainerModel({
      tags: ['app-logbook-summary'],
      items: new ObservableMap({
        'title': new TextModel({
          tags: ['app-summary-title'],
          text: `${LogbookViewKey.LOGBOOK_SUMMARY}/summary`
        }),
        'costs': this.createCostsModel()
      })
    });
    this.refreshComponentModel(componentModel);
    return componentModel;
  }

  protected refreshComponentModel(model: ContainerModel): void {
    if (!this.viewModel.employeeId) {
      model.isHidden = true;
      return;
    }
    model.isHidden = false;
    model.items.set('costs', this.createCostsModel());
  }

  private createCostsModel(): ContainerModel {
    const values = {
      'costs': new TextModel({
        tags: ['app-summary-costs', 'app-title'],
        text: `${LogbookViewKey.LOGBOOK_SUMMARY}/costs`
      })
    };
    if (this.viewModel.summary && this.viewModel.summary.infoPerCostRate) {
      const costInfos = this.viewModel.summary.infoPerCostRate;
      const sortedKeys = Object.keys(costInfos).sort((a, b) => +a  - +b);
      for (const key of sortedKeys) {
        const info = costInfos[key] as CostInfo;
        if (info !== null) {
          const valueModel = this.createEuroValueModel();
          valueModel.value = info.costs;
          values['costs-for-' + info.costRate] = new FormFieldModel({
            key: info.costRate !== null ? info.costRate.toString() : 'null', tags: ['app-costs-detail'],
            label: this.getLabel(info),
            control: valueModel
          });
        }
      }
    }
    const totalCostsValueModel = this.createEuroValueModel();
    totalCostsValueModel.value = (this.viewModel.summary && this.viewModel.summary.totalCosts) || null;
    totalCostsValueModel.value = (this.viewModel.summary && this.viewModel.summary.totalCosts) || null;
    values['totalCosts'] = new FormFieldModel({
      tags: ['app-logbook-summary-total-costs'],
      key: 'totalCosts',
      label: `${LogbookViewKey.LOGBOOK_SUMMARY}/totalCosts`,
      control: totalCostsValueModel,
    });
    const totalKilometersValueModel = this.createKilometerValueModel();
    totalKilometersValueModel.value = (this.viewModel.summary && this.viewModel.summary.totalKilometers) || null;
    values['totalKilometers'] = new FormFieldModel({
      key: 'totalKilometers',
      label: `${LogbookViewKey.LOGBOOK_SUMMARY}/totalKilometers`,
      control: totalKilometersValueModel,
    });
    return new ContainerModel({
      tags: ['app-summary-container'],
      items: new ObservableMap(values)
    });
  }

  private getLabel(info: CostInfo): string {
    if (info.costRate !== null && info.kilometer !== null && info.costs !== null) {
      return this.textService.getText(`${LogbookViewKey.LOGBOOK_SUMMARY}/amount-kilometer-per-rate`, {
        amount: formatValue(info.kilometer, CoreFormatKey.NUMBER_0),
        rate: formatValue(info.costRate, CoreFormatKey.NUMBER_2)
      });
    } else if (info.kilometer !== null && info.costRate === null) {
      return this.textService.getText(`${LogbookViewKey.LOGBOOK_SUMMARY}/kilometer-without-rate`, {
        amount: formatValue(info.kilometer, CoreFormatKey.NUMBER_0)
      });
    } else if (info.costRate !== null && info.kilometer === null) {
      return this.textService.getText(`${LogbookViewKey.LOGBOOK_SUMMARY}/cost-rate-without-kilometer`, {
        rate: formatValue(info.costRate, CoreFormatKey.NUMBER_2)
      });
    }
  }

  private createKilometerValueModel(): ValueModel {
    return new ValueModel({
      tags: ['app-form-value'],
      format: CoreFormatKey.NUMBER_0, suffix: ' km', alignment: Alignments.RIGHT
    });
  }

  private createEuroValueModel(): ValueModel {
    return new ValueModel({
      tags: ['app-form-value'],
      format: CoreFormatKey.NUMBER_2, suffix: ' €', alignment: Alignments.RIGHT,
    });
  }

}
