import { WorkingHoursRecordData } from '../model/working-hours-record.model';
import { hasValue } from '@typescript-kit/core';

export class WorkingHoursService {

  getWorkingHoursRecordListPerDay(workingHoursRecordList: Iterable<WorkingHoursRecordData>): Map<number, WorkingHoursRecordData[]> {
    const workingHoursRecordListPerDay = new Map<number, WorkingHoursRecordData[]>();
    if (workingHoursRecordList) {
      for (const record of workingHoursRecordList) {
        if (!hasValue(record.weekDay) || !hasValue(record.hours)) {
          continue;
        }
        const dayWorkingHoursRecordList = workingHoursRecordListPerDay.get(record.weekDay);
        if (dayWorkingHoursRecordList) {
          dayWorkingHoursRecordList.push(record);
        } else {
          workingHoursRecordListPerDay.set(record.weekDay, [record]);
        }
      }
    }
    return workingHoursRecordListPerDay;
  }

  getWorkingHoursPerDay(workingHoursRecordList: Iterable<WorkingHoursRecordData>): Map<number, number> {
    const workingHoursPerDay = new Map<number, number>();
    if (workingHoursRecordList) {
      for (const record of workingHoursRecordList) {
        if (!hasValue(record.weekDay) || !hasValue(record.hours)) {
          continue;
        }
        workingHoursPerDay.set(record.weekDay, (workingHoursPerDay.get(record.weekDay) || 0) + record.hours);
      }
    }
    return workingHoursPerDay;
  }

}
