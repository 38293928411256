import { TextInputData } from '@typescript-kit/view';
import { ObservableModel, ObserveModes, ObservableList } from '@typescript-kit/core';
import { TagMapping } from '@teamworks/global';

export interface TagTableData extends TextInputData {
  tagList?: Iterable<TagMapping>;
}

export class TagTableFieldKey {
  static readonly TAG_LIST = 'tagList';
}

export class TagTableModel extends ObservableModel implements TagTableData {
  static readonly KEYS = Object.keys(TagTableFieldKey).map((key) => TagTableFieldKey[key]);

  constructor(data?: TagTableData) {
    super(data, TagTableModel.KEYS, { observeMode: ObserveModes.FLAT });
  }

  get tagList(): ObservableList<TagMapping> {
    return this.get(TagTableFieldKey.TAG_LIST);
  }

  set tagList(value: ObservableList<TagMapping>) {
    this.set(TagTableFieldKey.TAG_LIST, value);
  }
}
