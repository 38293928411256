import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewModule } from '@angular-kit/view';
import { SharedInitializer } from './shared.initializer';
import { CoreModule } from '@angular-kit/core';
import { DemoService } from './service/demo.service';
import { AutoCompleteInputComponent } from './component/auto-complete-input/auto-complete-input.component';
import { MonthSelectComponent } from './component/month-select/month-select.component';
import { CustomTableComponent } from './component/custom-table/custom-table.component';
import { ResizeTextAreaComponent } from './component/resize-text-area/resize-text-area.component';
import { EntityService } from './service/entity.service';
import { MatDialogModule } from '@angular/material/dialog';
import { QuestionDialogComponent } from './component/question-dialog/question-dialog.component.component';
import { SharedModule as AngularSharedModule } from '@teamworks/angular';
import { BsViewModule } from '@angular-kit/bs-view';
import { ErrorDialogComponent } from './component/error-dialog/error-dialog.component';
import { UserDetailComponent } from './component/user-detail/user-detail.component';
import { UserSidebarComponent } from './component/user-sidebar/user-sidebar.component';
import { EmployeePermissionsDialogComponent } from './component/employee-permissions-dialog/employee-permissions-dialog.component';
import { EmployeeService } from './service/employee.service';
import { CompanyDetailComponent } from './component/company-detail/company-detail.component';
import { CompanyService } from './service/company.service';
import { ProjectPermissionsDialogComponent } from './component/project-permissions-dialog/project-permissions-dialog.component';
import { SubscriptionDetailComponent } from './component/subscription-detail/subscription-detail.component';
import { PaymentMethodsComponent } from './component/payment-methods/payment-methods.component';
import { CurrentSubscriptionComponent } from './component/current-subscription/current-subscription.component';
import { ManageSubscriptionComponent } from './component/manage-subscription/manage-subscription.component';
import { TagTableComponent } from './component/tag-table/tag-table.component';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    ViewModule,
    AngularSharedModule,
    MatDialogModule,
    BsViewModule
  ],
  providers: [
    EntityService,
    DemoService,
    EmployeeService,
    CompanyService
  ],
  declarations: [
    AutoCompleteInputComponent,
    MonthSelectComponent,
    CustomTableComponent,
    ResizeTextAreaComponent,
    QuestionDialogComponent,
    ErrorDialogComponent,
    UserDetailComponent,
    UserSidebarComponent,
    EmployeePermissionsDialogComponent,
    ProjectPermissionsDialogComponent,
    CurrentSubscriptionComponent,
    ManageSubscriptionComponent,
    CompanyDetailComponent,
    PaymentMethodsComponent,
    SubscriptionDetailComponent,
    TagTableComponent
  ],
  entryComponents: [
    AutoCompleteInputComponent,
    MonthSelectComponent,
    CustomTableComponent,
    ResizeTextAreaComponent,
    QuestionDialogComponent,
    ErrorDialogComponent,
    UserDetailComponent,
    UserSidebarComponent,
    EmployeePermissionsDialogComponent,
    ProjectPermissionsDialogComponent,
    SubscriptionDetailComponent,
    TagTableComponent
  ],
  exports: [
    AutoCompleteInputComponent,
    MonthSelectComponent,
    CustomTableComponent,
    ResizeTextAreaComponent,
    UserDetailComponent,
    UserSidebarComponent,
    CurrentSubscriptionComponent,
    ManageSubscriptionComponent,
    CompanyDetailComponent,
    PaymentMethodsComponent,
    SubscriptionDetailComponent,
    TagTableComponent
  ]
})
export class SharedModule {

  constructor() {
    SharedInitializer.initialize();
  }

}
