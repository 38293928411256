import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { Subscription } from 'rxjs';
import { ChangedEvent } from '@typescript-kit/core';
import { SubscriptionViewFieldKey, SubscriptionViewModel } from '../../view/subscription.view-model';
import { SubscriptionViewService } from '../../view/subscription.view-service';

@Component({
  selector: 'app-subscription-detail',
  templateUrl: './subscription-detail.component.html',
  styleUrls: ['./subscription-detail.component.scss'],
  providers: [
    SubscriptionViewService
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubscriptionDetailComponent implements OnInit, OnDestroy {
  private viewModelChangedSubscription: Subscription;

  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly viewService: SubscriptionViewService
  ) {
  }

  get viewModel(): SubscriptionViewModel {
    return this.viewService.viewModel;
  }

  ngOnInit() {
    this.viewService.initialize();
    this.viewModelChangedSubscription = this.viewModel.changedEvent.subscribe(event => this.onViewModelChanged(event));
  }

  ngOnDestroy() {
    this.viewModelChangedSubscription.unsubscribe();
    this.viewService.finalize();
  }

  private async onViewModelChanged(event: ChangedEvent) {
    if (event.changes[SubscriptionViewFieldKey.COMPANY]
      || event.changes[SubscriptionViewFieldKey.CURRENT_SUBSCRIPTIONS]) {
      this.changeDetector.markForCheck();
    }
  }
}
