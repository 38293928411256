import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
} from '@angular/core';

import { TabsComponent } from '@angular-kit/view';

@Component({
  selector: 'kit-bs-tabs',
  templateUrl: './bs-tabs.component.html',
  styleUrls: ['./bs-tabs.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BsTabsComponent extends TabsComponent {
  get hostClass(): string {
    return null;
  }
}
