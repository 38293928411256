import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { AccountingPermissionKey, SalaryPermissionKey, TimeRecordingGlobalTextKey, TimeRecordingPermissionKey } from '@teamworks/global';
import { ShellComponent } from './shell/component/shell/shell.component';
import { View } from './shell/model/view.model';

import { ShellViewKey } from './shell/key/shell-view.key';
import { TimeRecordingViewKey } from './time-recording/key/time-recording-view.key';
import { LogbookViewKey } from './logbook/key/logbook-view.key';
import { TimeClockViewKey } from './time-clock/key/time-clock-view.key';
import { AccountingViewKey } from './accounting/key/accounting-view.key';
import { SalaryViewKey } from './salary/key/salary-view.key';
import { AuthenticationService } from '@angular-kit/authentication';
import { Subscription } from 'rxjs';
import { UserData } from '@typescript-kit/authentication';
import { ShellViewModel } from './shell/view/shell.view-model';
import { AuthorizationService } from '@teamworks/angular';
import { SharedPermissionKey } from '../../../teamworks/global/src/shared/key/shared-permission.key';
import { SharedViewKey } from './shared/key/shared-view.key';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements AfterViewInit, OnDestroy {

  @ViewChild(ShellComponent, { static: true }) shellComponent: ShellComponent;

  private userChangedSubscription: Subscription;

  constructor(
    private authenticationService: AuthenticationService,
    private authorizationService: AuthorizationService
  ) {
  }

  private get shellViewModel(): ShellViewModel {
    return this.shellComponent.viewService.viewModel;
  }

  ngAfterViewInit(): void {
    this.shellViewModel.setValues({
      appTitle: TimeRecordingGlobalTextKey.APP_TITLE,
    });
    this.userChangedSubscription = this.authenticationService.currentUserChanged.subscribe(user => this.refreshViewList(user));
  }

  ngOnDestroy(): void {
    this.userChangedSubscription.unsubscribe();
  }

  private async refreshViewList(user: UserData) {
    const viewList = [
      new View({
        id: 'login', name: `${ShellViewKey.LOGIN}/name`, selectable: false, hideShellMenu: true
      }),
      new View({
        id: 'register', name: `${ShellViewKey.REGISTER}/name`, selectable: false, hideShellMenu: true
      }),
      new View({
        id: 'reset-password', name: `${ShellViewKey.RESET_PASSWORD}/name`, selectable: false, hideShellMenu: true
      }),
      new View({
        id: 'setup-user', name: `welcome/component/setup-user/name`, selectable: false, hideShellMenu: true
      })
    ];
    if (user) {
      const permissionList = await this.authorizationService.loadPermissionList();
      const permissionKeySet = new Set(permissionList.map(permission => permission.key));
      const hasEmployeePermission = permissionKeySet.has(TimeRecordingPermissionKey.EMPLOYEE);
      const hasAccountingRecordPermission = permissionKeySet.has(AccountingPermissionKey.RECORD);
      const hasSalaryOverviewPermission = permissionKeySet.has(SalaryPermissionKey.OVERVIEW);
      const hasEmployeeAdminPermission = permissionKeySet.has(SharedPermissionKey.EMPLOYEE_ADMIN);
      const hasUserAdminPermission = permissionKeySet.has(SharedPermissionKey.USER_ADMIN);
      const hasCompanyAdminPermission = permissionKeySet.has(SharedPermissionKey.COMPANY_ADMIN);
      const hasProjectAdminPermission = permissionKeySet.has(SharedPermissionKey.PROJECT_ADMIN);
      const hasProjectOverviewPermission = permissionKeySet.has(SharedPermissionKey.PROJECT_OVERVIEW);
      viewList.push(
        new View({
          id: 'time-clock', name: `${TimeClockViewKey.SHELL}/name`, selectable: false, hideShellMenu: true
        }),
        new View({
          id: 'employee-time-recording', name: `${TimeRecordingViewKey.EMPLOYEE_TIME_RECORDING}/name`, selectable: hasEmployeePermission
        }),
        new View({
          id: 'projects', name: `${TimeRecordingViewKey.PROJECT}/name`, selectable: hasProjectAdminPermission || hasProjectOverviewPermission
        }),
        new View({
          id: 'logbook', name: `${LogbookViewKey.LOGBOOK_ROOT}/name`, selectable: hasEmployeePermission
        }),
        new View({
          id: 'accounting', name: `${AccountingViewKey.ACCOUNTING_ROOT}/name`, selectable: hasAccountingRecordPermission
        }),
        new View({
          id: 'salary-overview', name: `${SalaryViewKey.SALARY_OVERVIEW}/name`, selectable: hasSalaryOverviewPermission
        }),
        new View({
          id: 'employee', name: `${TimeRecordingViewKey.EMPLOYEE}/name`, selectable: hasEmployeeAdminPermission
        }),
        new View({
          id: 'user', name: `${SharedViewKey.USER}/name`, selectable: hasUserAdminPermission
        }),
        new View({
          id: 'subscription', name: `${SharedViewKey.SUBSCRIPTION}/name`, selectable: hasCompanyAdminPermission
        })
      );
    }
    this.shellViewModel.viewList = viewList;
  }

}
