import { Injectable, Injector } from '@angular/core';

import { ChangedEvent, toArray } from '@typescript-kit/core';
import {
  ActionModel,
  Alignments,
  ComponentModel,
  ContainerModel,
  Orientations,
  TableCellModel,
  TableColumnModel,
  TableModel,
  TableRowModel,
  TextInputModel,
  TextModel,
  ValueModel
} from '@typescript-kit/view';
import { ViewService } from '@angular-kit/view';

import { TimeRecordingComponentKey } from '../key/time-recording-component.key';
import { Subscription } from 'rxjs';
import { EntityService } from '../../shared/service/entity.service';
import { ProjectCommonViewFieldKey, ProjectCommonViewModel } from '../view-model/project-common.view-model';
import { ProjectModel } from '@teamworks/global';
import { ProjectCommonViewService } from './project-common-shared.view-service';
import { DeleteProjectDialogComponent } from '../component/delete-project-dialog/delete-project-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { EditActivityTokensDialogComponent } from '../component/edit-activity-tokens-dialog/edit-activity-tokens-dialog.component';
import { TimeRecordingViewKey } from '../key/time-recording-view.key';

class ColumnIndex {
  static readonly REMOVE = 0;
  static readonly NAME = 1;
  static readonly TAGS = 2;
}

class ColumnKey {
  static readonly [ColumnIndex.REMOVE] = `${TimeRecordingComponentKey.PROJECT_ACTIVITIES_TABLE}/column/remove`;
  static readonly [ColumnIndex.NAME] = `${TimeRecordingComponentKey.PROJECT_ACTIVITIES_TABLE}/column/name`;
  static readonly [ColumnIndex.TAGS] = `${TimeRecordingComponentKey.PROJECT_ACTIVITIES_TABLE}/column/tags`;
}

@Injectable()
export class ProjectActivitiesTableViewService extends ViewService<ProjectCommonViewModel, TableModel> {
  private readonly refreshingRows = new Set<number>();

  private entityEventSubscription?: Subscription;

  constructor(
    injector: Injector,
    private readonly entityService: EntityService,
    private readonly sharedViewService: ProjectCommonViewService,
    private readonly matDialog: MatDialog
  ) {
    super(injector);
  }

  initialize(viewModel?: ProjectCommonViewModel): void {
    super.initialize(viewModel);
    this.entityEventSubscription = this.entityService.entityEvent.subscribe((event) => {
      if (event.entity instanceof ProjectModel) {
        const activityIndex = this.viewModel.activityList.indexOf(event.entity);
        if (activityIndex < 0) {
          return;
        }
        const rowModel = this.componentModel.getRow(activityIndex + 1);
        this.entityService.setEntityEventTag(rowModel, event);
      }
    });
  }

  finalize(): void {
    this.entityEventSubscription?.unsubscribe();
    super.finalize();
  }

  protected onViewModelPropertyChanged(event: ChangedEvent): void {
    if (event.originalEvent !== event) {
      if (event.originalEvent.source === this.viewModel.activityList) {
        this.onActivityListPropertyChanged(event.originalEvent);
      }
    } else if (event.changes[ProjectCommonViewFieldKey.ACTIVITY_LIST]
      || event.changes[ProjectCommonViewFieldKey.ACTIVITY_TAGS]) {
      this.refreshComponentModel();
    }
  }

  private onActivityListPropertyChanged(event: ChangedEvent) {
    if (event.originalEvent !== event) {
      if (event.originalEvent.source instanceof ProjectModel) {
        this.onActivityPropertyChanged(event.originalEvent);
      }
    } else {
      this.refreshComponentModel();
    }
  }

  private onActivityPropertyChanged(event: ChangedEvent) {
    const activity = event.source as ProjectModel;
    const activityIndex = this.viewModel.activityList.indexOf(activity);
    if (activityIndex >= 0) {
      this.refreshRow(activity, activityIndex);
    }
  }

  protected createComponentModel(): TableModel {
    return new TableModel({
      tags: ['app-project-activities-table'],
      isHidden: !this.viewModel.activityList,
      columns: this.createColumnModels(),
      rows: this.createRowModels(),
      cells: this.createCellModels(),
      columnHeaderCount: 1
    });
  }

  protected refreshComponentModel() {
    this.componentModel.setValues({
      isHidden: !this.viewModel.activityList,
      columns: this.createColumnModels(),
      rows: this.createRowModels(),
      cells: this.createCellModels()
    });
  }

  private createColumnModels() {
    return [
      new TableColumnModel({ index: ColumnIndex.REMOVE, tags: ['app-remove'] }),
      new TableColumnModel({ index: ColumnIndex.NAME, tags: ['app-name'] }),
      new TableColumnModel({ index: ColumnIndex.TAGS, tags: ['app-tags'] })
    ];
  }

  private createRowModels() {
    const rows = [
      new TableRowModel({ index: 0, tags: ['app-project-activities-table-header'] })
    ];

    this.viewModel.activityList?.forEach((_, i) => rows.push(new TableRowModel({ index: i + 1 })));

    return rows;
  }

  private createCellModels() {
    const cells = [
      new TableCellModel({ row: 0, column: ColumnIndex.REMOVE, content: this.createAddCellContent() }),
      new TableCellModel({ row: 0, column: ColumnIndex.NAME, content: ColumnKey[ColumnIndex.NAME] }),
      new TableCellModel({ row: 0, column: ColumnIndex.TAGS, content: ColumnKey[ColumnIndex.TAGS] })
    ];

    this.viewModel.activityList?.forEach((activity, i) => {
      const row = i + 1;
      const rowCells: TableCellModel[] = [
        this.createRemoveCellModel(row, ColumnIndex.REMOVE),
        this.createNameValueCell(row, ColumnIndex.NAME, activity),
        this.createTagsCell(row, ColumnIndex.TAGS, activity)
      ];
      cells.push(...rowCells);
      this.refreshRowCells(row, rowCells, activity);
    });

    return cells;
  }

  private createAddCellContent(): ComponentModel {
    return new ActionModel({
      tags: ['app-form-btn', 'app-form', 'btn-sm', 'btn-outline-secondary'],
      content: '+',
      onClick: async () => {
        if (this.viewModel.projectId === undefined) {
          return;
        }

        await this.sharedViewService.createActivity();
        this.refreshComponentModel();
      }
    });
  }

  private createRemoveCellModel(row: number, column: number): TableCellModel {
    return new TableCellModel({
      row, column, content: new ActionModel({
        tags: ['app-form-btn', 'app-form', 'btn-sm', 'btn-outline-secondary'],
        content: '-',
        onClick: () => this.removeActivity(row)
      })
    });
  }

  private createNameValueCell(row: number, column: number, activity: ProjectModel): TableCellModel {
    return new TableCellModel({
      row, column, content: new TextInputModel({
        tags: ['app-form-value'],
        placeholder: ' - ',
        value: activity.name,
        onChanged: (event: ChangedEvent, model: ValueModel) => {
          if (event.changes['isFocused']) {
            model.setValues({ placeholder: model.isFocused ? '' : ' - ' });
          }
        },
        onValueChanged: (value) => {
          if (!this.refreshingRows.has(row)) {
            activity.name = value;
          }
        }
      })
    });
  }

  private createTagsCell(row: number, column: number, activity: ProjectModel): TableCellModel {
    if (!activity.isConfirmed) {
      return this.createConfirmCell(row, column, activity);
    }

    return new TableCellModel({
      row, column, content: new ContainerModel({
        orientation: Orientations.SIDE_BY_SIDE,
        alignment: Alignments.END,
        items: [
          new ValueModel({
            tags: ['app-form-value'],
            value: this.viewModel.activityTags.get(activity.id)?.length ?? 0,
            format: {
              format: (count: number = 0) => this.textService.getText(`${TimeRecordingComponentKey.PROJECT_ACTIVITIES_TABLE}/tag-count`, { count })
            },
            alignment: Alignments.RIGHT
          }),
          new ActionModel({
            tags: ['app-form-btn', 'app-form', 'btn-sm', 'btn-outline-secondary'],
            content: `${TimeRecordingComponentKey.PROJECT_ACTIVITIES_TABLE}/edit-tags`,
            onClick: async () => {
              const dialog = this.matDialog.open(EditActivityTokensDialogComponent, {
                width: '400px',
                data: { activity, tagMappings: this.viewModel.activityTags?.get(activity.id) ?? [] }
              });
              const tagMappings = await dialog.afterClosed().toPromise();
              this.sharedViewService.viewModel.activityTags.set(activity.id, tagMappings);
              this.refreshComponentModel();
            }
          })
        ]
      })
    });
  }

  private createConfirmCell(row: number, column: number, activity: ProjectModel): TableCellModel {
    return new TableCellModel({
      row, column, content: new ActionModel({
        tags: ['app-form-btn', 'btn-sm', 'btn-outline-secondary', 'app-confirm-project'],
        content: new TextModel({
          text: `#(${TimeRecordingViewKey.PROJECT}/confirm-project)`
        }),
        onClick: () => {
          activity.isConfirmed = true;
          this.refreshComponentModel();
        }
      })
    });
  }

  private refreshRow(activityModel: ProjectModel, recordIndex: number) {
    const row = recordIndex + 1;
    if (row < this.componentModel.cellArray.length) {
      this.refreshRowCells(row, this.componentModel.cellArray[row], activityModel);
    }
  }

  private refreshRowCells(row: number, rowCells: TableCellModel[], model: ProjectModel) {
    this.refreshingRows.add(row);
    rowCells[ColumnIndex.NAME].content.set('context', model);
    rowCells[ColumnIndex.TAGS].content.set('value', this.viewModel.activityTags.get(model.id)?.length ?? 0);
    this.refreshingRows.delete(row);
    rowCells.forEach((cell) => {
      if (cell.content instanceof ValueModel) {
        cell.content.validate();
      }
    });
  }

  private async removeActivity(row: number) {
    const dialog = this.matDialog.open(DeleteProjectDialogComponent, {
      width: '400px',
      data: {
        projects: toArray(this.viewModel.projectList)
      },
      panelClass: 'overflow-visible'
    });

    const replacementProjectId = await dialog.afterClosed().toPromise();
    if (replacementProjectId === undefined) {
      return;
    }

    const index = row - 1;
    const originalRecordModelList = this.viewModel.activityList;
    if (index < 0 || index > originalRecordModelList.size - 1) {
      throw new Error(`Invalid index '${index}'`);
    }
    const activityList = this.viewModel.activityList.toArray();
    const activity = activityList[index];
    activityList.splice(index, 1);
    this.viewModel.activityList.setValues(activityList, { exclusive: true });
    await this.sharedViewService.deleteProject(activity, replacementProjectId);
  }
}
