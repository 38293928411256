import { Injectable } from '@angular/core';
import { AuthenticationService } from '@angular-kit/authentication';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(private authenticationService: AuthenticationService) { }

  createTimeRecordingReport(employeeId: string, year: string, month: string, hiddenColumns: string, fileName: string) {
    const authorization = this.authenticationService.authenticationToken;
    const encodedEmployeeId = encodeURIComponent(employeeId);
    const encodedYear = encodeURIComponent(year);
    const encodedMonth = encodeURIComponent(month);
    let query = `authorization=${authorization}`;
    if (hiddenColumns) {
      query += `&hiddenColumns=${encodeURIComponent(hiddenColumns)}`;
    }
    query += `&fileName=${encodeURIComponent(fileName)}`;
    window.open(`api/time-recording/report/${encodedEmployeeId}/${encodedYear}/${encodedMonth}?${query}`);
  }

  createProjectReport(projectId: string, year: string, month: string, fileName: string) {
    const authorization = this.authenticationService.authenticationToken;
    const encodedProjectId = encodeURIComponent(projectId);
    const encodedYear = encodeURIComponent(year);
    const encodedMonth = encodeURIComponent(month);
    const query = `authorization=${authorization}&fileName=${encodeURIComponent(fileName)}`;
    window.open(`api/time-recording/report/project/${encodedProjectId}/${encodedYear}/${encodedMonth}?${query}`);
  }
}
