<thead #headerElement>
<tr>
  <th class="app-custom-table-head-left">
    <table class="app-content-table app-content-table-left">
      <tr *ngFor="let row of columnHeaderModels" [kitTags]="row.tags" [kitClick]="row" class="kit-table-row">
        <ng-container *ngFor="let column of rowHeaderModels">
          <th *ngIf="getCell(row, column)" [kitTags]="column.tags" [kitClick]="column" scope="col"
              class="kit-table-column">
            <div [kitTags]="getCell(row, column).tags" [kitClick]="getCell(row, column)" class="kit-table-cell">
              <ng-container [kitComponent]="getCell(row, column).content"></ng-container>
            </div>
          </th>
        </ng-container>
      </tr>
    </table>
  </th>
  <th #headCenterElement class="app-custom-table-head-center">
    <table class="app-content-table app-content-table-center">
      <tr *ngFor="let row of columnHeaderModels" [kitTags]="row.tags" [kitClick]="row" class="kit-table-row">
        <ng-container *ngFor="let column of columnModels">
          <th *ngIf="getCell(row, column)" [kitTags]="column.tags" [kitClick]="column" scope="col"
              class="kit-table-column">
            <div [kitTags]="getCell(row, column).tags" [kitClick]="getCell(row, column)" class="kit-table-cell">
              <ng-container [kitComponent]="getCell(row, column).content"></ng-container>
            </div>
          </th>
        </ng-container>
      </tr>
    </table>
  </th>
  <!--  <th class="app-custom-table-head-right">-->
  <!--  </th>-->
</tr>
</thead>
<tbody #bodyElement>
<tr>
  <th #bodyLeftElement class="app-custom-table-body-left">
    <table class="app-content-table app-content-table-left">
      <tr *ngFor="let row of rowModels" [kitTags]="row.tags" [kitClick]="row" class="kit-table-row">
        <ng-container *ngFor="let column of rowHeaderModels">
          <th *ngIf="getCell(row, column)" [kitTags]="column.tags" [kitClick]="column" scope="row"
              class="kit-table-column">
            <div [kitTags]="getCell(row, column).tags" [kitClick]="getCell(row, column)" class="kit-table-cell">
              <ng-container [kitComponent]="getCell(row, column).content"></ng-container>
            </div>
          </th>
        </ng-container>
      </tr>
    </table>
  </th>
  <td #bodyCenterElement class="app-custom-table-body-center">
    <table class="app-content-table app-content-table-center">
      <tr *ngFor="let row of rowModels" [kitTags]="row.tags" [kitClick]="row" class="kit-table-row">
        <ng-container *ngFor="let column of columnModels">
          <td *ngIf="getCell(row, column)" [kitTags]="column.tags" [kitClick]="column"
              [rowSpan]="getRowSpan(row, column)"
              class="kit-table-column">
            <div [kitTags]="getCell(row, column).tags" [kitClick]="getCell(row, column)" class="kit-table-cell">
              <ng-container [kitComponent]="getCell(row, column).content"></ng-container>
            </div>
          </td>
        </ng-container>
      </tr>
    </table>
  </td>
  <!--  <th class="app-custom-table-body-right">-->
  <!--  </th>-->
</tr>
</tbody>
