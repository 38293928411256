import { NgModule } from '@angular/core';
import { AccountingInitializer } from './accounting.initializer';
import { BsViewModule } from '@angular-kit/bs-view';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@angular-kit/core';
import { ViewModule } from '@angular-kit/view';
import { AccountingRoutingModule } from './accounting-routing.module';
import { AuthenticationModule } from '@angular-kit/authentication';
import { SharedModule } from '../shared/shared.module';
import { ShellModule } from '../shell/shell.module';
import { AccountingListComponent } from './component/accounting-list/accounting-list.component';
import { AccountingDetailComponent } from './component/accounting-detail/accounting-detail.component';
import { AccountingService } from './service/accounting.service';
import { AccountingShellMenuComponent } from './component/accounting-shell-menu/accounting-shell-menu.component';


@NgModule({
  declarations: [
    AccountingListComponent,
    AccountingDetailComponent,
    AccountingShellMenuComponent
  ],
  entryComponents: [
    AccountingShellMenuComponent,
    AccountingListComponent
  ],
  providers: [
    AccountingService
  ],
  imports: [
    CommonModule,
    CoreModule,
    ViewModule,
    BsViewModule,
    AuthenticationModule,
    SharedModule,
    ShellModule,
    AccountingRoutingModule
  ],
})
export class AccountingModule {
  constructor() {
    AccountingInitializer.initialize();
  }
}
