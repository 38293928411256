import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthenticationGuard } from '@angular-kit/authentication';

import { LoginComponent } from './component/login/login.component';
import { RegisterComponent } from './component/register/register.component';
import { ResetPasswordComponent } from './component/reset-password/reset-password.component';
import { PrivacyDeclarationComponent } from './component/privacy-declaration/privacy-declaration.component';
import { ImprintComponent } from './component/imprint/imprint.component';
import { DataProcessingComponent } from './component/data-processing/data-processing.component';

const routes: Routes = [
  {
    path: 'login',
    canActivate: [AuthenticationGuard],
    component: LoginComponent
  },
  {
    path: 'register',
    component: RegisterComponent
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent
  },
  {
    path: 'privacy-declaration',
    component: PrivacyDeclarationComponent
  },
  {
    path: 'data-processing',
    component: DataProcessingComponent
  },
  {
    path: 'imprint',
    component: ImprintComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class ShellRoutingModule {
}
