import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ContextBindings, FormFieldModel, SelectInputModel, TextInputModel } from '@typescript-kit/view';
import { Subscription } from 'rxjs';
import { ChangedEvent, CoreValidationKey, isEmpty, TextService, validate, ValidationError, hasValue } from '@typescript-kit/core';
import { SharedComponentKey } from '../../key/shared-component.key';
import { CompanyFieldKey, COUNTRY_CODES, CountryCode, SharedModelKey } from '@teamworks/global';
import { SubscriptionViewService } from '../../view/subscription.view-service';
import { SubscriptionViewFieldKey, SubscriptionViewModel } from '../../view/subscription.view-model';

@Component({
  selector: 'app-company-detail',
  templateUrl: './company-detail.component.html',
  styleUrls: ['./company-detail.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompanyDetailComponent implements OnInit, OnDestroy {
  public readonly titleText = `${SharedComponentKey.COMPANY_DETAIL}/title`;

  public form = {
    nameField: new FormFieldModel(),
    streetField: new FormFieldModel(),
    zipCodeField: new FormFieldModel(),
    cityField: new FormFieldModel(),
    countryCodeField: new FormFieldModel(),
    contactNameField: new FormFieldModel(),
    contactEmailField: new FormFieldModel(),
    taxIdField: new FormFieldModel()
  };

  private viewModelChangedSubscription: Subscription;

  private readonly countryCodeKeys = COUNTRY_CODES.map((c) => `${SharedModelKey.COUNTRY_CODE}/${c}`);

  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly viewService: SubscriptionViewService,
    private readonly textService: TextService
  ) {
  }

  get viewModel(): SubscriptionViewModel {
    return this.viewService.viewModel;
  }

  async ngOnInit(): Promise<void> {
    this.viewModelChangedSubscription = this.viewModel.changedEvent.subscribe(event => this.onViewModelChanged(event));
    await this.createComponentModels();
  }

  ngOnDestroy() {
    this.viewModelChangedSubscription.unsubscribe();
  }

  private async onViewModelChanged(event: ChangedEvent) {
    if (event.changes[SubscriptionViewFieldKey.COMPANY]) {
      await this.refreshComponentModels();
    }
    if (event.changes[SubscriptionViewFieldKey.VALIDATION_VISIBLE]) {
      await this.addValidation();
    }
  }

  private async createComponentModels() {
    this.form = {
      nameField: new FormFieldModel({
        label: true,
        control: new TextInputModel({
          key: CompanyFieldKey.NAME,
          scope: `${SharedModelKey.COMPANY}/field`,
          tags: ['form-control', 'form-control-sm', `app-company-name`],
          contextBinding: ContextBindings.TWO_WAY
        })
      }),
      streetField: new FormFieldModel({
        label: true,
        control: new TextInputModel({
          key: CompanyFieldKey.STREET,
          scope: `${SharedModelKey.COMPANY}/field`,
          tags: ['form-control', 'form-control-sm', 'app-company-street'],
          contextBinding: ContextBindings.TWO_WAY
        })
      }),
      zipCodeField: new FormFieldModel({
        label: true,
        control: new TextInputModel({
          key: CompanyFieldKey.ZIP_CODE,
          scope: `${SharedModelKey.COMPANY}/field`,
          tags: ['form-control', 'form-control-sm', 'app-company-zip-code'],
          contextBinding: ContextBindings.TWO_WAY
        })
      }),
      cityField: new FormFieldModel({
        label: true,
        control: new TextInputModel({
          key: CompanyFieldKey.CITY,
          scope: `${SharedModelKey.COMPANY}/field`,
          tags: ['form-control', 'form-control-sm', 'app-company-city'],
          contextBinding: ContextBindings.TWO_WAY
        })
      }),
      countryCodeField: new FormFieldModel({
        label: true,
        control: new SelectInputModel({
          key: CompanyFieldKey.COUNTRY_CODE,
          scope: `${SharedModelKey.COMPANY}/field`,
          tags: ['form-control', 'form-control-sm', 'app-company-country-code'],
          options: this.countryCodeKeys,
          onValueChanged: (value) => this.selectCountry(value)
        })
      }),
      contactNameField: new FormFieldModel({
        label: true,
        control: new TextInputModel({
          key: CompanyFieldKey.CONTACT_NAME,
          scope: `${SharedModelKey.COMPANY}/field`,
          tags: ['form-control', 'form-control-sm', 'app-company-contact-name'],
          contextBinding: ContextBindings.TWO_WAY
        })
      }),
      contactEmailField: new FormFieldModel({
        label: true,
        control: new TextInputModel({
          key: CompanyFieldKey.CONTACT_EMAIL,
          scope: `${SharedModelKey.COMPANY}/field`,
          tags: ['form-control', 'form-control-sm', 'app-company-contact-email'],
          contextBinding: ContextBindings.TWO_WAY
        })
      }),
      taxIdField: new FormFieldModel({
        label: true,
        control: new TextInputModel({
          key: CompanyFieldKey.TAX_ID,
          scope: `${SharedModelKey.COMPANY}/field`,
          tags: ['form-control', 'form-control-sm', 'app-company-contact-email'],
          contextBinding: ContextBindings.TWO_WAY
        })
      })
    };

    Object.values(this.form).forEach((i) => {
      if (!hasValue(i.control.get('onValueChanged'))) {
        i.control.set('onValueChanged', () => this.updateValidity());
      }
    });

    await this.refreshComponentModels();
  }

  private async refreshComponentModels() {
    const companyModel = this.viewModel.company;
    const updateValues: any = {
      context: companyModel,
      isHidden: !companyModel
    };

    this.form.nameField.setValues(updateValues);
    this.form.streetField.setValues(updateValues);
    this.form.zipCodeField.setValues(updateValues);
    this.form.cityField.setValues(updateValues);
    this.form.countryCodeField.setValues({
      isHidden: updateValues.isHidden,
      value: this.viewModel.company ? `${SharedModelKey.COUNTRY_CODE}/${this.viewModel.company.countryCode}` : ''
    });
    this.form.contactNameField.setValues(updateValues);
    this.form.contactEmailField.setValues(updateValues);
    this.form.taxIdField.setValues(updateValues);

    await this.updateValidity();

    this.changeDetector.markForCheck();
  }

  private selectCountry(key: string): void {
    const countryCode = key.slice(SharedModelKey.COUNTRY_CODE.length + 1) as CountryCode;
    if (COUNTRY_CODES.includes(countryCode)) {
      this.viewModel.company?.set(CompanyFieldKey.COUNTRY_CODE, countryCode, {silent: false});
    }
  }

  get displayName(): string {
    if (this.viewModel.company) {
      return isEmpty(this.viewModel.company.name)
        ? this.textService.getText(`${SharedComponentKey.COMPANY_DETAIL}/company/no-name-set`)
        : this.viewModel.company.name;
    }

    return '';
  }

  private async updateValidity(): Promise<void> {
    const fieldValidities = await Promise.all(
      Object
        .values(this.form)
        .map(async (f) => {
          if (this.viewModel.validationVisible) {
            return f.control.validate();
          }

          if (f.control.key === CompanyFieldKey.TAX_ID) {
            return true;
          }

          const validation = [CoreValidationKey.REQUIRED];
          if (f.control.key === CompanyFieldKey.CONTACT_EMAIL) {
            validation.push(CoreValidationKey.EMAIL);
          }

          return validate(validation, f.control.value)
            .then((errors) => !errors?.some((e) => e instanceof ValidationError));
        })
    );

    this.viewModel.companyValid = !fieldValidities.includes(false);
  }

  private async addValidation(): Promise<void> {
    for (const field of Object.values(this.form)) {
      if (field.control.key === CompanyFieldKey.TAX_ID) {
        continue;
      }

      field.control.validation = [CoreValidationKey.REQUIRED];

      if (field.control.key === CompanyFieldKey.CONTACT_EMAIL) {
        field.control.validation.push(CoreValidationKey.EMAIL);
      }
    }

    await this.updateValidity();
  }
}
