import { Component, ViewEncapsulation, ChangeDetectionStrategy, AfterViewInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { BaseDirective } from '@angular-kit/view';
import { DropdownModel, ComponentFieldKey, DropdownFieldKey } from '@typescript-kit/view';
import { ChangedEvent } from '@typescript-kit/core';

@Component({
  selector: 'kit-bs-dropdown',
  templateUrl: './bs-dropdown.component.html',
  styleUrls: ['./bs-dropdown.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BsDropdownComponent extends BaseDirective<DropdownModel> implements AfterViewInit, OnDestroy {

  private isViewInitialized = false;
  private onOpenedHandler: () => void;
  private onClosedHandler: () => void;

  @ViewChild('dropdown', { static: true })
  private dropdownRef: ElementRef;

  @ViewChild('trigger', { static: true })
  private triggerRef: ElementRef;

  @ViewChild('items', { static: true })
  private itemsRef: ElementRef;

  get hostClass(): string {
    return 'kit-dropdown';
  }

  get dropdownElement(): HTMLDivElement {
    return this.dropdownRef.nativeElement;
  }

  get triggerElement(): HTMLButtonElement {
    return this.triggerRef.nativeElement;
  }

  get itemsElement(): HTMLDivElement {
    return this.itemsRef.nativeElement;
  }

  get focusElement(): HTMLButtonElement {
    return this.triggerRef.nativeElement;
  }

  ngAfterViewInit(): void {
    this.onOpenedHandler = () => this.ngZone.run(() => this.onOpened());
    $(this.dropdownElement).on('shown.bs.dropdown', this.onOpenedHandler);
    this.onClosedHandler = () => this.ngZone.run(() => this.onClosed());
    $(this.dropdownElement).on('hidden.bs.dropdown', this.onClosedHandler);
    this.isViewInitialized = true;
    setTimeout(() => {
      if (this.model) {
        this.refreshIsActive();
      }
    }, 0);
  }

  ngOnDestroy(): void {
    if (this.onOpenedHandler) {
      $(this.dropdownElement).off('shown.bs.dropdown', this.onOpenedHandler);
      this.onOpenedHandler = null;
    }
    if (this.onClosedHandler) {
      $(this.dropdownElement).off('hidden.bs.dropdown', this.onClosedHandler);
      this.onClosedHandler = null;
    }
    super.ngOnDestroy();
  }

  protected onModelChanged(model: DropdownModel) {
    super.onModelChanged(model);
    if (this.isViewInitialized) {
      this.refreshIsActive();
    }
  }

  protected onModelPropertyChanged(event: ChangedEvent) {
    super.onModelPropertyChanged(event);
    if (event.changes[ComponentFieldKey.IS_ACTIVE]) {
      this.refreshIsActive();
    }
    if (event.changes[DropdownFieldKey.ITEMS]) {
      // TODO CHECK SCROLL
    }
    if (event.changes[DropdownFieldKey.SCROLL_TO_ACTIVE_ITEM]) {
      // TODO CHECK SCROLL
    }
    // listen to child event is active if scroll to active
  }

  protected onOpened(): void {
    this.model.isActive = true;
    if (this.model.scrollToActiveItem) {
      this.scrollToActiveItem();
    }
  }

  protected onClosed(): void {
    this.model.isActive = false;
  }

  private scrollToActiveItem(): void {
    let activeAtemIndex = -1;
    this.model.items.firstKey((item, key, index) => {
      if (!item.isActive) {
        return false;
      }
      activeAtemIndex = index;
      return true;
    });
    if (activeAtemIndex < 0) {
      return;
    }
    const activeItemElement = this.itemsElement.children[activeAtemIndex] as HTMLElement;
    if (!activeItemElement) {
      return;
    }
    // activeItemElement.scrollIntoView();
    this.itemsElement.scrollTop = activeItemElement.offsetTop
      - Math.round(this.itemsElement.offsetHeight / 2) + Math.round(activeItemElement.offsetHeight / 2);
  }

  private refreshIsActive(): void {
    const isActive = this.model.isActive;
    if (this.itemsElement.classList.contains('show') === isActive) {
      return;
    }
    // IMPORTANT: Boostrap 4: call dropdown on trigger element, otherwise events are not fired correctly
    this.ngZone.runOutsideAngular(() => $(this.triggerElement).dropdown(isActive ? 'show' : 'hide'));
  }

}
