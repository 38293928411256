import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActionModel, CheckboxModel, TextModel } from '@typescript-kit/view';
import { AlertService, TextService } from '@typescript-kit/core';
import { ProjectPermissions } from '../../view/user-common.view-model';
import { getProjectTree, ProjectModel, ProjectTree } from '@teamworks/global';
import { SharedComponentKey } from '../../key/shared-component.key';
import { UserService } from '../../service/user.service';

@Component({
  selector: 'app-project-permissions-dialog',
  templateUrl: 'project-permissions-dialog.component.html',
  styleUrls: ['project-permissions-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectPermissionsDialogComponent implements OnInit {
  public readonly titleText: string;
  public readonly projectText: string;
  public readonly viewPermissionText: string;
  public readonly overviewPermissionText: string;

  private readonly userId: string;
  private readonly isProjectAdmin: boolean;
  private readonly projects: ProjectModel[];
  private readonly allText = `${SharedComponentKey.PROJECT_PERMISSIONS_DIALOG}/all`;

  public projectFields: [string, CheckboxModel, CheckboxModel | undefined][];
  public confirmAction: ActionModel;
  public cancelAction: ActionModel;

  private projectPermissions: ProjectPermissions;

  constructor(
    private readonly textService: TextService,
    private readonly dialog: MatDialogRef<ProjectPermissionsDialogComponent>,
    private readonly alertService: AlertService,
    private readonly userService: UserService,
    @Inject(MAT_DIALOG_DATA) private readonly data
  ) {
    this.userId = data.userId;
    this.isProjectAdmin = data.isProjectAdmin;
    this.projects = data.projects
      .filter((p: ProjectModel) => p.name)
      .sort((a: ProjectModel, b: ProjectModel) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0);
    this.projectPermissions = data.projectPermissions;
    this.titleText = `${SharedComponentKey.PROJECT_PERMISSIONS_DIALOG}/title`;
    this.projectText = `${SharedComponentKey.PROJECT_PERMISSIONS_DIALOG}/project`;
    this.viewPermissionText = `${SharedComponentKey.PROJECT_PERMISSIONS_DIALOG}/permission-visible`;
    this.overviewPermissionText = `${SharedComponentKey.PROJECT_PERMISSIONS_DIALOG}/permission-overview`;
  }

  ngOnInit(): void {
    this.confirmAction = new ActionModel({
      tags: ['app-form-btn', 'btn-sm', 'btn-outline-secondary'],
      content: new TextModel({
        text: `#(${SharedComponentKey.PROJECT_PERMISSIONS_DIALOG}/actions/confirm)`
      }),
      onClick: () => this.setProjectPermissions()
    });
    this.cancelAction = new ActionModel({
      tags: ['app-form-btn', 'btn-sm', 'btn-outline-secondary'],
      content: new TextModel({
        text: `#(${SharedComponentKey.PROJECT_PERMISSIONS_DIALOG}/actions/cancel)`
      }),
      onClick: () => this.dialog.close(null)
    });

    this.refreshProjectFields();
  }

  private refreshProjectFields(): void {
    const fields: [string, CheckboxModel, CheckboxModel | undefined][] = [];

    const canViewAll = this.projectPermissions['*']?.permission === 'visible';
    const canSeeOverviewOfAll = this.projectPermissions['*']?.permission === 'overview';

    fields.push([
      this.textService.getText(this.allText),
      new CheckboxModel({
        checked: this.isProjectAdmin || canViewAll || canSeeOverviewOfAll,
        isDisabled: this.isProjectAdmin || canSeeOverviewOfAll,
        onCheckedChanged: (isChecked) => this.setPermission('*', 'visible', isChecked)
      }),
      new CheckboxModel({
        checked: canSeeOverviewOfAll,
        onCheckedChanged: (isChecked) => this.setPermission('*', 'overview', isChecked)
      })
    ]);

    for (const project of this.projects) {
      if (project.parentId) {
        continue;
      }

      fields.push(...this.getModelsForProject(canViewAll, canSeeOverviewOfAll, project));
    }

    this.projectFields = fields;
  }

  private getModelsForProject(canViewAll: boolean, canSeeOverviewOfAll: boolean, project: ProjectModel): [string, CheckboxModel, CheckboxModel | undefined][] {
    const canView = this.projectPermissions[project.id]?.permission === 'visible';
    const canSeeOverview = this.projectPermissions[project.id]?.permission === 'overview';

    const projectModels: [string, CheckboxModel, CheckboxModel | undefined][] = [
      [
        project.name,
        new CheckboxModel({
          checked: !this.isProjectAdmin && (canSeeOverview || !canViewAll && canView),
          isDisabled: this.isProjectAdmin || canSeeOverview,
          onCheckedChanged: (isChecked) => this.setPermission(project, 'visible', isChecked)
        }),
        new CheckboxModel({
          checked: !canSeeOverviewOfAll && canSeeOverview,
          onCheckedChanged: (isChecked) => this.setPermission(project, 'overview', isChecked)
        })
      ]
    ];

    const addChildProjectModels = (project: ProjectModel, canSeeParentOverview: boolean) => {
      const childProjects = this.projects
        .filter(p => p.parentId === project.id)
        .sort((a: ProjectModel, b: ProjectModel) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0);

      for (const child of childProjects) {
        const canView = this.projectPermissions[child.id]?.permission === 'visible';

        projectModels.push([
          child.name,
          new CheckboxModel({
            checked: !this.isProjectAdmin && (canSeeParentOverview || !canViewAll && canView),
            isDisabled: this.isProjectAdmin || canSeeParentOverview,
            onCheckedChanged: (isChecked) => this.setPermission(child, 'visible', isChecked)
          }),
          undefined
        ]);

        addChildProjectModels(child, canSeeParentOverview)
      }
    };

    addChildProjectModels(project, canSeeOverview);

    return projectModels;
  }

  private setPermission(project: ProjectModel | '*', permission: 'visible' | 'overview', granted: boolean) {
    const id = project === '*' ? '*' : project.id;

    if (this.projectPermissions[id] === undefined) {
      this.projectPermissions[id] = {
        name: project === '*' ? this.textService.getText(this.allText) : project.name,
        permission: undefined
      };
    }

    if (project !== '*' && granted) {
      const allPermission = this.projectPermissions['*']?.permission;
      if (allPermission === permission) {
        this.projectPermissions['*'].permission = permission === 'overview' ? 'visible' : undefined;
      }
    }

    const newPermission = this.getNewProjectPermission(this.projectPermissions[id].permission, permission, granted);
    this.projectPermissions[id].permission = newPermission;

    this.refreshProjectFields();
  }

  private async setProjectPermissions(): Promise<void> {
    const permissions: { [id: string]: 'overview' | 'visible'; } = {};

    for (const [id, project] of Object.entries(this.projectPermissions)) {
      if (project.permission) {
        permissions[id] = project.permission;
      }
    }

    try {
      await this.userService.setProjectPermissions(this.userId, permissions);
      this.dialog.close();
    } catch (error) {
      this.alertService.alertError(error);
    }
  }

  private getNewProjectPermission(
    original: 'overview' | 'visible' | undefined,
    permission: 'overview' | 'visible',
    granted: boolean
  ): 'overview' | 'visible' | undefined {
    if (granted) {
      if (permission === 'visible') {
        return original === undefined ? 'visible' : original;
      }

      return 'overview';
    } else {
      if (permission === 'visible') {
        return original === 'overview' ? original : undefined;
      }

      return original === 'overview' ? 'visible' : original;
    }
  }
}
