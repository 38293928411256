import { Component, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';
import { BaseDirective } from '@angular-kit/view';
import { ListModel } from '@typescript-kit/view';

@Component({
  selector: 'app-accounting-list',
  templateUrl: './accounting-list.component.html',
  styleUrls: ['./accounting-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountingListComponent extends BaseDirective<ListModel> {
}
