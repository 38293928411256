import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActionModel, ComponentFieldKey, ImageModel, TextModel, ViewComponentKey } from '@typescript-kit/view';
import { ChangedEvent, LocaleService, toArray } from '@typescript-kit/core';
import { Subscription } from 'rxjs';
import { SharedSvgKey } from '../../key/shared-svg.key';
import { DialogService } from '../../service/dialog.service';
import { SharedViewKey } from '../../key/shared-view.key';
import { UserCommonViewService } from '../../view/user-common.view-service';
import { UserCommonViewFieldKey, UserCommonViewModel } from '../../view/user-common.view-model';
import { UserFieldKey, UserModel } from '@typescript-kit/authentication';

@Component({
  selector: 'app-user-sidebar',
  templateUrl: './user-sidebar.component.html',
  styleUrls: ['./user-sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserSidebarComponent implements OnInit, OnDestroy {

  private viewModelChangedSubscription: Subscription;

  public titleText: TextModel;
  public addUserAction: ActionModel;
  public removeUserAction: ActionModel;
  public userActionList: ActionModel[];

  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly localeService: LocaleService,
    private readonly viewService: UserCommonViewService,
    private readonly dialogService: DialogService
  ) {
  }

  get viewModel(): UserCommonViewModel {
    return this.viewService.viewModel;
  }

  ngOnInit(): void {
    this.viewModelChangedSubscription = this.viewModel.changedEvent.subscribe(event => this.onViewModelChanged(event));
    this.createComponentModels();
  }

  ngOnDestroy(): void {
    this.viewModelChangedSubscription.unsubscribe();
  }

  private onViewModelChanged(event: ChangedEvent) {
    if (event.originalEvent !== event) {
      if (event.originalEvent.source === this.viewModel.userList) {
        this.onUserChanged(event.originalEvent);
      }
      return;
    }
    if (event.changes[UserCommonViewFieldKey.USER_LIST]) {
      this.onUserListChanged();
    }
    if (event.changes[UserCommonViewFieldKey.USER_ID]) {
      this.onUserIdChanged();
    }
  }

  private onUserListChanged() {
    this.refreshUserActionModels();
    this.changeDetector.markForCheck();
  }

  private onUserIdChanged() {
    const selectedIndex = this.userActionList.findIndex(item => item.isActive);
    const actionIndex = this.userActionList.findIndex(item => item.key === this.viewModel.userId);
    if (actionIndex === selectedIndex) {
      return;
    }
    if (selectedIndex >= 0) {
      this.userActionList[selectedIndex].isActive = false;
    }
    if (actionIndex >= 0) {
      this.userActionList[actionIndex].isActive = true;
    }
  }

  private onUserChanged(event: ChangedEvent) {
    if (event.originalEvent !== event) {
      if (event.originalEvent.source instanceof UserModel) {
        this.onUserPropertyChanged(event.originalEvent);
      }

      return;
    }

    this.refreshUserActionModels();
    this.changeDetector.markForCheck();
  }

  private onUserPropertyChanged(event: ChangedEvent) {
    if (event.changes[UserFieldKey.name]) {
      this.refreshUserActionModels();
    } else {
      const userModel = event.source as UserModel;
      const actionKey = event.changes[UserFieldKey.ID] ? event.changes[UserFieldKey.ID].originalValue : userModel.id;
      const actionIndex = this.userActionList.findIndex(item => item.key === actionKey);
      if (actionIndex >= 0) {
        this.userActionList[actionIndex] = this.createUserActionModel(userModel);
      }
    }

    this.changeDetector.markForCheck();
  }

  private createComponentModels(): void {
    this.refreshHeaderActionModels();
    this.refreshUserActionModels();
  }

  private refreshHeaderActionModels(): void {
    this.titleText = new TextModel({
      text: `#(${SharedViewKey.USER}/name)`
    });
    this.addUserAction = new ActionModel({
      content: new ImageModel({
        type: ViewComponentKey.SVG_SYMBOL,
        source: SharedSvgKey.ADD_DOCUMENT
      }),
      onClick: () => this.viewService.createEmptyUser()
    });
    this.removeUserAction = new ActionModel({
      content: new ImageModel({
        type: ViewComponentKey.SVG_SYMBOL,
        source: SharedSvgKey.DELETE_DOCUMENT
      }),
      onClick: async () => {
        const isDeleteAllowed = this.viewModel.userId === null || await this.dialogService.openYesNoQuestion(
          {
            text: `#(${SharedViewKey.USER_SIDEBAR}/delete-user-dialog/question)`,
            textArgs: { user: this.viewModel.user.name }
          },
        );
        if (isDeleteAllowed) {
          await this.viewService.deleteUser(this.viewModel.user);
        }
      }
    });
  }

  private refreshUserActionModels(): void {
    this.userActionList = toArray(this.viewModel.userList)
      .sort((a: UserModel, b: UserModel) => {
        return (a.name || '').localeCompare((b.name || ''), this.localeService.localeKey, { sensitivity: 'accent' });
      })
      .map((entityModel: UserModel) => this.createUserActionModel(entityModel));
  }

  private createUserActionModel(entityModel: UserModel): ActionModel {
    const id = entityModel.id;
    const isSelected = this.viewModel.userId === id;
    const model = new ActionModel({
      type: ViewComponentKey.BUTTON,
      tags: { active: isSelected },
      key: entityModel.id,
      content: entityModel.name || '-',
      isActive: isSelected,
      onClick: () => {
        this.viewModel.userId = entityModel.id;
      },
      onChanged: (event: ChangedEvent) => {
        if (event.changes[ComponentFieldKey.IS_ACTIVE]) {
          model.tags.set('active', event.changes[ComponentFieldKey.IS_ACTIVE].currentValue);
        }
      }
    });
    return model;
  }
}
