import { Component, ViewEncapsulation, ChangeDetectionStrategy, Input } from '@angular/core';
import { BaseDirective } from '../../directive/base.directive';
import { ContentModel } from '@typescript-kit/view';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'kit-content,[kitContent]',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContentComponent extends BaseDirective<ContentModel> {

  get hostClass(): string {
    return 'kit-content';
  }

  @Input()
  set kitContent(model: ContentModel) {
    this.model = model;
  }

  get kitContent(): ContentModel {
    return this.model;
  }

}
