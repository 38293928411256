import { ObservableList, ObservableModel, ObserveModes, toArray } from '@typescript-kit/core';
import { SalaryOverviewRecordData, SalaryOverviewRecordModel } from '@teamworks/global';

export interface SalaryOverviewViewData {
  salaryOverviewRecordList?: Iterable<SalaryOverviewRecordData>;
}

export class SalaryOverviewViewFieldKey {
  static readonly SALARY_OVERVIEW_RECORD_LIST = 'salaryOverviewRecordList';
}

export class SalaryOverviewViewModel extends ObservableModel implements SalaryOverviewViewData {
  static readonly KEYS = Object.keys(SalaryOverviewViewFieldKey).map(key => SalaryOverviewViewFieldKey[key]);

  constructor(data?: SalaryOverviewViewData) {
    super(data, SalaryOverviewViewModel.KEYS, { observeMode: ObserveModes.FLAT });
  }

  get salaryOverviewRecordList(): ObservableList<SalaryOverviewRecordModel> {
    return this.get(SalaryOverviewViewFieldKey.SALARY_OVERVIEW_RECORD_LIST);
  }

  set salaryOverviewRecordList(value: ObservableList<SalaryOverviewRecordModel>) {
    this.set(SalaryOverviewViewFieldKey.SALARY_OVERVIEW_RECORD_LIST, value);
  }

  protected setupValues(viewData: SalaryOverviewViewData, exclusive: boolean): SalaryOverviewViewData {
    this.setupSalaryOverviewRecordList(viewData, exclusive);
    return super.setupValues(viewData, exclusive) as SalaryOverviewViewData;
  }

  private setupSalaryOverviewRecordList(viewData: SalaryOverviewViewData, exclusive: boolean) {
    if (!exclusive && !(SalaryOverviewViewFieldKey.SALARY_OVERVIEW_RECORD_LIST in viewData)
      || viewData[SalaryOverviewViewFieldKey.SALARY_OVERVIEW_RECORD_LIST] instanceof ObservableList) {
      return;
    }
    const recordModelList = toArray(viewData[SalaryOverviewViewFieldKey.SALARY_OVERVIEW_RECORD_LIST])
      .map((recordData) => recordData instanceof SalaryOverviewRecordModel
        ? recordData
        : new SalaryOverviewRecordModel(recordData)
      );
    const originalList = this.salaryOverviewRecordList;
    if (originalList) {
      originalList.setValues(recordModelList, { exclusive: true });
      viewData[SalaryOverviewViewFieldKey.SALARY_OVERVIEW_RECORD_LIST] = originalList;
    } else {
      viewData[SalaryOverviewViewFieldKey.SALARY_OVERVIEW_RECORD_LIST] =
        new ObservableList(recordModelList, { observeMode: ObserveModes.FLAT });
    }
  }

}
