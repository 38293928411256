import { ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { ValidityPeriod, ValidityPeriodTargetTypes } from '@teamworks/global';
import { ValidityPeriodService } from '../../service/validity-period.service';
import {
  ActionModel,
  TableCellModel,
  TableColumnModel,
  TableModel,
  TextInputModel,
  TextModel
} from '@typescript-kit/view';
import { DataQuery } from '@typescript-kit/data';
import { CoreFormatKey, CoreValidationKey, isEmpty, validateDate } from '@typescript-kit/core';
import { TimeRecordingComponentKey } from '../../key/time-recording-component.key';

@Component({
  selector: 'app-employment-duration',
  template: `
    <table [kitModel]="tableModel"></table>
  `,
  styleUrls: ['./employment-duration.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EmploymentDurationComponent implements OnChanges {
  @Input() employeeId: string;
  private validityPeriodList: ValidityPeriod[];

  public readonly tableModel = new TableModel({
    key: 'employmentDurationTable',
    tags: ['app-employment-duration-record-table'],
    columnHeaderCount: 1,
    columns: [
      new TableColumnModel({ index: 0, tags: ['app-employment-duration-start'] }),
      new TableColumnModel({ index: 1, tags: ['app-employment-duration-end'] }),
      new TableColumnModel({ index: 2, tags: ['app-employment-duration-action'] })
    ]
  });

  constructor(
    private readonly validityPeriodService: ValidityPeriodService,
    private readonly changeDetector: ChangeDetectorRef
  ) {
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    await this.refreshDurationTable();
  }

  private async refreshDurationTable() {
    if (!this.employeeId) {
      this.validityPeriodList = null;
    } else {
      const query: DataQuery = { filter: { targetId: this.employeeId }, sort: 'id' };
      this.validityPeriodList = await this.validityPeriodService.loadValidityPeriodList(ValidityPeriodTargetTypes.EMPLOYEE, query);
    }

    this.tableModel.setValues({ cells: this.createTableCellModels(this.validityPeriodList),  });
  }

  private createEmptyValidityPeriod(): ValidityPeriod {
    return {
      id: null,
      targetId: this.employeeId,
      targetType: ValidityPeriodTargetTypes.EMPLOYEE,
      begin: null,
      end: null,
      modified: null
    };
  }

  private createTableRow(index: number, period: ValidityPeriod): TableCellModel[] {
    let begin = period.begin;
    let end = period.end;
    const length = this.validityPeriodList.length;

    const save = async () => {
        const data: ValidityPeriod = {
          id: period.id,
          targetId: period.targetId,
          targetType: period.targetType,
          begin,
          end,
          modified: period.modified
        };
        await this.validityPeriodService.saveValidityPeriod(data);
        await this.refreshDurationTable();
        this.changeDetector.markForCheck();
    };
    const beginInput = new TableCellModel({
      row: index, column: 0,
      content: new TextInputModel({
        validation: {
          [CoreValidationKey.REQUIRED]: (value) => (period.id != null && !isEmpty(value)) || (period.id == null),
          [CoreValidationKey.DATE]: (value) => validateDate(value),
          [CoreValidationKey.LESS_THAN]: (value ) => isEmpty(end) || value < end
        },
        value: begin,
        placeholder: '-',
        format: CoreFormatKey.DATE,
        onValueChanged: async (value, originalValue, model) => {
          if (await model.validate()) {
            begin = value;
            await save();
          }
        }
      })
    });
    const endInput = new TableCellModel({
      row: index, column: 1,
      content: new TextInputModel({
        value: end,
        placeholder: '-',
        format: CoreFormatKey.DATE,
        validation: {
          [CoreValidationKey.REQUIRED]: () => period.id != null && index <= length ,
          [CoreValidationKey.DATE]: (value) =>  validateDate(value) ,
          [CoreValidationKey.GREATER_THAN_OR_EQUAL]: (value) => isEmpty(value) || value >= begin
        },
        onValueChanged: async (value, originalValue, model) => {
          if (await model.validate()) {
            end = value;
            await save();
          }
        }
      })
    });
    const deleteAction = new TableCellModel({
      row: index, column: 2,
      content: new ActionModel({
        tags: ['app-form-btn', 'btn-sm', 'btn-outline-secondary'],
        content: '-',
        isHidden: period.id == null || index === 1,
        onClick: async () => {
          if (period.id) {
            await this.validityPeriodService.deleteValidityPeriod(period.id);
            await this.refreshDurationTable();
          }

        }
      })
    });
    return [beginInput, endInput, deleteAction];
  }

  private createTableCellModels(periodList: ValidityPeriod[]): TableCellModel[] {

    const tableCellModels: TableCellModel[] = [
      new TableCellModel({ row: 0, column: 0,
        content: new TextModel({ text: `#(${TimeRecordingComponentKey.EMPLOYEE_DURATION}/entry)` }) }),
      new TableCellModel({ row: 0, column: 1,
        content: new TextModel({ text: `#(${TimeRecordingComponentKey.EMPLOYEE_DURATION}/leave)` }) })
    ];

    periodList.forEach((period: ValidityPeriod, index: number) => {
      const row = index + 1;
      tableCellModels.push(...this.createTableRow(row, period));
    });
    if (periodList.length === 0 || periodList[periodList.length - 1].end !== null) {
      tableCellModels.push(...this.createTableRow(periodList.length + 1, this.createEmptyValidityPeriod()));
    }
    return tableCellModels;
  }


}
