import { Entity, EntityData, EntityFieldKey, EntityModel } from '@typescript-kit/data';

export class BalanceTypes {
  static readonly FLEXTIME = 'flextime';
  static readonly HOLIDAY = 'holiday';
}

export interface BalanceData extends EntityData {
  companyId?: string;
  employeeId?: string;
  type?: string;
  date?: string;
  value?: number;
}

export class BalanceFieldKey extends EntityFieldKey {
  static readonly COMPANY_ID = 'companyId';
  static readonly EMPLOYEE_ID = 'employeeId';
  static readonly TYPE = 'type';
  static readonly DATE = 'date';
  static readonly VALUE = 'value';
}

export class Balance extends Entity implements BalanceData {
  readonly companyId: string;
  readonly employeeId: string;
  readonly type: string;
  readonly date: string;
  readonly value: number;

  constructor(data: BalanceData) {
    super(data);
    this.companyId = data.companyId;
    this.employeeId = data.employeeId;
    this.type = data.type;
    this.date = data.date;
    this.value = data.value;
  }
}

export class BalanceModel extends EntityModel implements BalanceData {
  static readonly KEYS = EntityModel.KEYS.concat(
    Object.keys(BalanceFieldKey).map((key) => BalanceFieldKey[key])
  );

  constructor(data: BalanceData) {
    super(data, BalanceModel.KEYS);
  }

  get employeeId(): string {
    return this.get(BalanceFieldKey.EMPLOYEE_ID);
  }

  get type(): string {
    return this.get(BalanceFieldKey.TYPE);
  }

  get date(): string {
    return this.get(BalanceFieldKey.DATE);
  }

  get value(): number {
    return this.get(BalanceFieldKey.VALUE);
  }

  set value(value: number) {
    this.set(BalanceFieldKey.VALUE, value);
  }
}
