import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { ShellViewService } from '../../../shell/view/shell.view-service';
import { EmployeeCommonViewService } from '../../view-service/employee-common.view-service';
import { TemplateModel } from '@angular-kit/view';
import {
  ActionModel,
  ContextBindings,
  FormFieldModel,
  TextInputModel,
  TextModel,
  ValueModel
} from '@typescript-kit/view';
import { EmployeeCommonViewFieldKey, EmployeeCommonViewModel } from '../../view-model/employee-common.view-model';
import { Subscription } from 'rxjs';
import { ChangedEvent, isEmpty, ObservableList, ObserveModes, TextService } from '@typescript-kit/core';
import { SharedModelKey, TagMapping, TagMappingTagTypes, ValidityPeriod } from '@teamworks/global';
import { TagService } from '../../service/tag.service';
import { DataQuery } from '@typescript-kit/data';
import { MatDialog } from '@angular/material/dialog';
import { AssignTokenDialogComponent } from '../assign-token-dialog/assign-token-dialog.component';
import { TimeRecordingComponentKey } from '../../key/time-recording-component.key';
import { TagMappingTargetTypes } from '@teamworks/global';
import { TagTableModel } from '../../../shared/component-model/tag-table.model';

@Component({
  selector: 'app-employee-detail',
  templateUrl: './employee-detail.component.html',
  styleUrls: ['./employee-detail.component.scss'],
  providers: [
    EmployeeCommonViewService
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmployeeDetailComponent implements OnInit, OnDestroy {

  @ViewChild('sidebarTemplate', { static: true })
  private sidebarTemplate: TemplateRef<any>;

  private viewModelChangedSubscription: Subscription;

  public titleValue: ValueModel;
  public nameField: FormFieldModel;
  public tagList: TagMapping[];
  public authorizationTitle: TextModel;
  public assignTokenAction: ActionModel;
  // public assignPinAction: ActionModel;
  public tagTable: TagTableModel;

  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly shellViewService: ShellViewService,
    private readonly viewService: EmployeeCommonViewService,
    private readonly tagService: TagService,
    private readonly matDialogService: MatDialog,
    private readonly textService: TextService
  ) {
  }

  get viewModel(): EmployeeCommonViewModel {
    return this.viewService.viewModel;
  }

  async ngOnInit(): Promise<void> {
    this.viewService.initialize();
    this.shellViewService.setSidebar(new TemplateModel({ template: this.sidebarTemplate }));
    this.viewModelChangedSubscription = this.viewModel.changedEvent.subscribe(event => this.onViewModelChanged(event));
    await this.createComponentModels();
  }

  ngOnDestroy() {
    this.viewModelChangedSubscription.unsubscribe();
    this.shellViewService.restoreSidebar();
    this.viewService.finalize();
  }

  private async onViewModelChanged(event: ChangedEvent) {
    if (event.changes[EmployeeCommonViewFieldKey.EMPLOYEE]) {
      await this.refreshComponentModels();
    }
  }

  private async createComponentModels() {
    this.titleValue = new ValueModel({
      key: 'name',
      contextBinding: ContextBindings.TWO_WAY
    });
    this.nameField = new FormFieldModel({
      label: true,
      control: new TextInputModel({
        key: 'name', scope: `${SharedModelKey.EMPLOYEE}/field`,
        tags: ['form-control', 'form-control-sm', `app-employee-name`],
        contextBinding: ContextBindings.TWO_WAY,
      })
    });
    this.authorizationTitle = new TextModel({
      isHidden: true,
      text: `#(${TimeRecordingComponentKey.EMPLOYEE_DETAIL}/authorization/title)`
    });
    this.assignTokenAction = new ActionModel({
      tags: ['app-form-btn', 'btn-sm', 'btn-outline-secondary'],
      content: new TextModel({
        text: `#(${TimeRecordingComponentKey.ASSIGN_TOKEN_DIALOG}/title)`
      }),
      isHidden: true,
      onClick: async () => {
        const dialog = this.matDialogService.open(AssignTokenDialogComponent, {
          width: '350px',
          data: { targetId: this.viewModel.employeeId, targetType: TagMappingTargetTypes.EMPLOYEE }
        });
        const newTagMapping = await dialog.afterClosed().toPromise();
        if (newTagMapping !== null && newTagMapping !== undefined) {
          await this.refreshComponentModels();
        }
      }
    });
    /*this.assignPinAction = new ActionModel({
      tags: ['app-form-btn', 'btn-sm', 'btn-outline-secondary'],
      content: new TextModel({
        text: `#(${TimeRecordingComponentKey.ASSIGN_PIN_DIALOG}/title)`
      }),
      isHidden: true,
      onClick: async () => {
        const dialog = this.matDialogService.open(AssignPinDialogComponent, {
          data: { targetId: this.viewModel.employeeId, targetType: TagMappingTargetTypes.EMPLOYEE }
        });
        const newTagMapping = await dialog.afterClosed().toPromise();
        if (newTagMapping !== null && newTagMapping !== undefined) {
          await this.refreshComponentModels();
        }
      }
    });*/
    this.tagTable = new TagTableModel();
    await this.refreshComponentModels();
  }

  private async refreshComponentModels() {

    const employeeModel = this.viewModel.employee;
    const updateValues = {
      context: employeeModel,
      isHidden: !employeeModel,
    };
    this.titleValue.setValues(updateValues);
    this.nameField.setValues(updateValues);
    this.nameField.control.isFocused = !!employeeModel;
    this.assignTokenAction.isHidden = !employeeModel;
    this.authorizationTitle.isHidden = !employeeModel;

    if (employeeModel) {
      const query: DataQuery = { filter: { targetId: this.viewModel.employeeId } };
      this.tagList = null;
      this.tagList = await this.tagService.loadTagMappingList(query);
      this.tagTable.setValues({ tagList: new ObservableList(this.tagList, { observeMode: ObserveModes.FLAT }) });
      // this.assignPinAction.isHidden = this.tagList && this.tagList.filter(mapping => mapping.tagType === 'pin').length > 0;

    } else {
      // this.assignPinAction.isHidden = !employeeModel;
      this.tagList = null;
    }
    this.changeDetector.markForCheck();
  }

  trackByTag(index: number, item: TagMapping) {
    return item.tagId;
  }

  trackByValidityPeriod(index: number, item: ValidityPeriod) {
    return item.id;
  }

  get displayName(): string {
    if (this.viewModel.employee !== null) {
      return !isEmpty(this.viewModel.employee.name) ? this.viewModel.employee.name : this.textService.getText(`${TimeRecordingComponentKey.EMPLOYEE_DETAIL}/employee/no-name-set`);
    }
    return '';
  }

}
