import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { TimeRecordingSharedViewService } from '../../view-service/time-recording-shared.view-service';
import { EmployeeTimeRecordingSharedViewService } from '../../view-service/employee-time-recording-shared.view-service';
import { EmployeeTimeRecordingShellMenuViewService } from '../../view-service/employee-time-recording-shell-menu.view-service';
import { ShellViewService } from '../../../shell/view/shell.view-service';
import { TimeRecordingViewModel } from '../../view-model/time-recording.view-model';
import { EmployeeTimeRecordingSidebarViewService } from '../../view-service/employee-time-recording-sidebar.view-service';
import { EmployeeTimeRecordingDetailViewService } from '../../view-service/employee-time-recording-detail.view-service';
import { EmployeeTimeRecordingDetailComponentModel } from '../../component-model/employee-time-recording-detail.component-model';

@Component({
  selector: 'app-employee-time-recording',
  templateUrl: './employee-time-recording.component.html',
  styleUrls: ['./employee-time-recording.component.scss'],
  providers: [
    TimeRecordingSharedViewService,
    EmployeeTimeRecordingSharedViewService,
    EmployeeTimeRecordingShellMenuViewService,
    EmployeeTimeRecordingSidebarViewService,
    EmployeeTimeRecordingDetailViewService,
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmployeeTimeRecordingComponent implements OnInit, OnDestroy {

  constructor(
    private readonly shellViewService: ShellViewService,
    private readonly timeRecordingSharedViewService: TimeRecordingSharedViewService,
    private readonly employeeTimeRecordingSharedViewService: EmployeeTimeRecordingSharedViewService,
    private readonly employeeTimeRecordingShellMenuViewService: EmployeeTimeRecordingShellMenuViewService,
    private readonly employeeTimeRecordingSidebarViewService: EmployeeTimeRecordingSidebarViewService,
    private readonly employeeTimeRecordingDetailViewService: EmployeeTimeRecordingDetailViewService
  ) {
  }

  get viewModel(): TimeRecordingViewModel {
    return this.employeeTimeRecordingDetailViewService.viewModel;
  }

  get componentModel(): EmployeeTimeRecordingDetailComponentModel {
    return this.employeeTimeRecordingDetailViewService.componentModel;
  }

  ngOnInit() {
    this.timeRecordingSharedViewService.initialize();
    this.employeeTimeRecordingSharedViewService.initialize();
    this.employeeTimeRecordingShellMenuViewService.initialize();
    this.employeeTimeRecordingSidebarViewService.initialize();
    this.employeeTimeRecordingDetailViewService.initialize();
    this.shellViewService.setMenu(this.employeeTimeRecordingShellMenuViewService.componentModel);
    this.shellViewService.setSidebar(this.employeeTimeRecordingSidebarViewService.componentModel);
  }

  ngOnDestroy(): void {
    this.shellViewService.restoreSidebar();
    this.shellViewService.restoreMenu();
    this.employeeTimeRecordingDetailViewService.finalize();
    this.employeeTimeRecordingSidebarViewService.finalize();
    this.employeeTimeRecordingShellMenuViewService.finalize();
    this.employeeTimeRecordingSharedViewService.finalize();
    this.timeRecordingSharedViewService.finalize();
  }

}
