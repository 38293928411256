import { ViewService } from '@angular-kit/view';
import {
  ActionModel,
  ComponentFieldKey,
  ComponentModel,
  ContainerModel,
  DropdownModel,
  ImageModel,
  ListModel,
  Orientations,
  SelectionModes,
  TextModel,
  ViewComponentKey
} from '@typescript-kit/view';
import { Injectable, Injector } from '@angular/core';
import { ChangedEvent, hasValue, isEmpty, LocaleService, ObservableList, ObservableMap, toArray } from '@typescript-kit/core';
import {
  AccountingEntityFieldKey,
  AccountingEntityModel,
  AccountingRecordFieldKey,
  AccountingRecordModel,
  AccountingRecordTypes
} from '@teamworks/global';
import { AccountingViewKey } from '../key/accounting-view.key';
import { AccountingComponentKey } from '../key/accounting-component.key';
import { AccountingCommonViewFieldKey, AccountingCommonViewModel } from '../view-model/accounting-common.view-model';
import { SharedSvgKey } from '../../shared/key/shared-svg.key';
import { AccountingCommonViewService } from './accounting-common.view-service';
import { ShellSvgKey } from '../../shell/key/shell-svg.key';
import { AccountingActionKey } from '../key/accounting-action.key';

@Injectable()
export class AccountingListViewService extends ViewService<AccountingCommonViewModel, ListModel> {

  private readonly localeService: LocaleService;

  constructor(
    injector: Injector,
    private readonly accountingCommonViewService: AccountingCommonViewService,
  ) {
    super(injector);
    this.localeService = injector.get(LocaleService);
  }

  initialize() {
    super.initialize(this.accountingCommonViewService.viewModel);
  }

  protected onViewModelPropertyChanged(event: ChangedEvent): void {
    if (event.originalEvent !== event) {
      if (event.originalEvent.source === this.viewModel.accountingEntityList) {
        this.onAccountingListItemChanged(event.originalEvent);
      }
      return;
    }
    const changes = event.changes;
    if (changes[AccountingCommonViewFieldKey.ACCOUNTING_ENTITY_LIST]) {
      this.onAccountingRecordListChanged(event);
    }
    if (changes[AccountingCommonViewFieldKey.ACCOUNTING_ENTITY_ID]) {
      const itemKey = hasValue(this.viewModel.accountingEntityId) ? this.viewModel.accountingEntityId.toString() : null;
      const selectedItem = this.componentModel.items
        .firstValue((item) => item.key === itemKey);
      this.componentModel.selectedItems = selectedItem ? [selectedItem] : [];
    }
  }

  // noinspection JSUnusedLocalSymbols
  private onAccountingRecordListChanged(event: ChangedEvent) {
    this.refreshComponentModel(this.componentModel);
  }

  private onAccountingListItemChanged(event: ChangedEvent) {
    if (event.originalEvent !== event) {
      if (event.originalEvent.source instanceof AccountingEntityModel) {
        this.onAccountingEntityPropertyChanged(event.originalEvent);
      }
      return;
    }
    this.refreshComponentModel(this.componentModel);
    if (this.componentModel.items.size === 0) {
      this.viewModel.accountingEntityId = null;
    } else if (this.componentModel.selectedItems.length === 0) {
      this.viewModel.accountingEntityId = +this.componentModel.items.get(0).key;
    }
  }

  private onAccountingEntityPropertyChanged(event: ChangedEvent) {
    if (event.originalEvent !== event) {
      if (event.originalEvent.source instanceof AccountingRecordModel) {
        this.onAccountingRecordPropertyChanged(event.originalEvent, event.source as AccountingEntityModel);
      }
      return;
    }
    const recordModel = event.source as AccountingEntityModel;
    const actionKey = event.changes[AccountingEntityFieldKey.INDEX]
      ? event.changes[AccountingEntityFieldKey.INDEX].originalValue.toString()
      : recordModel.index.toString();
    const actionModel = this.componentModel.items.firstValue((item) => item.key === actionKey) as ActionModel;
    if (!actionModel) {
      return;
    }
    const changes = event.changes;
    if (changes[AccountingEntityFieldKey.INDEX]) {
      // refresh (re-order) list
      this.refreshComponentModel(this.componentModel);
    }
  }

  private onAccountingRecordPropertyChanged(event: ChangedEvent, entityModel: AccountingEntityModel) {
    if (event.originalEvent !== event) {
      return;
    }
    const changes = event.changes;
    if (changes[AccountingRecordFieldKey.DESIGNATION]
      || changes[AccountingRecordFieldKey.CUSTOMER]
    ) {
      // refresh (re-order) list
      this.refreshComponentModel(this.componentModel);
    } else {
      switch (entityModel.record.type) {
        case AccountingRecordTypes.OFFERING:
          if (changes[AccountingRecordFieldKey.OFFER_DATE]) {
            const actionModel = this.componentModel.items.firstValue(model => model.key === entityModel.index.toString()) as ActionModel;
            this.refreshAccountingRecordListItemModel(actionModel, entityModel);
          }
          break;
        case AccountingRecordTypes.DELIVERY_NOTE:
        case AccountingRecordTypes.ORDER_CONFIRMATION:
          if (changes[AccountingRecordFieldKey.EXECUTION_DATE]) {
            const actionModel = this.componentModel.items.firstValue(model => model.key === entityModel.index.toString()) as ActionModel;
            this.refreshAccountingRecordListItemModel(actionModel, entityModel);
          }
          break;
        case AccountingRecordTypes.INVOICE:
          if (changes[AccountingRecordFieldKey.INVOICE]) {
            const actionModel = this.componentModel.items.firstValue(model => model.key === entityModel.index.toString()) as ActionModel;
            this.refreshAccountingRecordListItemModel(actionModel, entityModel);
          }
          break;
      }
    }
  }

  protected createComponentModel(): ListModel {
    const listModel = new ListModel({
      type: AccountingComponentKey.ACCOUNTING_LIST,
      tags: ['app-accounting-list'],
      selectionMode: SelectionModes.SINGLE,
      scrollToSelectedItem: true,
      header: this.createAccountingListHeaderModel(),
      items: new ObservableList()
    });
    this.refreshComponentModel(listModel);
    return listModel;
  }

  protected refreshComponentModel(model: ListModel): void {
    const items = this.createAccountingRecordListItemModels();
    const selectedItems = items.filter((item) => item.isActive);
    model.setValues({items, selectedItems});
  }

  private createAccountingListHeaderModel(): ContainerModel {
    return new ContainerModel({
      key: 'accountingListHeader', tags: ['app-accounting-list-header'], orientation: Orientations.SIDE_BY_SIDE,
      items: [
        new TextModel({
          tags: ['app-accounting-list-header-label'],
          text: `#(${AccountingViewKey.ACCOUNTING_LIST}/label)`
        }),
        new ActionModel({
          tags: ['app-accounting-list-header-action'],
          content: new ImageModel({
            type: ViewComponentKey.SVG_SYMBOL,
            source: SharedSvgKey.ADD_DOCUMENT
          }),
          onClick: () => {
            this.createAccountingRecord();
          }
        }),
        new ActionModel({
          tags: ['app-accounting-list-header-action'],
          content: new ImageModel({
            type: ViewComponentKey.SVG_SYMBOL,
            source: SharedSvgKey.DELETE_DOCUMENT
          }),
          isActive: this.viewModel.accountingEntityId != null,
          onClick: () => {
            this.deleteAccountingRecord();
          }
        }),
        new DropdownModel({
          trigger: new ImageModel({
            type: ViewComponentKey.SVG_SYMBOL, source: ShellSvgKey.MENU
          }),
          items: [
            new ActionModel({
              content: new ContainerModel({
                orientation: Orientations.SIDE_BY_SIDE,
                items: [
                  new ImageModel({
                    type: ViewComponentKey.SVG_SYMBOL,
                    source: SharedSvgKey.COPY_DOCUMENT
                  }),
                  new TextModel({
                    // key: 'label', tags: ['app-view-dropdown-text'],
                    text: AccountingActionKey.COPY_DOCUMENT
                  })
                ]
              }),
              onClick: () => {
                this.copyAccountingRecord();
              }
            }),
            new ActionModel({
              content: new ContainerModel({
                orientation: Orientations.SIDE_BY_SIDE,
                items: [
                  new ImageModel({
                    type: ViewComponentKey.SVG_SYMBOL,
                    source: SharedSvgKey.MOVE_FORWARD
                  }),
                  new TextModel({
                    text: AccountingActionKey.MOVE_DOCUMENT_FORWARD
                  })
                ]
              }),
              onClick: () => {
                this.moveAccountingRecordForward();
              }
            }),
            new ActionModel({
              content: new ContainerModel({
                orientation: Orientations.SIDE_BY_SIDE,
                items: [
                  new ImageModel({
                    type: ViewComponentKey.SVG_SYMBOL,
                    source: SharedSvgKey.MOVE_BACKWARD
                  }),
                  new TextModel({
                    text: AccountingActionKey.MOVE_DOCUMENT_BACKWARD
                  })
                ]
              }),
              onClick: () => {
                this.moveAccountingRecordBackward();
              }
            }),
          ]
        })
      ]
    });
  }

  private createAccountingRecordListItemModels(): ActionModel[] {
    return toArray(this.viewModel.accountingEntityList)
      .map((entityModel: AccountingEntityModel) => this.createAccountingEntityListItemModel(entityModel))
      .sort((a: ActionModel, b: ActionModel) => {
        const labelA = this.getTextFromAccountingEntityListItemModel(a);
        const labelB = this.getTextFromAccountingEntityListItemModel(b);
        return labelA.localeCompare(labelB, this.localeService.localeKey, {sensitivity: 'accent'});
      });
  }

  private getTextFromAccountingEntityListItemModel(actionModel: ActionModel): string {
    const items: ObservableMap<ComponentModel> = (actionModel.content as ContainerModel)?.items;
    if (items && items.size > 1) {
      return (items.get('text') as TextModel)?.text || '';
    } else {
      return '';
    }
  }

  private createAccountingEntityListItemModel(entityModel: AccountingEntityModel): ActionModel {
    const index = entityModel.index;
    const isActive = this.viewModel.accountingEntityId === index;
    const model = new ActionModel({
      type: ViewComponentKey.BUTTON, tags: {active: isActive},
      key: index.toString(),
      content: new ContainerModel({
        orientation: Orientations.SIDE_BY_SIDE,
        items: [
          new TextModel({
            key: 'text'
          }),
          new ImageModel({
            key: 'icon',
            type: ViewComponentKey.SVG_SYMBOL,
            source: SharedSvgKey.DONE,
            isHidden: true
          }),
        ]
      }),
      isActive,
      onClick: () => this.viewModel.accountingEntityId = entityModel.index,
      onChanged: (event: ChangedEvent) => {
        if (event.changes[ComponentFieldKey.IS_ACTIVE]) {
          model.tags.set('active', event.changes[ComponentFieldKey.IS_ACTIVE].currentValue);
        }
      }
    });
    this.refreshAccountingRecordListItemModel(model, entityModel);
    return model;
  }

  private refreshAccountingRecordListItemModel(model: ActionModel, entityModel: AccountingEntityModel) {
    model.set(ComponentFieldKey.KEY, entityModel.index.toString());
    const textModel = (model.content as ContainerModel).items.get('text') as TextModel;
    if (entityModel.index === 0) {
      textModel.text = `#(${AccountingViewKey.ACCOUNTING_LIST}/new-record)`;
    } else {
      const content: string[] = [];
      if (entityModel.record.designation) {
        content.push(entityModel.record.designation);
      }
      if (entityModel.record.customer) {
        content.push(entityModel.record.customer);
      }
      textModel.text = content.join(' - ') || '-';
      const iconModel = (model.content as ContainerModel).items.get('icon') as ImageModel;
      iconModel.isHidden = !this.isAccountingRecordDone(entityModel);
    }
  }

  private isAccountingRecordDone(entityModel: AccountingEntityModel) {
    switch (entityModel.record.type) {
      case AccountingRecordTypes.OFFERING:
        return !isEmpty(entityModel.record.offerDate);
      case AccountingRecordTypes.DELIVERY_NOTE:
      case AccountingRecordTypes.ORDER_CONFIRMATION:
        return !isEmpty(entityModel.record.executionDate);
      case AccountingRecordTypes.INVOICE:
        return !isEmpty(entityModel.record.invoice);
    }
    return false;
  }

  private createAccountingRecord(): void {
    this.accountingCommonViewService.createAccountingEntity();
  }

  private async deleteAccountingRecord() {
    if (!this.viewModel.accountingRecord) {
      return;
    }
    this.accountingCommonViewService.deleteAccountingEntity(this.viewModel.accountingEntity);
  }

  private async copyAccountingRecord() {
    if (!this.viewModel.accountingRecord) {
      return;
    }
    this.accountingCommonViewService.copyAccountingEntity(this.viewModel.accountingEntity);
  }

  private async moveAccountingRecordForward() {
    if (!this.viewModel.accountingRecord) {
      return;
    }
    await this.accountingCommonViewService.moveAccountingEntityForward(this.viewModel.accountingEntity);
  }

  private async moveAccountingRecordBackward() {
    if (!this.viewModel.accountingRecord) {
      return;
    }
    await this.accountingCommonViewService.moveAccountingEntityBackward(this.viewModel.accountingEntity);
  }

}
