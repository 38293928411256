<h1><strong>Einwilligung zur Datenverarbeitung</strong></h1>
<p>
  Der TeamWorks-Benutzer stimmt zu, dass seine persönlichen Daten, nämlich Rechnungsinformationen (Firma, Anschrift,
  Kontaktperson, UID-Nummer), Mitarbeiterdaten (Name, Eintrtts- und Austrittsdatum, E-Mail-Adresse), aufgezeichnete
  Arbeitszeiten (Datum, Uhrzeit von-bis, Iststunden, Sollstunden, Projektzuordnung, Tätigkeitsbeschreibung,
  Krankenstandszeiten, Urlaubszeiten) sowie Lohnverrechnungsdaten (Stundenlohn, Gehalt, Zuschläge und Kostenersätze) zum
  Zweck der Arbeitszeitaufzeichnung und Lohnverrechnung mit dem Produkt TeamWorks bei der Firma Signum Plus GmbH
  verarbeitet werden. An Dritte werden diese Daten nicht weitergegeben.<br>
  Diese Einwilligung kann jederzeit bei der Signum Plus GmbH,
  <a href="mailto:office@signum-plus.at" target="_blank" rel="noopener noreferrer">mailto:office@signum-plus.at</a>, +43
  732 90225950 oder implizit durch das Löschen des Teamworks-Benutzerkontos widerrufen werden. Durch den Widerruf wird
  die Rechtmäßigkeit der bis dahin erfolgten Verarbeitung nicht berührt.
</p>
