import { Change, ChangeOptions, hasValue } from '@typescript-kit/core';
import { Entity, EntityData, EntityFieldKey, EntityModel } from '@typescript-kit/data';

export interface WorkingHoursRecordData extends EntityData {
  companyId?: string;
  employeeId?: string;
  index?: number;
  projectId?: string;
  weekDay?: number;
  hours?: number;
}

export class WorkingHoursRecordFieldKey extends EntityFieldKey {
  static readonly COMPANY_ID = 'companyId';
  static readonly EMPLOYEE_ID = 'employeeId';
  static readonly INDEX = 'index';
  static readonly PROJECT_ID = 'projectId';
  static readonly WEEKDAY = 'weekDay';
  static readonly HOURS = 'hours';
}

export class WorkingHoursRecord extends Entity implements WorkingHoursRecordData {
  readonly companyId: string;
  readonly employeeId: string;
  readonly index: number;
  readonly projectId: string;
  readonly weekDay: number;
  readonly hours: number;

  constructor(data: WorkingHoursRecordData) {
    super(data);
    this.companyId = data.companyId;
    this.employeeId = data.employeeId;
    this.index = data.index;
    this.projectId = data.projectId;
    this.weekDay = data.weekDay;
    this.hours = data.hours;
  }
}

export class WorkingHoursRecordModel extends EntityModel implements WorkingHoursRecordData {
  static readonly KEYS = EntityModel.KEYS.concat(
    Object.keys(WorkingHoursRecordFieldKey).map((key) => WorkingHoursRecordFieldKey[key])
  );

  constructor(data: WorkingHoursRecordData) {
    super(data, WorkingHoursRecordModel.KEYS);
  }

  get companyId(): string {
    return this.get(WorkingHoursRecordFieldKey.COMPANY_ID);
  }

  get employeeId(): string {
    return this.get(WorkingHoursRecordFieldKey.EMPLOYEE_ID);
  }

  get index(): number {
    return this.get(WorkingHoursRecordFieldKey.INDEX);
  }

  get projectId(): string {
    return this.get(WorkingHoursRecordFieldKey.PROJECT_ID);
  }

  set projectId(value: string) {
    this.set(WorkingHoursRecordFieldKey.PROJECT_ID, value);
  }

  get weekDay(): number {
    return this.get(WorkingHoursRecordFieldKey.WEEKDAY);
  }

  set weekDay(value: number) {
    this.set(WorkingHoursRecordFieldKey.WEEKDAY, value);
  }

  get hours(): number {
    return this.get(WorkingHoursRecordFieldKey.HOURS);
  }

  set hours(value: number) {
    this.set(WorkingHoursRecordFieldKey.HOURS, value);
  }

  setValues(values: WorkingHoursRecordData, options?: ChangeOptions): { [p: string]: Change<any> } {
    return super.setValues(values, options);
  }

  protected setupValues(values: WorkingHoursRecordData, exclusive: boolean): WorkingHoursRecordData {
    if (WorkingHoursRecordFieldKey.WEEKDAY in values) {
      if (!hasValue(values[WorkingHoursRecordFieldKey.WEEKDAY])) {
        if (!(WorkingHoursRecordFieldKey.HOURS in values)) {
          values.hours = null;
        }
        if (!(WorkingHoursRecordFieldKey.PROJECT_ID in values)) {
          values.projectId = null;
        }
      }
    }
    return super.setupValues(values, exclusive) as WorkingHoursRecordData;
  }
}
