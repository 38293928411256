import {
  Component,
  OnInit,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  OnDestroy,
  HostBinding,
  ChangeDetectorRef,
  ViewChild,
  TemplateRef
} from '@angular/core';
import { AlertContainerViewService } from '@angular-kit/view';
import { ShellViewService } from '../../view/shell.view-service';
import { ShellComponentModel } from './shell-component.model';
import { Subscription } from 'rxjs';
import { AlertKinds, AlertService, ChangedEvent } from '@typescript-kit/core';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
  providers: [ShellViewService, AlertContainerViewService],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShellComponent implements OnInit, OnDestroy {
  @ViewChild('alertContainer', { static: true })
  public alertContainer: TemplateRef<any>;

  private subscriptions: Subscription[] = [];

  constructor(
    private readonly alertService: AlertService,
    private readonly changeDetector: ChangeDetectorRef,
    public readonly viewService: ShellViewService
  ) { }

  @HostBinding('class.app-shell-menu-visible')
  get isMenuVisible(): boolean {
    return this.viewService.isInitialized
      && this.viewService.componentModel.isMenuVisible;
  }

  @HostBinding('class.app-shell-sidebar-visible')
  get isSidebarVisible(): boolean {
    return this.viewService.isInitialized
      && this.viewService.componentModel.isSidebarVisible
      && !!this.viewService.componentModel.sidebarModel;
  }

  @HostBinding('class.app-shell-print')
  get isPrinting(): boolean {
    return this.viewService.isInitialized
      && this.viewService.viewModel.isPrinting;
  }

  get model(): ShellComponentModel {
    return this.viewService.componentModel;
  }

  get helpLink(): string {
    return `https://teamworks-help.signum-plus.at/category/help/${this.viewService.viewModel?.view?.helpSection ?? ''}`;
  }

  ngOnInit() {
    this.viewService.initialize();
    this.subscriptions.push(this.model.changedEvent
      .subscribe((event) => this.onModelChanged(event)));
    this.subscriptions.push(this.alertService.alertsChanged
      .subscribe((event) => {
        if (event.addedAlerts.length > 0 && event.addedAlerts.find((a) => a.kind === AlertKinds.ERROR)) {
          const element = this.alertContainer.elementRef.nativeElement as HTMLElement;
          setTimeout(() => element.scrollIntoView(), 0);
        }
      }));
  }

  ngOnDestroy(): void {
    this.viewService.finalize();
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  private onModelChanged(event: ChangedEvent) {
    if (event.originalEvent !== event) {
      return;
    }
    this.changeDetector.markForCheck();
  }

}
