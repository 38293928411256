import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { ShellViewService } from '../../../shell/view/shell.view-service';
import { TemplateModel } from '@angular-kit/view';
import {
  ActionModel,
  ContainerModel,
  ContextBindings,
  FormFieldModel,
  Orientations,
  TextInputModel,
  TextModel,
  ValueModel
} from '@typescript-kit/view';
import { Subscription } from 'rxjs';
import { ChangedEvent, isEmpty, TextService, toArray } from '@typescript-kit/core';
import { UserCommonViewService } from '../../view/user-common.view-service';
import { UserCommonViewFieldKey, UserCommonViewModel } from '../../view/user-common.view-model';
import { SharedComponentKey } from '../../key/shared-component.key';
import { AuthenticationModelKey } from '@typescript-kit/authentication';
import { CheckboxModel } from '@typescript-kit/view';
import { SharedPermissionKey } from '@teamworks/global';
import { AuthenticationService } from '@angular-kit/authentication';
import { EmployeePermissionsDialogComponent } from '../employee-permissions-dialog/employee-permissions-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ProjectPermissionsDialogComponent } from '../project-permissions-dialog/project-permissions-dialog.component';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss'],
  providers: [
    UserCommonViewService
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserDetailComponent implements OnInit, OnDestroy {
  public readonly permissionsText = `${SharedComponentKey.USER_DETAIL}/permissions`;

  public titleValue: ValueModel;
  public nameField: FormFieldModel;
  public userNameField: FormFieldModel;
  public emailField: FormFieldModel;
  public permissionModels: { container: ContainerModel, field: CheckboxModel; }[] = [];
  public saveNewUserAction: ActionModel;
  public sendPasswordEmailAction: ActionModel;

  @ViewChild('sidebarTemplate', { static: true })
  private sidebarTemplate: TemplateRef<any>;

  private viewModelChangedSubscription: Subscription;
  private isProjectAdmin: boolean = false;

  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly shellViewService: ShellViewService,
    private readonly viewService: UserCommonViewService,
    private readonly textService: TextService,
    private readonly authService: AuthenticationService,
    private readonly matDialogService: MatDialog
  ) {
  }

  get creatingNewUser(): boolean {
    return this.viewModel.userId === null;
  }

  get viewModel(): UserCommonViewModel {
    return this.viewService.viewModel;
  }

  async ngOnInit(): Promise<void> {
    this.viewService.initialize();
    this.shellViewService.setSidebar(new TemplateModel({ template: this.sidebarTemplate }));
    this.viewModelChangedSubscription = this.viewModel.changedEvent.subscribe(event => this.onViewModelChanged(event));
    await this.createComponentModels();
  }

  ngOnDestroy() {
    this.viewModelChangedSubscription.unsubscribe();
    this.shellViewService.restoreSidebar();
    this.viewService.finalize();
  }

  private async onViewModelChanged(event: ChangedEvent) {
    if (event.changes[UserCommonViewFieldKey.USER]
      || event.changes[UserCommonViewFieldKey.PERMISSION_LIST]
      || event.changes[UserCommonViewFieldKey.EMPLOYEE_PERMISSION_LIST]
      || event.changes[UserCommonViewFieldKey.PROJECT_PERMISSIONS]) {
      await this.refreshComponentModels();
    }
  }

  private async createComponentModels() {
    this.titleValue = new ValueModel({
      key: 'name',
      contextBinding: ContextBindings.TWO_WAY
    });
    this.nameField = new FormFieldModel({
      label: true,
      control: new TextInputModel({
        key: 'name', scope: `${AuthenticationModelKey.USER}/field`,
        tags: ['form-control', 'form-control-sm', `app-user-name`],
        contextBinding: ContextBindings.TWO_WAY
      })
    });
    this.userNameField = new FormFieldModel({
      label: true,
      control: new TextInputModel({
        key: 'userName', scope: `${AuthenticationModelKey.USER}/field`,
        tags: ['form-control', 'form-control-sm', 'app-user-username'],
        contextBinding: ContextBindings.TWO_WAY
      })
    });
    this.emailField = new FormFieldModel({
      label: true,
      control: new TextInputModel({
        key: 'email', scope: `${AuthenticationModelKey.USER}/field`,
        tags: ['form-control', 'form-control-sm', 'app-user-mail'],
        contextBinding: ContextBindings.TWO_WAY
      })
    });
    this.saveNewUserAction = new ActionModel({
      tags: ['app-form-btn', 'btn-sm', 'btn-outline-secondary'],
      content: new TextModel({
        text: `#(${SharedComponentKey.USER_DETAIL}/save-new-user)`
      }),
      onClick: () => this.saveNewUser()
    });
    this.sendPasswordEmailAction = new ActionModel({
      tags: ['app-form-btn', 'btn-sm', 'btn-outline-secondary'],
      content: new TextModel({
        text: `#(${SharedComponentKey.USER_DETAIL}/send-pasword-email)`
      }),
      onClick: () => this.sendPasswordEmail()
    });

    await this.refreshComponentModels();
  }

  private async refreshComponentModels() {
    const userModel = this.viewModel.user;
    let updateValues: any = {
      context: userModel,
      isHidden: !userModel
    };

    this.titleValue.setValues(updateValues);

    this.nameField.setValues(updateValues);
    this.nameField.control.isFocused = !!userModel;
    this.userNameField.setValues(updateValues);
    this.emailField.setValues(updateValues);

    const permissionArray = toArray(this.viewModel.permissionList);
    const permissionSet = new Set(permissionArray.filter((p) => p.granted).map((p) => p.key));
    this.permissionModels = toArray(this.viewModel.permissionList).map((permission) => {
      const items = [];

      const field = new CheckboxModel({
        label: true,
        key: permission.key,
        tags: ['app-user-permission'],
        checked: permission.granted,
        onCheckedChanged: () => this.updatePermissions(),
        isDisabled: (permission.key === SharedPermissionKey.USER_ADMIN && this.isCurrentUser())
          || (permissionSet.has(SharedPermissionKey.USER_ADMIN) && permission.key === SharedPermissionKey.EMPLOYEE_ADMIN)
      });
      items.push(field);

      if (permission.key === SharedPermissionKey.PROJECT_ADMIN) {
        items.push(this.createProjectPermissionsContainer());
      } else if (permission.key === SharedPermissionKey.EMPLOYEE_ADMIN) {
        items.push(this.createEmployeePermissionsContainer());
      }

      return {
        container: new ContainerModel({
          tags: ['form-group'],
          items,
          isHidden: !userModel || this.creatingNewUser
        }),
        field
      };
    });

    this.sendPasswordEmailAction.setValues({ isHidden: this.creatingNewUser || !userModel });
    this.saveNewUserAction.setValues({ isHidden: !this.creatingNewUser || !userModel });

    this.changeDetector.markForCheck();
  }

  private createEmployeePermissionsContainer() {
    return new ContainerModel({
      tags: ['app-user-detail-employee-permissions'],
      items: [
        new TextModel({
          text: `#(${SharedComponentKey.USER_DETAIL}/employee-permissions)`,
          textArgs: {
            permissions: this.employeePermissionsText
          }
        }),
        new ActionModel({
          tags: ['app-form-btn', 'btn-sm', 'btn-outline-secondary'],
          content: new TextModel({
            text: `#(${SharedComponentKey.USER_DETAIL}/change-employee-permissions)`
          }),
          onClick: async () => {
            await this.viewService.refreshEmployeeList();
            await this.viewService.refreshEmployeePermissionList();

            const dialog = this.matDialogService.open(EmployeePermissionsDialogComponent, {
              width: '350px',
              data: {
                userId: this.viewModel.userId,
                employees: toArray(this.viewModel.employeeList),
                employeePermissions: this.viewModel.employeePermissionList === 'all'
                  ? 'all'
                  : toArray(this.viewModel.employeePermissionList)
              }
            });
            const result = await dialog.afterClosed().toPromise();
            if (result !== null) {
              await this.viewService.refreshEmployeePermissionList();
            }
          }
        })
      ],
      orientation: Orientations.SIDE_BY_SIDE
    });
  }

  private createProjectPermissionsContainer() {
    this.isProjectAdmin = this.permissionModels.find((f) => f.field.key === SharedPermissionKey.PROJECT_ADMIN && f.field.checked) !== undefined;
    const permissionsText = this.projectPermissionsText;
    return new ContainerModel({
      tags: ['app-user-detail-project-permissions'],
      items: [
        new TextModel({
          text: `#(${SharedComponentKey.USER_DETAIL}/project-permissions-visible)`,
          textArgs: {
            permissions: permissionsText[0]
          }
        }),
        new TextModel({
          text: `#(${SharedComponentKey.USER_DETAIL}/project-permissions-overview)`,
          textArgs: {
            permissions: permissionsText[1]
          }
        }),
        new ActionModel({
          tags: ['app-form-btn', 'btn-sm', 'btn-outline-secondary'],
          content: new TextModel({
            text: `#(${SharedComponentKey.USER_DETAIL}/change-project-permissions)`
          }),
          onClick: async () => {
            await this.viewService.refreshProjectList();
            await this.viewService.refreshProjectPermissions();

            const dialog = this.matDialogService.open(ProjectPermissionsDialogComponent, {
              width: '500px',
              data: {
                userId: this.viewModel.userId,
                isProjectAdmin: this.isProjectAdmin,
                projects: toArray(this.viewModel.projectList),
                projectPermissions: this.viewModel.projectPermissions
              }
            });
            const result = await dialog.afterClosed().toPromise();
            if (result !== null) {
              await this.viewService.refreshProjectPermissions();
            }
          }
        })
      ],
      orientation: Orientations.SIDE_BY_SIDE
    });
  }

  private async saveNewUser(): Promise<void> {
    const userModel = this.viewModel.user;
    userModel.isActive = true;
    await this.viewService.saveNewUser(userModel);
  }

  private async updatePermissions(): Promise<void> {
    const permissions = this.permissionModels
      .filter((container) => container.field.checked)
      .map((container) => container.field.key);

    if (permissions.includes(SharedPermissionKey.USER_ADMIN) && !permissions.includes(SharedPermissionKey.EMPLOYEE_ADMIN)) {
      const permission = this.permissionModels.find((c) => c.field.key === SharedPermissionKey.EMPLOYEE_ADMIN);
      if (permission !== undefined) {
        permission.field.checked = true;
      }

      permissions.push(SharedPermissionKey.EMPLOYEE_ADMIN);
    }

    await this.viewService.updatePermissions(permissions);

    this.createProjectPermissionsContainer();
  }

  private async sendPasswordEmail(): Promise<void> {
    this.sendPasswordEmailAction.setValues({ isDisabled: true });

    await this.viewService.sendPasswordEmail();

    this.sendPasswordEmailAction.setValues({ isDisabled: false });
  }

  private isCurrentUser(): boolean {
    return this.authService.currentUser.id === this.viewModel.userId;
  }

  get displayName(): string {
    if (this.viewModel.user !== null) {
      return isEmpty(this.viewModel.user.name)
        ? this.textService.getText(`${SharedComponentKey.USER_DETAIL}/user/no-name-set`)
        : this.viewModel.user.name;
    }

    return '';
  }

  get employeePermissionsText(): string {
    const permissions = this.viewModel.employeePermissionList;
    if (permissions === 'all') {
      return this.textService.getText(`${SharedComponentKey.USER_DETAIL}/employee-permissions-all`);
    }

    const permissionsArray = toArray(permissions);
    if (permissionsArray.length === 0) {
      return this.textService.getText(`${SharedComponentKey.USER_DETAIL}/employee-permissions-none`);
    }

    return permissionsArray.map((p) => p.name).join(', ');
  }

  get projectPermissionsText(): [string, string] {
    const permissions = Object.values(this.viewModel.projectPermissions ?? {});

    const viewProjects = this.isProjectAdmin ? ['*'] : permissions.filter((p) => p.permission).map((p) => p.name);
    const overviewProjects = permissions.filter((p) => p.permission === 'overview').map((p) => p.name);

    const allText = this.textService.getText(`${SharedComponentKey.USER_DETAIL}/project-permissions-all`);
    const noneText = this.textService.getText(`${SharedComponentKey.USER_DETAIL}/project-permissions-none`);

    return [viewProjects, overviewProjects].map((p) => p.length > 0
      ? p.includes('*')
        ? allText
        : p.join(', ')
      : noneText
    ) as [string, string];
  }
}
