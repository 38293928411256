<form class="app-reset-password-form" novalidate>
  <h1 class="h2 mt-2 font-weight-normal" kitText="{{titleText}}"></h1>
  <p class="text-muted app-developed-by" kitText="{{developedByText}}"></p>
  <img class="mb-5 app-logo" src="{{logoKey}}" alt="">

  <label [kitValueLabel]="emailInput" for="inputEmail" class="sr-only"></label>
  <input #email [kitModel]="emailInput" id="inputEmail" class="form-control">

  <button [kitModel]="resetPasswordAction" class="mt-4 btn btn-lg btn-primary btn-block" type="submit"></button>

  <a href [kitModel]="loginAction" class="app-login"></a>

  <p class="mt-5 mb-2 text-muted">
    <span>© <span [kitText]="vendorName"></span></span>
    <br>
    <a href="https://www.signum-plus.at">www.signum-plus.at</a>
  </p>
</form>
