import { ViewService } from '@angular-kit/view';
import { SalaryOverviewViewData, SalaryOverviewViewFieldKey, SalaryOverviewViewModel } from './salary-overview.view-model';
import {
  Alignments,
  ContainerModel,
  ContextBindings,
  TableCellModel,
  TableColumnModel,
  TableModel,
  TableRowModel,
  TextInputModel,
  ValueModel
} from '@typescript-kit/view';
import { Injectable, Injector } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { PersistenceService } from '@angular-kit/core';
import { ShellViewService } from '../../shell/view/shell.view-service';
import { SalaryService } from '../service/salary.service';
import { ChangedEvent } from '@typescript-kit/core';
import { SalaryCommonViewService } from './salary-common.view-service';
import {
  SalaryOverviewRecordData,
  SalaryOverviewRecordFieldKey,
  SalaryOverviewRecordModel
} from '@teamworks/global';
import { SalaryRecord, SalaryRecordModel } from '@teamworks/global';
import { MonthSelectViewFieldKey, MonthSelectViewModel } from '../../shared/view/month-select.view-model';
import { SharedComponentKey } from '../../shared/key/shared-component.key';
import { EntityService } from '../../shared/service/entity.service';


@Injectable()
export class SalaryOverviewViewService extends ViewService<SalaryOverviewViewModel, ContainerModel> {
  private static readonly SAVE_SALARY_RECORD_DELAY = 100;

  private commonViewModelPropertyChangedSubscription: Subscription;
  private loadingCounter: number;
  private columnFieldKeys: string[];
  private inputFieldKeys: Set<string>;
  private selectedRow: TableRowModel;
  private selectedColumn: TableColumnModel;

  constructor(
    injector: Injector,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly persistenceService: PersistenceService,
    private readonly entityService: EntityService,
    private readonly salaryService: SalaryService,
    private readonly shellViewService: ShellViewService,
    private readonly commonViewService: SalaryCommonViewService
  ) {
    super(injector);
    this.loadingCounter = 0;
    this.columnFieldKeys = SalaryOverviewRecordModel.KEYS.slice(5);
    this.inputFieldKeys = new Set([
      SalaryOverviewRecordFieldKey.HOUR_RATE,
      SalaryOverviewRecordFieldKey.SALARY,
      SalaryOverviewRecordFieldKey.HOLIDAY_SUBVENTION,
      SalaryOverviewRecordFieldKey.CHRISTMAS_REMUNERATION,
      SalaryOverviewRecordFieldKey.HOLIDAY_COMPENSATION,
      SalaryOverviewRecordFieldKey.TRAVEL_COSTS_REMUNERATION,
      SalaryOverviewRecordFieldKey.TRAVEL_COSTS_COMPENSATION,
      SalaryOverviewRecordFieldKey.SALARY_TRANSFORMATION,
      SalaryOverviewRecordFieldKey.NON_CASH_BENEFITS_CAR,
      SalaryOverviewRecordFieldKey.NON_CASH_BENEFITS_OTHER,
    ]);
  }

  protected get commonViewModel(): MonthSelectViewModel {
    return this.commonViewService.viewModel;
  }

  initialize(viewData?: SalaryOverviewViewData) {
    const viewModel = viewData instanceof SalaryOverviewViewModel ? viewData : new SalaryOverviewViewModel(viewData);
    super.initialize(viewModel);
    this.commonViewModelPropertyChangedSubscription = this.commonViewService.viewModel.changedEvent
      .subscribe((event) => this.onCommonViewModelPropertyChanged(event));
    this.refreshSalaryOverviewRecordList();
  }

  finalize() {
    this.commonViewModelPropertyChangedSubscription.unsubscribe();
    this.shellViewService.viewModel.isLoading = false;
    super.finalize();
  }

  protected onCommonViewModelPropertyChanged(event: ChangedEvent) {
    if (event.originalEvent !== event) {
      return;
    }
    if (event.changes[MonthSelectViewFieldKey.MONTH] || event.changes[MonthSelectViewFieldKey.YEAR]) {
      this.refreshSalaryOverviewRecordList();
    }
  }

  protected onViewModelChanged() {
    super.onViewModelChanged();
  }

  protected onViewModelPropertyChanged(event: ChangedEvent) {
    if (event.originalEvent !== event) {
      if (event.originalEvent.source === this.viewModel.salaryOverviewRecordList) {
        this.onSalaryOverviewRecordModelChanged(event.originalEvent);
      }
      return;
    }
    super.onViewModelPropertyChanged(event);
  }

  protected onSalaryOverviewRecordModelChanged(event: ChangedEvent) {
    if (event.originalEvent !== event) {
      if (event.originalEvent.source instanceof SalaryOverviewRecordModel) {
        this.onSalaryOverviewRecordModelPropertyChanged(event.originalEvent);
      }
      return;
    }
    this.refreshComponentModel(this.componentModel);
  }

  protected onSalaryOverviewRecordModelPropertyChanged(event: ChangedEvent) {
    if (event.originalEvent !== event) {
      return;
    }
    if (SalaryRecordModel.KEYS.find((fieldKey) => fieldKey in event.changes)) {
      this.scheduleSaveSalaryRecord(event.source);
    }
  }

  private scheduleSaveSalaryRecord(salaryOverviewRecord: SalaryOverviewRecordModel) {
    this.entityService.scheduleModify(
      salaryOverviewRecord,
      () => this.salaryService.saveSalaryRecord(new SalaryRecord(salaryOverviewRecord)),
      SalaryOverviewViewService.SAVE_SALARY_RECORD_DELAY
    );
  }

  private async refreshSalaryOverviewRecordList() {
    try {
      this.incrementLoadingCounter();
      this.viewModel.setValues({
        [SalaryOverviewViewFieldKey.SALARY_OVERVIEW_RECORD_LIST]: await this.loadSalaryOverviewRecordList()
      });
    } catch (error) {
      if (!this.isFinalized) {
        this.alertService.alertError(error);
      }
      this.viewModel.setValues({
        [SalaryOverviewViewFieldKey.SALARY_OVERVIEW_RECORD_LIST]: null
      });
    } finally {
      this.decrementLoadingCounter();
    }
  }

  private async loadSalaryOverviewRecordList(): Promise<SalaryOverviewRecordData[]> {
    const year = this.commonViewModel.year;
    const month = this.commonViewModel.month;
    if (!month || !year) {
      return undefined;
    }
    return this.salaryService.loadSalaryOverviewRecordList(year, month);
  }

  protected createComponentModel(): ContainerModel {
    return new ContainerModel({
      tags: ['app-salary-overview-container'],
      items: [
        this.createSalaryOverviewTableModel()
      ]
    });
  }

  protected refreshComponentModel(componentModel: ContainerModel) {
    this.refreshSalaryOverviewTableModel(componentModel.items.get('salaryOverviewTable') as TableModel);
  }

  private createSalaryOverviewTableModel(): TableModel {
    return new TableModel({
      key: 'salaryOverviewTable', tags: ['app-salary-overview-table'],
      type: SharedComponentKey.CUSTOM_TABLE,
      columnHeaderCount: 1,
      rowHeaderCount: 1,
      columns: this.createSalaryOverviewTableColumnModels(),
      cells: this.createSalaryOverviewTableCellModels()
    });
  }

  private refreshSalaryOverviewTableModel(tableModel: TableModel) {
    tableModel.setValues({
      cells: this.createSalaryOverviewTableCellModels()
    });
  }

  private createSalaryOverviewTableColumnModels(): TableColumnModel[] {
    return this.columnFieldKeys.map((fieldKey, index) => new TableColumnModel(
      { index, tags: `app-field-${fieldKey}` }
    ));
  }

  private createSalaryOverviewTableCellModels(): TableCellModel[] {
    const cellModels = this.columnFieldKeys.map((fieldKey, index) => new TableCellModel(
      { row: 0, column: index, content: `salary/view/salary-overview/column/${fieldKey}` }
    ));
    this.viewModel.salaryOverviewRecordList.forEach((recordModel, recordIndex) => {
      cellModels.push(...this.columnFieldKeys.map(
        (fieldKey, fieldIndex) => this.createSalaryOverviewRecordFieldCellModel(recordIndex + 1, fieldIndex, recordModel, fieldKey)
      ));
    });
    return cellModels;
  }

  private createSalaryOverviewRecordFieldCellModel(row: number, column: number, recordModel: SalaryOverviewRecordModel, fieldKey: string) {
    const tableCellModel = !this.inputFieldKeys.has(fieldKey)
      ? this.createSalaryOverviewRecordValueCellModel(row, column, recordModel, fieldKey)
      : this.createSalaryOverviewRecordInputCellModel(row, column, recordModel, fieldKey);
    tableCellModel.setValues({
      onClick: (event, model) => this.onTableCellClick(event, model)
    });
    return tableCellModel;
  }

  private onTableCellClick(event: Event, model: TableCellModel) {
    const tableModel = this.componentModel.items.get('salaryOverviewTable') as TableModel;
    const rowModel = tableModel.getRow(model.row);
    const columnModel = tableModel.getColumn(model.column);
    if (this.selectedRow !== rowModel) {
      if (this.selectedRow) {
        this.selectedRow.tags.remove('app-selected-row');
      }
      rowModel.tags.set('app-selected-row', true);
      this.selectedRow = rowModel;
    }
    if (this.selectedColumn !== columnModel) {
      if (this.selectedColumn) {
        this.selectedColumn.tags.remove('app-selected-column');
      }
      columnModel.tags.set('app-selected-column', true);
      this.selectedColumn = columnModel;
    }
  }

  private createSalaryOverviewRecordValueCellModel(row: number, column: number, recordModel: SalaryOverviewRecordModel, fieldKey: string) {
    return new TableCellModel({
      row, column, content: new ValueModel({
        key: fieldKey,
        format: 'number-2',
        alignment: column > 0 ? Alignments.RIGHT : Alignments.LEFT,
        context: recordModel,
        contextBinding: ContextBindings.CONTEXT_TO_MODEL
      })
    });
  }

  private createSalaryOverviewRecordInputCellModel(row: number, column: number, recordModel: SalaryOverviewRecordModel, fieldKey: string) {
    return new TableCellModel({
      row, column, content: new TextInputModel({
        key: fieldKey,
        format: 'input-decimal-2',
        alignment: Alignments.RIGHT,
        context: recordModel,
        contextBinding: ContextBindings.TWO_WAY,
        autoselect: true
      })
    });
  }

  private incrementLoadingCounter() {
    this.loadingCounter++;
    this.shellViewService.viewModel.isLoading = true;
  }

  private decrementLoadingCounter() {
    this.loadingCounter--;
    if (this.loadingCounter === 0) {
      this.shellViewService.viewModel.isLoading = false;
    } else if (this.loadingCounter < 0) {
      throw new Error('Loading counter must not be less than zero');
    }
  }
}
