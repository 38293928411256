import { NumberFormatter } from '@typescript-kit/core';
import { Big } from 'big.js';

export class HoursFormatter extends NumberFormatter {

  parse(value: string): number | string | Big {
    const hoursMatch = /^\s*(\d*)\s*:\s*(\d*)\s*$/.exec(value);
    if (!hoursMatch) {
      return super.parse(value);
    }
    const hours = hoursMatch[1] ? +hoursMatch[1] : 0;
    const minutes = hoursMatch[2] ? +hoursMatch[2] : 0;
    const result = new Big(hours).times(60).plus(minutes).div(60);
    switch (this.options.parseTo) {
      case 'number':
        return result.toNumber();
      case 'string':
        return result.toString();
      case 'big':
        return result;
    }
  }
}
