import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActionModel, TextModel } from '@typescript-kit/view';
import { Project, ProjectModel } from '@teamworks/global';
import { TimeRecordingViewKey } from '../../key/time-recording-view.key';
import { ProjectAutoCompleteInputModel } from '../../component-model/project-auto-complete-input.component-model';

@Component({
  selector: 'app-delete-project-dialog',
  templateUrl: 'delete-project-dialog.component.html',
  styleUrls: ['delete-project-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeleteProjectDialogComponent implements OnInit {
  public readonly titleText: string;
  public readonly replacementText: string;
  public readonly deleteText: string;
  public readonly deleteAndReplaceText: string;
  public readonly cancelText: string;

  public projectAutoComplete: ProjectAutoCompleteInputModel;
  public deleteAction: ActionModel;
  public cancelAction: ActionModel;

  private readonly projects: ProjectModel[];

  constructor(
    private readonly dialog: MatDialogRef<DeleteProjectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private readonly data
  ) {
    this.projects = this.data.projects.filter((p: Project) => p.isActive && p.isConfirmed && p.name !== data.nameToReplace);
    this.titleText = `${TimeRecordingViewKey.DELETE_PROJECT_DIALOG}/title`;
    this.replacementText = `${TimeRecordingViewKey.DELETE_PROJECT_DIALOG}/replacement`;
    this.deleteText = `${TimeRecordingViewKey.DELETE_PROJECT_DIALOG}/delete`;
    this.deleteAndReplaceText = `${TimeRecordingViewKey.DELETE_PROJECT_DIALOG}/delete-and-replace`;
    this.cancelText = `${TimeRecordingViewKey.DELETE_PROJECT_DIALOG}/cancel`;
  }

  ngOnInit(): void {
    this.projectAutoComplete = new ProjectAutoCompleteInputModel({
      onValueChanged: () => this.refreshComponentModels(),
      projects: this.projects,
      value: '-'
    });
    this.deleteAction = new ActionModel({
      tags: ['app-form-btn', 'btn-sm', 'btn-outline-secondary'],
      content: new TextModel({ text: `#(${this.deleteText})` }),
      onClick: () => {
        const replacementProject = this.getReplacementProject();
        if (replacementProject === undefined) {
          this.refreshComponentModels();
          return;
        }

        this.dialog.close(replacementProject?.id ?? null);
      },
      isDisabled: false
    });
    this.cancelAction = new ActionModel({
      tags: ['app-form-btn', 'btn-sm', 'btn-outline-secondary'],
      content: new TextModel({ text: `#(${this.cancelText})` }),
      onClick: () => this.dialog.close(undefined)
    });

    this.refreshComponentModels();
  }

  private refreshComponentModels() {
    let replacementProject = this.getReplacementProject();
    if (replacementProject === undefined) {
      this.projectAutoComplete.setValues({ value: '-' });
      replacementProject = this.getReplacementProject();
    }
    this.deleteAction.setValues({
      isDisabled: replacementProject === undefined
    });
    this.deleteAction.content = new TextModel({
      text: `#(${replacementProject ? this.deleteAndReplaceText : this.deleteText})`
    });
  }

  private getReplacementProject(): ProjectModel | undefined | null {
    const value = this.projectAutoComplete.value;
    if (value === '-') {
      return null;
    }

    if (typeof value !== 'string') {
      return undefined;
    }

    const projectName = value.split(':').pop();

    return this.projects.find((p) => p.name === projectName);
  }
}
