<h2 class="app-company-detail-title" [kitText]="titleText"></h2>

<kit-bs-form-field [kitModel]="form.nameField"></kit-bs-form-field>
<kit-bs-form-field [kitModel]="form.streetField"></kit-bs-form-field>
<kit-bs-form-field [kitModel]="form.zipCodeField"></kit-bs-form-field>
<kit-bs-form-field [kitModel]="form.cityField"></kit-bs-form-field>
<kit-bs-form-field [kitModel]="form.countryCodeField"></kit-bs-form-field>
<kit-bs-form-field [kitModel]="form.contactNameField"></kit-bs-form-field>
<kit-bs-form-field [kitModel]="form.contactEmailField"></kit-bs-form-field>
<kit-bs-form-field [kitModel]="form.taxIdField"></kit-bs-form-field>
