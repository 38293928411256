import { LocaleProvider } from '@typescript-kit/core';
import { deviceDeLocale } from './locale/de.locale';

export class DeviceInitializer {

  static initialize() {
    DeviceInitializer.registerLocales();
  }

  static registerLocales() {
    LocaleProvider.registerPackageLocale('de', 'device', deviceDeLocale);
  }
}
