import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { SalaryOverviewViewService } from '../../view/salary-overview.view-service';
import { ContainerModel } from '@typescript-kit/view';
import { SalaryShellMenuViewService } from '../../view/salary-shell-menu.view-service';
import { SalaryCommonViewService } from '../../view/salary-common.view-service';
import { ShellViewService } from '../../../shell/view/shell.view-service';

@Component({
  selector: 'app-salary-overview',
  templateUrl: './salary-overview.component.html',
  styleUrls: ['./salary-overview.component.scss'],
  providers: [
    SalaryCommonViewService,
    SalaryShellMenuViewService,
    SalaryOverviewViewService
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SalaryOverviewComponent implements OnInit, OnDestroy {

  constructor(
    private readonly shellViewService: ShellViewService,
    private readonly salaryCommonViewService: SalaryCommonViewService,
    private readonly salaryShellMenuViewService: SalaryShellMenuViewService,
    private readonly salaryOverviewViewService: SalaryOverviewViewService
  ) { }

  public get model(): ContainerModel {
    return this.salaryOverviewViewService.componentModel;
  }

  ngOnInit() {
    this.salaryCommonViewService.initialize();
    this.salaryShellMenuViewService.initialize();
    this.salaryOverviewViewService.initialize();
    this.shellViewService.setMenu(this.salaryShellMenuViewService.componentModel);
  }

  ngOnDestroy(): void {
    this.shellViewService.restoreMenu();
    this.salaryOverviewViewService.finalize();
    this.salaryShellMenuViewService.finalize();
    this.salaryCommonViewService.finalize();
  }

}
