import { CompanyData, CompanyModel, SubscriptionModel } from '@teamworks/global';
import { ObservableList, ObservableModel, ObserveModes } from '@typescript-kit/core';

export class SubscriptionViewFieldKey {
  static readonly COMPANY = 'company';
  static readonly COMPANY_VALID = 'companyValid';
  static readonly VALIDATION_VISIBLE = 'validationVisible';
  static readonly CURRENT_SUBSCRIPTIONS = 'currentSubscriptions';
  static readonly TRIAL_SUBSCRIPTION = 'trialSubscription';
  static readonly REGULAR_SUBSCRIPTION = 'regularSubscription';
  static readonly PAYMENT_METHOD = 'paymentMethod';
}

export interface SubscriptionViewData {
  company?: CompanyData;
  companyValid?: boolean;
  validationVisible?: boolean;
  currentSubscriptions?: Iterable<SubscriptionModel>;
  trialSubscription?: SubscriptionModel;
  regularSubscription?: SubscriptionModel;
  paymentMethod?: any;
}

export class SubscriptionViewModel extends ObservableModel implements SubscriptionViewData {
  static readonly KEYS = Object.keys(SubscriptionViewFieldKey).map((key) => SubscriptionViewFieldKey[key]);

  constructor(values?: SubscriptionViewData) {
    super(values, SubscriptionViewModel.KEYS, { observeMode: ObserveModes.FLAT });
  }

  get company(): CompanyModel {
    return this.get(SubscriptionViewFieldKey.COMPANY);
  }

  set company(value: CompanyModel) {
    this.set(SubscriptionViewFieldKey.COMPANY, value);
  }

  get companyValid(): boolean {
    return this.get(SubscriptionViewFieldKey.COMPANY_VALID, false);
  }

  set companyValid(value: boolean) {
    this.set(SubscriptionViewFieldKey.COMPANY_VALID, value);
  }

  get validationVisible(): boolean {
    return this.get(SubscriptionViewFieldKey.VALIDATION_VISIBLE, false);
  }

  set validationVisible(value: boolean) {
    this.set(SubscriptionViewFieldKey.VALIDATION_VISIBLE, value);
  }

  get currentSubscriptions(): ObservableList<SubscriptionModel> {
    return this.get(SubscriptionViewFieldKey.CURRENT_SUBSCRIPTIONS);
  }

  set currentSubscriptions(value: ObservableList<SubscriptionModel>) {
    this.set(SubscriptionViewFieldKey.CURRENT_SUBSCRIPTIONS, value);
  }

  get trialSubscription(): SubscriptionModel {
    return this.get(SubscriptionViewFieldKey.TRIAL_SUBSCRIPTION);
  }

  set trialSubscription(value: SubscriptionModel) {
    this.set(SubscriptionViewFieldKey.TRIAL_SUBSCRIPTION, value);
  }

  get regularSubscription(): SubscriptionModel {
    return this.get(SubscriptionViewFieldKey.REGULAR_SUBSCRIPTION);
  }

  set regularSubscription(value: SubscriptionModel) {
    this.set(SubscriptionViewFieldKey.REGULAR_SUBSCRIPTION, value);
  }

  get paymentMethod(): any {
    return this.get(SubscriptionViewFieldKey.PAYMENT_METHOD);
  }

  set paymentMethod(value: any) {
    this.set(SubscriptionViewFieldKey.PAYMENT_METHOD, value);
  }
}
