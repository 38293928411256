import { ObservableList, ObserveModes } from '@typescript-kit/core';
import { Employee, ProjectData, ProjectModel, PublicHoliday, ProjectTimeRecordModel, TagMapping, } from '@teamworks/global';
import { MonthSelectViewData, MonthSelectViewFieldKey, MonthSelectViewModel } from '../../shared/view/month-select.view-model';

export const projectTypes = ['active', 'inactive', 'unconfirmed'] as const;

export interface ProjectCommonViewData extends MonthSelectViewData {
  printView?: boolean;
  employeeList?: Employee[];
  projectList?: Iterable<ProjectModel>;
  activityList?: Iterable<ProjectModel>;
  projectId?: string;
  project?: ProjectData;
  timeRecordList?: Iterable<ProjectTimeRecordModel>;
  publicHolidayList?: PublicHoliday[];
  isProjectAdmin?: boolean;
  canSeeOverview?: boolean;
  activityTags?: Map<string, TagMapping[]>;
  selectedProjectType?: typeof projectTypes[number];
  currentTab?: 'time-recording' | 'management' | 'activities';
}

export class ProjectCommonViewFieldKey extends MonthSelectViewFieldKey {
  static readonly PRINT_VIEW = 'printView';
  static readonly EMPLOYEE_LIST = 'employeeList';
  static readonly PROJECT_LIST = 'projectList';
  static readonly ACTIVITY_LIST = 'activityList';
  static readonly PROJECT_ID = 'projectId';
  static readonly PROJECT = 'project';
  static readonly TIME_RECORD_LIST = 'timeRecordList';
  static readonly PUBLIC_HOLIDAY_LIST = 'publicHolidayList';
  static readonly IS_PROJECT_ADMIN = 'isProjectAdmin';
  static readonly CAN_SEE_OVERVIEW = 'canSeeOverview';
  static readonly ACTIVITY_TAGS = 'activityTags';
  static readonly SELECTED_PROJECT_TYPE = 'selectedProjectType';
  static readonly CURRENT_TAB = 'currentTab';
}

export class ProjectCommonViewModel extends MonthSelectViewModel implements ProjectCommonViewData {
  static readonly KEYS = MonthSelectViewModel.KEYS.concat(
    Object.keys(ProjectCommonViewFieldKey).map((key) => ProjectCommonViewFieldKey[key])
  );

  constructor(data?: ProjectCommonViewData) {
    super(data, ProjectCommonViewModel.KEYS, { observeMode: ObserveModes.FLAT });
  }

  get printView(): boolean {
    return this.get(ProjectCommonViewFieldKey.PRINT_VIEW);
  }

  set printView(value: boolean) {
    this.set(ProjectCommonViewFieldKey.PRINT_VIEW, value);
  }

  get employeeList(): Employee[] {
    return this.get(ProjectCommonViewFieldKey.EMPLOYEE_LIST);
  }

  set employeeList(value: Employee[]) {
    this.set(ProjectCommonViewFieldKey.EMPLOYEE_LIST, value);
  }

  get projectList(): ObservableList<ProjectModel> {
    return this.get(ProjectCommonViewFieldKey.PROJECT_LIST);
  }

  set projectList(value: ObservableList<ProjectModel>) {
    this.set(ProjectCommonViewFieldKey.PROJECT_LIST, value);
  }

  get activityList(): ObservableList<ProjectModel> {
    return this.get(ProjectCommonViewFieldKey.ACTIVITY_LIST);
  }

  set activityList(value: ObservableList<ProjectModel>) {
    this.set(ProjectCommonViewFieldKey.ACTIVITY_LIST, value);
  }

  get projectId(): string {
    return this.get(ProjectCommonViewFieldKey.PROJECT_ID);
  }

  set projectId(value: string) {
    this.set(ProjectCommonViewFieldKey.PROJECT_ID, value);
  }

  get project(): ProjectModel {
    return this.get(ProjectCommonViewFieldKey.PROJECT);
  }

  set project(value: ProjectModel) {
    this.set(ProjectCommonViewFieldKey.PROJECT, value);
  }

  get timeRecordList(): ObservableList<ProjectTimeRecordModel> {
    return this.get(ProjectCommonViewFieldKey.TIME_RECORD_LIST);
  }

  set timeRecordList(value: ObservableList<ProjectTimeRecordModel>) {
    this.set(ProjectCommonViewFieldKey.TIME_RECORD_LIST, value);
  }

  get publicHolidayList(): PublicHoliday[] {
    return this.get(ProjectCommonViewFieldKey.PUBLIC_HOLIDAY_LIST);
  }

  set publicHolidayList(value: PublicHoliday[]) {
    this.set(ProjectCommonViewFieldKey.PUBLIC_HOLIDAY_LIST, value);
  }

  get isProjectAdmin(): boolean {
    return this.get(ProjectCommonViewFieldKey.IS_PROJECT_ADMIN);
  }

  set isProjectAdmin(value: boolean) {
    this.set(ProjectCommonViewFieldKey.IS_PROJECT_ADMIN, value);
  }

  get canSeeOverview(): boolean {
    return this.get(ProjectCommonViewFieldKey.CAN_SEE_OVERVIEW);
  }

  set canSeeOverview(value: boolean) {
    this.set(ProjectCommonViewFieldKey.CAN_SEE_OVERVIEW, value);
  }

  get activityTags(): Map<string, TagMapping[]> {
    return this.get(ProjectCommonViewFieldKey.ACTIVITY_TAGS);
  }

  set activityTags(value: Map<string, TagMapping[]>) {
    this.set(ProjectCommonViewFieldKey.ACTIVITY_TAGS, value);
  }

  get selectedProjectType(): typeof projectTypes[number] {
    return this.get(ProjectCommonViewFieldKey.SELECTED_PROJECT_TYPE, 'active');
  }

  set selectedProjectType(value: typeof projectTypes[number]) {
    this.set(ProjectCommonViewFieldKey.SELECTED_PROJECT_TYPE, value);
  }

  get currentTab(): 'time-recording' | 'management' | 'activities' | undefined {
    return this.get(ProjectCommonViewFieldKey.CURRENT_TAB);
  }

  set currentTab(value: 'time-recording' | 'management' | 'activities') {
    this.set(ProjectCommonViewFieldKey.CURRENT_TAB, value);
  }

  protected setupValues(values: ProjectCommonViewData, exclusive: boolean): ProjectCommonViewData {
    if (exclusive) {
      if (!(ProjectCommonViewFieldKey.PRINT_VIEW in values)) {
        values.printView = false;
      }
    }
    return super.setupValues(values, exclusive) as ProjectCommonViewData;
  }
}
