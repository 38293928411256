import { ObservableList, ObservableModel, ObserveModes } from '@typescript-kit/core';
import { EmployeeData, EmployeeModel } from '@teamworks/global';

export class EmployeeCommonViewFieldKey {
  static readonly EMPLOYEE_LIST = 'employeeList';
  static readonly EMPLOYEE_ID = 'employeeId';
  static readonly EMPLOYEE = 'employee';
}

export interface EmployeeCommonViewData {
  employeeList?: Iterable<EmployeeData>;
  employeeId?: string;
  employee: EmployeeData;
}

export class EmployeeCommonViewModel extends ObservableModel implements EmployeeCommonViewData {
  static readonly KEYS = Object.keys(EmployeeCommonViewFieldKey).map((key) => EmployeeCommonViewFieldKey[key]);

  constructor(values?: EmployeeCommonViewData) {
    super(values, EmployeeCommonViewModel.KEYS, { observeMode: ObserveModes.FLAT });
  }

  get employeeList(): ObservableList<EmployeeModel> {
    return this.get(EmployeeCommonViewFieldKey.EMPLOYEE_LIST);
  }

  set employeeList(value: ObservableList<EmployeeModel>) {
    this.set(EmployeeCommonViewFieldKey.EMPLOYEE_LIST, value);
  }

  get employeeId(): string {
    return this.get(EmployeeCommonViewFieldKey.EMPLOYEE_ID);
  }

  set employeeId(value: string) {
    this.set(EmployeeCommonViewFieldKey.EMPLOYEE_ID, value);
  }

  get employee(): EmployeeModel {
    return this.get(EmployeeCommonViewFieldKey.EMPLOYEE);
  }

  set employee(value: EmployeeModel) {
    this.set(EmployeeCommonViewFieldKey.EMPLOYEE, value);
  }

}
