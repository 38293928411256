import { Injectable } from '@angular/core';
import { HttpService } from '@angular-kit/core';
import { DataQuery } from '@typescript-kit/data';

@Injectable()
export class AuthorizationService {

  constructor(private readonly httpService: HttpService) {
  }

  async authorize(permissionKey: string, permissionContext?: any): Promise<boolean> {
    return this.httpService.get(`/api/authorization/authorize`, {
      'permission-key': permissionKey,
      'permission-context': permissionContext
    });
  }

  async loadPermissionList(dataQuery?: DataQuery): Promise<{ readonly key: string, readonly options?: any }[]> {
    return this.httpService.get(`/api/authorization/permission-list`, { 'data-query': dataQuery });
  }
}
