import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { Subscription } from 'rxjs';
import { ChangedEvent } from '@typescript-kit/core';
import { SharedComponentKey } from '../../key/shared-component.key';
import { SubscriptionViewService } from '../../view/subscription.view-service';
import { SubscriptionViewFieldKey, SubscriptionViewModel } from '../../view/subscription.view-model';
import { ValueModel } from '@typescript-kit/view';

@Component({
  selector: 'app-payment-methods',
  templateUrl: './payment-methods.component.html',
  styleUrls: ['./payment-methods.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentMethodsComponent implements OnInit, OnDestroy {
  public readonly titleText = `${SharedComponentKey.PAYMENT_METHODS}/title`;

  public paymentMethodModel: ValueModel;

  private viewModelChangedSubscription: Subscription;

  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly viewService: SubscriptionViewService
  ) {
  }

  get viewModel(): SubscriptionViewModel {
    return this.viewService.viewModel;
  }

  async ngOnInit(): Promise<void> {
    this.viewModelChangedSubscription = this.viewModel.changedEvent.subscribe(event => this.onViewModelChanged(event));
    await this.createComponentModels();
  }

  ngOnDestroy() {
    this.viewModelChangedSubscription.unsubscribe();
  }

  private async onViewModelChanged(event: ChangedEvent) {
    if (event.changes[SubscriptionViewFieldKey.PAYMENT_METHOD]) {
      await this.refreshComponentModels();
    }
  }

  private async createComponentModels() {
    this.paymentMethodModel = new ValueModel({
      key: `${SharedComponentKey.PAYMENT_METHODS}/payment-method`
    });

    await this.refreshComponentModels();
  }

  private async refreshComponentModels() {
    this.paymentMethodModel.value = this.viewModel.paymentMethod
      ? `${SharedComponentKey.PAYMENT_METHODS}/${this.viewModel.paymentMethod}`
      : `${SharedComponentKey.PAYMENT_METHODS}/none`;

    this.changeDetector.markForCheck();
  }
}
