import moment from 'moment';
import { LogbookComponentKey as ComponentKey } from '../key/logbook-component.key';
import { ViewService } from '@angular-kit/view';
import { LogbookViewModel } from '../view-model/logbook.view-model';
import {
  ActionModel,
  Alignments,
  DropdownModel,
  KeyCodes,
  TableCellModel,
  TableColumnModel,
  TableModel,
  TableRowModel,
  TextInputModel,
  ValueModel,
  ViewComponentKey
} from '@typescript-kit/view';
import { ActivatedRoute } from '@angular/router';
import { PersistenceService } from '@angular-kit/core';
import { LogbookService } from '@teamworks/global';
import { Injectable, Injector } from '@angular/core';
import { LogbookPersistenceKey } from '../key/logbook-persistence.key';
import { ChangedEvent, CoreFormatKey, CoreValidationKey } from '@typescript-kit/core';
import { LogbookEntryModel } from '@teamworks/global';
import { LogbookValidationKey } from '@teamworks/global';
import { LogbookSharedViewService } from './logbook-shared.view-service';

class ColumnIndex {
  static readonly DAY = 0;
  static readonly KILOMETER = 1;
  static readonly COST_RATE = 2;
  static readonly COSTS = 3;
  static readonly COMMENT = 4;
  static readonly INSERT_ROW = 5;
  static readonly REMOVE_ROW = 6;
}

class ColumnKey {
  static readonly [ColumnIndex.DAY] = `${ComponentKey.LOGBOOK_TABLE}/column/day`;
  static readonly [ColumnIndex.KILOMETER] = `${ComponentKey.LOGBOOK_TABLE}/column/kilometer`;
  static readonly [ColumnIndex.COST_RATE] = `${ComponentKey.LOGBOOK_TABLE}/column/costRate`;
  static readonly [ColumnIndex.COSTS] = `${ComponentKey.LOGBOOK_TABLE}/column/costs`;
  static readonly [ColumnIndex.COMMENT] = `${ComponentKey.LOGBOOK_TABLE}/column/comment`;
}

@Injectable()
export class LogbookTableViewService extends ViewService<LogbookViewModel, TableModel> {
  private readonly activatedRoute: ActivatedRoute;
  private readonly persistenceService: PersistenceService;
  private readonly logbookService: LogbookService;

  protected readonly sharedViewService = this.injector.get(LogbookSharedViewService);

  private readonly hiddenColumns: Set<number>;

  constructor(injector: Injector) {
    super(injector);
    this.activatedRoute = injector.get(ActivatedRoute);
    this.persistenceService = injector.get(PersistenceService);
    this.logbookService = injector.get(LogbookService);
    this.hiddenColumns = this.loadHiddenColumns();
  }

  initialize(): void {
    super.initialize(this.sharedViewService.viewModel);
  }

  protected onViewModelPropertyChanged(event: ChangedEvent): void {
    if (event.originalEvent !== event) {
      if (event.originalEvent.source === this.viewModel.logbookList) {
        this.onLogbookListPropertyChanged(event.originalEvent);
      }
      return;
    }
    if (event.changes['logbookList']) {
      this.refreshComponentModel(this.componentModel);
    }
  }

  private onLogbookListPropertyChanged(event: ChangedEvent) {
    if (event.originalEvent !== event) {
      if (event.originalEvent.source instanceof LogbookEntryModel) {
        this.onLogbookEntryPropertyChanged(event.originalEvent);
      }
      return;
    }
    this.refreshComponentModel(this.componentModel);
  }

  private onLogbookEntryPropertyChanged(event: ChangedEvent) {
    const logbookEntryModel = event.source as LogbookEntryModel;
    this.refreshRow(logbookEntryModel);
  }

  protected createComponentModel(): TableModel {
    return new TableModel({
      tags: ['app-logbook-table'],
      isHidden: !this.viewModel.logbookList,
      columns: this.createColumnModels(),
      rows: this.createRowModels(),
      cells: this.createCellModels(),
      columnHeaderCount: 1,
      rowHeaderCount: 1
    });
  }

  protected refreshComponentModel(tableModel: TableModel): void {
    tableModel.setValues({
      isHidden: !this.viewModel.logbookList,
      columns: this.createColumnModels(),
      rows: this.createRowModels(),
      cells: this.createCellModels()
    });
  }

  private createColumnModels() {
    const columns = [
      new TableColumnModel({ index: ColumnIndex.DAY, tags: ['app-day'] }),
      new TableColumnModel({ index: ColumnIndex.KILOMETER, tags: ['app-kilometer'] }),
      new TableColumnModel({ index: ColumnIndex.COST_RATE, tags: ['app-cost-rate'] }),
      new TableColumnModel({ index: ColumnIndex.COSTS, tags: ['app-costs'] }),
      new TableColumnModel({ index: ColumnIndex.COMMENT, tags: ['app-comment'] }),
      new TableColumnModel({ index: ColumnIndex.INSERT_ROW, tags: ['app-action-column'] }),
      new TableColumnModel({ index: ColumnIndex.REMOVE_ROW, tags: ['app-action-column'] })
    ];
    columns.forEach((column: TableColumnModel) => column.isHidden = this.hiddenColumns.has(column.index));
    return columns;
  }

  private createRowModels() {
    const rows = [
      new TableRowModel({ index: 0, tags: ['app-logbook-table-header'] })
    ];
    const logbookList = this.viewModel.logbookList;
    if (!logbookList) {
      return rows;
    }

    logbookList.forEach((logbookEntry: LogbookEntryModel, index: number) => {
      const tags = ['app-logbook-table-row'];
      rows.push(new TableRowModel({ index: index + 1, tags }));
    });
    return rows;
  }

  private createCellModels() {
    const cells = [
      new TableCellModel({ row: 0, column: ColumnIndex.DAY, content: ColumnKey[ColumnIndex.DAY] }),
      new TableCellModel({ row: 0, column: ColumnIndex.KILOMETER, content: ColumnKey[ColumnIndex.KILOMETER] }),
      new TableCellModel({ row: 0, column: ColumnIndex.COST_RATE, content: ColumnKey[ColumnIndex.COST_RATE] }),
      new TableCellModel({ row: 0, column: ColumnIndex.COSTS, content: ColumnKey[ColumnIndex.COSTS] }),
      new TableCellModel({ row: 0, column: ColumnIndex.COMMENT, content: ColumnKey[ColumnIndex.COMMENT] }),
      new TableCellModel({ row: 0, column: ColumnIndex.REMOVE_ROW, content: this.createVisibleColumnsDropdown() })
    ];

    const logbookList = this.viewModel.logbookList;
    if (!logbookList) {
      return cells;
    }

    logbookList.forEach((logbookEntry: LogbookEntryModel, index: number) => {
      const row = index + 1;
      const rowCells: TableCellModel[] = [
        this.createDateInputCell(row, ColumnIndex.DAY, logbookEntry),
        this.createKilometerInputCell(row, ColumnIndex.KILOMETER, logbookEntry),
        this.createCostRateInputCell(row, ColumnIndex.COST_RATE, logbookEntry),
        this.createCostsValueCell(row, ColumnIndex.COSTS),
        this.createCommentInputCell(row, ColumnIndex.COMMENT, logbookEntry),
        this.createInsertRowCellModel(row, ColumnIndex.INSERT_ROW),
        this.createRemoveRowCellModel(row, ColumnIndex.REMOVE_ROW)
      ];
      cells.push(...rowCells);
      this.refreshRowCells(row, rowCells, logbookEntry);
    });
    return cells;
  }

  private createVisibleColumnsDropdown(): DropdownModel {
    return new DropdownModel({
      tags: ['app-visible-columns-dropdown'],
      items: [
        this.createVisibleColumnDropdownItemModel(ColumnIndex.DAY),
        this.createVisibleColumnDropdownItemModel(ColumnIndex.KILOMETER),
        this.createVisibleColumnDropdownItemModel(ColumnIndex.COST_RATE),
        this.createVisibleColumnDropdownItemModel(ColumnIndex.COSTS),
        this.createVisibleColumnDropdownItemModel(ColumnIndex.COMMENT)
      ]
    });
  }

  private createVisibleColumnDropdownItemModel(columnIndex: number): ActionModel {
    return new ActionModel({
      type: ViewComponentKey.LINK,
      tags: ['dropdown-item'],
      content: `#(${ColumnKey[columnIndex]}) ${!this.hiddenColumns.has(columnIndex) ? '\u2714' : ''}`,
      onClick: (event: Event, model: ActionModel) => {
        if (this.hiddenColumns.has(columnIndex)) {
          this.hiddenColumns.delete(columnIndex);
          model.content = `#(${ColumnKey[columnIndex]}) \u2714` as any;
          this.componentModel.getColumn(columnIndex).isHidden = false;
        } else {
          this.hiddenColumns.add(columnIndex);
          model.content = ColumnKey[columnIndex] as any;
          this.componentModel.getColumn(columnIndex).isHidden = true;
        }
        this.saveHiddenColumns(this.hiddenColumns);
        event.stopPropagation();
      }
    });
  }

  private createDateInputCell(row: number, column: number, logbookEntry: LogbookEntryModel): TableCellModel {
    return this.createInputCell(row, column, new TextInputModel({
      value: logbookEntry.date ? moment(logbookEntry.date, 'YYYY-MM-DD').date() : null,
      alignment: Alignments.RIGHT,
      validation: {
        [LogbookValidationKey.DAY]: { year: this.viewModel.year, month: this.viewModel.month }
      },
      onValueChanged: async (value, originalValue, model: ValueModel) => {
        if (!(await model.validate())) {
          return;
        }
        logbookEntry.date = value ? `${this.viewModel.year}-${this.viewModel.month}-${value}` : null;
        // logbookEntry.date = value ? moment(`${this.viewModel.year}-${this.viewModel.month}-value`).format('YYYY-MM-DD') : null;
      }
    }));

  }

  private createKilometerInputCell(row: number, column: number, logbookEntry: LogbookEntryModel): TableCellModel {
    return this.createInputCell(row, column, new TextInputModel({
      value: logbookEntry.kilometer,
      format: CoreFormatKey.NUMBER_0,
      alignment: Alignments.RIGHT,
      suffix: ' km',
      validation: {
        [CoreValidationKey.GREATER_THAN]: 0,
        [CoreValidationKey.TYPE]: { type: 'number', strict: true }
      },
      onValueChanged: (value: number, originalValue, model: ValueModel) => {
        if (value != null) {
          value = +value.toFixed(0);
        }
        const updateValues: { [key: string]: any } = {
          kilometer: value
        };
        if (value === null) {
          updateValues.costs = null;
        } else {
          const costs = this.logbookService.calculateCosts(value, logbookEntry.costRate);
          if (costs !== null) {
            updateValues.costs = costs;
          }
        }
        logbookEntry.setValues(updateValues);
        model.suffix = value !== null ? ' km' : ' ';
        this.refreshCostsValueCell(row);
      }
    }));
  }

  private createCostRateInputCell(row: number, column: number, logbookEntry: LogbookEntryModel): TableCellModel {
    return this.createInputCell(row, column, new TextInputModel({
      value: logbookEntry.costRate,
      format: CoreFormatKey.NUMBER_2,
      alignment: Alignments.RIGHT,
      suffix: ' €',
      validation: {
        [CoreValidationKey.GREATER_THAN]: 0,
        [CoreValidationKey.TYPE]: { type: 'number' }
      },
      onValueChanged: (value: number, originalValue, model: ValueModel) => {
        if (value != null) {
          value = +value.toFixed(2);
        }
        const updateValues: { [key: string]: any } = {
          costRate: value
        };
        if (value === null) {
          updateValues.costs = null;
        } else {
          const costs = this.logbookService.calculateCosts(logbookEntry.kilometer, value);
          if (costs !== null) {
            updateValues.costs = costs;
          }
        }
        logbookEntry.setValues(updateValues);
        model.suffix = value !== null ? ' €' : ' ';
        this.refreshCostsValueCell(row);
      }
    }));
  }

  private createCostsValueCell(row: number, column: number): TableCellModel {
    return new TableCellModel({
      row, column, content: new ValueModel({
        tags: ['app-form-value'],
        value: null,
        format: 'number-2',
        suffix: ' ',
        alignment: Alignments.RIGHT,
        onValueChanged: (value, originalValue, model: ValueModel) => {
          model.tags.set('app-is-empty', value === null);
          model.suffix = value !== null ? ' €' : ' ';
        }
      })
    });
  }

  private refreshCostsValueCell(row: number) {
    const kilometerInputModel = this.componentModel.getCell(row, ColumnIndex.KILOMETER).content as ValueModel;
    const costRateInputModel = this.componentModel.getCell(row, ColumnIndex.COST_RATE).content as ValueModel;
    const costsValueModel = this.componentModel.getCell(row, ColumnIndex.COSTS).content as ValueModel;
    Promise.all([
      kilometerInputModel.resolveIsValid(),
      costRateInputModel.resolveIsValid()
    ])
      .then(() => costsValueModel.value = this.calculateCosts(row))
      .catch(() => costsValueModel.value = null);
  }

  private createCommentInputCell(row: number, column: number, logbookEntry: LogbookEntryModel): TableCellModel {
    return this.createInputCell(row, column, new TextInputModel({
      value: logbookEntry.comment,
      alignment: Alignments.LEFT,
      onValueChanged: value => logbookEntry.comment = value
    }));
  }

  private createInputCell(row: number, column: number, inputModel: ValueModel): TableCellModel {
    const suffix = inputModel.suffix;
    inputModel.setValues({
      placeholder: inputModel.isFocused ? '' : ' - ',
      suffix: (!suffix || inputModel.value) ? suffix : ' ',
      onChanged: (event: ChangedEvent, model: ValueModel) => {
        if (suffix && event.changes['value']) {
          model.suffix = model.value ? suffix : ' ';
        }
        if (event.changes['isFocused']) {
          model.setValues({ placeholder: model.isFocused ? '' : ' - ' });
        }
      },
      onKeydown: (event) => this.onInputCellKeydown(event, row, column)
    });
    return new TableCellModel({ row, column, content: inputModel });
  }

  private createInsertRowCellModel(row: number, column: number): TableCellModel {
    return new TableCellModel({
      row, column, content: new ActionModel({
        tags: ['app-form-btn', 'btn-sm', 'btn-outline-secondary'],
        content: '+',
        onClick: () => {
          this.insertRow(row);
          this.componentModel.getCell(row + 1, 0).content.isFocused = true;
        },
        onKeydown: (event) => this.onInputCellKeydown(event, row, column)
      })
    });
  }

  private createRemoveRowCellModel(row: number, column: number): TableCellModel {
    return new TableCellModel({
      row, column, content: new ActionModel({
        tags: ['app-form-btn', 'app-form', 'btn-sm', 'btn-outline-secondary'],
        content: '-',
        isHidden: false,
        onClick: () => {
          this.removeRow(row);
          this.componentModel.getCell(row > 1 ? row - 1 : row, ColumnIndex.INSERT_ROW).content.isFocused = true;
        },
        onKeydown: (event) => this.onInputCellKeydown(event, row, column)
      })
    });
  }

  private insertRow(row: number) {
    const index = row;
    const originalLogbookEntryModelList = this.viewModel.logbookList;
    if (index < 1 || index > originalLogbookEntryModelList.size) {
      throw new Error(`Invalid index: '${index}'`);
    }

    const logbookEntryModelList = new Array(originalLogbookEntryModelList.size + 1);
    for (let i = 0; i < index; i++) {
      logbookEntryModelList[i] = originalLogbookEntryModelList.get(i);
    }
    logbookEntryModelList[index] = new LogbookEntryModel({
      employeeId: this.viewModel.employeeId,
      date: null,
      kilometer: null,
      costRate: null
    });
    for (let i = index; i < originalLogbookEntryModelList.size; i++) {
      logbookEntryModelList[i + 1] = originalLogbookEntryModelList.get(i);
    }
    this.viewModel.logbookList.setValues(logbookEntryModelList as any, { exclusive: true });
  }

  private removeRow(row: number) {
    const index = row - 1;
    const originalLogbookEntryModelList = this.viewModel.logbookList;
    if (index < 0 || index >= originalLogbookEntryModelList.size) {
      throw new Error(`Invalid index: '${index}`);
    }
    const logbookEntry = originalLogbookEntryModelList.get(index);
    const logbookEntryModelList = new Array(originalLogbookEntryModelList.size === 1 ? 1 : originalLogbookEntryModelList.size - 1);
    if (logbookEntryModelList.length === 1 && originalLogbookEntryModelList.size === 1) {
      logbookEntryModelList[0] = new LogbookEntryModel({
        employeeId: this.viewModel.employeeId,
        date: null,
        kilometer: null,
        costRate: null
      });
    } else {
      for (let i = 0; i < index; i++) {
        logbookEntryModelList[i] = originalLogbookEntryModelList.get(i);
      }
      for (let i = index + 1; i < originalLogbookEntryModelList.size; i++) {
        logbookEntryModelList[i - 1] = originalLogbookEntryModelList.get(i);
      }
    }
    this.viewModel.logbookList.setValues(logbookEntryModelList as any, { exclusive: true });
    if (logbookEntry.modified) {
      this.sharedViewService.deleteLogbookEntry(logbookEntry);
    }
  }


  private refreshRowCells(row: number, rowCells: TableCellModel[], model: LogbookEntryModel) {
    rowCells[ColumnIndex.DAY].content.set('value', model.date ? moment(model.date, 'YYYY-MM-DD').date() : null);
    rowCells[ColumnIndex.KILOMETER].content.set('value', model.kilometer);
    rowCells[ColumnIndex.COST_RATE].content.set('value', model.costRate);
    rowCells[ColumnIndex.COSTS].content.set('value', this.calculateCosts(row));
    rowCells[ColumnIndex.COMMENT].content.set('value', model.comment);

    for (const cell of rowCells) {
      const cellModel = cell.content as ValueModel;
      if (cellModel instanceof TextInputModel) {
        cellModel.validate();
      }
    }
  }

  private calculateCosts(row: number) {
    const entryIndex = row - 1;
    const logbookEntryModel: LogbookEntryModel = this.viewModel.logbookList.get(entryIndex);
    if (logbookEntryModel.kilometer === null || logbookEntryModel.kilometer === undefined
      || logbookEntryModel.costRate === null || logbookEntryModel.costRate === undefined) {
      return null;
    }

    return logbookEntryModel.kilometer * logbookEntryModel.costRate;
  }

  private onInputCellKeydown(event: KeyboardEvent, row: number, column: number) {
    if (event.key === KeyCodes.UP.key) {
      this.getAboveInputCell(row, column).content.isFocused = true;
      event.preventDefault();
    } else if (event.key === KeyCodes.DOWN.key) {
      this.getBelowInputCell(row, column).content.isFocused = true;
      event.preventDefault();
    } else if (event.key === KeyCodes.RIGHT.key) {
      const inputElement = event.currentTarget;
      if (
        event.ctrlKey && event.altKey ||
        inputElement['selectionStart'] === undefined ||
        inputElement['selectionStart'] === inputElement['value'].length
      ) {
        this.getRightInputCell(row, column).content.isFocused = true;
        event.preventDefault();
      }
    } else if (event.key === KeyCodes.LEFT.key) {
      const inputElement = event.currentTarget;
      if (
        event.ctrlKey && event.altKey ||
        inputElement['selectionEnd'] === undefined ||
        inputElement['selectionEnd'] === 0
      ) {
        this.getLeftInputCell(row, column).content.isFocused = true;
        event.preventDefault();
      }
    } else if (event.key === KeyCodes.ENTER.key) {
      const inputElement = event.currentTarget;
      const inputModel = this.componentModel.getCell(row, column).content as ValueModel;
      if (inputModel.isValid && inputModel.formattedValue === inputElement['value']) {
        this.getNextInputCell(row, column).content.isFocused = true;
        event.preventDefault();
      }
    }
  }

  private getAboveInputCell(row: number, column: number): TableCellModel {
    const aboveRow = row > 1 ? row - 1 : this.componentModel.rowCount - 1;
    const aboveCell = this.componentModel.getCell(aboveRow, column);
    return this.isFocusableCell(aboveCell) ? aboveCell : this.getAboveInputCell(aboveRow, column);
  }

  private isFocusableCell(cell: TableCellModel): boolean {
    const content = cell.content;
    return !content.isDisabled && !content.isHidden && !this.componentModel.getColumn(cell.column).isHidden;
  }

  private getBelowInputCell(row: number, column: number): TableCellModel {
    const belowRow = row < this.componentModel.rowCount - 1 ? row + 1 : 1;
    const belowCell = this.componentModel.getCell(belowRow, column);
    return this.isFocusableCell(belowCell) ? belowCell : this.getBelowInputCell(belowRow, column);
  }

  private getLeftInputCell(row: number, column: number): TableCellModel {
    let leftColumn: number;
    if (column === ColumnIndex.DAY) {
      leftColumn = this.componentModel.columnCount - 1;
    } else if (column === ColumnIndex.COMMENT) {
      leftColumn = ColumnIndex.COST_RATE;
    } else {
      leftColumn = column - 1;
    }

    const leftCell = this.componentModel.getCell(row, leftColumn);
    return this.isFocusableCell(leftCell) ? leftCell : this.getLeftInputCell(row, leftColumn);
  }

  private getRightInputCell(row: number, column: number): TableCellModel {
    let rightColumn: number;
    if (column === ColumnIndex.COST_RATE) {
      rightColumn = ColumnIndex.COMMENT;
    } else if (column < this.componentModel.columnCount - 1) {
      rightColumn = column + 1;
    } else {
      rightColumn = ColumnIndex.DAY;
    }
    const rightCell = this.componentModel.getCell(row, rightColumn);
    return this.isFocusableCell(rightCell) ? rightCell : this.getRightInputCell(row, rightColumn);
  }

  private getNextInputCell(row: number, column: number): TableCellModel {
    let nextColumn: number;
    let nextRow: number;
    if (column === ColumnIndex.COST_RATE) {
      nextColumn = ColumnIndex.COMMENT;
      nextRow = row;
    } else {
      nextColumn = column + 1;
      nextRow = row;
    }
    return this.componentModel.getCell(nextRow, nextColumn);
  }

  private refreshRow(model: LogbookEntryModel) {
    const index = this.viewModel.logbookList.indexOf(model);
    if (index === undefined) {
      return;
    }
    const row = +index + 1;
    if (row < this.componentModel.cellArray.length) {
      this.refreshRowCells(row, this.componentModel.cellArray[row], model);
    }
  }

  private loadHiddenColumns(): Set<number> {
    const hiddenColumns = this.activatedRoute.snapshot.queryParamMap.get('hiddenColumns')
      || this.persistenceService.getValue(LogbookPersistenceKey.HIDDEN_COLUMNS);
    if (!hiddenColumns) {
      return new Set<number>();
    }
    try {
      return new Set((JSON.parse(hiddenColumns) as string[])
        .filter((columnName: string) => columnName in ColumnIndex)
        .map((columnName: string) => ColumnIndex[columnName]));
    } catch {
      return new Set<number>();
    }
  }

  private saveHiddenColumns(hiddenColumns: Set<number>) {
    const columnNames = Object.keys(ColumnIndex);
    const hiddenColumnArray: string [] = [];
    for (const columnIndex of hiddenColumns) {
      hiddenColumnArray.push(columnNames[columnIndex]);
    }
    this.persistenceService.setValue(LogbookPersistenceKey.HIDDEN_COLUMNS, JSON.stringify(hiddenColumnArray));
  }
}
