import { Component, ViewEncapsulation, ChangeDetectionStrategy, ViewChild, Input } from '@angular/core';
import { ActionModel } from '@typescript-kit/view';
import { ButtonComponent } from '@angular-kit/view';

@Component({
  selector: 'kit-bs-button',
  templateUrl: './bs-button.component.html',
  styleUrls: ['./bs-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BsButtonComponent {

  @ViewChild(ButtonComponent, { static: true }) buttonComponent: ButtonComponent;

  @Input('kitModel')
  set model(model: ActionModel) {
    this.buttonComponent.model = model;
  }

  get model(): ActionModel {
    return this.buttonComponent.model;
  }
}
