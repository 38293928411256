import { ObservableCollectionOptions, ObservableModel } from '@typescript-kit/core';

export interface MonthSelectViewData {
  year?: string;
  month?: string;
}

export class MonthSelectViewFieldKey {
  static readonly YEAR = 'year';
  static readonly MONTH = 'month';
}

export class MonthSelectViewModel extends ObservableModel implements MonthSelectViewData {
  static readonly KEYS = Object.keys(MonthSelectViewFieldKey).map(key => MonthSelectViewFieldKey[key]);

  constructor(data: MonthSelectViewData, allowedKeys?: string[], observeOptions?: ObservableCollectionOptions) {
    super(data, allowedKeys || MonthSelectViewModel.KEYS, observeOptions);
  }

  get year(): string {
    return this.get(MonthSelectViewFieldKey.YEAR);
  }

  set year(value: string) {
    this.set(MonthSelectViewFieldKey.YEAR, value);
  }

  get month(): string {
    return this.get(MonthSelectViewFieldKey.MONTH);
  }

  set month(value: string) {
    this.set(MonthSelectViewFieldKey.MONTH, value);
  }

  protected setupValues(values: MonthSelectViewData, exclusive: boolean): MonthSelectViewData {
    return super.setupValues(values, exclusive) as MonthSelectViewData;
  }
}
