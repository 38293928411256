import { ValidationPredicate } from '@typescript-kit/core';
import moment from 'moment';

export interface DayValidationOptions {
  year: number;
  month: number;
}

export const dayValidationPredicate: ValidationPredicate = (value: number, options: DayValidationOptions): boolean => {
  if (value === undefined || value === null) {
    return true;
  }
  if (typeof value !== 'number') {
    value = +value;
  }
  if (Number.isNaN(value) || value < 0) {
    return false;
  }
  const daysInMonth = moment([options.year, options.month - 1, 1]).daysInMonth();
  return value <= daysInMonth;
};
