import { ComponentData, ComponentModel, ComponentFieldKey } from '../model/component.model';
import { ObserveModes } from '@typescript-kit/core';
import { ViewComponentKey } from '../key/view-component.key';
import { TabData, TabModel } from './tab.component-model';

export interface TabsData extends ComponentData {
  currentTab?: TabData;
  tabs?: Iterable<TabData>;
}

export class TabsFieldKey {
  static readonly CURRENT_TAB = 'currentTab';
  static readonly TABS = 'tabs';
}

export class TabsModel extends ComponentModel implements TabsData {
  static readonly KEYS = ComponentModel.KEYS.concat(
    Object.keys(TabsFieldKey).map((key) => TabsFieldKey[key])
  );

  constructor(data?: TabsData, keys?: string[]) {
    super(data, keys || TabsModel.KEYS, { observeMode: ObserveModes.FLAT });
  }

  get currentTab(): TabModel {
    return this.get(TabsFieldKey.CURRENT_TAB);
  }

  set currentTab(value: TabModel) {
    this.set(TabsFieldKey.CURRENT_TAB, value);
  }

  get tabs(): TabModel[] {
    return this.get(TabsFieldKey.TABS);
  }

  set tabs(value: TabModel[]) {
    this.set(TabsFieldKey.TABS, value);
  }

  protected setupValues(values: TabsData, exclusive: boolean): TabsData {
    if (exclusive && !(ComponentFieldKey.TYPE in values)) {
      values.type = ViewComponentKey.TABS;
    }

    return super.setupValues(values, exclusive) as TabsData;
  }
}
