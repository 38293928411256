import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { ChangedEvent } from '@typescript-kit/core';
import { ViewService } from '@angular-kit/view';
import { TimeRecordingViewFieldKey, TimeRecordingViewModel } from '../view-model/time-recording.view-model';
import { ActionModel, ContainerModel, DropdownModel, ImageModel, Orientations, TextModel, ViewComponentKey } from '@typescript-kit/view';
import { MonthSelectViewService } from '../../shared/view/month-select.view-service';
import { SharedSvgKey } from '../../shared/key/shared-svg.key';
import { TimeRecordingViewKey } from '../key/time-recording-view.key';
import { EmployeeTimeRecordingSharedViewService } from './employee-time-recording-shared.view-service';
import { EmployeeSelectViewService } from '../../shared/view/employee-select.view-service';
import { EmployeeSelectViewFieldKey } from '../../shared/view/employee-select.view-model';

@Injectable()
export class EmployeeTimeRecordingShellMenuViewService extends ViewService<TimeRecordingViewModel, ContainerModel> {
  protected readonly sharedViewService = this.injector.get(EmployeeTimeRecordingSharedViewService);
  protected readonly employeeSelectViewService = new EmployeeSelectViewService(this.injector);
  protected readonly monthSelectViewService = new MonthSelectViewService(this.injector);

  private subscriptions: Subscription[];

  initialize() {
    const viewModel = this.sharedViewService.viewModel;
    this.employeeSelectViewService.initialize();
    this.monthSelectViewService.initialize(viewModel);
    super.initialize(viewModel);
    this.subscriptions = [
      this.employeeSelectViewService.viewModel.changedEvent
        .subscribe((event) => this.onEmployeeSelectViewModelPropertyChanged(event))
    ];
  }

  finalize() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.subscriptions = null;
    super.finalize();
    this.monthSelectViewService.finalize();
  }

  protected onViewModelPropertyChanged(event: ChangedEvent) {
    const changes = event.changes;
    if (changes[TimeRecordingViewFieldKey.EMPLOYEE_LIST] || changes[TimeRecordingViewFieldKey.EMPLOYEE_ID]) {
      this.employeeSelectViewService.viewModel.setValues({
        [EmployeeSelectViewFieldKey.EMPLOYEE_LIST]: this.viewModel.employeeList,
        [EmployeeSelectViewFieldKey.EMPLOYEE_ID]: this.viewModel.employeeId,
      });
    }
  }

  private onEmployeeSelectViewModelPropertyChanged(event: ChangedEvent) {
    if (event.changes[EmployeeSelectViewFieldKey.EMPLOYEE_ID]) {
      this.viewModel.employeeId = event.changes[EmployeeSelectViewFieldKey.EMPLOYEE_ID].currentValue;
    }
  }

  protected createComponentModel(): ContainerModel {
    return new ContainerModel({
      keys: 'employeeTimeRecordingShellMenuContainer',
      tags: ['app-employee-time-recording-shell-menu'],
      orientation: Orientations.SIDE_BY_SIDE,
      items: [
        this.employeeSelectViewService.componentModel,
        this.monthSelectViewService.componentModel,
        this.createToolsDropdownModel()
      ]
    });
  }

  // TODO: move to shell?
  private createToolsDropdownModel(): DropdownModel {
    return new DropdownModel({
      key: 'toolsDropdown',
      tags: ['app-tools-dropdown'],
      trigger: new ImageModel({
        type: ViewComponentKey.SVG_SYMBOL,
        source: SharedSvgKey.TOOLS
      }),
      items: [
        new ActionModel({
          key: 'to-pdf-action',
          type: ViewComponentKey.LINK,
          content: new TextModel({ text: `${TimeRecordingViewKey.TIME_RECORDING_TABLE}/create-pdf` }),
          onClick: () => {
            this.sharedViewService.createPdfReport();
          }
        }),
        new ActionModel({
          key: 'fill-expected-working-hours',
          type: ViewComponentKey.LINK,
          content: new TextModel({ text: `${TimeRecordingViewKey.TIME_RECORDING_TABLE}/fill-working-hours` }),
          onClick: () => {
            this.sharedViewService.fillExpectedWorkingHours();
          }
        })
      ]
    });
  }
}
