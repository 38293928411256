import { TimeRecordingService as GlobalTimeRecordingService } from '@teamworks/global';
import { Injectable } from '@angular/core';

import { HttpService } from '@angular-kit/core';
import { TargetHours, TargetHoursData } from '@teamworks/global';
// import { TimeRecord, TimeRecordData } from 'global/app/time-recording/model/time-record.model';


@Injectable()
export class TimeRecordingService extends GlobalTimeRecordingService {

  constructor(private httpService: HttpService) {
    super();
  }

  // async saveTimeRecord(data: TimeRecordData): Promise<{ timeRecord: TimeRecordData, targetHours: TargetHoursData}> {
  //   return this.httpService.post(`api/time-recording/time-record-with-target-hours`, data)
  //     .then((savedData: { timeRecord: TimeRecordData, targetHours: TargetHoursData }) => savedData);
  // }
  //
  // async deleteTimeRecord(data: TimeRecordData): Promise<{ timeRecord: TimeRecordData, targetHours: TargetHoursData}> {
  //   return this.httpService.delete(`api/time-record-with-target-hours/${data.employeeId}/${data.date}/${data.index}`)
  //     .then((deletedData: { timeRecord: TimeRecordData, targetHours: TargetHoursData }) => deletedData);
  // }

  async setDefaultWorkTargetHours(employeeId: string, date: string): Promise<TargetHours> {
    return this.httpService.post(`api/time-recording/set-default-work-target-hours/${employeeId}/${date}`)
      .then((savedData: TargetHoursData) => savedData && new TargetHours(savedData));
  }

  async clearDefaultWorkTargetHours(employeeId: string, date: string): Promise<TargetHours> {
    return this.httpService.post(`api/time-recording/clear-default-work-target-hours/${employeeId}/${date}`)
      .then((savedData: TargetHoursData) => savedData && new TargetHours(savedData));
  }

}
