<div class="app-user-sidebar-header">
  <div class="app-user-sidebar-header-title" [kitText]="titleText"></div>
  <kit-bs-button [kitModel]="addUserAction" class="app-user-sidebar-header-action"></kit-bs-button>
  <kit-bs-button [kitModel]="removeUserAction" class="app-user-sidebar-header-action"></kit-bs-button>
</div>
<div class="app-user-sidebar-items list-group list-group-flush">
  <button *ngFor="let item of userActionList" [kitModel]="item" type="button"
    class="list-group-item list-group-item-action">
  </button>
</div>
