import {
  Component,
  OnInit,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  OnDestroy
} from '@angular/core';
import { AccountingDetailViewService } from '../../view-service/accounting-detail.view-service';
import { ContainerModel, SelectInputModel } from '@typescript-kit/view';
import { AccountingCommonViewService } from '../../view-service/accounting-common.view-service';
import { AccountingShellMenuViewService } from '../../view-service/accounting-shell-menu.view-service';
import { AccountingListViewService } from '../../view-service/accounting-list.view-service';
import { ShellViewService } from '../../../shell/view/shell.view-service';

@Component({
  selector: 'app-accounting-detail',
  templateUrl: './accounting-detail.component.html',
  styleUrls: ['./accounting-detail.component.scss'],
  providers: [
    AccountingCommonViewService,
    AccountingShellMenuViewService,
    AccountingListViewService,
    AccountingDetailViewService
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountingDetailComponent implements OnInit, OnDestroy {

  constructor(
    private readonly shellViewService: ShellViewService,
    private readonly accountingShellMenuViewService: AccountingShellMenuViewService,
    private readonly accountingListViewService: AccountingListViewService,
    private readonly accountingCommonViewService: AccountingCommonViewService,
    private readonly accountingDetailViewService: AccountingDetailViewService
  ) {
  }

  get model(): ContainerModel {
    return this.accountingDetailViewService.componentModel;
  }

  get logo(): string {
    const logoSelectInput = this.accountingDetailViewService.componentModel.items.get('logo') as SelectInputModel;
    return logoSelectInput ? logoSelectInput.value : null;
  }

  ngOnInit() {
    this.accountingCommonViewService.initialize();
    this.accountingShellMenuViewService.initialize();
    this.accountingListViewService.initialize();
    this.accountingDetailViewService.initialize();
    this.shellViewService.setMenu(this.accountingShellMenuViewService.componentModel);
    this.shellViewService.setSidebar(this.accountingListViewService.componentModel);
  }

  ngOnDestroy(): void {
    this.shellViewService.restoreMenu();
    this.shellViewService.restoreSidebar();
    this.accountingDetailViewService.finalize();
    this.accountingListViewService.finalize();
    this.accountingShellMenuViewService.finalize();
    this.accountingCommonViewService.finalize();
  }
}
