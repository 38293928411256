import { Subscription } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ChangedEvent, toArray } from '@typescript-kit/core';
import { ViewService } from '@angular-kit/view';
import { PersistenceService } from '@angular-kit/core';
import { EmployeeSelectViewData, EmployeeSelectViewFieldKey, EmployeeSelectViewModel } from './employee-select.view-model';
import { ActionModel, DropdownModel, TextModel, ViewComponentKey } from '@typescript-kit/view';
import { Employee } from '@teamworks/global';
import { RouteBindingMode, RouteBindingModeKey } from '../model/route-binding-mode.model';
import { SharedPersistenceKey } from '../key/shared-persistence.key';
import { ShellViewKey } from '../../shell/key/shell-view.key';


@Injectable()
export class EmployeeSelectViewService extends ViewService<EmployeeSelectViewModel, DropdownModel> {
  private readonly router: Router;
  private readonly activatedRoute: ActivatedRoute;
  private readonly persistenceService: PersistenceService;

  private routeBindingMode: RouteBindingMode;
  private routeChangedSubscription: Subscription;

  constructor(
    injector: Injector,
  ) {
    super(injector);
    this.router = injector.get(Router);
    this.activatedRoute = injector.get(ActivatedRoute);
    this.persistenceService = injector.get(PersistenceService);
  }

  initialize(viewData?: EmployeeSelectViewData, routeBindingMode: RouteBindingMode = RouteBindingModeKey.TWO_WAY): void {
    this.routeBindingMode = routeBindingMode || RouteBindingModeKey.NONE;
    const viewModel = viewData instanceof EmployeeSelectViewModel
      ? viewData
      : new EmployeeSelectViewModel(viewData);
    super.initialize(viewModel);
    this.routeChangedSubscription = this.activatedRoute.paramMap
      .subscribe((paramMap: ParamMap) => this.onRouteChanged(paramMap));
  }

  finalize(): void {
    this.routeChangedSubscription.unsubscribe();
    super.finalize();
  }

  protected onViewModelPropertyChanged(event: ChangedEvent) {
    if (event.originalEvent && event.originalEvent.source !== event.source) {
      return;
    }
    const changes = event.changes;
    if (changes[EmployeeSelectViewFieldKey.EMPLOYEE_LIST] || changes[EmployeeSelectViewFieldKey.EMPLOYEE_ID]) {
      if (changes[EmployeeSelectViewFieldKey.EMPLOYEE_ID]) {
        if (this.routeBindingMode === RouteBindingModeKey.TWO_WAY || this.routeBindingMode === RouteBindingModeKey.MODEL_TO_ROUTE) {
          this.updateRoute();
        }
      } else if (changes[EmployeeSelectViewFieldKey.EMPLOYEE_LIST]) {
        if (this.routeBindingMode === RouteBindingModeKey.TWO_WAY || this.routeBindingMode === RouteBindingModeKey.ROUTE_TO_MODEL) {
          this.onRouteChanged(this.activatedRoute.snapshot.paramMap);
        }
      }
      this.refreshComponentModel(this.componentModel);
    }
  }

  private onRouteChanged(paramMap: ParamMap) {
    const employeeList = this.viewModel.employeeList;
    if (!employeeList) {
      return;
    }
    let employeeId = paramMap.get(EmployeeSelectViewFieldKey.EMPLOYEE_ID);
    if (!employeeId || !employeeList.find(employee => employee.id === employeeId)) {
      employeeId = this.persistenceService.getValue(SharedPersistenceKey.EMPLOYEE);
      if (!employeeId) {
        if (employeeList.length > 0) {
          employeeId = employeeList[0].id;
        } else {
          employeeId = null;
        }
      } else {
        if (!employeeList.find(employee => employee.id === employeeId)) {
          employeeId = null;
        }
      }
    }
    if (employeeId) {
      this.persistenceService.setValue(SharedPersistenceKey.EMPLOYEE, employeeId);
    }
    this.viewModel.setValues({ employeeId });
  }

  protected updateRoute() {
    const path = this.activatedRoute.routeConfig.path
      .replace(/(^|\/):([^\/]+)/g, (match, prefix, paramKey) => {
        const paramValue = paramKey === EmployeeSelectViewFieldKey.EMPLOYEE_ID
          ? this.viewModel.employeeId || '-'
          : this.activatedRoute.snapshot.paramMap.get(paramKey);
        return `${prefix}${paramValue}`;
      });
    this.router
      .navigate([path], { relativeTo: this.activatedRoute.parent, queryParamsHandling: 'preserve' })
      .catch((error) => {
        this.alertService.alertError(error);
      });
  }

  protected createComponentModel(): DropdownModel {
    const model = new DropdownModel({
      key: 'employeeDropdown', tags: ['app-employee-dropdown'],
      trigger: new TextModel({}),
      scrollToActiveItem: true
    });
    this.refreshComponentModel(model);
    return model;
  }

  protected refreshComponentModel(model: DropdownModel): void {
    const employeeId = this.viewModel.employeeId;
    const employeeList = this.viewModel.employeeList;
    model.setValues({
      isHidden: !employeeList,
      items: toArray(employeeList).map((employee) => this.createEmployeeDropdownItem(employee))
    });
    const selectedEmployee = employeeList && employeeId ? employeeList.find((employee) => employee.id === employeeId) : null;
    const triggerModel = model.trigger as TextModel;
    triggerModel.text = selectedEmployee ? selectedEmployee.name : `#(${ShellViewKey.EMPLOYEE_SELECT}/no-user-selected)`;
  }

  private createEmployeeDropdownItem(employee: Employee): ActionModel {
    const isActive = this.viewModel.employeeId === employee.id;
    return new ActionModel({
      key: employee.id, tabIndex: -1, tags: { active: isActive },
      type: ViewComponentKey.LINK,
      content: employee.name,
      isActive,
      onClick: () => this.viewModel.employeeId = employee.id,
      onChanged: (event: ChangedEvent, model: ActionModel) => {
        if (event.changes['isActive']) {
          model.tags.set('active', model.isActive);
        }
      }
    });
  }
}
