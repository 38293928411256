import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, ViewChild, Input } from '@angular/core';
import { SelectInputModel } from '@typescript-kit/view';
import { SelectInputComponent } from '@angular-kit/view';

@Component({
  selector: 'kit-bs-select-input',
  templateUrl: './bs-select-input.component.html',
  styleUrls: ['./bs-select-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BsSelectInputComponent {

  @ViewChild(SelectInputComponent, { static: true }) selectInputComponent: SelectInputComponent;

  @Input('kitModel')
  set model(model: SelectInputModel) {
    this.selectInputComponent.model = model;
  }

  get model(): SelectInputModel {
    return this.selectInputComponent.model;
  }

}
