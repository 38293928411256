import { Injectable, Injector } from '@angular/core';
import { ViewService } from '@angular-kit/view';
import { ChangedEvent, hasValue, ObservableList, ObserveModes } from '@typescript-kit/core';
import { SubscriptionViewData, SubscriptionViewFieldKey, SubscriptionViewModel } from './subscription.view-model';
import { CompanyService } from '../service/company.service';
import { EntityService } from '../service/entity.service';
import { ShellViewService } from '../../shell/view/shell.view-service';
import { CompanyModel, SubscriptionModel } from '@teamworks/global';

@Injectable()
export class SubscriptionViewService extends ViewService<SubscriptionViewModel, any> {
  constructor(
    injector: Injector,
    private readonly companyService: CompanyService,
    private readonly entityService: EntityService,
    private readonly shellViewService: ShellViewService
  ) {
    super(injector);
  }

  initialize(viewData?: SubscriptionViewData): void {
    const viewModel = viewData instanceof SubscriptionViewModel
      ? viewData
      : new SubscriptionViewModel(viewData);
    super.initialize(viewModel);
    // noinspection JSIgnoredPromiseFromCall
    this.refreshSubscriptions();
    // noinspection JSIgnoredPromiseFromCall
    this.refreshCompany();
  }

  public async refreshSubscriptions() {
    try {
      this.shellViewService.incrementLoadingCounter();
      const subscriptions = await this.companyService.loadActiveSubscriptionList();
      this.setSubscriptions(subscriptions);
    } catch (error) {
      if (this.isFinalized) {
        return;
      }
      this.alertService.alertError(error);
    } finally {
      this.shellViewService.decrementLoadingCounter();
    }
  }

  public async refreshCompany() {
    try {
      this.shellViewService.incrementLoadingCounter();
      const company = await this.companyService.loadCompany();
      if (this.viewModel.company) {
        this.viewModel.company.setValues(company);
      } else {
        this.viewModel.company = new CompanyModel(company);
      }
    } catch (error) {
      if (this.isFinalized) {
        return;
      }
      this.alertService.alertError(error);
    } finally {
      this.shellViewService.decrementLoadingCounter();
    }
  }

  protected onViewModelPropertyChanged(event: ChangedEvent) {
    if (event.originalEvent !== event) {
      const originalEvent = event.originalEvent;
      if (originalEvent.source === this.viewModel.company) {
        this.onCompanyChanged(originalEvent);
      }
      return;
    }
    if (event.changes[SubscriptionViewFieldKey.REGULAR_SUBSCRIPTION]) {
      this.viewModel.paymentMethod = hasValue(this.viewModel.regularSubscription) ? 'bill' : null;
    }
  }

  private onCompanyChanged(event: ChangedEvent) {
    if (event.originalEvent.source instanceof CompanyModel) {
      this.saveCompany(event.originalEvent.source);
    }
  }

  public async saveCompany(entityModel: CompanyModel) {
    const savedEntity = await this.entityService.scheduleModify(
      entityModel, () => this.companyService.saveCompany(entityModel)
    );
    if (this.isFinalized) {
      return;
    }
    entityModel.setValues({ id: savedEntity.id }, { silent: true });
  }

  public async deleteCompany() {
    try {
      await this.companyService.deleteCompany();
      window.location.reload();
    } catch (error) {
      this.alertService.alertError(error);
    }
  }

  public async setSubscriptionState(isActive: boolean) {
    try {
      const subscriptions = await this.companyService.setSubscriptionState(isActive);
      if (this.isFinalized) {
        return;
      }
      this.setSubscriptions(subscriptions);
    } catch (error) {
      this.alertService.alertError(error);
    }
  }

  private setSubscriptions(subscriptions: SubscriptionModel[]) {
    this.viewModel.setValues({
      trialSubscription: subscriptions.find((s) => s.type === 'trial'),
      regularSubscription: subscriptions.find((s) => s.type === 'normal'),
      currentSubscriptions: new ObservableList(subscriptions, { observeMode: ObserveModes.FLAT })
    });
  }
}
