import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { ChangedEvent } from '@typescript-kit/core';
import { ViewService } from '@angular-kit/view';
import { LogbookViewFieldKey, LogbookViewModel } from '../view-model/logbook.view-model';
import { ContainerModel, Orientations } from '@typescript-kit/view';
import { EmployeeSelectViewService } from '../../shared/view/employee-select.view-service';
import { MonthSelectViewService } from '../../shared/view/month-select.view-service';
import { LogbookSharedViewService } from './logbook-shared.view-service';
import { EmployeeSelectViewFieldKey } from '../../shared/view/employee-select.view-model';

@Injectable()
export class LogbookShellMenuViewService extends ViewService<LogbookViewModel, ContainerModel> {
  protected readonly sharedViewService = this.injector.get(LogbookSharedViewService);
  protected readonly employeeSelectViewService = new EmployeeSelectViewService(this.injector);
  protected readonly monthSelectViewService = new MonthSelectViewService(this.injector);

  private subscriptions: Subscription[];

  initialize() {
    const viewModel = this.sharedViewService.viewModel;
    this.employeeSelectViewService.initialize();
    this.monthSelectViewService.initialize(viewModel);
    super.initialize(viewModel);
    this.subscriptions = [
      this.employeeSelectViewService.viewModel.changedEvent
        .subscribe((event) => this.onEmployeeSelectViewModelPropertyChanged(event))
    ];
  }

  finalize() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.subscriptions = null;
    super.finalize();
    this.monthSelectViewService.finalize();
  }

  protected onViewModelPropertyChanged(event: ChangedEvent) {
    const changes = event.changes;
    if (changes[LogbookViewFieldKey.EMPLOYEE_LIST] || changes[LogbookViewFieldKey.EMPLOYEE_ID]) {
      this.employeeSelectViewService.viewModel.setValues({
        [EmployeeSelectViewFieldKey.EMPLOYEE_LIST]: this.viewModel.employeeList,
        [EmployeeSelectViewFieldKey.EMPLOYEE_ID]: this.viewModel.employeeId,
      });
    }
  }

  private onEmployeeSelectViewModelPropertyChanged(event: ChangedEvent) {
    if (event.changes[EmployeeSelectViewFieldKey.EMPLOYEE_ID]) {
      this.viewModel.employeeId = event.changes[EmployeeSelectViewFieldKey.EMPLOYEE_ID].currentValue;
    }
  }

  protected createComponentModel(): ContainerModel {
    return new ContainerModel({
      keys: 'employeeTimeRecordingShellMenuContainer',
      tags: ['app-logbook-shell-menu'],
      orientation: Orientations.SIDE_BY_SIDE,
      items: [
        this.employeeSelectViewService.componentModel,
        this.monthSelectViewService.componentModel
      ]
    });
  }
}
