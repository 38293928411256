import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActionModel, ComponentFieldKey, ImageModel, SelectInputModel, TextModel, ViewComponentKey } from '@typescript-kit/view';
import { ChangedEvent, LocaleService, toArray } from '@typescript-kit/core';
import { Subscription } from 'rxjs';
import { SharedSvgKey } from '../../../shared/key/shared-svg.key';
import { ProjectFieldKey, ProjectModel } from '@teamworks/global';
import { TimeRecordingViewKey } from '../../key/time-recording-view.key';
import { ProjectCommonViewService } from '../../view-service/project-common-shared.view-service';
import { ProjectCommonViewFieldKey, ProjectCommonViewModel } from '../../view-model/project-common.view-model';
import { MatDialog } from '@angular/material/dialog';
import { DeleteProjectDialogComponent } from '../delete-project-dialog/delete-project-dialog.component';

@Component({
  selector: 'app-project-sidebar',
  templateUrl: './project-sidebar.component.html',
  styleUrls: ['./project-sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectSidebarComponent implements OnInit, OnDestroy {
  public titleText: TextModel;
  public addProjectAction: ActionModel;
  public removeProjectAction: ActionModel;
  public typeSelectInput: SelectInputModel;
  public projectActionList: ActionModel[];

  private readonly projectTypes = ['active', 'inactive', 'unconfirmed'];

  private viewModelChangedSubscription: Subscription;

  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly localeService: LocaleService,
    private readonly viewService: ProjectCommonViewService,
    private readonly matDialog: MatDialog
  ) {
  }

  get viewModel(): ProjectCommonViewModel {
    return this.viewService.viewModel;
  }

  ngOnInit(): void {
    this.viewModelChangedSubscription = this.viewModel.changedEvent.subscribe(event => this.onViewModelChanged(event));
    this.createComponentModels();
  }

  ngOnDestroy(): void {
    this.viewModelChangedSubscription.unsubscribe();
  }

  private onViewModelChanged(event: ChangedEvent) {
    if (event.originalEvent !== event) {
      if (event.originalEvent.source === this.viewModel.projectList) {
        this.onProjectChanged(event.originalEvent);
      }
      return;
    }
    if (event.changes[ProjectCommonViewFieldKey.PROJECT_LIST]) {
      this.onProjectListChanged();
    }
    if (event.changes[ProjectCommonViewFieldKey.PROJECT_ID]) {
      this.onProjectIdChanged();
    }
    if (event.changes[ProjectCommonViewFieldKey.IS_PROJECT_ADMIN]) {
      this.refreshHeaderActionModels();
    }
    if (event.changes[ProjectCommonViewFieldKey.PROJECT]) {
      this.refreshSelectedType();
    }
  }

  private onProjectListChanged() {
    this.refreshProjectActionModels();
    this.changeDetector.markForCheck();
  }

  private onProjectIdChanged() {
    const selectedIndex = this.projectActionList.findIndex(item => item.isActive);
    const actionIndex = this.projectActionList.findIndex(item => item.key === this.viewModel.projectId);
    if (actionIndex === selectedIndex) {
      return;
    }
    if (selectedIndex >= 0) {
      this.projectActionList[selectedIndex].isActive = false;
    }
    if (actionIndex >= 0) {
      this.projectActionList[actionIndex].isActive = true;
    }
  }

  private onProjectChanged(event: ChangedEvent) {
    if (event.originalEvent !== event) {
      if (event.originalEvent.source instanceof ProjectModel) {
        this.onProjectPropertyChanged(event.originalEvent);
      }
      return;
    }
    this.refreshProjectActionModels();
    this.changeDetector.markForCheck();
  }

  private onProjectPropertyChanged(event: ChangedEvent) {
    if (event.source === this.viewModel.project
      && (event.changes[ProjectFieldKey.IS_ACTIVE] || event.changes[ProjectFieldKey.IS_CONFIRMED])) {
      this.refreshSelectedType();
    }

    if (event.changes[ProjectFieldKey.NAME]
      || event.changes[ProjectFieldKey.IS_ACTIVE]
      || event.changes[ProjectFieldKey.IS_CONFIRMED]) {
      // re-order list
      this.refreshProjectActionModels();
    } else {
      const projectModel = event.source as ProjectModel;
      const actionKey = event.changes[ProjectFieldKey.ID]
        ? event.changes[ProjectFieldKey.ID].originalValue
        : projectModel.id;
      const actionIndex = this.projectActionList.findIndex(item => item.key === actionKey);
      if (actionIndex >= 0) {
        this.projectActionList[actionIndex] = this.createProjectActionModel(projectModel);
      }
    }
    this.changeDetector.markForCheck();
  }

  private createComponentModels(): void {
    this.titleText = new TextModel({
      text: `#(${TimeRecordingViewKey.PROJECT}/name)`
    });
    this.addProjectAction = new ActionModel({
      content: new ImageModel({
        type: ViewComponentKey.SVG_SYMBOL,
        source: SharedSvgKey.ADD_DOCUMENT
      }),
      isHidden: !this.viewModel.isProjectAdmin,
      onClick: () => this.viewService.createProject()
    });
    this.removeProjectAction = new ActionModel({
      content: new ImageModel({
        type: ViewComponentKey.SVG_SYMBOL,
        source: SharedSvgKey.DELETE_DOCUMENT
      }),
      isHidden: !this.viewModel.isProjectAdmin,
      onClick: async () => {
        if (this.viewModel.projectId === undefined) {
          return;
        }

        const dialog = this.matDialog.open(DeleteProjectDialogComponent, {
          width: '400px',
          data: {
            projects: toArray(this.viewModel.projectList)
          },
          panelClass: 'overflow-visible'
        });
        const replacementProjectId = await dialog.afterClosed().toPromise();
        if (replacementProjectId !== undefined) {
          await this.viewService.deleteProject(this.viewModel.project, replacementProjectId);
        }
      }
    });
    this.typeSelectInput = new SelectInputModel({
      options: this.projectTypes,
      value: 'active',
      onValueChanged: () => this.refreshProjectActionModels(),
      formattedOptions: this.projectTypes.map((p) => `time-recording/view/project-sidebar/type-dropdown/${p}`),
      isHidden: !this.viewModel.isProjectAdmin
    });

    this.refreshHeaderActionModels();
    this.refreshProjectActionModels();
  }

  private refreshHeaderActionModels(): void {
    const isHidden = !this.viewModel.isProjectAdmin;
    this.addProjectAction.setValues({ isHidden });
    this.removeProjectAction.setValues({ isHidden });
    this.typeSelectInput.setValues({ isHidden });

    this.changeDetector.markForCheck();
  }

  private refreshProjectActionModels(): void {
    this.projectActionList = toArray(this.viewModel.projectList)
      .filter((p) => {
        if (p.parentId) {
          return false;
        }

        if (!this.viewModel.isProjectAdmin) {
          return p.isActive && p.isConfirmed;
        }

        switch (this.typeSelectInput.value) {
          case 'active': return p.isActive && p.isConfirmed;
          case 'inactive': return !p.isActive && p.isConfirmed;
          case 'unconfirmed': return !p.isConfirmed;
          default: return true;
        }
      })
      .sort((a, b) => (a.name || '').localeCompare((b.name || ''), this.localeService.localeKey, { sensitivity: 'accent' }))
      .map((entityModel) => this.createProjectActionModel(entityModel));

    this.changeDetector.markForCheck();
  }

  private createProjectActionModel(entityModel: ProjectModel): ActionModel {
    const id = entityModel.id;
    const isSelected = this.viewModel.projectId === id;
    const model = new ActionModel({
      type: ViewComponentKey.BUTTON,
      tags: { active: isSelected, 'app-project-state': entityModel.isActive ? 'active' : 'inactive' },
      key: entityModel.id,
      content: entityModel.name || '-',
      isActive: isSelected,
      onClick: () => {
        this.viewModel.projectId = entityModel.id;
      },
      onChanged: (event: ChangedEvent) => {
        if (event.changes[ComponentFieldKey.IS_ACTIVE]) {
          model.tags.set('active', event.changes[ComponentFieldKey.IS_ACTIVE].currentValue);
        }
      }
    });
    return model;
  }

  private refreshSelectedType() {
    if (!this.viewModel.project) {
      this.typeSelectInput.value = 'active';
      return;
    }

    this.typeSelectInput.value = this.viewModel.project.isConfirmed
      ? this.viewModel.project.isActive
        ? 'active'
        : 'inactive'
      : 'unconfirmed';
  }
}
