<h2 class="app-employee-detail-title" *ngIf="viewModel.employee">{{displayName}} </h2>

<kit-bs-form-field [kitModel]="nameField"></kit-bs-form-field>
<app-employment-duration [employeeId]="viewModel.employeeId" *ngIf="viewModel.employeeId"></app-employment-duration>

<div [kitText]="authorizationTitle" class="app-tag-detail-title"></div>
<app-tag-table [model]="tagTable" *ngIf="viewModel.employeeId"></app-tag-table>
<div class="app-tag-action-container">
<kit-bs-button [kitModel]="assignTokenAction"></kit-bs-button>
<!--<kit-bs-button [kitModel]="assignPinAction"></kit-bs-button>-->
</div>

<ng-template #sidebarTemplate>
  <app-employee-sidebar></app-employee-sidebar>
</ng-template>
