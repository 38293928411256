import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { ViewService } from '@angular-kit/view';
import { ActionModel, ContainerModel, DropdownModel, ImageModel, Orientations, TextModel, ViewComponentKey } from '@typescript-kit/view';
import { MonthSelectViewService } from '../../shared/view/month-select.view-service';
import { SharedSvgKey } from '../../shared/key/shared-svg.key';
import { TimeRecordingViewKey } from '../key/time-recording-view.key';
import { ProjectCommonViewModel } from '../view-model/project-common.view-model';
import { ProjectCommonViewService } from './project-common-shared.view-service';

@Injectable()
export class ProjectCommonShellMenuViewService extends ViewService<ProjectCommonViewModel, ContainerModel> {
  protected readonly sharedViewService = this.injector.get(ProjectCommonViewService);
  protected readonly monthSelectViewService = new MonthSelectViewService(this.injector);

  private subscriptions: Subscription[] = [];

  initialize() {
    const viewModel = this.sharedViewService.viewModel;
    this.monthSelectViewService.initialize(viewModel);
    super.initialize(viewModel);
  }

  finalize() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.subscriptions = null;
    super.finalize();
    this.monthSelectViewService.finalize();
  }

  protected createComponentModel(): ContainerModel {
    return new ContainerModel({
      keys: 'projectTimeRecordingShellMenuContainer',
      tags: ['app-project-time-recording-shell-menu', 'justify-content-end'],
      orientation: Orientations.SIDE_BY_SIDE,
      items: [
        this.monthSelectViewService.componentModel,
        this.createToolsDropdownModel()
      ]
    });
  }

  protected refreshComponentModel(model: ContainerModel) {
    model.setValues({
      isHidden: !this.sharedViewService.viewModel.canSeeOverview
    });
  }

  // TODO: move to shell?
  private createToolsDropdownModel(): DropdownModel {
    return new DropdownModel({
      key: 'toolsDropdown',
      tags: ['app-tools-dropdown'],
      trigger: new ImageModel({
        type: ViewComponentKey.SVG_SYMBOL,
        source: SharedSvgKey.TOOLS
      }),
      items: [
        new ActionModel({
          key: 'to-pdf-action',
          type: ViewComponentKey.LINK,
          content: new TextModel({ text: `${TimeRecordingViewKey.TIME_RECORDING_TABLE}/create-pdf` }),
          onClick: () => {
            this.sharedViewService.createPdfReport();
          }
        })
      ]
    });
  }
}
