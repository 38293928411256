import moment from 'moment';
import { Injectable } from '@angular/core';
import { ApplicationError, ChangedEvent, hasValue, ObservableList, ObserveModes } from '@typescript-kit/core';
import { ViewService } from '@angular-kit/view';
import { ProjectModel, ProjectTimeRecordActivities, ProjectTimeRecordModel, SharedPermissionKey, TimeRecord, PublicHoliday, TagMapping, TagMappingTargetTypes, TagMappingTagTypes } from '@teamworks/global';
import { ReportService } from '../service/report.service';
import { TimeRecordService } from '../service/time-record.service';
import { PublicHolidayService } from '../service/public-holiday.service';
import { ShellViewService } from '../../shell/view/shell.view-service';
import { ProjectService } from '../service/project.service';
import { EntityService } from '../../shared/service/entity.service';
import { TimeRecordingViewKey } from '../key/time-recording-view.key';
import { ProjectCommonViewData, ProjectCommonViewFieldKey, ProjectCommonViewModel } from '../view-model/project-common.view-model';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { EmployeeService } from '../../shared/service/employee.service';
import { SortOrders } from '@typescript-kit/data';
import { Subscription } from 'rxjs';
import { AuthenticationService } from '@angular-kit/authentication';
import { AuthorizationService } from '@teamworks/angular';
import { TagService } from '../service/tag.service';

@Injectable()
export class ProjectCommonViewService extends ViewService<ProjectCommonViewModel, any> {
  // TODO: move to shell
  // private readonly printMediaQueryList: MediaQueryList = window.matchMedia('print');
  // private readonly printMediaQueryEventListener = (event) => this.onPrintMediaQueryChanged(event);

  private readonly entityService = this.injector.get(EntityService);
  private readonly timeRecordService = this.injector.get(TimeRecordService);
  private readonly activatedRoute = this.injector.get(ActivatedRoute);
  private readonly router = this.injector.get(Router);
  private readonly employeeService = this.injector.get(EmployeeService);
  private readonly projectService = this.injector.get(ProjectService);
  private readonly publicHolidayService = this.injector.get(PublicHolidayService);
  private readonly reportService = this.injector.get(ReportService);
  private readonly authenticationService = this.injector.get(AuthenticationService);
  private readonly authorizationService = this.injector.get(AuthorizationService);
  private readonly tagService = this.injector.get(TagService);

  private readonly shellViewService = this.injector.get(ShellViewService);

  private subscriptions: Subscription[] = [];

  initialize(viewData?: ProjectCommonViewData) {
    const viewModel = viewData instanceof ProjectCommonViewModel
      ? viewData
      : new ProjectCommonViewModel(viewData);
    // viewModel.printView = this.printMediaQueryList.matches;
    // TODO: Remove activatedRoute and query parameter
    viewModel.printView = this.activatedRoute.snapshot.queryParamMap.get('print') === 'true';

    super.initialize(viewModel);

    this.subscriptions.push(this.activatedRoute.paramMap
      .subscribe((paramMap) => this.onRouteChanged(paramMap)));

    this.subscriptions.push(this.authenticationService.currentUserChanged
      .subscribe(() => this.refreshIsProjectAdmin()));

    // if (typeof this.printMediaQueryList.addEventListener === 'function') {
    //   this.printMediaQueryList.addEventListener('change', this.printMediaQueryEventListener);
    // } else {
    //   // noinspection JSDeprecatedSymbols
    //   this.printMediaQueryList.addListener(this.printMediaQueryEventListener);
    // }
    this.refreshIsProjectAdmin();
    this.refreshEmployeeList();
    this.refreshProjectList();
    if (!this.viewModel.projectId) {
      this.shellViewService.componentModel.isSidebarVisible = true;
    }
  }

  finalize() {
    this.subscriptions.forEach((s) => s.unsubscribe());
    // if (typeof this.printMediaQueryList.removeEventListener === 'function') {
    //   this.printMediaQueryList.removeEventListener('change', this.printMediaQueryEventListener);
    // } else {
    //   // noinspection JSDeprecatedSymbols
    //   this.printMediaQueryList.removeListener(this.printMediaQueryEventListener);
    // }
    super.finalize();
  }

  protected async onViewModelPropertyChanged(event: ChangedEvent) {
    if (event.originalEvent !== event) {
      const originalEvent = event.originalEvent;
      if (originalEvent.source === this.viewModel.projectList) {
        this.onProjectChanged(originalEvent);
      }
      // TODO MJ: QUICK FIX
      if (originalEvent.source instanceof ProjectModel) {
        await this.saveProject(originalEvent.source);
        await this.refreshProjectList();
      }
      // TODO MJ: QUICK FIX
      return;
    }
    const changes = event.changes;
    if (changes[ProjectCommonViewFieldKey.CURRENT_TAB] || changes[ProjectCommonViewFieldKey.PROJECT_ID]) {
      this.updateRoute();
    }
    if (changes[ProjectCommonViewFieldKey.PROJECT_ID] || changes[ProjectCommonViewFieldKey.PROJECT_LIST]) {
      const projectList = this.viewModel.projectList;
      const projectId = this.viewModel.projectId;
      const project = projectList && projectList.firstValue((p) => p.id === projectId) || null;
      const activityList = project && projectList.findValues((p) => p.parentId === projectId);
      if (projectList && !project) {
        this.viewModel.projectId = undefined;
      }

      const permissions = await this.authorizationService.loadPermissionList();
      const overviewPermissions = permissions.filter((p) => p.key === SharedPermissionKey.PROJECT_OVERVIEW);
      const canSeeOverview = overviewPermissions.find((p) => p.options?.projectId === '*' || p.options?.projectId === this.viewModel.projectId) !== undefined;

      this.viewModel.setValues({ canSeeOverview, project, activityList: new ObservableList(activityList, { observeMode: ObserveModes.FLAT }) });
    }
    if (changes[ProjectCommonViewFieldKey.ACTIVITY_LIST]) {
      await this.refreshActivityTags();
    }
    if (!this.viewModel.canSeeOverview) {
      return;
    }
    if (
      changes[ProjectCommonViewFieldKey.PROJECT_ID] ||
      this.viewModel.projectId && (changes[ProjectCommonViewFieldKey.YEAR] || changes[ProjectCommonViewFieldKey.MONTH])
    ) {
      this.shellViewService.incrementLoadingCounter();

      if (changes[ProjectCommonViewFieldKey.PROJECT_ID]) {
        this.viewModel.setValues({ project: await this.loadProject() });
      }

      try {
        const [timeRecordArray, publicHolidayList] = await Promise.all([this.loadTimeRecordList(), this.loadPublicHolidayList()]);
        if (!this.isFinalized) {
          const timeRecordList = this.createTimeRecordObservableList(timeRecordArray, publicHolidayList || this.viewModel.publicHolidayList);
          this.viewModel.setValues({ timeRecordList, publicHolidayList });
        }
      } catch (error) {
        console.error('Unexpected error', error);
      }

      this.shellViewService.decrementLoadingCounter();
    }
  }

  private onProjectChanged(event: ChangedEvent) {
    const source = event.originalEvent.source;
    if (event.originalEvent !== event && source instanceof ProjectModel) {
      this.saveProject(source);
    }
  }

  public async createActivity(): Promise<ProjectModel> {
    if (!this.viewModel.projectId) {
      return;
    }
    let activityModel = new ProjectModel({ id: null, isActive: true, name: '', isConfirmed: true, parentId: this.viewModel.projectId });
    const project = await this.projectService.saveProject(activityModel);
    activityModel = new ProjectModel(project);
    this.viewModel.projectList.push(activityModel);
    this.viewModel.activityList.insert(0, activityModel);
    return activityModel;
  }

  public async createProject(): Promise<ProjectModel> {
    if (this.viewModel.projectList.firstValue((project) => project.id === null)) {
      this.viewModel.projectId = null;
      return;
    }
    let projectModel = new ProjectModel({ id: null, isActive: true, name: '', isConfirmed: true });
    const project = await this.projectService.saveProject(projectModel);
    projectModel = new ProjectModel(project);
    this.viewModel.projectList.insert(0, projectModel);
    this.viewModel.setValues({ projectId: projectModel.id, currentTab: 'management' });
    return projectModel;
  }

  public async saveProject(entityModel: ProjectModel) {
    const savedEntity = await this.entityService.scheduleModify(
      entityModel, () => this.projectService.saveProject(entityModel)
    );
    if (this.isFinalized) {
      return;
    }
    entityModel.setValues({ id: savedEntity.id }, { silent: true });
    if (this.viewModel.project === entityModel) {
      this.viewModel.projectId = savedEntity.id;
    }
  }

  public async deleteProject(entityModel: ProjectModel, replacementProjectId: string | null = null) {
    if (!entityModel) {
      return;
    }
    if (entityModel.modified) {
      await this.entityService.scheduleModify(
        entityModel, () => this.projectService.deleteProject(entityModel.id, { replacementProjectId })
      );
    }
    if (this.isFinalized) {
      return;
    }
    const entityIndex = this.viewModel.projectList.firstIndex((entity) => entity.id === entityModel.id);
    if (entityIndex < 0) {
      return;
    }
    this.viewModel.projectList.remove(entityIndex);
    if (this.viewModel.projectId === entityModel.id) {
      this.viewModel.projectId = undefined;
    }
  }

  public async refreshActivityTags(): Promise<void> {
    const promises: Promise<[string, TagMapping[]]>[] = [];

    for (const activity of (this.viewModel.activityList ?? [])) {
      promises.push(
        this.tagService
          .loadTagMappingList({
            filter: {
              targetId: activity.id,
              targetType: TagMappingTargetTypes.PROJECT,
              tagType: TagMappingTagTypes.NFC
            }
          })
          .then((tagMappings) => [activity.id, tagMappings])
      );
    }

    const activityTagList = await Promise.all(promises);

    this.viewModel.setValues({ activityTags: new Map<string, TagMapping[]>(activityTagList) });
  }

  public async refreshTimeRecordList(): Promise<void> {
    const timeRecordList = await this.loadTimeRecordList();
    if (!this.isFinalized) {
      this.viewModel.timeRecordList = this.createTimeRecordObservableList(timeRecordList, this.viewModel.publicHolidayList);
    }
  }

  public async loadTimeRecordList(): Promise<TimeRecord[]> {
    const projectId = this.viewModel.projectId;
    const year = this.viewModel.year;
    const month = this.viewModel.month;
    if (!this.viewModel.canSeeOverview || !projectId || !year || !month) {
      return undefined;
    }

    return this.timeRecordService.loadProjectTimeRecordListByMonth(projectId, year, month)
      .then((records) => records.sort((a, b) => {
        const timeDif = moment(a.date).diff(moment(b.date), 'minutes') + moment.duration(a.from).subtract(moment.duration(b.from)).asMinutes();
        if (timeDif === 0) {
          return a.employeeId > b.employeeId ? 1 : a.employeeId < b.employeeId ? -1 : 0;
        }
        return timeDif;
      }))
      .catch((error: ApplicationError) => {
        if (!this.isFinalized) {
          this.alertService.alertError(error);
        }
        return undefined;
      });
  }

  public async refreshIsProjectAdmin() {
    try {
      this.shellViewService.incrementLoadingCounter();
      const permissions = await this.authorizationService.loadPermissionList();
      if (!this.isFinalized) {
        this.viewModel.isProjectAdmin = permissions.find((p) => p.key === SharedPermissionKey.PROJECT_ADMIN) !== undefined;
      }
    } catch (error) {
      if (!this.isFinalized) {
        this.viewModel.employeeList = null;
        this.alertService.alertError(error);
      }
    } finally {
      this.shellViewService.decrementLoadingCounter();
    }
  }

  public async refreshEmployeeList() {
    try {
      this.shellViewService.incrementLoadingCounter();
      const employeeList = await this.employeeService.loadEmployeeList();
      if (!this.isFinalized) {
        this.viewModel.employeeList = employeeList;
      }
    } catch (error) {
      if (!this.isFinalized) {
        this.viewModel.employeeList = null;
        this.alertService.alertError(error);
      }
    } finally {
      this.shellViewService.decrementLoadingCounter();
    }
  }

  public async refreshProjectList(): Promise<void> {
    try {
      const projectList = await this.projectService.loadProjectList({ sort: { name: SortOrders.ASC }, filter: { isActive: '*' } });
      if (!this.isFinalized) {
        this.viewModel.projectList = new ObservableList<ProjectModel>(
          projectList.map((project) => new ProjectModel(project)), { observeMode: ObserveModes.FLAT }
        );
      }
    } catch (error) {
      if (!this.isFinalized) {
        this.viewModel.projectList = null;
        this.alertService.alertError(error);
      }
    }
  }

  public async loadProjectList() {
    return this.projectService.loadProjectList({ filter: { isActive: '*' } })
      .catch((error: ApplicationError) => {
        if (!this.isFinalized) {
          this.alertService.alertError(error);
        }
        return undefined;
      });
  }

  public async loadPublicHolidayList() {
    const year = this.viewModel.year;
    const month = this.viewModel.month;
    if (!year || !month) {
      return undefined;
    }
    return this.publicHolidayService.loadPublicHolidayList(this.viewModel.year, this.viewModel.month)
      .catch((error: ApplicationError) => {
        if (!this.isFinalized) {
          this.alertService.alertError(error);
        }
        return undefined;
      });
  }

  public async loadProject(): Promise<ProjectModel> {
    const projectId = this.viewModel.projectId;
    if (projectId) {
      try {
        return this.projectService
          .loadProject(projectId, { filter: { isActive: '*' } })
          .then((project) => new ProjectModel(project));
      } catch (error) {
        if (!this.isFinalized) {
          this.alertService.alertError(error);
        }
      }
    }

    return undefined;
  }

  private createTimeRecordObservableList(
    timeRecordList: TimeRecord[], publicHolidayList: PublicHoliday[]
  ): ObservableList<ProjectTimeRecordModel> {
    if (!timeRecordList) {
      return null;
    }

    let timeRecordIndex = 0;
    const year = +this.viewModel.year;
    const month = +this.viewModel.month - 1;
    const momentDate = moment([year, month, 1]);
    const timeRecordModels: ProjectTimeRecordModel[] = [];
    while (momentDate.month() === month) {
      const date = momentDate.format('YYYY-MM-DD');
      if (timeRecordIndex < timeRecordList.length && timeRecordList[timeRecordIndex].date === date) {
        timeRecordModels.push(...this.getProjectTimeRecords(timeRecordList[timeRecordIndex]));
        timeRecordIndex++;
        while (timeRecordIndex < timeRecordList.length && timeRecordList[timeRecordIndex].date === date) {
          timeRecordModels.push(...this.getProjectTimeRecords(timeRecordList[timeRecordIndex]));
          timeRecordIndex++;
        }
      } else {
        const publicHoliday = this.findPublicHoliday(date, publicHolidayList);
        timeRecordModels.push(new ProjectTimeRecordModel({ date, holiday: publicHoliday }));
      }

      momentDate.add(1, 'days');
    }

    return new ObservableList<ProjectTimeRecordModel>(timeRecordModels, { observeMode: ObserveModes.FLAT });
  }

  private getProjectTimeRecords(timeRecord: TimeRecord): ProjectTimeRecordModel[] {
    const projectTimeRecords: ProjectTimeRecordModel[] = [];

    if (hasValue(timeRecord.from)) {
      projectTimeRecords.push(new ProjectTimeRecordModel({
        ...timeRecord,
        activity: ProjectTimeRecordActivities.COME,
        time: timeRecord.from,
        hours: null
      }));
    }
    if (hasValue(timeRecord.to)) {
      projectTimeRecords.push(new ProjectTimeRecordModel({
        ...timeRecord,
        activity: ProjectTimeRecordActivities.LEAVE,
        time: timeRecord.to
      }));
    }
    if (!hasValue(timeRecord.from) && !hasValue(timeRecord.to) && hasValue(timeRecord.hours)) {
      projectTimeRecords.push(new ProjectTimeRecordModel({
        ...timeRecord,
        activity: ProjectTimeRecordActivities.TOTAL_DAY
      }));
    }

    return projectTimeRecords;
  }

  public findPublicHoliday(date: string, publicHolidayList: PublicHoliday[]): PublicHoliday {
    return publicHolidayList && publicHolidayList.find((item) => item.date === date) || null;
  }

  public createPdfReport() {
    const project = this.viewModel.project?.name ?? this.viewModel.projectId;
    const year = this.viewModel.year;
    const month = this.viewModel.month;
    const documentName = this.textService.getText(`${TimeRecordingViewKey.PROJECT}/name`);
    const fileName = `${year}-${month} - ${project} - ${documentName}.pdf`;
    if (project && year && month) {
      this.reportService.createProjectReport(this.viewModel.projectId, year, month, fileName);
    }
  }

  // protected onPrintMediaQueryChanged(event: MediaQueryListEvent) {
  //   this.viewModel.printView = event.matches;
  // }

  private onRouteChanged(paramMap: ParamMap) {
    const projectId = paramMap.get('projectId');
    if (!hasValue(projectId) || projectId === '-') {
      this.viewModel.projectId = undefined;
    } else if (projectId === '~') {
      this.viewModel.projectId = null;
    } else {
      this.viewModel.projectId = projectId;
    }

    const currentTab = paramMap.get('currentTab') as 'time-recording' | 'management' | 'activities' | undefined;
    this.viewModel.currentTab = currentTab;
  }

  private async updateRoute() {
    let projectId = this.viewModel.projectId;
    if (projectId === undefined) {
      projectId = '-';
    } else if (projectId === null) {
      projectId = '~';
    }

    const currentTab = this.viewModel.currentTab ?? '-';

    const path = this.activatedRoute.pathFromRoot
      .map((p) => p.routeConfig?.path ?? '')
      .join('/')
      .replace(/(^|\/):([^\/]+)/g, (_, prefix, paramKey) => {
        const paramValue = paramKey === ProjectCommonViewFieldKey.PROJECT_ID
          ? projectId
          : paramKey === ProjectCommonViewFieldKey.CURRENT_TAB
            ? currentTab
            : this.activatedRoute.snapshot.paramMap.get(paramKey);

        return `${prefix}${paramValue}`;
      });

    try {
      await this.router.navigate([path], { queryParamsHandling: 'preserve' });
    } catch (error) {
      this.alertService.alertError(error);
    }
  }
}
