<input #inputElement class="form-control form-control-sm" [ngClass]="{ 'is-invalid': model.isValid === false }">
<div #optionPopperElement class="app-auto-complete-option-popper">
  <div *ngIf="visibleOptions" class="app-auto-complete-option-list">
    <div *ngFor="let option of visibleOptions; let index = index" class="app-auto-complete-option"
         [class.app-selected]="index === selectedOptionIndex"
         (mousedown)="onOptionMousedown($event, option.index)">
      <span class="app-auto-complete-option-prefix">{{option.prefix || ''}}</span>
      <strong class="app-auto-complete-option-match">{{option.match || ''}}</strong>
      <span class="app-auto-complete-option-suffix">{{option.suffix || ''}}</span>
    </div>
  </div>
</div>
