import { Entity, EntityData, EntityFieldKey, EntityModel } from '@typescript-kit/data';

export class LogbookEntryFieldKey extends EntityFieldKey {
  static readonly COMPANY_ID = 'companyId';
  static readonly EMPLOYEE_ID = 'employeeId';
  static readonly DATE = 'date';
  static readonly INDEX = 'index';
  static readonly KILOMETER = 'kilometer';
  static readonly COST_RATE = 'costRate';
  static readonly COMMENT = 'comment';
}

export interface LogbookEntryData extends EntityData {
  companyId?: string;
  employeeId?: string;
  date?: string;
  index?: number;
  kilometer?: number;
  costRate?: number;
  comment?: string;
}

export class LogbookEntry extends Entity implements LogbookEntryData {
  readonly companyId: string;
  readonly employeeId: string;
  readonly date: string;
  readonly index: number;
  readonly kilometer: number;
  readonly costRate: number;
  readonly comment: string;

  constructor(data: LogbookEntryData) {
    super(data);
    this.companyId = data.companyId;
    this.employeeId = data.employeeId;
    this.date = data.date;
    this.index = data.index;
    this.kilometer = data.kilometer;
    this.costRate = data.costRate;
    this.comment = data.comment;
  }
}

export class LogbookEntryModel extends EntityModel implements LogbookEntryData {
  static readonly KEYS = EntityModel.KEYS.concat(
    Object.keys(LogbookEntryFieldKey).map((key: string) => LogbookEntryFieldKey[key])
  );

  constructor(data: LogbookEntryData) {
    super(data, LogbookEntryModel.KEYS);
  }

  get employeeId(): string {
    return this.get(LogbookEntryFieldKey.EMPLOYEE_ID);
  }

  get date(): string {
    return this.get(LogbookEntryFieldKey.DATE);
  }

  set date(value: string) {
    this.set(LogbookEntryFieldKey.DATE, value);
  }

  get index(): number {
    return this.get(LogbookEntryFieldKey.INDEX);
  }

  get kilometer(): number {
    return this.get(LogbookEntryFieldKey.KILOMETER);
  }

  set kilometer(value: number) {
    this.set(LogbookEntryFieldKey.KILOMETER, value);
  }

  get costRate(): number {
    return this.get(LogbookEntryFieldKey.COST_RATE);
  }

  set costRate(value: number) {
    this.set(LogbookEntryFieldKey.COST_RATE, value);
  }

  get comment(): string {
    return this.get(LogbookEntryFieldKey.COMMENT);
  }

  set comment(value: string) {
    this.set(LogbookEntryFieldKey.COMMENT, value);
  }
}
