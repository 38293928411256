import { Injectable } from '@angular/core';
import { HttpService } from '@angular-kit/core';
import { DataQuery } from '@typescript-kit/data';
import { User, UserData } from '@typescript-kit/authentication';
import { Project, ProjectData, ProjectPermissionDto } from '@teamworks/global';

@Injectable({ providedIn: 'root' })
export class UserService {
  constructor(private httpService: HttpService) {
  }

  async loadUserList(dataQuery?: DataQuery): Promise<User[]> {
    return this.httpService.get(`api/user/list`, { 'data-query': dataQuery })
      .then((loadedData: UserData[]) => loadedData.map(data => new User(data)));
  }

  async loadUserAdminProjectList(dataQuery?: DataQuery): Promise<Project[]> {
    return this.httpService.get(`api/base-data/user-admin-project-list`, { 'data-query': dataQuery })
      .then((loadedData: ProjectData[]) => loadedData.map(data => new Project(data)));
  }

  async loadUser(id: string, dataQuery?: DataQuery): Promise<User> {
    return this.httpService.get(`api/user/${id}`, { 'data-query': dataQuery })
      .then((loadedData: UserData) => loadedData && new User(loadedData));
  }

  async saveUser(data: UserData): Promise<User> {
    return this.httpService.post(`api/user`, data)
      .then((savedData: UserData) => new User(savedData));
  }

  async deleteUser(id: string): Promise<User> {
    return this.httpService.delete(`api/user/${id}`)
      .then((deletedData: UserData) => new User(deletedData));
  }

  async updatePassword(id: string, data: { password: string; }): Promise<void> {
    return this.httpService.post(`api/user/${id}/password`, data);
  }

  async confirmUser(id: string, data: { password: string; }): Promise<void> {
    return this.httpService.post(`api/user/${id}/confirmUser`, data);
  }

  async sendPasswordEmail(id: string): Promise<void> {
    return this.httpService.post(`api/user/${id}/send-invitation-email`);
  }

  async getPermissions(id: string): Promise<string[]> {
    return this.httpService.get(`api/user/${id}/permissions`);
  }

  async setPermissions(id: string, permissions: string[]): Promise<void> {
    return this.httpService.post(`api/user/${id}/permissions`, permissions);
  }

  async getEmployeePermissions(id: string): Promise<string[]> {
    return this.httpService.get(`api/user/${id}/employee-permissions`);
  }

  async setEmployeePermissions(id: string, employeePermissions: string[]): Promise<void> {
    return this.httpService.post(`api/user/${id}/employee-permissions`, employeePermissions);
  }

  async getProjectPermissions(id: string): Promise<ProjectPermissionDto> {
    return this.httpService.get(`api/user/${id}/project-permissions`);
  }

  async setProjectPermissions(id: string, projectPermissions: ProjectPermissionDto): Promise<void> {
    return this.httpService.post(`api/user/${id}/project-permissions`, projectPermissions);
  }
}
