import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Input,
  ViewChild
} from '@angular/core';
import { Subscription } from 'rxjs';
import { ChangedEvent } from '@typescript-kit/core';
import { ProjectAutoCompleteInputFieldKey, ProjectAutoCompleteInputModel } from '../../component-model/project-auto-complete-input.component-model';
import { getProjectTree, ProjectTree } from '@teamworks/global';
import { AutoCompleteInputComponent } from '../../../shared/component/auto-complete-input/auto-complete-input.component';

@Component({
  selector: 'app-project-auto-complete-input',
  template: `<app-auto-complete-input [model]="model"></app-auto-complete-input>`,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectAutoCompleteInputComponent {
  private _model: ProjectAutoCompleteInputModel;

  private modelPropertyChangedSubscription: Subscription;

  private projectOptions: ProjectTree = {};

  private autoCompleteInput?: AutoCompleteInputComponent;

  @ViewChild(AutoCompleteInputComponent)
  private set viewChild(v: AutoCompleteInputComponent) {
    if (this.autoCompleteInput) {
      this.autoCompleteInput.inputElement.removeEventListener('input', () => this.refreshOptionsOnInput());
    }

    this.autoCompleteInput = v;
    this.autoCompleteInput.inputElement.addEventListener('input', () => this.refreshOptionsOnInput());
  }

  @Input()
  set model(model: ProjectAutoCompleteInputModel) {
    if (this.model === model) {
      return;
    }

    if (this._model) {
      this.modelPropertyChangedSubscription.unsubscribe();
      this.modelPropertyChangedSubscription = null;
    }

    if (model) {
      model.autoselectAfterOptionSelect = false;
    }

    this._model = model;

    if (this._model) {
      this.projectOptions = getProjectTree(this._model.projects, 'name');
      this.modelPropertyChangedSubscription = this._model.changedEvent
        .subscribe((event) => this.onModelPropertyChanged(event));
    }

    this.refreshOptions();
  }

  get model(): ProjectAutoCompleteInputModel {
    return this._model;
  }

  private refreshOptionsOnInput(): void {
    setTimeout(() => this.refreshOptions(), 0);
  }

  private onModelPropertyChanged(event: ChangedEvent) {
    if (event.changes[ProjectAutoCompleteInputFieldKey.PROJECTS]) {
      this.projectOptions = getProjectTree(this.model.projects, 'name');
      this.refreshOptions();
    }
  }

  private refreshOptions(): void {
    this.model.setValues({ options: this.getOptions() });
  }

  private getOptions(): string[] {
    const value = this.autoCompleteInput?.inputElement.value;
    if (typeof value !== 'string') {
      return Object
        .keys(this.projectOptions)
        .filter((name) => name.trim());
    }

    const projectNames = value.split(':');
    projectNames.pop();

    let prefix = projectNames.join(':');
    if (prefix && !prefix.endsWith(':')) {
      prefix += ':';
    }

    let options = this.projectOptions;

    for (const name of projectNames) {
      if (!name.trim()) {
        return [];
      }

      options = options[name] ?? {};
    }

    return Object
      .keys(options)
      .filter((name) => name.trim())
      .map((childProjectName) => prefix + childProjectName);
  }
}
